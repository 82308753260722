import { POST_TAG } from '../../constants/endpoints';
import request from '../request';
import formatTagPost from './formatTagPost';

const addTagToPost = async ({ postId, tagId }) => {
  const {
    data: { tagPost },
  } = await request({
    method: 'put',
    url: POST_TAG({ postId, tagId }),
  });

  return formatTagPost(tagPost);
};

export default addTagToPost;
