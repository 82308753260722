import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const NoNotification = styled('div')`
  text-align: center;
  font-family: ${theme.font.family.workSans};
  color: ${theme.color.black};
  font-size: ${theme.font.size.large};
  line-height: ${theme.font.lineHeight.large};
  padding-top: 24px;
`;

export const Divider = styled('div')`
  border-top: 1px solid;
  border-color: ${theme.color.designSystem.blackOpacity12};
`;

const notificationsActionButtonsHeight = '60px';
export const NotificationActionButtonsContainer = styled('div')`
  position: sticky;
  bottom: 0;
  background: ${theme.color.white};
  height: ${notificationsActionButtonsHeight};
  display: flex;
  align-items: center;
  justify-content: right;
  box-shadow: ${theme.shadow.low};
`;

export const NotificationTypeFilterWrapper = styled('div')`
  background: ${theme.color.white};
  padding: 12px 28px;
`;

const notificationTypeFilterWrapperHeight = '58px';
export const NotificationsList = styled('div')`
  overflow-y: auto;
  height: calc(
    100% - ${notificationTypeFilterWrapperHeight} -
      ${notificationsActionButtonsHeight}
  );
`;

export const ActionButtons = styled('div')`
  display: flex;
  margin-right: ${theme.spacing.medium};
  gap: ${theme.spacing.small};
`;
