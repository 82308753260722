import PropTypes from 'prop-types';

export const LTIProviderType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  description: PropTypes.string.isRequired,
  publicUrl: PropTypes.string,
  createdBy: PropTypes.string,
  ltiVersion: PropTypes.string,
});

export const ReactElementType = PropTypes.elementType;

export const ChildRouteType = PropTypes.shape({});
