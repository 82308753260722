import { CLASSROOM_MODULE_GET_VERSION } from '../actions';
import { getVersion as apiGetVersion } from '../api';
import { AulaReduxAction } from '../../types/state';
import { getCurrentSpaceId } from '../../selectors/space';
import { ActionStatus } from '../../constants/actionsStatus';
import { Version } from '../types';

const getVersionAction = (status: ActionStatus, payload?: unknown) => ({
  type: CLASSROOM_MODULE_GET_VERSION,
  status,
  payload,
});

type GetVersionActionFactory = (_: {
  sectionId: string;
  versionId: string;
}) => AulaReduxAction;

const getVersion: GetVersionActionFactory =
  ({ sectionId, versionId }) =>
  async (dispatch, getState) => {
    const state = getState();
    const classRoomId = getCurrentSpaceId(state);

    dispatch(getVersionAction('started', { versionId }));

    if (!sectionId || !classRoomId || !versionId) {
      dispatch(getVersionAction('aborted'));
      return;
    }

    try {
      const version: Version = await apiGetVersion(
        classRoomId,
        sectionId,
        versionId
      );
      dispatch(getVersionAction('success', { content: version }));
    } catch (error) {
      // This means the request was cancelled by selecting a new version.
      if (error.message !== 'Newer request.') {
        dispatch(getVersionAction('error', error));
      }
    }
  };

export default getVersion;
