import { GROUP_GROUP } from '../../constants/endpoints';
import request from './request';

const deleteGroup = async (accessToken, groupId) => {
  const {
    data: { studentsWithNoGroup },
  } = await request(accessToken, {
    method: 'delete',
    url: GROUP_GROUP(groupId),
  });

  return { studentsWithNoGroup };
};

export default deleteGroup;
