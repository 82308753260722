import { push } from 'react-router-redux';

let store = null;

export const setStore = (newStore) => {
  store = newStore;
};

const pushRoute = (route) => {
  if (store) {
    store.dispatch(push(route));
  }
};

export default pushRoute;
