import { upload } from '@ublend-npm/multipart-uploader';
import { FILE_UPLOADER_V2 } from '../constants/endpoints';

const uploadAvatar = async ({ file }): Promise<string> => {
  const response = await upload({
    file,
    uploadUrl: FILE_UPLOADER_V2(),
    storage: 'avatar',
  });
  return response.fileUrl;
};

export default uploadAvatar;
