import { GROUPS_LOADED } from '../actions';

const groupsLoaded = (id) => (dispatch) => {
  dispatch({
    type: GROUPS_LOADED,
    id,
  });
};

export default groupsLoaded;
