import { createSelector, defaultMemoize } from 'reselect';
import { getHoursBetweenDates } from '../utils/dates';
import { AulaState } from '../types/state';

/** @deprecated Use getSpace instead. */
export const classRoom = (state: AulaState) => state.classRoom;

export const spacePosts = (state: AulaState) => state.data.posts;
export const isUpdatingPostInSpace = (state: AulaState) =>
  state.data.isUpdatingPost;
export const isUpdatingPostIdInSpace = (state: AulaState) => (postId: string) =>
  state.data.postIdsUpdating.includes(postId);

/** @deprecated Use getAllSpaces instead. */
export const classRooms = (state: AulaState) => state.data.classRooms;
export const classRoomUsers = (state: AulaState) => state.data.classRoomUsers;

/** @deprecated Use getCurrentSpaceId instead. */
export const currentClassRoomId = (state: AulaState) =>
  classRoom(state).current;

/** @deprecated Use getCurrentSpace instead. */
export const currentClassRoom = (state: AulaState) =>
  classRooms(state)[currentClassRoomId(state)];

export const isAddingParticipantToSpace = (state: AulaState) =>
  state.classRoom.isAddingParticipantToSpace;

export const hasAddParticipantError = (state: AulaState) =>
  state.classRoom.hasAddParticipantError;

/** @deprecated Use getCurrentSpaceName instead. */
export const classRoomName = (state: AulaState) => {
  const detail = classRooms(state)[currentClassRoomId(state)];
  return detail && detail.name;
};

export const currentSpaceSelector = defaultMemoize((state: AulaState) =>
  currentClassRoom(state)
);

export const instructorUsers = (state: AulaState) => {
  const classRoomData = classRooms(state)[classRoom(state).current];
  if (classRoomData === undefined) {
    return [];
  }

  const { instructors } = classRoomData;
  return instructors;
};

export const isInstructorInSpace =
  (state: AulaState) => (userId: string | null, spaceId: string) => {
    const space = state.data.classRooms && state.data.classRooms[spaceId];
    if (space && userId) {
      return space.instructors.includes(userId);
    }

    return false;
  };

export const accessingSpaceAsStaff =
  (state: AulaState) => (spaceId: string) => {
    const space =
      spaceId && state.data.classRooms && state.data.classRooms[spaceId];
    if (space) {
      return !!space.isStaff;
    }

    return false;
  };

export const accessingCurrentSpaceAsStaff = (state: AulaState) => {
  const classRoomId = currentClassRoomId(state);
  return accessingSpaceAsStaff(state)(classRoomId);
};

export const isInstructor = (state: AulaState, userId?: string | null) => {
  const classRoomId = currentClassRoomId(state);
  const userToFind =
    typeof userId === 'string' && userId.length > 0
      ? userId
      : state.user.userId;
  return isInstructorInSpace(state)(userToFind, classRoomId);
};

export const isChangingAvatar = (state: AulaState) =>
  classRoom(state).isChangingAvatar;

export const participantIds = (state: AulaState, classRoomId: string) => {
  const participants = Object.values(classRoomUsers(state))
    .filter(
      (classRoomUser) => !classRoomId || classRoomUser.classRoom === classRoomId
    )
    .map((classRoomUser) => classRoomUser.user);
  const participantSet = new Set(participants);
  return Array.from(participantSet);
};

export const participants = (state: AulaState, classRoomId?: string) => {
  if (!classRoomId) {
    return [];
  }
  const space = state.data.classRooms[classRoomId];
  return space ? space.participants : [];
};

export const getIsStudent = (state: AulaState, userId?: string) => {
  return !accessingCurrentSpaceAsStaff(state) && !isInstructor(state, userId);
};

export const studentCountSelector = createSelector(
  [currentSpaceSelector],
  (space) => (space || {}).studentCount || 0
);

export const numberOfSpacesSelector = createSelector(
  [classRooms],
  (classRoomObjects) => classRoomObjects && Object.keys(classRoomObjects).length
);

export const participantCount = createSelector(
  [(state: AulaState) => studentCountSelector(state), instructorUsers],
  (studentCount, instructors) => studentCount + instructors.length
);

export const participantsHistory = (state: AulaState, classRoomId?: string) => {
  if (!classRoomId) {
    return {};
  }

  const space = state.data.classRooms[classRoomId];
  return space ? space.participantsHistory : {};
};

export const participantsHistoryCount = (
  state: AulaState,
  classRoomId?: string
) => {
  if (!classRoomId) {
    return {};
  }

  const space = state.data.classRooms[classRoomId];
  return space ? space.participantsHistoryCount : 0;
};

export const classifyParticipants = (
  state: AulaState,
  spaceParticipants: { id: string }[]
) => {
  const result: { students: string[]; educators: string[] } = {
    students: [],
    educators: [],
  };
  spaceParticipants.forEach((participant) => {
    if (isInstructor(state, participant.id)) {
      result.educators.push(participant.id);
    } else {
      result.students.push(participant.id);
    }
  });
  return result;
};

export const postExists = (state: AulaState, postId: string) =>
  !!state.data.posts[postId];

// POSTS SELECTORS
// These selectors are not fully optimised to avoid all re-renders as we are working mostly with arrays.
// If there are performance issues going forward, these can be altered.
export const postsSelector = createSelector(
  [spacePosts, currentSpaceSelector],
  (postsObj, space) => (space ? space.posts.map((id) => postsObj[id]) : [])
);

export const importantPostsSelector = createSelector(
  [currentClassRoom, spacePosts],
  (space, posts) => {
    if (!space) {
      return [];
    }

    return space.importantPosts.map((id) => posts[id]);
  }
);

export const importantPostsCountSelector = createSelector(
  [currentSpaceSelector],
  (space) => (space ? space.importantPostCount : 0)
);

// Recent important posts are important posts created in the last 72 hours
export const recentImportantPostsSelector = createSelector(
  [importantPostsSelector],
  (importantPosts) =>
    importantPosts.filter((post) => {
      const ageInHours = getHoursBetweenDates({
        startDate: post.createdAt,
        endDate: new Date(),
      });

      return ageInHours >= 0 && ageInHours <= 72;
    })
);

export const recentImportantPostsCountSelector = createSelector(
  [recentImportantPostsSelector],
  (posts) => posts.length
);

// Recent important posts are pinned at the top of the feed. The main feed should exclude them to avoid duplication
export const commonRoomPostsExcludingRecentImportantSelector = createSelector(
  [postsSelector, recentImportantPostsSelector],
  (posts, recentImportantPosts) =>
    posts.filter(
      (post) =>
        !recentImportantPosts.some(
          (importantPost) => importantPost.objectId === post.objectId
        )
    )
);

export const shouldShowInitialDataLoadingOverlay = (state: AulaState) => {
  const {
    ui: {
      loader: { isLoading },
    },
  } = state;

  const open =
    isLoading ||
    state.classRoom.isFetchingInstructors ||
    state.classRoom.isChangingClassRoom;

  return open;
};

export const announcementsSelector = createSelector(
  [spacePosts, currentSpaceSelector],
  (announcementsObj, space) =>
    space ? space.announcements.map((id) => announcementsObj[id]) : []
);

export const selectedFeedType = (state: AulaState) => classRoom(state).feedType;

export const hasFinishedFetchingPostsSelector = ({
  classRoom: {
    hasFinishedFetchingAnnouncementContent,
    hasFinishedFetchingCommonRoomContent,
    isChangingClassRoom,
  },
}: AulaState) =>
  !isChangingClassRoom &&
  hasFinishedFetchingAnnouncementContent &&
  hasFinishedFetchingCommonRoomContent;
