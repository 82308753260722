/* eslint-disable max-len */
import * as api from '../api/directMessages';
import { INBOX_ADD_PARTICIPANTS_TO_CONVERSATION } from '../actions';
import * as inboxSelectors from '../selectors';

const addParticipantsStarted = (payload) => ({
  type: INBOX_ADD_PARTICIPANTS_TO_CONVERSATION,
  status: 'started',
  payload,
});

const addParticipantsSuccess = (payload) => ({
  type: INBOX_ADD_PARTICIPANTS_TO_CONVERSATION,
  status: 'success',
  payload,
});

const addParticipantsError = (payload) => ({
  type: INBOX_ADD_PARTICIPANTS_TO_CONVERSATION,
  status: 'error',
  payload,
});

const addParticipant = (conversationId) => async (participantId) => {
  const participant = await api.conversations.postParticipant(
    conversationId,
    participantId
  );
  return { [participantId]: participant };
};

// eslint-disable-next-line no-unused-vars
const addParticipants = (participantIds) => async (dispatch, getState) => {
  const state = getState();
  const conversationId = inboxSelectors.currentConversationId(state);

  const payload = {
    conversationId,
    requestedParticipantIds: participantIds,
  };
  dispatch(addParticipantsStarted(payload));
  try {
    const addParticipantPromise = addParticipant(conversationId);
    const participants = await Promise.all(
      participantIds.map(addParticipantPromise)
    );
    dispatch(addParticipantsSuccess({ ...payload, participants }));
  } catch (error) {
    dispatch(addParticipantsError({ ...payload, error }));
  }
};

export default addParticipants;
