import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@ublend-npm/aulaui-next';
import Dropzone from '../../../../containers/common/Dropzone';

import {
  Container,
  UserName,
  Email,
  ProfileUpdateContainer,
  ImageUpload,
} from './ProfileUpdate.styled';

export default class ProfileUpdate extends PureComponent {
  constructor(props) {
    super(props);

    this.handleChangeAvatar = this.handleChangeAvatar.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.dropZone = React.createRef();
  }

  handleChangeAvatar() {
    this.dropZone.current.open();
  }

  handleDrop(files) {
    if (window.location.href.indexOf('?inbox=') > -1) {
      return false;
    }
    const { changeAvatar } = this.props;

    return changeAvatar(files[0]);
  }

  render() {
    const {
      user: { firstName, lastName, email, avatar },
    } = this.props;

    return (
      <Container>
        <Dropzone
          ref={this.dropZone}
          multiple={false}
          accept="image/*"
          onDropAccepted={this.handleDrop}
        />
        <ProfileUpdateContainer>
          <Avatar
            attributes={{
              container: {
                tabIndex: '0',
                role: 'button',
                'aria-label': 'Upload Image',
              },
            }}
            size="xLarge"
            className={ImageUpload}
            name={firstName}
            src={avatar}
            events={{
              onClick: this.handleChangeAvatar,
              onKeyPress: (e) => {
                if (e.key === 'Enter') this.handleChangeAvatar();
              },
            }}
          />
          <UserName>{`${firstName} ${lastName}`}</UserName>
          <Email>{email}</Email>
        </ProfileUpdateContainer>
      </Container>
    );
  }
}

ProfileUpdate.propTypes = {
  changeAvatar: PropTypes.func.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    avatar: PropTypes.string,
    email: PropTypes.string.isRequired,
  }).isRequired,
};
