import React from 'react';
import styled, { css } from 'react-emotion';
import { theme, styleUtils } from '@ublend-npm/aulaui-next';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CloudUpload from '@material-ui/icons/CloudUpload';

export const Content = styled(({ uploadEnable, ...props }) => (
  <ListItemIcon {...props} />
))`
  display: flex;
  align-items: center;
  margin-right: 0;
  min-width: 0;
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
`;

export const inputBoxCss = css`
  width: 0;
  height: 0;
  position: absolute;
  marginleft: -90px;
`;

export const Label = styled('label')`
  ${styleUtils.visuallyHidden()};
`;

export const UploadIcon = styled(CloudUpload)`
  font-size: 20px;
  border-radius: 4px;
  color: ${theme.color.grayLight1};
  background-color: ${theme.color.beige};
  padding: 9px;
`;

export const LoaderContainer = styled('div')`
  border-radius: 4px;
  background-color: ${theme.color.beige};
  padding: 9px;
`;
