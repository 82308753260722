import { EditorState } from '@ublend-npm/aula-editor';
import {
  CLASSROOM_MODULE_CREATE_SECTION,
  CLASSROOM_MODULE_GET_SECTION,
} from '../actions';
import * as userSelectors from '../../selectors/user';
import { AulaReduxAction, AulaThunkAction } from '../../types/state';
import { LocalSection } from '../types';
import { getCurrentSpaceId } from '../../selectors/space';
import { generateLocalOrder } from '../utils';
import { topLevelMaterials } from '../selectors';
import { generateLocalId } from '../../utils/localId';

type CreateSectionActionFactory = (_: {
  content: EditorState;
  parent?: string;
  educatorOnly: boolean;
  hidden?: boolean;
  onSelect: (id: string, educatorOnly: boolean) => AulaThunkAction<void>;
}) => AulaReduxAction;

const createSection: CreateSectionActionFactory =
  ({ content, parent, educatorOnly, hidden = true, onSelect }) =>
  async (dispatch, getState) => {
    const state = getState();
    const user = userSelectors.currentUserSlim(state);

    if (!user) {
      return;
    }

    const classRoom = getCurrentSpaceId(state);

    const id = generateLocalId();

    const section: LocalSection = {
      id,
      title: '',
      hidden,
      updatedAt: new Date().toISOString(),
      writer: user,
      space: classRoom,
      parent: parent || null,
      educatorOnly: educatorOnly || false,
      order: generateLocalOrder(topLevelMaterials(state)),
      scheduledFor: null,
      showDiscussion: false,
      discussionTopic: null,
      version: null,
    };

    dispatch({
      type: CLASSROOM_MODULE_CREATE_SECTION,
      payload: {
        section,
      },
    });

    dispatch({
      type: CLASSROOM_MODULE_GET_SECTION,
      status: 'success',
      payload: {
        content,
      },
    });

    dispatch(onSelect(id, educatorOnly));
  };

export default createSection;
