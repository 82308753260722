/* eslint-disable no-console,import/prefer-default-export */
// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';

import { newError } from '../errors';
import * as actionTypes from '../../constants/actions';
import * as analyticsTypes from '../../constants/analytics';
import * as endpoints from '../../constants/endpoints';

import { fetchedUser } from '../user';
import { fetchJoinedClassRoomsSuccess } from '../classRoom';
import { fetchNotificationSettingsSuccess } from '../../notifications/operations/fetchNotificationSettings.operation';
import formatNotificationSettings from '../../notifications/api/formatSettings';
import { fetchNotifications } from '../../notifications/actions';
import { socketConnect } from '../socket';
import {
  analyticsChangeUser,
  analyticsTrackEvent,
} from '../../utils/analytics';

const fetchEmojisSuccess = (emojis, dispatch) => {
  dispatch({
    type: actionTypes.FETCH_EMOJIS,
    status: 'success',
    emojis,
  });
};

// Fetch startup data
const fetchStartupDataStart = () => ({
  type: actionTypes.STARTUP_FETCH_DATA,
  status: 'started',
});

const fetchStartupDataSuccess = () => ({
  type: actionTypes.STARTUP_FETCH_DATA,
  status: 'success',
});

const fetchStartupDataError = (error, dispatch) => {
  dispatch(newError(error, 'fetchStartupData'));
  dispatch({
    type: actionTypes.STARTUP_FETCH_DATA,
    status: 'error',
    error,
  });
};

export const fetchStartupData =
  ({
    includeClosedConversations,
    conversations: {
      operations: { fetchConversations },
    },
    live,
    notifications: { shouldFetch = true, unreadOnly = false },
  }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      if (!getState().user.isLogged) {
        const err = { message: 'You need to login first' };
        return fetchStartupDataError(err, dispatch);
      }

      // Starting the fetch process
      dispatch(fetchStartupDataStart());
      // Only fetch notifications if notifications tab is not open
      // If the tab is open it will fetch all notifications by itself.
      if (shouldFetch) {
        dispatch(
          fetchNotifications({ unreadOnly, showNotificationsBadge: true })
        );
      }

      analyticsChangeUser(getState);
      analyticsTrackEvent(analyticsTypes.APP_LAUNCH, { activeClassroom: null });

      return axios
        .get(endpoints.STARTUP_DATA(), {
          withCredentials: true,
        })
        .then((response) => {
          if (response.status === 200) {
            const { data } = response.data;
            // Send necessary actions for the retrieved data
            fetchEmojisSuccess(data.emojis, dispatch);
            fetchNotificationSettingsSuccess(
              dispatch,
              formatNotificationSettings(data.notificationSettings)
            );

            fetchJoinedClassRoomsSuccess(data.classRooms, dispatch);

            dispatch(fetchedUser(data.user));
            dispatch(socketConnect(live));

            dispatch(fetchConversations(includeClosedConversations));

            dispatch(fetchStartupDataSuccess());

            resolve();
          }
          return 'Success';
        })
        .catch((error) => {
          console.log(error);
          fetchStartupDataError(error, dispatch);
          reject();
          return '';
        });
    });
