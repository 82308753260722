import {
  addUpdatingId,
  removeUpdatingId,
  computeNewImportantPosts,
  computeNewImportantPostCount,
} from '../../utils/posts';

const _updatePostImportance = ({ state, importantPost }) => {
  const spaceId = importantPost.classRoom;
  const postId = importantPost.objectId;

  const newPost = importantPost;
  const statePosts = {
    ...state.posts,
    [postId]: newPost,
  };
  const currentImportantPostCount =
    state.classRooms[spaceId].importantPostCount;
  const currentImportantPosts = state.classRooms[spaceId].importantPosts;
  const importantPosts = computeNewImportantPosts({
    posts: state.posts,
    newPost,
    importantPosts: currentImportantPosts,
  });

  const stateClassRooms = {
    ...state.classRooms,
    [spaceId]: {
      ...state.classRooms[spaceId],
      importantPostCount: computeNewImportantPostCount({
        posts: state.posts,
        newPost,
        importantPostCount: currentImportantPostCount,
      }),
    },
  };

  if (importantPosts) {
    stateClassRooms[spaceId].importantPosts = importantPosts;
  }

  return {
    ...state,
    posts: statePosts,
    classRooms: stateClassRooms,
    isUpdatingPost: false,
    postIdsUpdating: removeUpdatingId({ state, id: postId }),
  };
};

export const fetchClassRoomImportantPostsReducer = (state, action) => {
  switch (action.status) {
    case 'success': {
      const actionImportantPosts = action.importantPosts;
      const importantPostIds = [];
      const statePosts = { ...state.posts };
      actionImportantPosts.forEach((post) => {
        importantPostIds.push(post.objectId);

        if (!statePosts[post.objectId]) {
          statePosts[post.objectId] = {
            ...post,
            comments: [],
            tagPosts: [],
          };
        }
      });

      const classRooms = { ...state.classRooms };
      if (actionImportantPosts.length > 0) {
        const classRoomId = actionImportantPosts[0].classRoom;
        const classRoom = classRooms[classRoomId];

        const classRoomImportantPosts = [
          ...new Set([
            ...(classRoom.importantPosts || []),
            ...importantPostIds,
          ]),
        ];

        classRooms[classRoomId] = {
          ...classRoom,
          importantPosts: classRoomImportantPosts,
        };
      }
      return {
        ...state,
        posts: statePosts,
        classRooms,
      };
    }
    case 'error':
      return {
        ...state,
      };

    default:
      return state;
  }
};

export const setPostImportantReducer = (state, action) => {
  switch (action.status) {
    case 'started':
      return {
        ...state,
        isUpdatingPost: true,
        postIdsUpdating: addUpdatingId({ state, id: action.id }),
      };
    case 'success': {
      return _updatePostImportance({
        state,
        importantPost: {
          important: true,
          ...action.importantPost,
        },
      });
    }
    case 'error':
      return {
        ...state,
        isUpdatingPost: false,
        postIdsUpdating: removeUpdatingId({ state, id: action.id }),
      };

    default:
      return {
        ...state,
        isUpdatingPost: false,
      };
  }
};

export const unsetPostImportantReducer = (state, action) => {
  switch (action.status) {
    case 'started':
      return {
        ...state,
        isUpdatingPost: true,
        postIdsUpdating: addUpdatingId({ state, id: action.id }),
      };
    case 'success': {
      return _updatePostImportance({
        state,
        importantPost: {
          important: false,
          ...action.importantPost,
        },
      });
    }
    case 'error':
      return {
        ...state,
        isUpdatingPost: false,
        postIdsUpdating: removeUpdatingId({ state, id: action.id }),
      };

    default:
      return {
        ...state,
        isUpdatingPost: false,
      };
  }
};
