import PropTypes from 'prop-types';
import React from 'react';
import { Snackbar } from '@ublend-npm/aulaui-next';

class ErrorBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      forceClosed: false,
    };

    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.hideBar = this.hideBar.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error && nextProps.error.message && nextProps.error.visible) {
      this.setState({ forceClosed: false });
      this.hideBar();
    }
  }

  hideBar() {
    this.setState({ forceClosed: false });
    setTimeout(this.props.cleanError, 3000);
  }

  handleRequestClose() {
    this.setState({ forceClosed: true });
  }

  render() {
    const excludedFrom = ['login'];
    const shouldOpen = () =>
      !this.state.forceClosed &&
      this.props.error.visible &&
      !(excludedFrom.indexOf(this.props.error.from) >= 0);

    const containerStyle = {
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      position: 'absolute',
      width: shouldOpen() ? '100vw' : 0,
      height: shouldOpen() ? '100vh' : 0,
      top: 0,
      zIndex: shouldOpen() ? 9999 : 0,
      opacity: shouldOpen() ? 1 : 0,
    };

    return (
      <div style={containerStyle}>
        <Snackbar
          onClose={this.handleRequestClose}
          open={shouldOpen()}
          message={this.props.error.message || ''}
        />
      </div>
    );
  }
}

ErrorBar.propTypes = {
  error: PropTypes.object,
  cleanError: PropTypes.func,
};

export default ErrorBar;
