import { REMOVE_REACTION } from '../actions';

const onReactionRemoved = (payload) => async (dispatch) =>
  dispatch({
    type: REMOVE_REACTION,
    status: 'success',
    payload,
  });

export default onReactionRemoved;
