const getCategories = (spaces) =>
  spaces
    .filter((space) => space.categories.length > 0)
    .map((space) => space.categories[0])
    .filter(
      (category, index, categories) =>
        // filter for unique values by returning only the first occurence of each category
        categories.findIndex((c) => c.label === category.label) === index
    )
    .sort((a, b) => a.order - b.order);

export default getCategories;
