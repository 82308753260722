import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const Container = styled('div')`
  display: flex;
  padding: 16px 28px;
  gap: ${theme.spacing.medium};
  cursor: pointer;
  background-color: ${({ unread, hover }) => {
    if (unread && hover)
      return theme.color.designSystem.background.lightPurple.hoverAlt;
    if (unread) return theme.color.designSystem.purple10;
    if (hover) return theme.color.designSystem.background.grey.hover;
    return 'white';
  }};
`;

export const Divider = styled('div')`
  border-top: 1px solid;
  border-color: ${theme.color.designSystem.blackOpacity12};
`;

export const NotificationContainer = styled('div')`
  flex: 1;
  display: flex;
  gap: ${theme.spacing.medium};
`;

export const AvatarContainer = styled('div')`
  flex: 0;
`;

export const InfoContainer = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const NewBadgeContainer = styled('div')`
  flex: 0;
`;

export const NewBadgeButton = styled('button')`
  display: flex;
  padding: 4px;
  height: 24px;
  width: 24px;
  cursor: pointer;
  border: 0;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: transparent;

  &:hover {
    background-color: ${theme.color.designSystem.blackOpacity12};
  }
`;

export const NewBadge = styled('div')`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${theme.color.red};
`;

export const EducatorBadge = styled('span')`
  color: ${theme.color.pink};
`;

export const HeaderLabel = styled('div')`
  font-size: ${theme.font.size.copy};
  line-height: ${theme.font.lineHeight.copy};
`;

export const ContentLabel = styled('div')`
  max-width: 420px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: ${theme.font.size.copy};
  line-height: ${theme.font.lineHeight.copy};
  color: ${theme.color.designSystem.blackOpacity60};
`;

export const FooterLabel = styled('div')`
  height: ${theme.font.lineHeight.small};
  font-size: ${theme.font.size.small};
  line-height: ${theme.font.lineHeight.small};
  color: ${theme.color.designSystem.blackOpacity60};
`;

export const SpaceSpan = styled('span')`
  font-weight: ${theme.font.weight.semiBold};
`;
