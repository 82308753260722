import axios from 'axios';
import { getHeaders } from './utils';
import { SUBMISSIONS } from '../../constants/endpoints';

let requestSource;

const fetchSubmissions = ({ accessToken, assignmentId, group }) => {
  if (requestSource) {
    requestSource.cancel();
  }

  requestSource = axios.CancelToken.source();

  return axios.get(
    `${SUBMISSIONS(assignmentId)}?page=0&displayLimit=2000&fetchCount=1${
      group ? `&group=${group}` : ''
    }`,
    {
      headers: getHeaders(accessToken),
      cancelToken: requestSource.token,
    }
  );
};

export default fetchSubmissions;
