import React from 'react';

import createSvgIcon from './hoc/createSvgIcon';

export default createSvgIcon({
  svg: () => (
    <>
      <path d="M22.653 17.434c0.293-0.043 0.497 0.132 0.491 0.444-0.009 0.515-0.069 1.027-0.186 1.529-0.335 1.454-1.015 2.73-2.059 3.797-1.213 1.241-2.679 2.003-4.413 2.228-0.286 0.037-0.571 0.038-0.996 0.053-1.787-0.020-3.498-0.629-4.961-1.888-1.336-1.15-2.169-2.602-2.532-4.323-0.092-0.441-0.14-0.89-0.151-1.341-0.010-0.318 0.125-0.49 0.391-0.507l14.416 0.008z" />
      <path d="M30.885 6.042h-4.047v-4.045h-1.618v4.045h-4.048v1.617h4.048v4.043h1.618v-4.043h4.047z" />
      <path d="M13.665 13.512c0 1.060-0.861 1.922-1.925 1.922-1.061 0-1.923-0.861-1.923-1.922 0-1.062 0.863-1.923 1.923-1.923 1.063-0 1.925 0.861 1.925 1.923z" />
      <path d="M21.172 13.512c0 1.060-0.86 1.922-1.923 1.922s-1.925-0.861-1.925-1.922c0-1.062 0.861-1.923 1.925-1.923s1.923 0.861 1.923 1.923z" />
      <path d="M26.795 17.855c-0.062 6.183-5.097 11.177-11.3 11.177-6.241 0-11.302-5.057-11.302-11.292 0-6.236 5.061-11.293 11.302-11.293v-0.97c-6.778 0-12.272 5.492-12.272 12.264s5.494 12.263 12.272 12.263c6.74 0 12.208-5.43 12.271-12.148h-0.971z" />
    </>
  ),
  displayName: 'AddReaction',
  viewBox: '0 0 32 32',
});
