import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import PropTypes from 'prop-types';

const CreateSvgIcon = ({ svg: SvgComponent, displayName, viewBox }) => {
  // eslint-disable-next-line react/prop-types
  const Component = ({ svgProps, ...props }, ref) => (
    <SvgIcon
      data-testid={`${displayName}Icon`}
      viewBox={viewBox}
      ref={ref}
      {...props}
    >
      <SvgComponent {...svgProps} />
    </SvgIcon>
  );

  return React.memo(React.forwardRef(Component));
};

CreateSvgIcon.propTypes = {
  svg: PropTypes.node.isRequired,
  displayName: PropTypes.string.isRequired,
  viewBox: PropTypes.string.isRequired,
};

export default CreateSvgIcon;
