import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import logout from './logout';

export const onFullfilled = (response: AxiosResponse) =>
  // Interceptor handling a successful response
  response;

export type Response = {
  errors: { message: string }[];
};

export const onRejected = async (error: AxiosError<Response>) => {
  // Interceptor handling a failed response
  if (
    !error.config ||
    !error.response ||
    error.response?.status !== 401 ||
    error.response.data?.errors?.length
  ) {
    return Promise.reject(error);
  }

  const url = `${error.config?.baseURL}${error.config?.url}`;

  if (!url.includes('aula.education') || url.includes('/auth/logout')) {
    return Promise.reject(error);
  }

  await logout({ returnAfterLogin: true, makeLogoutApiCall: false });

  return Promise.reject(error);
};

export default (instance: AxiosInstance) => {
  (instance || axios).interceptors.response.use(onFullfilled, onRejected);
};
