import {
  FETCH_ASSIGNMENTS,
  CHANGE_ASSIGNMENTS_SELECTED_SPACE,
  CREATE_ASSIGNMENT,
  EDIT_ASSIGNMENT,
  DELETE_ASSIGNMENT,
  SEARCH_ASSIGNMENT,
  CLOSE_ASSIGNMENT,
  REOPEN_ASSIGNMENT,
  FETCH_SUBMISSIONS,
  DELETE_SUBMISSION,
  RESET_ERROR_STATE,
  CONVERT_ASSIGNMENT,
} from '../actions';
import fetchAssignments from './fetchAssignments.app.reducer';
import changeSpace from './changeSpace.app.reducer';
import createAssignment from './createAssignment.app.reducer';
import convertAssignment from './convertAssignment.app.reducer';
import editAssignment from './editAssignment.app.reducer';
import deleteAssignment from './deleteAssignment.app.reducer';
import searchAssignment from './searchAssignment.app.reducer';
import closeAssignment from './closeAssignment.app.reducer';
import reopenAssignment from './reopenAssignment.app.reducer';
import fetchSubmissions from './fetchSubmissions.app.reducer';
import deleteSubmission from './deleteSubmission.app.reducer';
import resetErrorState from './resetErrorState.app.reducer';

const inititialState = {
  isFetching: false,
  isCreating: false,
  isEditing: false,
  allSpaces: false,
  assignmentCreationError: null,
  assignmentUpdateError: null,
  hasFetchedAssignments: false,
  selectedSpace: '',
  query: '',
  submissions: {},
};

const reducers = {
  [FETCH_ASSIGNMENTS]: fetchAssignments,
  [CHANGE_ASSIGNMENTS_SELECTED_SPACE]: changeSpace,
  [CREATE_ASSIGNMENT]: createAssignment,
  [EDIT_ASSIGNMENT]: editAssignment,
  [DELETE_ASSIGNMENT]: deleteAssignment,
  [SEARCH_ASSIGNMENT]: searchAssignment,
  [CLOSE_ASSIGNMENT]: closeAssignment,
  [REOPEN_ASSIGNMENT]: reopenAssignment,
  [FETCH_SUBMISSIONS]: fetchSubmissions,
  [DELETE_SUBMISSION]: deleteSubmission,
  [RESET_ERROR_STATE]: resetErrorState,
  [CONVERT_ASSIGNMENT]: convertAssignment,
};

const reducer = (state = inititialState, action) => {
  if (!action) {
    return state;
  }
  const r = reducers[action.type];
  return r ? r(state, action) : state;
};

export default reducer;
