import { appendAndReorderMessages, getMessageById, unique } from './utils';

const byMessageIdReducer = (curr, message) => {
  // eslint-disable-next-line no-param-reassign
  curr[message.id] = message;
  return curr;
};

const fetchMessagesReducer = (state, action) => {
  const {
    payload: { conversationId, messages, replace },
    status,
  } = action;

  switch (status) {
    case 'started': {
      if (!replace) {
        return state;
      }

      const conversation = state.conversations[conversationId];
      const updatedMessages = { ...state.messages };
      conversation.messages.forEach((id) => delete updatedMessages[id]);
      const updatedConversation = {
        ...conversation,
        messages: [],
      };

      return {
        ...state,
        messages: updatedMessages,
        conversations: {
          ...state.conversations,
          [conversationId]: updatedConversation,
        },
      };
    }
    case 'success': {
      const conversation = state.conversations[conversationId];
      const currentMessages = (conversation.messages || []).map(
        getMessageById(state.messages)
      );
      const newMessages = messages.reduce(byMessageIdReducer, {});
      const updatedConversationMessages = appendAndReorderMessages(
        currentMessages,
        Object.values(newMessages)
      );
      const updatedMessagesIds = updatedConversationMessages.map((m) => m.id);
      const uniqueUpdatedMessageIds = unique(updatedMessagesIds);
      const newLatestMessage =
        updatedConversationMessages[updatedConversationMessages.length - 1];
      const updatedLatestMessage =
        conversation.latestMessage &&
        conversation.latestMessage.createdAt > newLatestMessage.createdAt
          ? conversation.latestMessage
          : newLatestMessage;

      const updatedConversation = {
        ...conversation,
        messages: uniqueUpdatedMessageIds,
        latestMessage: updatedLatestMessage,
      };

      const updatedMessages = {
        ...state.messages,
        ...newMessages,
      };

      return {
        ...state,
        conversations: {
          ...state.conversations,
          [conversationId]: updatedConversation,
        },
        messages: updatedMessages,
      };
    }
    default:
      return state;
  }
};

export default fetchMessagesReducer;
