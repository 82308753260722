const fetchConversationReducer = (state, action) => {
  if (action.status !== 'success') return state;

  const {
    payload: { conversationId, conversation },
  } = action;

  const conversationFromState = state.conversations[conversationId];

  return {
    ...state,
    conversations: {
      ...state.conversations,
      [conversationId]: {
        messages: [],
        ...conversationFromState,
        ...conversation,
      },
    },
  };
};

export default fetchConversationReducer;
