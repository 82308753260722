import * as actions from '../actionsTypes';
import * as NotificationType from '../../constants/notificationTypes';

import newNotificationReducer from './newNotification.app.reducer';
import fetchNotificationSettingsReducer from './fetchNotificationSettings.app.reducer';
import saveNotificationSettingsReducer from './saveNotificationSettings.app.reducer';
import changeNotificationTypeFilterReducer from './changeNotificationTypeFilter.app.reducer';

const initialState = {
  newNotification: null,
  isFetchingNotificationsSettings: false,
  isSavingNotificationsSettings: false,
  isErrored: false,
  isFetched: false,
  notificationTypeFilter: NotificationType.ALL,
};

const reducerMap = {
  [actions.NOTIFICATIONS_NEW_NOTIFICATION]: newNotificationReducer,
  [actions.NOTIFICATION_SETTINGS_FETCH]: fetchNotificationSettingsReducer,
  [actions.NOTIFICATION_SETTINGS_SAVE]: saveNotificationSettingsReducer,
  [actions.NOTIFICATION_TYPE_FILTER_CHANGE]:
    changeNotificationTypeFilterReducer,
};

export default (state = initialState, action) => {
  const reducer = reducerMap[action.type];
  if (typeof reducer === 'undefined') return state;
  return reducer(state, action);
};
