import { MaterialAppReducer } from './state';
import { CLASSROOM_MODULE_LEAVE_WITHOUT_SAVING } from '../actions';
import { GenericAction } from '../../types/redux';

type LeaveWithoutSavingAction = GenericAction<
  typeof CLASSROOM_MODULE_LEAVE_WITHOUT_SAVING,
  {
    keepEditing: boolean | null;
    destinationPath: string;
  }
>;

const leaveWithoutSaving: MaterialAppReducer<LeaveWithoutSavingAction> = (
  state,
  action
) => ({
  ...state,
  leaveDestinationPath: action.payload.destinationPath,
  editing:
    action.payload.keepEditing !== null
      ? action.payload.keepEditing
      : state.editing,
});

export default leaveWithoutSaving;
