import * as types from '../../constants/actions';

const initialState = {
  last: {
    message: undefined,
    from: undefined,
    error: undefined,
    visible: false,
  },
};

const buildLastErrorObject = (error, from) => {
  if (!error) {
    return { ...initialState };
  }

  const message = error.message || 'Sorry, trouble connecting to Aula';

  const last = {
    error: { ...error },
    message,
    from,
    visible: error.visible !== undefined ? error.visible : true,
  };

  return last;
};

const errorsReducer = (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case types.ERROR_NEW:
      return {
        ...state,
        last: buildLastErrorObject(action.error, action.from),
      };
    case types.ERROR_CLEAN:
      return {
        ...state,
        last: { ...initialState.last, visible: false },
      };

    default:
      return state;
  }
};

export default errorsReducer;
