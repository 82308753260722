import { TAGS } from '../../constants/endpoints';
import request from '../request';
import formatTag from './formatTag';

const createTag = async ({ spaceId, name }) => {
  const {
    data: { tag },
  } = await request({
    method: 'post',
    url: TAGS(spaceId),
    data: { name },
  });

  return formatTag(tag);
};

export default createTag;
