import PropTypes from 'prop-types';
import React from 'react';
import TabPanel from '../../../common/TabPanel/TabPanel';

import { tabType } from '../../Tabs';
import { Container } from './Navigation.styled';

const Navigation = ({ className, currentTab, onTabChange, tabs }) => (
  <Container className={className}>
    <TabPanel
      tabs={tabs}
      selectedTabIndex={currentTab}
      onTabClick={onTabChange}
      dense
      titlePosition={null}
    />
  </Container>
);

Navigation.propTypes = {
  currentTab: PropTypes.number.isRequired,
  onTabChange: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(tabType).isRequired,
  className: PropTypes.string,
};

export default Navigation;
