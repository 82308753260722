import { AulaReduxAction } from '../../types/state';
import { CLASSROOM_MODULE_SET_EDITING } from '../actions';

type SetEditingActionFactory = (editing: boolean) => AulaReduxAction<void>;

const setEditing: SetEditingActionFactory = (editing) => (dispatch) =>
  dispatch({
    type: CLASSROOM_MODULE_SET_EDITING,
    payload: {
      editing,
    },
  });

export default setEditing;
