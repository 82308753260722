import push from '../../utils/routing';
import { getAnnouncementsPath } from './routes';

export const goToAnnouncements =
  // eslint-disable-next-line no-undef
  (spaceId: string, queryParams?: object) => () =>
    push({
      pathname: getAnnouncementsPath(spaceId),
      ...(queryParams ? { query: queryParams } : {}),
    });
