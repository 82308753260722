import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar } from '@ublend-npm/aulaui-next';

const Toast = ({ open, onClose, emoji, message, autoHideDuration }) => (
  <Snackbar
    open={open}
    emoji={emoji}
    message={message}
    onClose={onClose}
    autoHideDuration={autoHideDuration}
  />
);

Toast.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  emoji: PropTypes.string,
  message: PropTypes.string.isRequired,
  autoHideDuration: PropTypes.number,
};

Toast.defaultProps = {
  emoji: '',
  autoHideDuration: 5000,
};

export default Toast;
