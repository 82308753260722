import Raven from './raven';
import { getStore } from '../store';
import redirectToGeneral from './redirectToGeneral';
import { logout as requestLogout } from '../../core/actions/user/logout';

const logout = async ({
  returnAfterLogin = false,
  makeLogoutApiCall = true,
}) => {
  const store = getStore();
  await store
    .dispatch(requestLogout(makeLogoutApiCall))
    .then((err: Error | string) => {
      if (err) {
        Raven.captureException(err, {
          tags: {
            context: 'logout',
          },
        });
      }

      localStorage.clear();

      redirectToGeneral({ returnAfterLogin });
    });
};

export default logout;
