import {
  UpdateFolderBody,
  UpdateFolderContract,
} from '@ublend-npm/aula-schema/types/materials';
import { CLASSROOM_MATERIAL_FOLDER } from '../../constants/endpoints';
import request, { getData } from './request';
import toFolder from './toFolder';

const patchFolder = async (
  classRoomId: string,
  folderId: string,
  patch: UpdateFolderBody
) => {
  const url = CLASSROOM_MATERIAL_FOLDER(classRoomId, folderId);
  const data = await request<UpdateFolderContract>({
    method: 'patch',
    url,
    data: { folder: patch },
  }).then(getData);
  return toFolder(data);
};

export default patchFolder;
