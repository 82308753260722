const fetchSubmissions = (state, action) => {
  const {
    payload: { assignmentId, group, totalSubmissions },
  } = action;
  const submission = {
    ...state.submissions[assignmentId],
  };
  switch (action.status) {
    case 'started':
      submission.fetching = true;
      submission.fetched = false;
      submission.errored = false;
      submission.byGroup = group;
      break;
    case 'success': {
      submission.fetching = false;
      submission.errored = false;
      submission.fetched = true;
      submission.totalSubmissions = totalSubmissions;
      break;
    }
    case 'error':
      submission.fetching = false;
      submission.errored = true;
      break;
    default:
      return state;
  }

  return {
    ...state,
    submissions: {
      ...state.submissions,
      [assignmentId]: submission,
    },
  };
};

export default fetchSubmissions;
