import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import Intercom, { IntercomAPI as intercomAPI } from 'react-intercom';
import Fade from '@material-ui/core/Fade';

import './Intercom.style.css';
import IntercomConfig from '../../../../core/config/intercom';
import styles from './style';

@Radium
class IntercomWrapper extends PureComponent {
  componentDidMount() {
    intercomAPI('onHide', () => {
      this.props.hide();
    });
    intercomAPI('onShow', () => {
      this.props.show();
    });
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.open) {
      intercomAPI('show');
    } else {
      intercomAPI('hide');
    }
    return nextProps;
  }

  render() {
    const { userId, toggle, open, userName, email, institution, role } =
      this.props;
    return (
      <div style={{ width: '100%', position: 'relative' }}>
        <Intercom
          appID={IntercomConfig.APPID}
          user_id={userId}
          email={email}
          name={userName}
          Institution={institution}
          Role={role}
          custom_launcher_selector="#no-selector--"
        />
        {
          open && (
            /* eslint-disable jsx-a11y/no-static-element-interactions */
            <Fade in={open} unmountOnExit>
              <div style={styles.overlay} onClick={toggle} />
            </Fade>
          )
          /* eslint-enable jsx-a11y/no-static-element-interactions */
        }
      </div>
    );
  }
}

IntercomWrapper.defaultProps = {
  open: false,
  toggle: () => {},
  hide: () => {},
  show: () => {},
};

IntercomWrapper.propTypes = {
  userId: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  institution: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  toggle: PropTypes.func,
  hide: PropTypes.func,
  show: PropTypes.func,
  open: PropTypes.bool,
};

export default IntercomWrapper;
