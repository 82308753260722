import { INBOX_MESSAGE } from '../../../constants/endpoints';
import request from './request';

export default async ({ conversationId, messageId, content }) => {
  const url = INBOX_MESSAGE(conversationId, messageId);
  const payload = {
    url,
    method: 'put',
    data: { content },
  };
  const response = await request(payload);
  return response.data.message;
};
