import { createSelector } from 'reselect';
import { currentClassRoomId } from '../selectors/classroom';

const selectGroups = (state) => state.data.groups.groups;

export const group = (state, id) => state.data.groups.groups[id];

const selectSets = (state) => state.data.groups.sets;

export const groupSet = (state, id) => state.data.groups.sets[id];

export const selectHasQueryForParticipantsInGroups = (state) =>
  typeof state.groups.searchingForParticipantsInGroupsQuery === 'string' &&
  !!state.groups.searchingForParticipantsInGroupsQuery.length;

export const selectIsSearchingForParticipantsInGroups = (state) =>
  state.groups.isSearchingForParticipantsInGroups;

export const selectSearchedSets = (state) => state.data.groups.searchedSets;

export const selectSetsForCurrentSpace = createSelector(
  selectSets,
  selectGroups,
  currentClassRoomId,
  (sets, groups, spaceId) =>
    Object.values(sets).reduce((setsInCurrentSpace, set) => {
      if (set.space === spaceId) {
        return [
          ...setsInCurrentSpace,
          {
            ...set,
            ...(set.groups && {
              groups: set.groups.map((groupId) => groups[groupId]),
            }),
          },
        ];
      }
      return setsInCurrentSpace;
    }, [])
);

export const groupNamesByStudentId = (state, userId) =>
  Object.values(state.data.groups.groups)
    .filter(({ students }) => students.includes(userId))
    .map(({ name }) => name);

export const isCreateGroupSetDialogOpen = (state) =>
  state.groups.createGroupSetDialogOpen;
export const editGroupSetDialogOpenFor = (state) =>
  state.groups.editGroupSetDialogOpenFor;
export const createGroupDialogOpenFor = (state) =>
  state.groups.createGroupDialogOpenFor;
export const editGroupDialogOpenFor = (state) =>
  state.groups.editGroupDialogOpenFor;
export const generateGroupsDialogOpenFor = (state) =>
  state.groups.generateGroupsDialogOpenFor;

export const isCreatingGroups = (state) => state.groups.creatingGroups;
export const isGeneratingGroups = (state) => state.groups.generatingGroups;
export const selectGroupSetsLoaded = (state) => state.groups.groupSetsLoaded;
export const selectGroupsLoaded = (state) => state.groups.groupsLoaded;
export const selectGroupsFetchError = (state) => state.groups.error;

export const selectCurrentGroupSetId = (state) => {
  let groupSetId;

  const { routing } = state;

  if (routing) {
    const {
      locationBeforeTransitions: { pathname },
    } = routing;

    const groupsPathSegment = pathname.search('/groups/');
    if (groupsPathSegment !== -1) {
      groupSetId = pathname
        .substring(groupsPathSegment)
        .replace('/groups/', '');
    }
  }

  return groupSetId;
};
