import { reopenAssignment as reopenAssignmentApi } from '../api';
import { REOPEN_ASSIGNMENT } from '../actions';
import { accessToken as getAccessToken } from '../../selectors/user';

import { analyticsTrackEvent } from '../../utils/analytics';
import * as analyticEvents from '../../constants/analytics';

const reopenAssignmentDispatch = ({ dispatch, status, payload }) =>
  dispatch({
    type: REOPEN_ASSIGNMENT,
    status,
    payload,
  });

const reopenAssignment =
  ({ spaceId, assignment }) =>
  async (dispatch, getState) => {
    reopenAssignmentDispatch({
      dispatch,
      status: 'started',
      payload: { assignment },
    });

    const state = getState();
    const accessToken = getAccessToken(state, spaceId);

    if (!accessToken) {
      reopenAssignmentDispatch({
        dispatch,
        payload: { spaceId },
        status: 'error',
      });

      return null;
    }

    try {
      const { data } = await reopenAssignmentApi({
        accessToken,
        spaceId,
        assignment,
      });

      if (!data || !data.assignment) {
        throw new Error('fail to re-open assignment');
      }

      const updatedAssignment = data.assignment;

      analyticsTrackEvent(analyticEvents.REOPEN_ASSIGNMENT, {
        assignment: updatedAssignment,
        activeClassroom: spaceId,
      });

      reopenAssignmentDispatch({
        dispatch,
        status: 'success',
        payload: {
          spaceId,
          assignment: updatedAssignment,
        },
      });

      return updatedAssignment;
    } catch (e) {
      reopenAssignmentDispatch({
        dispatch,
        payload: { spaceId },
        status: 'error',
      });

      return null;
    }
  };

export default reopenAssignment;
