import axios from 'axios';
import { ACCESS_TOKEN_ENDPOINT } from '../../constants/endpoints';

export const fetchAccessToken = async ({ spaceId }) => {
  const URL = ACCESS_TOKEN_ENDPOINT(spaceId);
  const { data } = await axios.get(URL, {
    withCredentials: true,
  });

  return data;
};
