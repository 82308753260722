import { UserInfo } from '@ublend-npm/aula-schema';
import { AulaState } from '../types/state';
import {
  accessingSpaceAsStaff,
  classRoomUsers,
  isInstructorInSpace,
} from './classroom';
import { getAllSpaces, getCurrentSpaceId } from './space';
import { UserSpaceRole } from '../constants/space';

const getSpaceAccessToken = (state: AulaState, spaceId: string) =>
  state.accessTokens[spaceId];

export const accessToken = (state: AulaState, spaceId: string) =>
  getSpaceAccessToken(state, spaceId)?.token;

export const needsToRefreshAccessToken = (
  state: AulaState,
  spaceId: string
) => {
  const token = getSpaceAccessToken(state, spaceId);
  return !token || Date.now() > token.expiry;
};

export const getUserState = (state: AulaState) => state.user;

/** @deprecated Use {@link getUserState} instead */
export const user = getUserState;

const classRoomUser = (state: AulaState) => state.classRoomUser;

export const currentUserId = (state: AulaState) => getUserState(state).userId;

export const currentUser = (state: AulaState) => getUserState(state).user;

export const isVisibleNotificationSettingsTab = (state: AulaState) =>
  getUserState(state).isVisibleNotificationSettingsTab;

export const currentUserSlim = (state: AulaState): UserInfo | undefined => {
  const currentUserFull = currentUser(state);
  if (!currentUserFull) {
    return undefined;
  }
  const { objectId, firstName, lastName, avatar } = currentUserFull;

  // eslint-disable-next-line consistent-return
  return {
    id: objectId,
    firstName,
    lastName,
    avatar,
  };
};

export const getUserRoles = (state: AulaState) =>
  (currentUser(state) || {}).userRoles || [];

export const isInstructorInAnySpace = (state: AulaState) => {
  const spaces = getAllSpaces(state);
  const spaceUsers = classRoomUsers(state);
  const userId = currentUserId(state);
  const spaceUser = classRoomUser(state);
  return spaceUser.self
    .map(
      (objectId) =>
        spaceUsers[objectId] && spaces[spaceUsers[objectId].classRoom]
    )
    .some(
      (classRoom) =>
        !!classRoom && isInstructorInSpace(state)(userId, classRoom.objectId)
    );
};

export const getUserRole = (state: AulaState) => {
  const roles = getUserRoles(state);
  if (roles.includes('admin')) {
    return 'Admin';
  }
  return isInstructorInAnySpace(state) ? 'Instructor' : 'Student';
};

export const getUserSpaceRole = (
  state: AulaState
): UserSpaceRole | undefined => {
  const userId = currentUserId(state);

  if (!userId) {
    return undefined;
  }

  const spaceId = getCurrentSpaceId(state);

  if (accessingSpaceAsStaff(state)(spaceId)) {
    return 'staff';
  }

  return isInstructorInSpace(state)(userId, spaceId) ? 'educator' : 'student';
};

export const isChangingStatus = (state: AulaState) =>
  getUserState(state).changingStatus;
