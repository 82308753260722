const getValue = (item) => (key) => {
  let value = item[key];

  // convert en dash to minus so that this char is displayed properly on CSV
  if (typeof value === 'string') {
    value = value.replace('−', '-');
  }

  if (typeof value === 'string' && value.includes(',')) {
    return `"${value}"`;
  }
  return value;
};

const buildHeaders = (columns, headers) =>
  columns.map((column) => headers[column] || column);

const jsToCSV = (columns, data, headers = {}) =>
  data.reduce((accum, item) => {
    const row = columns.map(getValue(item)).join(',');

    return `${accum}\r\n${row}`;
  }, buildHeaders(columns, headers).join(','));

export default jsToCSV;
