import { isCancel } from 'axios';
import { GROUPS_PARTICIPANT_SEARCH } from '../actions';
import refreshAccessToken from '../../actions/accessToken/refreshAccessToken.action';
import { currentClassRoomId as getCurrentClassRoomId } from '../../selectors/classroom';
import { searchGroupSets } from '../api';

const searchParticipantsInGroupsStarted = (query) => ({
  type: GROUPS_PARTICIPANT_SEARCH,
  status: 'started',
  query,
});

const searchParticipantsInGroupsSuccess = (sets) => ({
  type: GROUPS_PARTICIPANT_SEARCH,
  status: 'success',
  sets,
});

const searchParticipantsInGroupsError = (error) => ({
  type: GROUPS_PARTICIPANT_SEARCH,
  status: 'error',
  error,
});

const searchParticipantsInGroups = (query) => async (dispatch, getState) => {
  dispatch(searchParticipantsInGroupsStarted(query));
  try {
    const state = getState();
    const spaceId = getCurrentClassRoomId(state);
    const accessToken = await refreshAccessToken(
      dispatch,
      getState
    )({ spaceId });
    if (query.length <= 1) {
      searchGroupSets.cancelToken.current.cancel();
      dispatch(searchParticipantsInGroupsSuccess([]));
    } else {
      const sets = await searchGroupSets.fn(accessToken, {
        search: query,
        spaceId,
        excludeInstitutionManaged: false,
      });
      dispatch(searchParticipantsInGroupsSuccess(sets));
    }
  } catch (error) {
    if (isCancel(error)) {
      return;
    }

    dispatch(searchParticipantsInGroupsError(error));
  }
};

export default searchParticipantsInGroups;
