import fetchAccessToken from './accessToken.action';
import {
  accessToken as getAccessToken,
  needsToRefreshAccessToken,
} from '../../selectors/user';

const refreshAccessToken =
  (dispatch, getState) =>
  async ({ spaceId }) => {
    const state = getState();
    const needsToRefresh = needsToRefreshAccessToken(state, spaceId);
    return needsToRefresh
      ? fetchAccessToken(dispatch)({ spaceId })
      : getAccessToken(state, spaceId);
  };

export default refreshAccessToken;
