const closeAssignmentReducer = (state, action) => {
  if (action.status !== 'success') return state;

  const {
    payload: { assignment },
  } = action;

  const assignments = state.assignments.map((item) =>
    item.externalId === assignment.externalId
      ? { ...item, status: 'closed' }
      : item
  );

  return {
    ...state,
    assignments,
  };
};

export default closeAssignmentReducer;
