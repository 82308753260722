import { GROUPS_ADD_GROUP_SET } from '../actions';
import { groupSet as getGroupSet } from '../selectors';

const onGroupSetCreated = (groupSet) => (dispatch, getState) => {
  const state = getState();
  const alreadyExists = !!getGroupSet(state, groupSet.id);

  if (alreadyExists) {
    return;
  }

  dispatch({
    type: GROUPS_ADD_GROUP_SET,
    status: 'success',
    payload: { groupSet },
  });
};

export default onGroupSetCreated;
