import fetchGroupSets from './fetchGroupSets.operation';
import fetchGroupSet from './fetchGroupSet.operation';
import addGroupSet from './addGroupSet.operation';
import editGroupSet from './editGroupSet.operation';
import deleteGroupSet from './deleteGroupSet.operation';
import addGroup from './addGroup.operation';
import addGroups from './addGroups.operation';
import editGroup from './editGroup.operation';
import deleteGroup from './deleteGroup.operation';
import generateGroups from './generateGroups.operation';
import exportGroupSetToCSV from './exportGroupSet.operation';
import onGroupSetCreated from './onGroupSetCreated.operation';
import onGroupSetDeleted from './onGroupSetDeleted.operation';
import onGroupSetUpdated from './onGroupSetUpdated.operation';
import onGroupCreated from './onGroupCreated.operation';
import onGroupDeleted from './onGroupDeleted.operation';
import onGroupUpdated from './onGroupUpdated.operation';
import searchParticipantsInGroups from './searchParticipantsInGroups.operation';
import validateGroupEmails from './validateGroupEmails.operation';
import {
  openAddGroupSet,
  closeAddGroupSet,
} from './openCloseAddGroupSet.operation';
import {
  openEditGroupSet,
  closeEditGroupSet,
} from './openCloseEditGroupSet.operation';
import { openAddGroup, closeAddGroup } from './openCloseAddGroup.operation';
import { openEditGroup, closeEditGroup } from './openCloseEditGroup.operation';
import {
  openGenerateGroups,
  closeGenerateGroups,
} from './openCloseGenerateGroups.operation';
import selectGroupSet from './selectGroupSet.operation';
import groupsLoaded from './groupsLoaded.operation';

export default {
  fetchGroupSets,
  fetchGroupSet,
  addGroupSet,
  editGroupSet,
  deleteGroupSet,
  addGroup,
  addGroups,
  editGroup,
  deleteGroup,
  generateGroups,
  onGroupSetCreated,
  onGroupSetDeleted,
  onGroupSetUpdated,
  onGroupCreated,
  onGroupDeleted,
  onGroupUpdated,
  openAddGroupSet,
  closeAddGroupSet,
  openEditGroupSet,
  closeEditGroupSet,
  openAddGroup,
  closeAddGroup,
  openEditGroup,
  closeEditGroup,
  openGenerateGroups,
  closeGenerateGroups,
  validateGroupEmails,
  exportGroupSetToCSV,
  searchParticipantsInGroups,
  selectGroupSet,
  groupsLoaded,
};
