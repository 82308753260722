import { GenericAction } from '../../types/redux';
import { CLASSROOM_OPEN_CLOSE_RENAME_FOLDER } from '../actions';
import { MaterialAppReducer } from './state';

type OpenCloseRenameFolderAction = GenericAction<
  typeof CLASSROOM_OPEN_CLOSE_RENAME_FOLDER,
  { renameFolderDialogOpenFor: string }
>;

const openCloseRenameFolder: MaterialAppReducer<OpenCloseRenameFolderAction> = (
  state,
  action
) => ({
  ...state,
  renameFolderDialogOpenFor: action.payload.renameFolderDialogOpenFor,
});

export default openCloseRenameFolder;
