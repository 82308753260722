import React from 'react';
import PropTypes from 'prop-types';

import Dialog from './Dialog.styled';
import DialogTitle from './DialogTitle.styled';
import DialogActions from './DialogActions.styled';
import AlertDialogContent, {
  ContentWrapper,
} from './AlertDialogContent.styled';

// The alert dialog content is abstracted away in a different React component
// in order to follow Material UI's performance best practices
// https://material-ui.com/components/modal/#performance
const AlertDialogInner = ({
  dialogId,
  title,
  message,
  action,
  secondaryAction,
  onClose,
}) => (
  <ContentWrapper onClick={(e) => e.stopPropagation()}>
    <DialogTitle id={`${dialogId}-dialogtitle`} onClose={onClose}>
      {title}
    </DialogTitle>
    <AlertDialogContent>{message}</AlertDialogContent>
    <DialogActions primary={action} secondary={secondaryAction} />
  </ContentWrapper>
);

const actionType = PropTypes.shape({
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  danger: PropTypes.bool,
  loading: PropTypes.bool,
});

AlertDialogInner.propTypes = {
  dialogId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onClose: PropTypes.func.isRequired,
  action: actionType,
  secondaryAction: actionType,
};

AlertDialogInner.defaultProps = {
  action: null,
  secondaryAction: null,
};

const AlertDialog = ({ id, open, onClose, maxWidth, ...props }) => (
  <Dialog
    id={id}
    open={open}
    onClose={onClose}
    maxWidth={maxWidth}
    style={{ zIndex: 2500 }}
  >
    <AlertDialogInner dialogId={id} onClose={onClose} {...props} />
  </Dialog>
);

AlertDialog.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  maxWidth: PropTypes.string,
};

AlertDialog.defaultProps = {
  maxWidth: 'sm',
};

export default AlertDialog;
