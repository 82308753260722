import React from 'react';
import PropTypes from 'prop-types';

import { TabLabelContainer, TabLabelIconContainer } from './TabLabel.styled';

const TabLabel = ({ text, icon }) => (
  <TabLabelContainer>
    <TabLabelIconContainer>{icon}</TabLabelIconContainer>
    <div>{text}</div>
  </TabLabelContainer>
);

TabLabel.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.node,
};

export default TabLabel;
