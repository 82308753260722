import * as types from '../../constants/actions';

const initialState = {
  isErrored: false,
  self: [],
};

const fetchClassRoomsReducer = (state = initialState, action) => {
  switch (action.status) {
    case 'started':
      return { ...state };
    case 'success': {
      return {
        ...state,
        self: action.classRoomUsers
          .filter((cu) => !!cu.classRoom)
          .map((classRoomUser) => classRoomUser.objectId),
        isErrored: false,
      };
    }
    case 'error':
      return {
        ...state,
        isErrored: true,
      };

    default:
      return state;
  }
};

const classRoomUserReducer = (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case types.CLASSROOM_FETCH_JOINED:
      return fetchClassRoomsReducer(state, action);
    case types.USER_LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
};

export default classRoomUserReducer;
