import { CREATE_REACTION } from '../actions';
import { postReaction } from '../api';
import { reactionForItem } from '../selectors';
import { currentUserId } from '../../selectors/user';

const createReactionStarted = (dispatch, payload) =>
  dispatch({
    type: CREATE_REACTION,
    status: 'started',
    payload,
  });

const createReactionSuccess = (dispatch, payload) =>
  dispatch({
    type: CREATE_REACTION,
    status: 'success',
    payload,
  });

const createReactionError = (dispatch, payload) =>
  dispatch({
    type: CREATE_REACTION,
    status: 'error',
    payload,
  });

const createReaction = (params) => async (dispatch, getState) => {
  const state = getState();
  const oldReaction = reactionForItem(state, params);
  const user = currentUserId(state);
  createReactionStarted(dispatch, {
    oldReaction,
    params,
    user,
  });
  try {
    const reaction = await postReaction(params);
    createReactionSuccess(dispatch, {
      reaction,
      params,
      user,
      local: true,
    });
    return reaction;
  } catch (error) {
    createReactionError(dispatch, {
      params,
      error,
      oldReaction,
      user,
    });
    throw error;
  }
};

export default createReaction;
