import { POST } from '../../constants/endpoints';
import request from '../request';
import formatPost from './formatPost';
import formatReaction from './formatReaction';
import formatComment from './formatComment';

const fetchPostById = async (postId) => {
  const {
    data: { post, tagPosts, comments, reactions },
  } = await request({
    method: 'get',
    url: POST(postId),
  });
  return {
    post: formatPost(post, tagPosts),
    comments: comments.map(formatComment),
    reactions: reactions.map(formatReaction),
  };
};

export default fetchPostById;
