import { theme } from '@ublend-npm/aulaui-next';

export default {
  emojiWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 2,
    cursor: 'pointer',
    minWidth: 40,
    minHeight: 40,
    ':hover': {
      backgroundColor: theme.color.blackAlpha('0.04'),
    },
    marginRight: 6,
    marginBottom: 6,
  },
  emojiContainer: {
    display: 'block',
  },
  emoji: {
    fontSize: '1.3rem',
  },
};
