import styled from 'react-emotion';

const TabTitle = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  height: 22px;
`;

export default TabTitle;
