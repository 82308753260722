import axios from 'axios';
import getAnalyticsHeaders from '../../utils/analyticsHeaders';

const request = async (accessToken, config) => {
  const headers = {
    ...config.headers,
    Authorization: `Bearer ${accessToken}`,
    ...getAnalyticsHeaders(),
  };

  const response = await axios({ ...config, headers });
  return response;
};

export default request;
