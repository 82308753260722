const editGroupSetReducer = (state, action) => {
  if (action.status !== 'success') {
    return state;
  }

  const {
    payload: { groupSet },
  } = action;

  const existingSet = state.sets[groupSet.id];

  if (!existingSet) {
    return state;
  }

  return {
    ...state,
    sets: {
      ...state.sets,
      [groupSet.id]: {
        ...existingSet,
        ...groupSet,
        groups: existingSet.groups,
      },
    },
  };
};

export default editGroupSetReducer;
