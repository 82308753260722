import React from 'react';
import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';
import BaseIconButton from '@material-ui/core/IconButton';
import BaseDialog from '@material-ui/core/Dialog';
import BaseDialogContent from '@material-ui/core/DialogContent';

export const Dialog = styled((props) => (
  <BaseDialog
    {...props}
    classes={{
      paper: 'DialogPaper',
    }}
  />
))`
  & * div.DialogPaper {
    position: absolute;
    left: 0px;
    width: ${({ width }) => width || '680px'};
    background-color: ${theme.color.white};
    overflow: hidden;
  }
`;

export const CloseButton = styled(BaseIconButton)`
  width: 40px;
  height: 40px;
  font-size: 24px;
  color: ${theme.color.grey11};
  border-radius: 4px;
  margin-right: -12px;
`;

export const DialogContent = styled(BaseDialogContent)`
  padding: 0px;
  position: relative;
`;

export const TitleContainer = styled.div`
  padding-left: ${theme.spacing.medium};
  padding-top: ${theme.spacing.medium};
  padding-bottom: ${theme.spacing.medium};
`;

export const TitleText = styled('h3')`
  margin: 0;
  font-weight: ${theme.font.weight.semiBold};
  font-size: 18px;
  line-height: 27px;
  color: ${theme.color.text};
`;

export const Subtitle = styled('div')`
  font-size: 16px;
  line-height: 24px;
  color: ${theme.color.grey11};
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  box-sizing: border-box;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: ${theme.spacing.medium};
`;
