import * as api from '../api/directMessages';
import { INBOX_EDIT_MESSAGE } from '../actions';
import * as inboxSelectors from '../selectors';

const editMessageStarted = (dispatch, payload) =>
  dispatch({
    type: INBOX_EDIT_MESSAGE,
    status: 'started',
    payload,
  });

const editMessageSuccess = (dispatch, payload) =>
  dispatch({
    type: INBOX_EDIT_MESSAGE,
    status: 'success',
    payload,
  });

const editMessageError = (dispatch, payload) =>
  dispatch({
    type: INBOX_EDIT_MESSAGE,
    status: 'error',
    payload,
  });

const editMessage = (updatedContent) => async (dispatch, getState) => {
  const state = getState();
  const conversationId = inboxSelectors.currentConversationId(state);
  const { id: messageId, content: previousContent } =
    inboxSelectors.currentlyEditedMessage(state);

  const payload = {
    messageId,
    previousContent,
    updatedContent,
    editedAt: new Date().toISOString(),
  };

  editMessageStarted(dispatch, payload);

  try {
    const { editedAt } = await api.conversations.editMessage({
      conversationId,
      messageId,
      content: updatedContent,
    });

    editMessageSuccess(dispatch, {
      ...payload,
      editedAt,
    });
  } catch (error) {
    editMessageError(dispatch, { ...payload, error });
  }
};

export default editMessage;
