import { NOTIFICATION_SETTINGS } from '../../constants/endpoints';
import request from '../../api/request';
import formatSettings from './formatSettings';

const fetchSettings = async () => {
  const url = NOTIFICATION_SETTINGS();
  const {
    data: { notificationSettings },
  } = await request({
    method: 'get',
    url,
  });
  return formatSettings(notificationSettings);
};

export default fetchSettings;
