const fetchGroupSetsReducer = (state, action) => {
  if (action.status !== 'success') {
    return state;
  }

  const {
    payload: { groupSets },
  } = action;

  const groups = []
    .concat(...groupSets.map((set) => set.groups))
    .filter((group) => group)
    .reduce((acc, group) => {
      acc[group.id] = group;
      return acc;
    }, {});

  const sets = groupSets.reduce((acc, set) => {
    acc[set.id] = {
      ...set,
      ...(set.groups && { groups: set.groups.map((group) => group.id) }),
    };
    return acc;
  }, {});

  return {
    ...state,
    sets,
    groups,
  };
};

export default fetchGroupSetsReducer;
