import { NOTIFICATION_SETTINGS_FETCH } from '../actionsTypes';
import { fetchSettings } from '../api';
import { hasFetchedSettings } from '../selectors';

const fetchNotificationSettingsStart = (dispatch) => {
  dispatch({
    type: NOTIFICATION_SETTINGS_FETCH,
    status: 'started',
    payload: {},
  });
};

export const fetchNotificationSettingsSuccess = (
  dispatch,
  notificationSettings
) => {
  dispatch({
    type: NOTIFICATION_SETTINGS_FETCH,
    status: 'success',
    payload: { notificationSettings },
  });
};

const fetchNotificationSettingsError = (dispatch, error) => {
  dispatch({
    type: NOTIFICATION_SETTINGS_FETCH,
    status: 'error',
    payload: { error },
  });
};

const fetchNotificationsSettings = () => async (dispatch, getState) => {
  if (hasFetchedSettings(getState())) {
    return;
  }

  fetchNotificationSettingsStart(dispatch);

  try {
    const notificationSettings = await fetchSettings();
    fetchNotificationSettingsSuccess(dispatch, notificationSettings);
  } catch (error) {
    fetchNotificationSettingsError(dispatch, error);
  }
};

export default fetchNotificationsSettings;
