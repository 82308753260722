import * as actions from '../actions';

import addParticipantsReducer from './addParticipants.data.reducer';
import createConversationReducer from './createConversation.data.reducer';
import closeConversationReducer from './closeConversation.data.reducer';
import reopenConversationReducer from './reopenConversation.data.reducer';
import editMessageReducer from './editMessage.data.reducer';
import fetchConversationReducer from './fetchConversation.data.reducer';
import fetchConversationsReducer from './fetchConversations.data.reducer';
import fetchMessageReducer from './fetchMessage.data.reducer';
import fetchMessagesReducer from './fetchMessages.data.reducer';
import leaveConversationReducer from './leaveConversation.data.reducer';
import reconnectReducer from './reconnect.data.reducer';
import removeParticipantReducer from './removeParticipant.data.reducer';
import renameConversationReducer from './renameConversation.data.reducer';
import sendMessageReducer from './sendMessage.data.reducer';
import setConversationReadReducer from './setConversationRead.data.reducer';
import updateMessageDraftReducer from './updateMessageDraft.data.reducer';

const initialState = {
  conversations: {},
  messages: {},
  participants: {},
  drafts: {},
};

const reducerMap = {
  [actions.INBOX_ADD_PARTICIPANTS_TO_CONVERSATION]: addParticipantsReducer,
  [actions.INBOX_CREATE_CONVERSATION]: createConversationReducer,
  [actions.INBOX_SET_CONVERSATION_CLOSED]: closeConversationReducer,
  [actions.INBOX_SET_CONVERSATION_REOPENED]: reopenConversationReducer,
  [actions.INBOX_EDIT_MESSAGE]: editMessageReducer,
  [actions.INBOX_FETCH_CONVERSATION]: fetchConversationReducer,
  [actions.INBOX_FETCH_CONVERSATIONS]: fetchConversationsReducer,
  [actions.INBOX_FETCH_MESSAGE]: fetchMessageReducer,
  [actions.INBOX_FETCH_MESSAGES]: fetchMessagesReducer,
  [actions.INBOX_LEAVE_CONVERSATION]: leaveConversationReducer,
  [actions.INBOX_RECONNECT]: reconnectReducer,
  [actions.INBOX_REMOVE_PARTICIPANT_FROM_CONVERSATION]:
    removeParticipantReducer,
  [actions.INBOX_RENAME_CONVERSATION]: renameConversationReducer,
  [actions.INBOX_SEND_MESSAGE]: sendMessageReducer,
  [actions.INBOX_SET_CONVERSATION_READ]: setConversationReadReducer,
  [actions.INBOX_UPDATE_DRAFT]: updateMessageDraftReducer,
};

export default (state = initialState, action) => {
  const reducer = reducerMap[action.type];
  if (typeof reducer === 'undefined') return state;
  return reducer(state, action);
};
