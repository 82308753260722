import React from 'react';
import { Route } from 'react-router';
import { getStore } from '../store';
import OverlayLoader from '../components/containers/OverlayLoader';
import fetchFilesCookie from '../../core/utils/filesSignedCookie';
import * as actions from '../../core/constants/actions';

const onEnter = async (nextState) => {
  const { getState, dispatch } = getStore();
  dispatch({ type: actions.STARTUP_FETCH_FILES_COOKIE, status: 'started' });
  const {
    institution: {
      general: { institution },
    },
  } = getState();
  await fetchFilesCookie();
  window.location.replace(
    `https://files.${institution}.aula.education/${nextState.params.fileId}`
  );
};

const FilesRoute = (
  <Route path="files/:fileId" onEnter={onEnter} component={OverlayLoader} />
);

export default FilesRoute;
