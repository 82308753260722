/** @deprecated Use getSpace instead. */
export const space = (state) => state.classRoom;

/** @deprecated Use getAllSpaces instead. */
export const spacesData = (state) => state.data.classRooms;

/** @deprecated Use getCurrentSpaceId instead. */
export const current = (classRoom) => classRoom.current;

const sortedByName = ({ name: nameA }, { name: nameB }) => {
  const lowerA = nameA.toLowerCase();
  const lowerB = nameB.toLowerCase();
  if (lowerA < lowerB) return -1;
  if (lowerA > lowerB) return 1;
  return 0;
};

/** @deprecated Use getAlphabeticallySortedSpaces instead. */
export const alphabeticallySorted = (classRooms) =>
  Object.values(classRooms).sort(sortedByName);

/** @deprecated Use getSpaceById instead. */
export const spaceById = (spaces, spaceId) => (spaces ? spaces[spaceId] : null);

/** @deprecated Use getInitialSpaceId instead. */
export const initialSpaceId = (state) => {
  const spaces = alphabeticallySorted(spacesData(state));
  const firstNonArchived = spaces.find((s) => !s.archived);
  const selectedSpace = firstNonArchived || spaces[0];
  return selectedSpace ? selectedSpace.objectId : null;
};
