import React from 'react';
import { theme } from '@ublend-npm/aulaui-next';

const style = {
  container: {
    position: 'absolute',
    padding: 10,
    bottom: 20,
    left: 'calc(50% - 150px)',
    zIndex: 99999,
    color: theme.color.black,
    backgroundColor: theme.color.white,
    textAlign: 'center',
    borderRadius: 5,
    boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
    display: 'flex',
    alignItems: 'center',
    borderWidth: '1px',
  },
};

const ConnectionIndicator = () => (
  <div style={style.container}>Trying to reconnect...</div>
);

export default ConnectionIndicator;
