import { connect } from 'react-redux';

import { isNotificationsOpen } from '../../../selectors/routing';
import { toggleNotifications } from '../../../actions/navigation';
import NotificationsDialog from './NotificationsDialog';

const mapStateToProps = (state) => ({
  open: isNotificationsOpen(state),
});

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(toggleNotifications()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsDialog);
