import React from 'react';
import { Filler, Rectangle, SpaceLoaderContainer } from './Loaders.styled';

export const SpaceLoader = () => (
  <SpaceLoaderContainer>
    <Rectangle width={24} height={24} marginRight={8} opacity={0.28} />
    <Rectangle width={110} height={10} opacity={0.72} />
    <Filler />
  </SpaceLoaderContainer>
);
