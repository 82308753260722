import { ANNOUNCEMENTS, COMMON_ROOM } from '../../constants/postFeedType';
import { getPostById } from '../posts.selectors';
import { getCurrentSpace } from './space.selectors';

export const feedTypeStateProperties = {
  [COMMON_ROOM]: 'scheduledPosts',
  [ANNOUNCEMENTS]: 'scheduledAnnouncements',
};

const getScheduledPostIds = (space, feedType) => {
  return space[feedTypeStateProperties[feedType]];
};

export const getScheduledPosts = (state, feedType) => {
  const space = getCurrentSpace(state);

  if (space && feedType) {
    return getScheduledPostIds(space, feedType).map((id) =>
      getPostById(state, id)
    );
  }

  return [];
};

export const getScheduledPostsCount = (state, feedType) => {
  const space = getCurrentSpace(state);
  return space && feedType ? getScheduledPostIds(space, feedType).length : 0;
};

export const getNextScheduledPostId = (state, feedType) => {
  const space = getCurrentSpace(state);

  if (!(space && feedType)) {
    return null;
  }

  return getScheduledPostIds(space, feedType)[0] || null;
};

export const getNextScheduledPost = (state, feedType) => {
  const postId = getNextScheduledPostId(state, feedType);

  if (!postId) {
    return null;
  }

  return getPostById(state, postId);
};
