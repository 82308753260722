import { USERS } from '../../constants/endpoints';
import request from '../request';

const fetchUsersByIds = async ({ userIds }) => {
  const { data: users } = await request({
    method: 'POST',
    url: `${USERS()}/getByIds`,
    data: { userIds },
  });

  return users.users.map((u) => ({ ...u, objectId: u.id }));
};

export default fetchUsersByIds;
