import styled from 'react-emotion';
import BaseInput from '@material-ui/core/Input';
import { theme } from '@ublend-npm/aulaui-next';

export const Container = styled('div')`
  height: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: ${theme.font.size.copy};
  color: ${theme.color.designSystem.blackOpacity60};

  svg.searchIcon {
    color: ${theme.color.designSystem.blackOpacity60};
  }

  border: 1px solid ${theme.color.designSystem.blackOpacity38};
  border-radius: ${theme.borderRadius.medium};
  padding-left: ${theme.spacing.small};
`;

export const Input = styled(BaseInput)`
  width: 100%;
  margin-left: ${theme.spacing.xxSmall};
  color: ${theme.color.designSystem.blackOpacity87};
  font-size: ${theme.font.size.copy};
  font-family: ${theme.font.family.workSans};
`;
