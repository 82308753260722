const highlightMessage = (state, action) => {
  const {
    status,
    payload: { messageId },
  } = action;

  if (status !== 'success') {
    return state;
  }

  return {
    ...state,
    highlightedMessage: messageId,
  };
};

export default highlightMessage;
