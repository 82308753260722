import flattenDeep from 'lodash.flattendeep';
import { Material } from '../types';
import series from '../../utils/series';
import pipe from '../../utils/pipe';
import zip from '../../utils/zip';
import { CreateFolder } from '../api/createFolder';
import { CreateSection } from '../api/createSection';
import { EditorState } from '@ublend-npm/aula-editor';

/**
 * We "save" imported materials by calling the respective create endpoint.
 *
 * Here we derive the return type of the saves from the API functions so that
 * it will be kept in sync.
 */
export type SaveMaterialResponse = Promise<
  Awaited<ReturnType<CreateFolder>> | Awaited<ReturnType<CreateSection>>
>;

export type Save = (material: PreparedV2Material) => () => SaveMaterialResponse;

export type Importer<T> = (
  save: Save,
  userId: string,
  materials: T
) => Promise<Material[]>;

export type JsonPage = {
  hidden: boolean;
  educatorOnly: boolean;
  title: string | null;
  content: EditorState;
  showDiscussion?: boolean;
  discussionTopic: string | null;
};

export type JsonFolder = {
  hidden: boolean;
  title: string | null;
  educatorOnly: boolean;
  pages: JsonPage[];
};

export type JsonMaterial = JsonFolder | JsonPage;

/**
 * The structure of an exported material(s) JSON file.
 */
export type MaterialFormat = {
  version: string;
  material: JsonMaterial[];
};

export type V2Importer = (
  save: Save,
  userId: string,
  materials: MaterialFormat
) => Promise<Material[]>;

type WithStringContent = Omit<JsonPage, 'content'> & {
  content: string;
};
type WithWriter = WithStringContent & { writer: string };
type PreparedV2Material = WithWriter & { parent: string | undefined };

const withContentStringified = (obj: JsonPage): WithStringContent => ({
  ...obj,
  content: JSON.stringify(obj.content),
});

const setWriterTo =
  (userId: string) =>
  (section: WithStringContent): WithWriter => ({
    ...section,
    writer: userId,
  });

const onlyUsefulBits = <T extends JsonPage>({
  title,
  content,
  hidden,
  educatorOnly,
  showDiscussion,
  discussionTopic,
}: T): JsonPage => ({
  title,
  content,
  hidden: !!hidden,
  educatorOnly: !!educatorOnly,
  showDiscussion: !!showDiscussion,
  discussionTopic: discussionTopic || null,
});

const setParent =
  (parentId: string | undefined) =>
  (section: WithWriter): PreparedV2Material => ({
    ...section,
    parent: parentId,
  });

const prepareSection = (
  userId: string,
  parentId: string | undefined
): ((_: JsonMaterial) => PreparedV2Material) =>
  pipe(
    onlyUsefulBits,
    withContentStringified,
    setWriterTo(userId),
    setParent(parentId)
  );

const importItem =
  ({
    userId,
    parentId,
    save,
  }: {
    userId: string;
    parentId: string | undefined;
    save: Save;
  }) =>
  (item: JsonMaterial) =>
    save(prepareSection(userId, parentId)(item));

const importItems =
  ({ userId, save }: { userId: string; save: Save }) =>
  (parentId: string | undefined, items: JsonMaterial[]) => {
    const importOne = importItem({ userId, parentId, save });
    const importQueue = items.map(importOne);
    return series(importQueue);
  };

const v2Importer: V2Importer = async (
  save,
  userId,
  { material: materials }
) => {
  const importLevel = importItems({ userId, save });

  const importFolder =
    ([folderId, pages]: [string, JsonPage[]]) =>
    () =>
      importLevel(folderId, pages);

  // This will also include top level pages
  const folders = await importLevel(undefined, materials);

  const folderIds = folders.map((folder) => folder.id);
  const allPages = materials.map(
    (material) => (material as JsonFolder).pages || []
  );

  const folderIdsAndPages = zip(folderIds, allPages);

  const pagePromises = folderIdsAndPages.map(importFolder);

  const savedPages = await series(pagePromises);

  return flattenDeep([...folders, ...savedPages]);
};

export default v2Importer;
