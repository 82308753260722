import dismissSpaceSelectorOperation from './dismissSpaceSelector.operation';
import openSpaceSelectorOperation from './openSpaceSelector.operation';
import selectSpaceSelectorOperation from './selectSpaceSelector.operation';
import toggleArchivedSpaceSelectorOperation from './toggleArchivedSpaceSelector.operation';
import searchSpaceSelectorOperation from './searchSpaceSelector.operation';
import sortSpaceSelectorOperation from './sortSpaceSelector.operation';
import subCategorySelectedSpaceSelectorOperation from './subCategorySelectedSpaceSelector.operation';
import categorySelectedSpaceSelectorOperation from './categorySelectedSpaceSelector.operation';
import toggleStarSpaceOperation from './toggleStarSpace.operation';

export const dismissSpaceSelector = dismissSpaceSelectorOperation;
export const openSpaceSelector = openSpaceSelectorOperation;
export const selectSpaceSelector = selectSpaceSelectorOperation;
export const toggleArchivedSpaceSelector = toggleArchivedSpaceSelectorOperation;
export const searchSpaceSelector = searchSpaceSelectorOperation;
export const sortSpaceSelector = sortSpaceSelectorOperation;
export const subCategorySelectedSpaceSelector =
  subCategorySelectedSpaceSelectorOperation;
export const categorySelectedSpaceSelector =
  categorySelectedSpaceSelectorOperation;
export const toggleStarSpace = toggleStarSpaceOperation;
