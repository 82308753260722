import { GROUPS_ADD_GROUP } from '../actions';
import { group as getGroup } from '../selectors';

const onGroupCreated =
  ({ group, studentsWithNoGroup = [] }) =>
  (dispatch, getState) => {
    const state = getState();
    const alreadyExists = !!getGroup(state, group.id);

    if (alreadyExists) {
      return;
    }

    dispatch({
      type: GROUPS_ADD_GROUP,
      status: 'success',
      payload: { group, studentsWithNoGroup },
    });
  };

export default onGroupCreated;
