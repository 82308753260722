const fetchNotificationSettingsReducer = (state, action) => {
  if (action.status !== 'success') {
    return state;
  }

  const {
    payload: { notificationSettings },
  } = action;
  return {
    ...state,
    settings: notificationSettings,
  };
};

export default fetchNotificationSettingsReducer;
