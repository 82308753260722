import { DisplayRevertModalAction } from '../sharedActions';
import { MaterialAppReducer } from './state';

const displayRevertModal: MaterialAppReducer<DisplayRevertModalAction> = (
  state,
  action
) => {
  const { section } = action.payload;

  return {
    ...state,
    currentRevertCandidate: section ? section.id : null,
    isFetchingVersions: action.status === 'started',
  };
};

export default displayRevertModal;
