import { STARTED_STATUS, SUCCESS_STATUS } from '../../constants/actionsStatus';
import * as types from '../../constants/actions';

const initialState = {
  isCreatingPost: false,
  schedulePostDialogOpenFor: null,
  hasFetchedAllCommonRoomPosts: false,
  hasFetchedAllAnnouncementPosts: false,
};

const fetchCommonRoomPostsReducer = (state = initialState, action) => {
  switch (action.status) {
    case STARTED_STATUS:
      return {
        ...state,
        hasFetchedAllCommonRoomPosts: false,
      };
    case SUCCESS_STATUS:
      return {
        ...state,
        // Page size is 10, as using a higher number would break behaviour
        hasFetchedAllCommonRoomPosts: action.content.length < 10,
      };

    default:
      return state;
  }
};

const fetchAnnouncementPostsReducer = (state = initialState, action) => {
  switch (action.status) {
    case STARTED_STATUS:
      return {
        ...state,
        hasFetchedAllAnnouncementPosts: false,
      };
    case SUCCESS_STATUS:
      return {
        ...state,
        // Page size is 10, as using a higher number would break behaviour
        hasFetchedAllAnnouncementPosts: action.content.length < 10,
      };

    default:
      return state;
  }
};

const createPostReducer = (state = initialState, action) => {
  switch (action.status) {
    case 'started':
      return {
        ...state,
        isCreatingPost: true,
      };
    case 'success':
      return {
        ...state,
        isCreatingPost: false,
      };
    case 'error':
      return {
        ...state,
        isCreatingPost: false,
      };

    default:
      return state;
  }
};

const classRoomChange = (state = initialState, action) => {
  switch (action.status) {
    case 'started':
      return initialState;
    case 'success':
      return state;

    default:
      return state;
  }
};

const openCloseSchedulePost = (state, action) => ({
  ...state,
  schedulePostDialogOpenFor: action.payload.schedulePostDialogOpenFor,
});

const PostsReducer = (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case types.SPACE_FETCH_COMMON_ROOM:
      return fetchCommonRoomPostsReducer(state, action);
    case types.SPACE_FETCH_ANNOUNCEMENTS:
      return fetchAnnouncementPostsReducer(state, action);
    case types.CLASSROOM_CREATE_POST:
      return createPostReducer(state, action);
    case types.CLASSROOM_CHANGE:
      return classRoomChange(state, action);
    case types.CLASSROOM_OPEN_CLOSE_SCHEDULE_POST:
      return openCloseSchedulePost(state, action);
    default:
      return state;
  }
};

export default PostsReducer;
