import PropTypes from 'prop-types';
import React from 'react';
import { theme, Loader } from '@ublend-npm/aulaui-next';

import overlayLoaderStyle from '../../../styles/presentational/OverlayLoader/overlayLoaderStyle';

const getContainerStyle = (props) => {
  const containerStyle = overlayLoaderStyle.container;
  if (props.open) {
    return { ...containerStyle, ...overlayLoaderStyle.open };
  }
  return containerStyle;
};

class OverlayLoaderPresentational extends React.Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.open !== nextProps.open || this.props.shouldUpdate) {
      return true;
    }

    return false;
  }

  render() {
    return (
      <div
        style={{
          ...getContainerStyle(this.props),
          ...{
            backgroundColor: this.props.backgroundColor || theme.color.overlay,
          },
        }}
      >
        <div style={overlayLoaderStyle.loaderContainer}>
          {this.props.open ? (
            <Loader
              size={50}
              status="loading"
              color={theme.color.designSystem.purple60}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

OverlayLoaderPresentational.propTypes = {
  open: PropTypes.bool.isRequired,
  logo: PropTypes.bool,
  backgroundColor: PropTypes.string,
  loaderColor: PropTypes.string,
  logoColor: PropTypes.string,
  shouldUpdate: PropTypes.bool,
};

OverlayLoaderPresentational.defaultProps = {
  shouldUpdate: true,
};

export default OverlayLoaderPresentational;
