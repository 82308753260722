import axios from 'axios';
import { getHeaders } from './utils';
import { AULA_ASSIGNMENTS } from '../../constants/endpoints';

const edit = (accessToken, spaceId, assignment) =>
  axios.put(
    `${AULA_ASSIGNMENTS()}/${assignment.objectId || assignment.externalId}`,
    {
      classId: spaceId,
      ...assignment,
    },
    {
      withCredentials: true,
      headers: getHeaders(accessToken),
    }
  );

export default edit;
