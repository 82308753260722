export const registerLiveEvent =
  ({ onNewNotification }) =>
  (socket) =>
  (dispatch) => {
    socket.on('Notification:UserCreated', (payload) =>
      dispatch(onNewNotification(payload))
    );
  };

export default registerLiveEvent;
