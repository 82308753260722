import {
  STARTED_STATUS,
  SUCCESS_STATUS,
  ERROR_STATUS,
  ADDED_STATUS,
  CLEARED_STATUS,
} from '../../constants/actionsStatus';
import * as types from '../../constants/actions';

export const initialState = {
  current: undefined,
  isFetchingClassRooms: false,
  isFetchingParticipants: false,
  isFetchingParticipantsHistory: false,
  hasFetchedAllParticipants: false,
  hasFetchedAllParticipantsHistory: false,
  searchingParticipantQuery: null,
  isActingOnParticipant: false,
  isFetchingCommonRoomContent: false,
  isFetchingAnnouncementContent: false,
  hasFinishedFetchingCommonRoomContent: false,
  hasFinishedFetchingAnnouncementContent: false,
  isFetchingImportantPosts: false,
  hasFetchedAllImportantPosts: false,
  isFetchingScheduledPosts: false,
  isFetchingScheduledAnnouncements: false,
  isChangingClassRoom: false,
  isChangingAvatar: false,
  isErrored: false,
  filterTag: '',
  lastEventDate: '',
  isFetchingFilteredPosts: false,
  onlineUsers: [],
  hasAddParticipantError: false,
  isAddingParticipantToSpace: false,
  feedType: undefined,
};

const changeSpaceReducer = (state = initialState, action) => {
  switch (action.status) {
    case STARTED_STATUS:
      return {
        ...state,
        isChangingClassRoom: true,
        current: action.payload.id,
        onlineUsers: [],
        isErrored: false,
      };
    case SUCCESS_STATUS: {
      const {
        payload: { id, tagId },
      } = action;
      return {
        ...state,
        current: id,
        isChangingClassRoom: false,
        hasFetchedAllParticipants: false,
        hasFetchedAllParticipantsHistory: false,
        filterTag: tagId || '',
        isErrored: false,
      };
    }
    case ERROR_STATUS:
      return {
        ...state,
        isChangingClassRoom: false,
        isErrored: true,
      };
    default:
      return state;
  }
};

const fetchSpacesReducer = (state = initialState, action) => {
  switch (action.status) {
    case STARTED_STATUS:
      return { ...state, isFetchingClassRooms: true };
    case SUCCESS_STATUS:
      return {
        ...state,
        isFetchingClassRooms: false,
        isErrored: false,
      };

    case ERROR_STATUS:
      return {
        ...state,
        isFetchingClassRooms: false,
        isErrored: true,
      };

    default:
      return state;
  }
};

const fetchSpaceParticipantsReducer = (state = initialState, action) => {
  switch (action.status) {
    case STARTED_STATUS:
      return { ...state, isFetchingParticipants: true };
    case SUCCESS_STATUS: {
      return {
        ...state,
        isErrored: false,
        isFetchingParticipants: false,
        hasFetchedAllParticipants: action.participants.length === 0,
      };
    }
    case ERROR_STATUS:
      return {
        ...state,
        isErrored: true,
        isFetchingParticipants: false,
      };

    default:
      return state;
  }
};

const fetchSpaceParticipantsHistoryReducer = (state = initialState, action) => {
  switch (action.status) {
    case STARTED_STATUS:
      return { ...state, isFetchingParticipantsHistory: true };
    case SUCCESS_STATUS: {
      return {
        ...state,
        isErrored: false,
        lastEventDate: action.lastEventDate,
        isFetchingParticipantsHistory: false,
        hasFetchedAllParticipantsHistory: action.participantsHistoryCount === 0,
      };
    }
    case ERROR_STATUS:
      return {
        ...state,
        isErrored: true,
        isFetchingParticipantsHistory: false,
      };

    default:
      return state;
  }
};

const addParticipantsToSpaceReducer = (state = initialState, action) => {
  switch (action.status) {
    case STARTED_STATUS:
      return { ...state, isAddingParticipantToSpace: true };
    case SUCCESS_STATUS: {
      return {
        ...state,
        hasAddParticipantError: false,
        isAddingParticipantToSpace: false,
      };
    }
    case ERROR_STATUS:
      return {
        ...state,
        hasAddParticipantError: true,
        isAddingParticipantToSpace: false,
      };

    default:
      return state;
  }
};

const searchSpaceParticipantsReducer = (state = initialState, action) => ({
  ...state,
  searchingParticipantQuery: action.query,
});

const spaceActingOnParticipantReducer = (state = initialState, action) => {
  switch (action.status) {
    case STARTED_STATUS:
      return { ...state, isActingOnParticipant: true };
    case SUCCESS_STATUS: {
      return {
        ...state,
        isActingOnParticipant: false,
      };
    }
    case ERROR_STATUS:
      return {
        ...state,
        isErrored: true,
        isActingOnParticipant: false,
      };

    default:
      return state;
  }
};

const fetchSpaceCommonRoomReducer = (state = initialState, action) => {
  switch (action.status) {
    case STARTED_STATUS:
      return {
        ...state,
        isFetchingCommonRoomContent: true,
        hasFinishedFetchingCommonRoomContent: false,
      };
    case SUCCESS_STATUS: {
      return {
        ...state,
        isErrored: false,
        isFetchingCommonRoomContent: false,
        hasFinishedFetchingCommonRoomContent: true,
      };
    }
    case ERROR_STATUS:
      return {
        ...state,
        isErrored: true,
        isFetchingCommonRoomContent: false,
      };

    default:
      return state;
  }
};

const fetchSpaceAnnouncementsReducer = (state = initialState, action) => {
  switch (action.status) {
    case STARTED_STATUS:
      return {
        ...state,
        isFetchingAnnouncementContent: true,
        hasFinishedFetchingAnnouncementContent: false,
      };
    case SUCCESS_STATUS: {
      return {
        ...state,
        isErrored: false,
        isFetchingAnnouncementContent: false,
        hasFinishedFetchingAnnouncementContent: true,
      };
    }
    case ERROR_STATUS:
      return {
        ...state,
        isErrored: true,
        isFetchingAnnouncementContent: false,
      };

    default:
      return state;
  }
};

const fetchImportantPostsReducer = (state = initialState, action) => {
  switch (action.status) {
    case STARTED_STATUS:
      return { ...state, isFetchingImportantPosts: true };
    case SUCCESS_STATUS: {
      return {
        ...state,
        isErrored: false,
        isFetchingImportantPosts: false,
        hasFetchedAllImportantPosts: action.importantPosts.length === 0,
      };
    }
    case ERROR_STATUS:
      return {
        ...state,
        isErrored: true,
        isFetchingImportantPosts: false,
      };

    default:
      return state;
  }
};

const newPostInSpaceReducer = (state = initialState, action) => {
  switch (action.status) {
    case STARTED_STATUS:
      return { ...state };
    case SUCCESS_STATUS: {
      return {
        ...state,
        isErrored: false,
      };
    }
    case ERROR_STATUS:
      return {
        ...state,
        isErrored: true,
      };

    default:
      return state;
  }
};

const changeSpaceAvatarReducer = (state = initialState, action) => {
  switch (action.status) {
    case STARTED_STATUS:
      return { ...state, isChangingAvatar: true };
    case SUCCESS_STATUS: {
      return {
        ...state,
        isErrored: false,
        isChangingAvatar: false,
      };
    }
    case ERROR_STATUS:
      return {
        ...state,
        isErrored: true,
        isChangingAvatar: false,
      };

    default:
      return state;
  }
};

const filterTagReducer = (state = initialState, action) => {
  switch (action.status) {
    case STARTED_STATUS:
      return {
        ...state,
        isFetchingFilteredPosts: true,
      };
    case ADDED_STATUS:
      return {
        ...state,
        filterTag: action.tagId,
        isFetchingFilteredPosts: false,
      };
    case CLEARED_STATUS:
      return {
        ...state,
        filterTag: '',
        isFetchingFilteredPosts: false,
      };
    default:
      return state;
  }
};

const selectFeedType = (state, action) => ({
  ...state,
  feedType: action.payload.feedType,
});

const spaceReducer = (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case types.CLASSROOM_CHANGE:
      return changeSpaceReducer(state, action);
    case types.CLASSROOM_FETCH_JOINED:
      return fetchSpacesReducer(state, action);
    case types.CLASSROOM_FETCH_PARTICIPANTS:
      return fetchSpaceParticipantsReducer(state, action);
    case types.CLASSROOM_ADD_PARTICIPANTS:
      return addParticipantsToSpaceReducer(state, action);
    case types.CLASSROOM_FETCH_PARTICIPANTS_HISTORY:
      return fetchSpaceParticipantsHistoryReducer(state, action);
    case types.CLASSROOM_SEARCH_PARTICIPANTS:
      return searchSpaceParticipantsReducer(state, action);
    case types.CLASSROOM_PROMOTE_PARTICIPANT:
      return spaceActingOnParticipantReducer(state, action);
    case types.CLASSROOM_UNPROMOTE_PARTICIPANT:
      return spaceActingOnParticipantReducer(state, action);
    case types.CLASSROOM_REMOVE_PARTICIPANT:
      return spaceActingOnParticipantReducer(state, action);
    case types.SPACE_FETCH_COMMON_ROOM:
      return fetchSpaceCommonRoomReducer(state, action);
    case types.SPACE_FETCH_ANNOUNCEMENTS:
      return fetchSpaceAnnouncementsReducer(state, action);
    case types.CLASSROOM_CREATE_POST:
      return newPostInSpaceReducer(state, action);
    case types.FETCH_IMPORTANT_POSTS:
      return fetchImportantPostsReducer(state, action);
    case types.CLASSROOM_CHANGE_AVATAR:
      return changeSpaceAvatarReducer(state, action);
    case types.USER_LOGOUT:
      return { ...initialState };
    case types.CLASSROOM_UPDATE_ONLINE_USERS:
      return { ...state, onlineUsers: action.onlineUsers };
    case types.FEED_FILTER_TAG:
      return filterTagReducer(state, action);
    case types.SPACE_SELECT_FEED_TYPE:
      return selectFeedType(state, action);
    default:
      return state;
  }
};

export default spaceReducer;
