import queryString from 'query-string';
import { changeClassRoom } from '../../../core/actions/classRoom';
import * as selectors from '../../../core/selectors/spaces';
import push from '../../utils/routing';

const AllowedQueryParams = new Set(['inbox', 'tagId', 'user-profile']);
const filterQuery = (query) =>
  Object.keys(query).reduce(
    (acc, key) =>
      AllowedQueryParams.has(key) ? { ...acc, [key]: query[key] } : acc,
    {}
  );

export default (spaceId, searchQuery, fetchFeatureFlags) =>
  async (dispatch, getState) => {
    const state = getState();
    const currentSpaceId = selectors.current(selectors.space(state));
    const filteredQuery = filterQuery(queryString.parse(searchQuery));
    const { tagId } = filteredQuery;
    const desiredSpaceId =
      spaceId || currentSpaceId || selectors.initialSpaceId(state);

    if (!desiredSpaceId) {
      push({
        pathname: '/nospaces',
        query: filteredQuery,
      });
      return;
    }
    const report = false; // Don't send CHANGE_CLASSROOM event on startup
    const reset = false;
    if (typeof spaceId === 'undefined') {
      push({
        pathname: `/dashboard/${desiredSpaceId}/community/feed`,
        query: filteredQuery,
      });
    } else {
      await dispatch(
        changeClassRoom(desiredSpaceId, report, reset, tagId, fetchFeatureFlags)
      );
    }
  };
