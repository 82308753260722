import { INBOX_OPEN_CLOSE_RENAME_GROUP } from '../actions';

const openRenameGroup = () => (dispatch) =>
  dispatch({
    type: INBOX_OPEN_CLOSE_RENAME_GROUP,
    payload: {
      renameGroupOpened: true,
    },
  });

export default openRenameGroup;
