const filterConversations = (state, action) => {
  const {
    status,
    payload: { query, conversationIds },
  } = action;

  switch (status) {
    case 'started':
      return {
        ...state,
        filtering: {
          ...state.filtering,
          query,
        },
      };
    case 'success':
      return {
        ...state,
        filtering: {
          ...state.filtering,
          conversationIds: query ? conversationIds : [],
        },
      };
    default:
      return state;
  }
};

export default filterConversations;
