export const GROUPS_FETCH_GROUP_SETS = 'GROUPS_FETCH_GROUP_SETS';
export const GROUPS_FETCH_GROUP_SET = 'GROUPS_FETCH_GROUP_SET';
export const GROUPS_ADD_GROUP_SET = 'GROUPS_ADD_GROUP_SET';
export const GROUPS_EDIT_GROUP_SET = 'GROUPS_EDIT_GROUP_SET';
export const GROUPS_DELETE_GROUP_SET = 'GROUPS_DELETE_GROUP_SET';
export const GROUPS_ADD_GROUP = 'GROUPS_ADD_GROUP';
export const GROUPS_ADD_GROUPS = 'GROUPS_ADD_GROUPS';
export const GROUPS_EDIT_GROUP = 'GROUPS_EDIT_GROUP';
export const GROUPS_DELETE_GROUP = 'GROUPS_DELETE_GROUP';
export const GROUPS_GENERATE_GROUPS = 'GROUPS_GENERATE_GROUPS';

export const GROUPS_OPEN_CLOSE_ADD_GROUP_SET =
  'GROUPS_OPEN_CLOSE_ADD_GROUP_SET';
export const GROUPS_OPEN_CLOSE_EDIT_GROUP_SET =
  'GROUPS_OPEN_CLOSE_EDIT_GROUP_SET';
export const GROUPS_OPEN_CLOSE_ADD_GROUP = 'GROUPS_OPEN_CLOSE_ADD_GROUP';
export const GROUPS_OPEN_CLOSE_EDIT_GROUP = 'GROUPS_OPEN_CLOSE_EDIT_GROUP';
export const GROUPS_OPEN_CLOSE_GENERATE_GROUPS =
  'GROUPS_OPEN_CLOSE_GENERATE_GROUPS';

export const GROUPS_PARTICIPANT_SEARCH = 'GROUP_PARTICIPANT_SEARCH';
export const GROUPS_LOADED = 'GROUPS_LOADED';
