import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

const addPaddingIfNecessary = (props) =>
  props._dense ? '0px' : `${theme.spacing.xLarge} 0 ${theme.spacing.xLarge} 0`;

export const TabPanelContainer = styled.div`
  background-color: ${theme.color.white};
  padding: ${(props) => addPaddingIfNecessary(props)};
  flex: 0 0 190px;
  box-sizing: border-box;
  overflow: ${({ scrollable }) => (scrollable ? 'auto' : 'hidden')};

  .tabPanel__wrapper:focus {
    .tabPanel__tab {
      background-color: ${theme.color.grayLight};
    }
  }
`;

export const Title = styled.div`
  font-size: ${theme.font.size.small};
  color: ${theme.color.grey11};
  padding: ${(props) =>
      props.position !== undefined ? theme.spacing.xxLarge : '0px'}
    ${theme.spacing.medium} ${theme.spacing.small};
`;

export const Tab = styled.div`
  padding: ${theme.spacing.xSmall} ${theme.spacing.medium};
  width: 100%;
  font-size: ${theme.font.size.copy};
  font-weight: ${(props) =>
    props.selected ? theme.font.weight.medium : theme.font.weight.regular};
  color: ${(props) => {
    if (props.selected) return theme.color.purple;
    if (props.disabled) return theme.color.grayDark;
    return theme.color.mediumBlue;
  }};
  background-color: ${(props) =>
    props.selected ? 'rgba(144,19,254,0.08)' : theme.color.white};
  box-sizing: border-box;
  transition: background-color ${theme.transition.speed.xFast}
    ${theme.transition.easing.easeOut};
  cursor: ${(props) =>
    !props.canSelectDisabled && props.disabled ? 'default' : 'pointer'};
  pointer-events: ${(props) =>
    !props.canSelectDisabled && props.disabled ? 'none' : 'auto'};
  &:hover,
  &:focus {
    background-color: ${theme.color.grayLight};
  }
`;
