import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ModeComment from '@material-ui/icons/ModeComment';
import { IconButton, icons } from '@ublend-npm/aulaui-next';

import { config as breakpoints } from '../../../../hooks/useBreakpoints';
import Scrollbar from '../../common/ScrollBar';
import ConversationFilter from './ConversationFilter.container';
import CircularProgress from '../../CircularProgress';

import {
  Button,
  ButtonContainer,
  Container,
  ConversationList,
  CircularProgressContainer,
  InboxButton,
  InboxButtonCount,
  IconButtonInner,
  IconButtonText,
  InboxTitle,
  InboxTitleContainer,
  ArrowIcon,
  styles,
} from './Inbox.styled';
import InboxItem from './InboxItem.container';
import InboxCreateConversation from '../Legacy/InboxCreateConversation';

const IS_DM_COLLAPSED = 'aula-is-dm-collapsed';

class InboxMenu extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isInboxCollapsed: this.calculateInitialIsInboxCollapsed(),
      openConversationDialog: false,
    };

    this.conversationFilter = null;

    this.onFilterChange = this.onFilterChange.bind(this);
    this.setConversationFilter = this.setConversationFilter.bind(this);
    this.changeGroup = this.changeGroup.bind(this);
    this.handleCollapse = this.handleCollapse.bind(this);
    this.hanldeExpand = this.hanldeExpand.bind(this);
    this.handleOpenConversationDialog =
      this.handleOpenConversationDialog.bind(this);
    this.handleCloseConversationDialog =
      this.handleCloseConversationDialog.bind(this);
  }

  onFilterChange(filterValue) {
    const { filterConversations } = this.props;
    filterConversations(filterValue);
    this.setState({ filterValue });
  }

  setConversationFilter(reference) {
    this.conversationFilter = reference;
  }

  // eslint-disable-next-line class-methods-use-this
  calculateInitialIsInboxCollapsed() {
    const savedState = localStorage.getItem(IS_DM_COLLAPSED);
    if (savedState) {
      return savedState === 'true';
    }
    return window.innerWidth < breakpoints.md;
  }

  changeGroup() {
    if (this.conversationFilter) {
      this.conversationFilter.reset();
    }
    const { changeGroup } = this.props;
    changeGroup();
  }

  handleCollapse() {
    this.setState({ isInboxCollapsed: true });
    localStorage.setItem(IS_DM_COLLAPSED, 'true');
  }

  hanldeExpand() {
    this.setState({ isInboxCollapsed: false });
    localStorage.setItem(IS_DM_COLLAPSED, 'false');
  }

  handleOpenConversationDialog() {
    this.setState({ openConversationDialog: true });
  }

  handleCloseConversationDialog() {
    this.setState({ openConversationDialog: false });
  }

  render() {
    const { isInboxCollapsed, openConversationDialog } = this.state;

    const {
      conversationIds,
      fetchingConversations,
      getMenuProps,
      getItemProps,
      highlightedIndex,
      getInputProps,
      setItemCount,
      totalUnreadConversations,
    } = this.props;

    setItemCount(conversationIds.length);

    const { role, ...menuProps } = getMenuProps();
    const unreadBadgeMessage =
      totalUnreadConversations > 99 ? ':)' : `${totalUnreadConversations}`;

    return (
      <>
        <Container
          className={isInboxCollapsed ? styles.shrinked : styles.expanded}
        >
          {!isInboxCollapsed && (
            <ButtonContainer>
              <InboxTitleContainer>
                <InboxTitle>Messages</InboxTitle>
                <IconButton
                  label="Hide message panel"
                  icon={icons.Close}
                  size="xSmall"
                  type="secondary"
                  onClick={this.handleCollapse}
                />
              </InboxTitleContainer>
              <Button
                type="secondary"
                onClick={this.handleOpenConversationDialog}
                id="new-message"
                iconLeft={ModeComment}
                data-testid="new-message-button"
                aria-label="Create new conversation"
              >
                <span>New message</span>
              </Button>
            </ButtonContainer>
          )}
          {!isInboxCollapsed && (
            <ConversationList aria-live="polite" {...menuProps}>
              <Scrollbar viewProps={{ role: 'listbox' }} autoHide>
                {conversationIds.map((conversationId, index) => (
                  <InboxItem
                    key={conversationId}
                    changeGroup={this.changeGroup}
                    conversationId={conversationId}
                    highlighted={highlightedIndex === index}
                    getItemProps={getItemProps}
                    index={index}
                  />
                ))}
                {fetchingConversations && !conversationIds.length && (
                  <CircularProgressContainer>
                    <CircularProgress />
                  </CircularProgressContainer>
                )}
              </Scrollbar>
            </ConversationList>
          )}

          {!isInboxCollapsed && (
            <ConversationFilter
              ref={this.setConversationFilter}
              onChange={this.onFilterChange}
              getInputProps={getInputProps}
              handleCollapse={this.handleCollapse}
              filterValue={this.state.filterValue}
            />
          )}
          <InboxCreateConversation
            open={openConversationDialog}
            onClose={this.handleCloseConversationDialog}
          />
        </Container>
        <InboxButton
          className={
            isInboxCollapsed
              ? styles.inboxButtonShown
              : styles.inboxButtonHidden
          }
          role="button"
          onClick={this.hanldeExpand}
        >
          <IconButtonInner>
            <IconButtonText>Messages</IconButtonText>
            {totalUnreadConversations && (
              <InboxButtonCount>{unreadBadgeMessage}</InboxButtonCount>
            )}
          </IconButtonInner>
          <ArrowIcon aria-label="Expand conversations" />
        </InboxButton>
      </>
    );
  }
}

InboxMenu.propTypes = {
  conversationIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  filterConversations: PropTypes.func.isRequired,
  openConversation: PropTypes.func.isRequired,
  fetchingConversations: PropTypes.bool.isRequired,
  getMenuProps: PropTypes.func.isRequired,
  getItemProps: PropTypes.func.isRequired,
  getInputProps: PropTypes.func.isRequired,
  setItemCount: PropTypes.func.isRequired,
  highlightedIndex: PropTypes.number,
  changeGroup: PropTypes.func.isRequired,
  totalUnreadConversations: PropTypes.number,
};

InboxMenu.defaultProps = {
  highlightedIndex: null,
  totalUnreadConversations: 0,
};

export default InboxMenu;
