import { INBOX_CONVERSATION_MESSAGES } from '../../../constants/endpoints';
import request from './request';

export default async (conversationId, messageData, localId) => {
  const url = INBOX_CONVERSATION_MESSAGES(conversationId);
  const { content, repliedMessageId } = messageData;
  const payload = {
    url,
    method: 'post',
    data: {
      content: JSON.stringify(content),
      repliedMessageId,
      localId,
    },
  };
  const response = await request(payload);
  return response.data.message;
};
