import axios from 'axios';
import { getHeaders } from './utils';
import { HANDIN_SUBMISSIONS } from '../../constants/endpoints';

let requestSource;

const deleteSubmissionsPath = ({
  assignmentExternalId,
  studentId,
  groupId,
}) => {
  const basePath = `${HANDIN_SUBMISSIONS()}?assignmentId=${assignmentExternalId}`;
  if (studentId) {
    return `${basePath}&studentId=${studentId}`;
  }
  return `${basePath}&groupId=${groupId}`;
};

const deleteSubmissions = ({
  accessToken,
  assignmentExternalId,
  studentId,
  groupId,
}) => {
  if (requestSource) {
    requestSource.cancel();
  }

  requestSource = axios.CancelToken.source();

  return axios.delete(
    deleteSubmissionsPath({ assignmentExternalId, studentId, groupId }),
    {
      headers: getHeaders(accessToken),
      cancelToken: requestSource.token,
    }
  );
};

export default deleteSubmissions;
