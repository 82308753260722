import { formatObjectKeysToCamelCase } from '@core/utils/formatObjectKeys';
import { LDClient } from 'launchdarkly-js-client-sdk';

export const fetchFeatureFlags = async (
  ldClient: LDClient,
  institutionName: string,
  spaceId: string,
  userId: string,
  spaceRole: string,
  numberOfSpaces: number
) => {
  try {
    await ldClient.identify({
      kind: 'user',
      key: userId,
      spaceId,
      institution: institutionName,
      spaceRole,
      numberOfSpaces,
    });

    const flags = ldClient.allFlags();

    return formatObjectKeysToCamelCase(flags);
  } catch (error) {
    return {};
  }
};
