const buildClassUserRoomArrayFromStateAndAction = (state, action) => {
  const reducedClassRoomUsers = action.classRoomUsers
    .filter((cu) => !!cu.classRoom && !!cu.user)
    .reduce((previous, current) => {
      Object.assign(previous, {
        [current.objectId]: Object.assign({}, current, {
          classRoom: current.classRoom.objectId,
          user: current.user.objectId,
        }),
      });
      return previous;
    }, {});

  return { ...state.classRoomUsers, ...reducedClassRoomUsers };
};

export const createClassRoomReducer = (state, action) => {
  switch (action.status) {
    case 'success':
      return {
        ...state,
        classRoomUsers: Object.assign(state.classRoomUsers, {
          [action.classRoomUser.objectId]: Object.assign(
            {},
            action.classRoomUser,
            {
              classRoom: action.classRoomUser.classRoom.objectId,
              user: action.classRoomUser.user.objectId,
            }
          ),
        }),
      };
    default:
      return state;
  }
};

export const joinClassRoomReducer = (state, action) => {
  switch (action.status) {
    case 'success':
      return {
        ...state,
        classRoomUsers: Object.assign(state.classRoomUsers, {
          [action.classRoomUser.objectId]: Object.assign(
            {},
            action.classRoomUser,
            {
              classRoom: action.classRoomUser.classRoom.objectId,
              user: action.classRoomUser.user.objectId,
            }
          ),
        }),
      };
    default:
      return state;
  }
};

export const fetchClassRoomsReducer = (state, action) => {
  switch (action.status) {
    case 'success': {
      return {
        ...state,
        classRoomUsers: buildClassUserRoomArrayFromStateAndAction(
          state,
          action
        ),
      };
    }
    default:
      return state;
  }
};

export const promoteClassRoomParticipantReducer = (state, action) => {
  switch (action.status) {
    case 'started': {
      return state;
    }
    case 'success': {
      const classRoomUsers = Object.assign({}, state.classRoomUsers);
      classRoomUsers[action.promotedClassRoomUser] = Object.assign(
        {},
        classRoomUsers[action.promotedClassRoomUser],
        { isInstructor: true }
      );

      return {
        ...state,
        classRoomUsers,
      };
    }
    default:
      return state;
  }
};

export const unpromoteClassRoomParticipantReducer = (state, action) => {
  switch (action.status) {
    case 'started': {
      return state;
    }
    case 'success': {
      const classRoomUsers = Object.assign({}, state.classRoomUsers);
      classRoomUsers[action.unpromotedClassRoomUser] = Object.assign(
        {},
        classRoomUsers[action.unpromotedClassRoomUser],
        { isInstructor: false }
      );

      return {
        ...state,
        classRoomUsers,
      };
    }
    default:
      return state;
  }
};
