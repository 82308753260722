// @ts-check

import { fetchAccessToken } from '../../api/accessToken/accessToken';
import {
  FETCH_ACCESS_TOKEN_STARTED,
  FETCH_ACCESS_TOKEN_SUCCESS,
  FETCH_ACCESS_TOKEN_ERROR,
} from '../../constants/actions';

const fetchAccessTokenStarted = () => ({
  type: FETCH_ACCESS_TOKEN_STARTED,
});

const fetchAccessTokenSuccess = ({ spaceId, accessToken }) => ({
  type: FETCH_ACCESS_TOKEN_SUCCESS,
  payload: {
    spaceId,
    accessToken,
  },
});

const fetchAccessTokenFailed = ({ spaceId }) => ({
  type: FETCH_ACCESS_TOKEN_ERROR,
  payload: { spaceId },
});

const fetchAccessTokenAction =
  (dispatch) =>
  async ({ spaceId }) => {
    dispatch(fetchAccessTokenStarted());

    try {
      const { access_token: accessToken } = await fetchAccessToken({
        spaceId,
      });
      dispatch(fetchAccessTokenSuccess({ spaceId, accessToken }));
      return accessToken;
    } catch (e) {
      dispatch(fetchAccessTokenFailed({ spaceId }));
      return null;
    }
  };

export default fetchAccessTokenAction;
