import { CreateSectionBody, UpdateSectionBody } from '@ublend-npm/aula-schema';
import diff from 'deep-diff';
import { CLASSROOM_MODULE_SAVE_CURRENT_SECTION } from '../actions';
import { createSection, patchSection } from '../api';
import { analyticsTrackEvent } from '../../utils/analytics';
import {
  ADD_DISCUSSION,
  REMOVE_DISCUSSION,
  SHOW_DISCUSSION,
  HIDE_DISCUSSION,
} from '../../constants/analytics';
import * as selectors from '../selectors';
import setEditing from './setEditing.operation';
import { ActionStatus } from '../../constants/actionsStatus';
import { AulaReduxAction } from '../../types/state';
import { Action } from 'redux';
import { getCurrentSpaceId } from '../../selectors/space';
import { Patch, Section as SavedSection, LocalSection } from '../types';
import { isLocalId } from '../../utils/localId';

type Section = SavedSection | LocalSection;

const buildPatchedSection = (section: Section, patch: Patch) => ({
  ...section,
  ...patch,
});

const buildCreateSectionBody = (
  section: Section,
  patch: Patch
): CreateSectionBody => {
  const patchedSection = buildPatchedSection(section, patch);

  return {
    content: patch.content && JSON.stringify(patch.content),
    discussionTopic: patchedSection.discussionTopic,
    educatorOnly: patchedSection.educatorOnly,
    hidden: patchedSection.hidden,
    order: patchedSection.order,
    parent: patchedSection.parent,
    scheduledFor: patchedSection.scheduledFor,
    showDiscussion: patchedSection.showDiscussion,
    title: patchedSection.title,
    writer:
      typeof section.writer === 'string'
        ? section.writer
        : section.writer
        ? section.writer.id
        : '',
  };
};

const buildUpdateSectionBody = (patch: Patch): UpdateSectionBody => ({
  ...patch,
  content: JSON.stringify(patch.content),
});

export const hasChanges = (section: Section, patch: Patch) => {
  return (
    !!diff(section.title, patch.title) ||
    !!diff(section.content, patch.content) ||
    (patch.scheduledFor !== undefined &&
      !!diff(section.scheduledFor, patch.scheduledFor)) ||
    (patch.discussionTopic !== undefined &&
      !!diff(section.discussionTopic, patch.discussionTopic)) ||
    (patch.showDiscussion !== undefined &&
      !!diff(section.showDiscussion, patch.showDiscussion))
  );
};

const saveSectionAction = (status: ActionStatus, payload: unknown) => ({
  type: CLASSROOM_MODULE_SAVE_CURRENT_SECTION,
  status,
  payload,
});

/* eslint-disable-next-line consistent-return */
const getAnalyticsEventType = (oldSection: Section, newSection: Patch) => {
  if (!oldSection.discussionTopic && newSection.discussionTopic) {
    return ADD_DISCUSSION;
  }
  if (oldSection.discussionTopic && !newSection.discussionTopic) {
    return REMOVE_DISCUSSION;
  }
  if (!oldSection.showDiscussion && newSection.showDiscussion) {
    return SHOW_DISCUSSION;
  }
  if (oldSection.showDiscussion && !newSection.showDiscussion) {
    return HIDE_DISCUSSION;
  }
};

const trackAnalytics = ({
  oldSection,
  newSection,
  classRoomId,
  sectionId,
}: {
  oldSection: Section;
  newSection: Patch;
  classRoomId: string;
  sectionId: string;
}) => {
  const eventType = getAnalyticsEventType(oldSection, newSection);
  if (eventType) {
    analyticsTrackEvent(eventType, {
      activeClassroom: classRoomId,
      itemId: sectionId,
    });
  }
};

type SaveSectionActionFactory = (_: {
  id?: string;
  patch: Patch;
  select: (id: string, educatorOnly: boolean) => Action;
  educatorOnly: boolean;
  correlationId?: string;
}) => AulaReduxAction;

const saveSection: SaveSectionActionFactory =
  ({ id, patch, select, educatorOnly, correlationId }) =>
  async (dispatch, getState) => {
    const state = getState();
    dispatch(setEditing(false));

    const classRoomId = getCurrentSpaceId(state);
    const section = id
      ? selectors.getSection(state, id)
      : selectors.currentSection(state);

    if (!section) {
      return;
    }

    const uneditedSection = {
      ...section,
      content: selectors.uneditedContent(state),
    };

    const local = isLocalId(section.id);
    const sectionId = section.id;

    if (!local && !hasChanges(uneditedSection, patch)) {
      return;
    }

    try {
      dispatch(saveSectionAction('started', { sectionId, patch }));
      const savedSection = local
        ? await createSection(
            classRoomId,
            buildCreateSectionBody(section, patch),
            sectionId,
            correlationId
          )
        : await patchSection(
            classRoomId,
            sectionId,
            buildUpdateSectionBody(patch),
            false,
            correlationId
          );

      const payload = {
        classRoom: classRoomId,
        section: savedSection,
        oldSection: section,
      };
      dispatch(saveSectionAction('success', payload));
      if (select) {
        dispatch(select(savedSection.id, educatorOnly));
      }

      trackAnalytics({
        oldSection: section,
        newSection: patch,
        classRoomId,
        sectionId,
      });
    } catch (error: unknown) {
      dispatch(saveSectionAction('error', { oldSection: section, error }));
    }
  };

export default saveSection;
