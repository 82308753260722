import { css } from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';
import Prefixer from 'inline-style-prefixer';
import React from 'react';
import fonts from '../../../fonts';

interface Styles {
  headerContainer: React.CSSProperties;
  header: React.CSSProperties;
  iconButtonContainer: React.CSSProperties;
  wrapper: React.CSSProperties;
  to: React.CSSProperties;
  autocompleteWrapper: React.CSSProperties;
  groupNameField: React.CSSProperties;
  groupInfo: React.CSSProperties;
  groupLink: React.CSSProperties;
  tooltip: React.CSSProperties;
  popper: string;
}

const dialogStyle = new Prefixer().prefix<Styles>({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing.xLarge,
  },
  header: {
    fontWeight: theme.font.weight.semiBold,
    fontFamily: theme.font.family.workSans,
    fontSize: '20px',
    margin: 0,
  },
  iconButtonContainer: {
    position: 'absolute',
    right: '19px',
    top: '19px',
  },
  wrapper: {
    padding: '24px',
    paddingTop: '22px',
  },
  to: {
    ...fonts.regular,
    color: 'black',
    fontSize: 16,
    width: 30,
    float: 'left',
    paddingTop: 2,
  },
  autocompleteWrapper: {
    marginLeft: 30,
  },
  groupNameField: {
    width: 'calc(100% - 30px)',
    marginLeft: 30,
    marginTop: 10,
    fontSize: 16,
  },
  groupInfo: {
    marginLeft: 30,
    marginTop: theme.spacing.xLarge,
    fontSize: theme.font.size.small,
    lineHeight: '16px',
  },
  groupLink: {
    cursor: 'pointer',
    fontWeight: theme.font.weight.semiBold,
    color: theme.color.purple3,
  },
  tooltip: {
    marginLeft: 10,
  },
  popper: css`
    z-index: 1650;
  `,
});

export default dialogStyle;
