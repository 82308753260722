import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const AvatarSuggestion = styled.span`
  float: left;
`;

export const NameSuggestion = styled.span`
  height: 30px;
  line-height: 33px;
  color: black;
  font-size: 14px;
  margin-left: 10px;
`;

export const AvatarTag = styled.span`
  height: 24px;
  width: 24px;
  float: left;
`;

export const TagContainer = styled.div`
  float: left;
  width: 100%;
  margin-bottom: ${(props) => (props.margin ? '10px' : '0px')};
  margin-left: 2px;
`;

export const Tag = styled.div`
  float: left;
  margin-right: 5px;
  margin-bottom: 5px;
  :focus {
    outline: Highlight auto;
    outline: -webkit-focus-ring-color auto;
    outline-offset: 1px;
  }
`;

export const Educator = styled.span`
  color: ${theme.color.pink};
  padding-left: 7px;
  font-size: 12px;
`;

export const EducatorAvatar = styled.span`
  color: ${theme.color.pink};
  padding-right: 4px;
  font-size: 12px;
`;

export const LoaderWrapper = styled.div`
  display: table;
  color: ${theme.color.green};
  padding: ${theme.spacing.medium};
  margin: 0px auto;
`;
