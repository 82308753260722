import { INBOX_RENAME_CONVERSATION } from '../actions';
import * as api from '../api/directMessages';
import * as inboxSelectors from '../selectors';

const sendRenameStarted = (dispatch, payload) =>
  dispatch({
    type: INBOX_RENAME_CONVERSATION,
    status: 'started',
    payload,
  });

const sendRenameSuccess = (dispatch, payload) =>
  dispatch({
    type: INBOX_RENAME_CONVERSATION,
    status: 'success',
    payload,
  });

const sendRenameError = (dispatch, payload) =>
  dispatch({
    type: INBOX_RENAME_CONVERSATION,
    status: 'error',
    payload,
  });

const renameConversation = (title) => async (dispatch, getState) => {
  const conversationId = inboxSelectors.currentConversationId(getState());
  const payload = { conversationId, title };
  sendRenameStarted(dispatch, { conversationId, title });
  try {
    await api.conversations.putConversation(conversationId, title);
    sendRenameSuccess(dispatch, payload);
  } catch (error) {
    sendRenameError(dispatch, { ...payload, error });
  }
};

export default renameConversation;
