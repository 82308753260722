export const tempReactionId = ({ itemId, itemType, emojiName }) =>
  `${itemType}-${itemId}-${emojiName}`;

export const reactionWithoutUser = (reaction, userId) => {
  if (!reaction.users[userId]) {
    return reaction;
  }
  const updatedReaction = { ...reaction };
  updatedReaction.count -= 1;
  updatedReaction.users = { ...reaction.users };
  delete updatedReaction.users[userId];
  updatedReaction.updatedAt = new Date().toISOString();
  return updatedReaction;
};

export const reactionWithUser = (reaction, userId) => {
  if (reaction.users[userId]) {
    return reaction;
  }
  const updatedReaction = { ...reaction };
  updatedReaction.count += 1;
  updatedReaction.users = {
    ...reaction.users,
    [userId]: {},
  };
  updatedReaction.updatedAt = new Date().toISOString();
  return updatedReaction;
};

export const createTempReaction = ({ itemId, itemType, emojiName }) => ({
  id: tempReactionId({ itemId, itemType, emojiName }),
  itemId,
  itemType,
  emojiName,
  count: 0,
  users: {},
  createdAt: new Date().toISOString(),
});

export const deleteReaction = (reactions, reactionId) => {
  const updatedReactions = { ...reactions };
  delete updatedReactions[reactionId];
  return updatedReactions;
};

export const updateReactions = (reactions, reaction, local, user) => {
  const oldReaction = reactions[reaction.id];
  const shouldReplace =
    !oldReaction ||
    !oldReaction.updatedAt ||
    oldReaction.updatedAt <= reaction.updatedAt;
  if (shouldReplace) {
    return {
      ...reactions,
      [reaction.id]: reaction,
    };
  }

  const shouldUpdateLocalUser = oldReaction && local;
  if (shouldUpdateLocalUser) {
    const userAdded = !oldReaction.users[user] && reaction.users[user];
    const userRemoved = oldReaction.users[user] && !reaction.users[user];
    if (userAdded) {
      return {
        ...reactions,
        [reaction.id]: reactionWithUser(oldReaction, user),
      };
    }
    if (userRemoved) {
      return {
        ...reactions,
        [reaction.id]: reactionWithoutUser(oldReaction, user),
      };
    }
  }
  return reactions;
};
