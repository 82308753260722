import { GROUPS_ADD_GROUP_SET, GROUPS_EDIT_GROUP_SET } from '../actions';
import { groupSet as getGroupSet } from '../selectors';

const onGroupSetUpdated =
  ({ set: groupSet }) =>
  (dispatch, getState) => {
    const state = getState();
    const alreadyExists = !!getGroupSet(state, groupSet.id);
    const type = alreadyExists ? GROUPS_EDIT_GROUP_SET : GROUPS_ADD_GROUP_SET;

    dispatch({
      type,
      status: 'success',
      payload: { groupSet },
    });
  };

export default onGroupSetUpdated;
