export const INBOX_ADD_PARTICIPANTS_TO_CONVERSATION =
  'INBOX_ADD_PARTICIPANTS_TO_CONVERSATION';
export const INBOX_CREATE_CONVERSATION = 'INBOX_CREATE_CONVERSATION';
export const INBOX_EDIT_MESSAGE = 'INBOX_EDIT_MESSAGE';
export const INBOX_OPEN_CLOSE_LEAVE_GROUP = 'INBOX_OPEN_CLOSE_LEAVE_GROUP';
export const INBOX_OPEN_CLOSE_RENAME_GROUP = 'INBOX_OPEN_CLOSE_RENAME_GROUP';
export const INBOX_OPEN_CLOSE_VIEW_PARTICIPANTS =
  'INBOX_OPEN_CLOSE_VIEW_PARTICIPANTS';
export const INBOX_OPEN_CLOSE_EDIT_MESSAGE = 'INBOX_OPEN_EDIT_MESSAGE';
export const INBOX_FETCH_CONVERSATION = 'INBOX_FETCH_CONVERSATION';
export const INBOX_FETCH_CONVERSATIONS = 'INBOX_FETCH_CONVERSATIONS';
export const INBOX_FETCH_MESSAGE = 'INBOX_FETCH_MESSAGE';
export const INBOX_FETCH_MESSAGES = 'INBOX_FETCH_MESSAGES';
export const INBOX_REPLY_TO_MESSAGE = 'INBOX_REPLY_TO_MESSAGE';
export const INBOX_FILTER_CONVERSATIONS = 'INBOX_FILTER_CONVERSATIONS';
export const INBOX_LEAVE_CONVERSATION = 'INBOX_LEAVE_CONVERSATION';
export const INBOX_RECONNECT = 'INBOX_RECONNECT';
export const INBOX_REMOVE_PARTICIPANT_FROM_CONVERSATION =
  'INBOX_REMOVE_PARTICIPANT_FROM_CONVERSATION';
export const INBOX_RENAME_CONVERSATION = 'INBOX_RENAME_CONVERSATION';
export const INBOX_SEND_MESSAGE = 'INBOX_SEND_MESSAGE';
export const INBOX_SET_CONVERSATION_READ = 'INBOX_SET_CONVERSATION_READ';
export const INBOX_SET_CONVERSATION_CLOSED = 'INBOX_SET_CONVERSATION_CLOSED';
export const INBOX_SET_CONVERSATION_REOPENED =
  'INBOX_SET_CONVERSATION_REOPENED';
export const LIVE_TYPING = 'LIVE_TYPING';
export const INBOX_UPDATE_DRAFT = 'INBOX_UPDATE_DRAFT';
export const INBOX_HIGHLIGHT_MESSAGE = 'INBOX_HIGHLIGHT_MESSAGE';
