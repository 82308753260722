const seriesReducer = <T>(curr: Promise<T>[], next: () => Promise<T>) => {
  const p = curr.length ? curr[curr.length - 1] : Promise.resolve();
  curr.push(p.then(next));
  return curr;
};

const series = <T>(a: (() => Promise<T>)[]): Promise<T[]> =>
  Promise.all(a.reduce(seriesReducer, [] as Promise<T>[]));

export default series;
