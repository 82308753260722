import { connect } from 'react-redux';

import Toast from './Toast';
import { selectors, operations } from '../../../store/toasts';

const mapStateToProps = (state) => {
  const activeToast = selectors.activeToast(state) || {};
  return {
    open: selectors.isToastActive(state),
    emoji: activeToast.emoji || '',
    message: activeToast.message || '',
  };
};

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(operations.dismissToast()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Toast);
