import * as actions from '../actions';

import showToastReducer from './showToastReducer.app.reducer';
import dismissToastReducer from './dismissToastReducer.app.reducer';

const initialState = {
  activeToast: null,
};

const reducerMap = {
  [actions.TOASTS_SHOW_TOAST]: showToastReducer,
  [actions.TOASTS_DISMISS_TOAST]: dismissToastReducer,
};

export default (state = initialState, action) => {
  const reducer = reducerMap[action.type];
  if (!reducer) return state;
  return reducer(state, action);
};
