import { GROUPS_ADD_GROUP_SET } from '../actions';
import { addGroupSet as addGroupSetApi } from '../api';

import refreshAccessToken from '../../actions/accessToken/refreshAccessToken.action';
import { currentClassRoomId as getCurrentClassRoomId } from '../../selectors/classroom';
import showToast from '../../toasts/operations/showToast.operation';
import { ADD_GROUP_SET_SUCCESS, ADD_GROUP_SET_ERROR } from './toasts';

const addGroupSetSuccess = (payload) => ({
  type: GROUPS_ADD_GROUP_SET,
  status: 'success',
  payload,
});

const addGroupSetError = (payload) => ({
  type: GROUPS_ADD_GROUP_SET,
  status: 'error',
  payload,
});

const addGroupSet = (groupSet) => async (dispatch, getState) => {
  const state = getState();
  const spaceId = getCurrentClassRoomId(state);
  const payload = {
    groupSet: {
      ...groupSet,
      space: spaceId,
    },
  };
  try {
    const accessToken = await refreshAccessToken(
      dispatch,
      getState
    )({ spaceId });
    const createdGroupSet = await addGroupSetApi(accessToken, payload.groupSet);
    dispatch(addGroupSetSuccess({ groupSet: createdGroupSet }));
    dispatch(showToast(ADD_GROUP_SET_SUCCESS));
  } catch (error) {
    dispatch(showToast(ADD_GROUP_SET_ERROR));
    dispatch(addGroupSetError({ ...payload, error }));
  }
};

export default addGroupSet;
