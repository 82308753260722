import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';

import { changeSpace } from '@app/actions/navigation';
import changeClassroomAvatar from '@core/actions/classRoom/avatar';
import * as selectors from '@core/selectors/spaces';
import * as classRoomSelectors from '@core/selectors/classroom';
import * as userSelectors from '@core/selectors/user';
import {
  openSpaceSelector,
  dismissSpaceSelector,
  selectSpaceSelector,
  toggleArchivedSpaceSelector,
  searchSpaceSelector,
  sortSpaceSelector,
  categorySelectedSpaceSelector,
  subCategorySelectedSpaceSelector,
  toggleStarSpace,
} from '@core/spaceSelector/operations';
import SpaceSelector from './SpaceSelector';

const selectSpacesData = createStructuredSelector({
  space: selectors.space,
  spacesData: selectors.spacesData,
  isAdmin: classRoomSelectors.isInstructor,
  isLoading: classRoomSelectors.isChangingAvatar,
  userRoles: userSelectors.getUserRoles,
});

const mapStateToProps = createSelector(
  selectSpacesData,
  ({ space, spacesData, isAdmin, isLoading, userRoles }) => ({
    currentSpaceId: space.current,
    currentSpace: selectors.spaceById(spacesData, space.current) || {},
    isAdmin,
    userRoles,
    spaces: selectors.alphabeticallySorted(spacesData).map((s) => ({
      id: s.objectId,
      name: s.name,
      archived: s.archived,
      categories: s.categories,
      avatar: s.avatar,
      hasActivity: s.unread,
      isStarred: s.isStarred || false,
    })),
    isLoading,
  })
);

const mapDispatchToProps = (dispatch) => ({
  onOpen: () => dispatch(openSpaceSelector()),
  onDismiss: (showArchived, sort, category) =>
    dispatch(dismissSpaceSelector(showArchived, sort, category)),
  onArchivedToggleChange: (showArchived, sort, category) =>
    dispatch(toggleArchivedSpaceSelector(showArchived, sort, category)),
  onSearchChange: (search, sort, showArchived, category) =>
    dispatch(searchSpaceSelector(search, sort, showArchived, category)),
  onSortChange: (previousSort, sort, showArchived, category) =>
    dispatch(sortSpaceSelector(previousSort, sort, showArchived, category)),
  onSpaceSelect: (spaceId, showArchived, sort, category, subCategory) => {
    dispatch(changeSpace(spaceId));
    dispatch(
      selectSpaceSelector(
        spaceId,
        showArchived,
        sort,
        category,

        subCategory
      )
    );
  },
  onSpaceStar: async ({
    spaceId,
    showArchived,
    sort,
    search,
    category,
    subCategory,
  }) => {
    await dispatch(
      toggleStarSpace({
        spaceId,
        search,
        sort,
        showArchived,
        category,
        subCategory,
      })
    );
  },
  onSubCategorySelect: (category, subCategory) =>
    dispatch(subCategorySelectedSpaceSelector(category, subCategory)),
  onCategorySelect: (category) =>
    dispatch(categorySelectedSpaceSelector(category)),
  onSpaceImageChange: (file) => dispatch(changeClassroomAvatar(file)),
});

const mergeProps = (
  { currentSpaceId, ...otherStateProps },
  { ...otherDispatchProps },
  ownProps
) => ({
  ...otherStateProps,
  ...otherDispatchProps,
  ...ownProps,
});

const SpaceSelectorContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(SpaceSelector);

export default SpaceSelectorContainer;
