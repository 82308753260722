import createFolder from './createFolder.data.reducer';
import createSection from './createSection.data.reducer';
import deleteMaterial from './deleteMaterial.data.reducer';
import displayRevertModal from './displayRevertModal.data.reducer';
import fetchSections from './fetchSections.data.reducer';
import importMaterials from './importMaterials.data.reducer';
import liveDeletedMaterial from './liveDeletedMaterial.data.reducer';
import liveUpdatedMaterial from './liveUpdatedMaterial.data.reducer';
import revertSectionToVersion from './revertSectionToVersion.data.reducer';
import saveSection from './saveSection.data.reducer';
import recorderMaterial from './reorderMaterial.data.reducer';
import editMaterial from './editMaterial.data.reducer';
import {
  MaterialsDataActionType,
  MaterialDataReducer,
  MaterialsDataState,
} from './state';
import { Action, DuckReducers } from '../../types/redux';
import {
  DeleteSectionAction,
  DeleteFolderAction,
  HideUnhideMaterialAction,
  EditMaterialAction,
  EditFolderAction,
} from '../sharedActions';
import * as actions from '../actions';
import { ActionStatus } from '../../constants/actionsStatus';
import updateMaterialProgressDataReducer from './updateMaterialProgress.data.reducer';

const initialState: MaterialsDataState = {};

const reducers: DuckReducers<MaterialsDataState, MaterialsDataActionType> = {
  [actions.CLASSROOM_MODULE_CREATE_FOLDER]: createFolder,
  [actions.CLASSROOM_MODULE_CREATE_SECTION]: createSection,
  [actions.CLASSROOM_MODULE_DELETE_SECTION]:
    deleteMaterial as MaterialDataReducer<DeleteSectionAction>,
  [actions.CLASSROOM_DELETE_FOLDER]:
    deleteMaterial as MaterialDataReducer<DeleteFolderAction>,
  [actions.CLASSROOM_MODULE_DISPLAY_REVERT_MODAL]: displayRevertModal,
  [actions.CLASSROOM_MODULE_FETCH_SECTIONS]: fetchSections,
  [actions.CLASSROOM_MODULE_HIDEUNHIDE_MATERIAL]:
    editMaterial as MaterialDataReducer<HideUnhideMaterialAction>,
  [actions.CLASSROOM_MODULE_EDIT_MATERIAL]:
    editMaterial as MaterialDataReducer<EditMaterialAction>,
  [actions.CLASSROOM_MODULE_IMPORT_SECTIONS]: importMaterials,
  [actions.CLASSROOM_MODULE_LIVE_DELETED_MATERIAL]: liveDeletedMaterial,
  [actions.CLASSROOM_MODULE_LIVE_UPDATED_MATERIAL]: liveUpdatedMaterial,
  [actions.CLASSROOM_MODULE_REVERT]: revertSectionToVersion,
  [actions.CLASSROOM_MODULE_SAVE_CURRENT_SECTION]: saveSection,
  [actions.CLASSROOM_MODULE_REORDER_MATERIAL]: recorderMaterial,
  [actions.CLASSROOM_EDIT_FOLDER]:
    editMaterial as MaterialDataReducer<EditFolderAction>,
  [actions.CLASSROOM_MODULE_UPDATE_SECTION_PROGRESS]:
    updateMaterialProgressDataReducer,
};

const reducer = <T extends MaterialsDataActionType>(
  state = initialState,
  action: Action<T, ActionStatus, unknown>
) => {
  if (!action) {
    return state;
  }

  const r = reducers[action.type];
  return r ? r(state, action) : state;
};

export default reducer;
