import { MaterialAppReducer } from './state';
import { EditMaterialAction } from '../sharedActions';

const editMaterial: MaterialAppReducer<EditMaterialAction> = (
  state,
  action
) => {
  if (action.status !== 'success') {
    return state;
  }

  return {
    ...state,
    selectedContent: action.payload.updatedMaterial.content,
  };
};

export default editMaterial;
