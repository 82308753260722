import { INBOX_UPDATE_DRAFT } from '../actions';

const updateMessageDraft = (conversationId, draft) => (dispatch) =>
  dispatch({
    type: INBOX_UPDATE_DRAFT,
    status: 'success',
    payload: { conversationId, draft },
  });

export default updateMessageDraft;
