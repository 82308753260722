import * as userSelector from '../../selectors/user';
import * as api from '../api/directMessages';
import { INBOX_SET_CONVERSATION_READ } from '../actions';
import * as inboxSelector from '../selectors';

const sendConversationReadStarted = (dispatch, payload) =>
  dispatch({
    type: INBOX_SET_CONVERSATION_READ,
    status: 'started',
    payload,
  });

const sendConversationReadSuccess = (dispatch, payload) =>
  dispatch({
    type: INBOX_SET_CONVERSATION_READ,
    status: 'success',
    payload,
  });

const sendConversationReadError = (dispatch, payload) =>
  dispatch({
    type: INBOX_SET_CONVERSATION_READ,
    status: 'error',
    payload,
  });

const setConversationRead =
  (readUpTo, convId) => async (dispatch, getState) => {
    const state = getState();
    const { objectId: userId } = userSelector.currentUser(state);
    const conversationId = convId || inboxSelector.currentConversationId(state);
    const payload = { userId, conversationId, readUpTo };
    sendConversationReadStarted(dispatch, payload);

    try {
      await api.conversations.patchParticipant(
        conversationId,
        userId,
        readUpTo
      );
      sendConversationReadSuccess(dispatch, payload);
    } catch (error) {
      sendConversationReadError(dispatch, { ...payload, error });
    }
  };

export default setConversationRead;
