import { isProduction } from '../utils/environment';

const config = {
  prod: {
    APPID: 'vxd45up6',
  },
  dev: {
    APPID: 'l440w6dl',
  },
};

let env;
if (isProduction()) {
  env = config.prod;
} else {
  env = config.dev;
}

export default {
  APPID: env.APPID,
};
