import { connect } from 'react-redux';
import Prefixer from 'inline-style-prefixer';
import PropTypes from 'prop-types';
import React from 'react';
import { theme } from '@ublend-npm/aulaui-next';

import styles from './style';
import { generateColor } from '../../../../../core/utils/avatar';

const prefixer = new Prefixer();

const AvatarComopnent = (props) => {
  const backgroundColor = !props.user.isInstructor
    ? generateColor(props.user.objectId)
    : theme.color.pink;
  const backgroundImage = props.user.avatar
    ? `url(${props.user.avatar})`
    : 'none';

  return (
    <div
      aria-hidden="true"
      style={{
        ...styles.base,
        ...props.style,
        backgroundColor,
        backgroundImage,
        width: props.size,
        height: props.size,
        borderRadius: props.size < 50 ? '15%' : 5,
        position: 'relative',
      }}
    >
      {props.currentUser !== props.user.objectId &&
      props.onlineClients[props.user.objectId] ? (
        <div
          style={{
            backgroundColor: theme.color.designSystem.success,
            border: '3px solid #FFF',
            borderRadius: 100,
            width: 10,
            height: 10,
            position: 'absolute',
            top: -7,
            left: -7,
            ...props.indicatorStyle,
          }}
        />
      ) : null}

      {!props.user.avatar && (
        <span
          style={prefixer.prefix({
            ...styles.letter,
            width: props.size,
            height: props.size,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: props.size * 0.4,
            lineHeight: `${props.size}px`,
          })}
        >
          {props.user.firstName ? props.user.firstName[0].toUpperCase() : ''}
        </span>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.userId,
  onlineClients: state.live.clients,
});

const mapDispatchToProps = (dispatch) => ({});

const Avatar = connect(mapStateToProps, mapDispatchToProps)(AvatarComopnent);

Avatar.propTypes = {
  isInstructor: PropTypes.bool,
  user: PropTypes.object.isRequired,
  size: PropTypes.number.isRequired,
  style: PropTypes.object,
  indicatorStyle: PropTypes.object,
};

export default Avatar;
