import FileDownload from 'js-file-download';
import showToast from '../../toasts/operations/showToast.operation';
import jsToCsv from '../../utils/jsToCsv';

const userFields = ['groupName', 'email', 'firstName', 'lastName', 'studentId'];
const userHeaders = {
  groupName: 'Group name',
  email: 'Email address',
  firstName: 'First name',
  lastName: 'Last name',
  studentId: 'Student ID',
};

const normalizeGroupSetExportData = (groupName, participants) =>
  participants.map((participant) => ({
    groupName,
    email: participant.email,
    firstName: participant.firstName,
    lastName: participant.lastName,
    studentId: participant.custom?.studentId ?? '',
  }));

const exportGroupSetToCSV = (groupSetId) => async (dispatch, getState) => {
  try {
    const state = getState();
    const dataGroups = state.data.groups;
    const set = dataGroups.sets[groupSetId];
    const participants = [];

    set.groups.forEach((groupId) => {
      const group = dataGroups.groups[groupId];

      participants.push(
        normalizeGroupSetExportData(group.name, group.educators)
      );
      participants.push(
        normalizeGroupSetExportData(group.name, group.students)
      );
    });

    FileDownload(
      jsToCsv(userFields, participants.flat(), userHeaders),
      `${set.name}-participants.csv`,
      'text/csv;charset=utf-8;'
    );

    dispatch(
      showToast({
        emoji: '🙌',
        message: 'Success! The CSV was exported!',
      })
    );
  } catch (e) {
    dispatch(
      showToast({
        emoji: '🤔',
        message: 'Oops! We failed to download the list of participants',
      })
    );
  }
};

export default exportGroupSetToCSV;
