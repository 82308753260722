import * as types from '../../constants/actions';
import * as conversationsTypes from '../../conversations/actions';

const STEPS = {
  CONNECTED: 'connected',
  DISCONNECTED: 'disconnected',
  JOINING: 'joining',
  JOINED: 'joined',
};

const initialState = {
  step: STEPS.DISCONNECTED,
  userId: null,
  serverAcknowledgedConnection: false,
  conversations: null,
};

const reinitStateWithUser = (userId) => ({
  ...initialState,
  userId,
});

const fetchConversationsReducer = (state, action) => {
  const { step, serverAcknowledgedConnection } = state;
  const { status, payload } = action;

  if (step !== STEPS.CONNECTED) return state;
  if (status !== 'success') return state;

  return {
    ...state,
    step: serverAcknowledgedConnection ? STEPS.JOINING : state.step,
    conversations: payload.conversations.map((c) => c.id),
  };
};

const clientConnectedReducer = (state, action) => {
  const { step, conversations } = state;
  if (step !== STEPS.CONNECTED) return state;

  const { clientId } = action;
  if (clientId !== state.userId) return state;
  return {
    ...state,
    step: conversations !== null ? STEPS.JOINING : state.step,
  };
};

const loginReducer = (state, action) => {
  const { status, user } = action;

  if (status !== 'success') return state;
  return {
    ...state,
    userId: user.id,
  };
};

const liveJoinConvReducer = (state, action) => {
  const { status } = action;
  const { step } = state;

  if (step !== STEPS.JOINING) return state;
  if (status !== 'success') return state;

  return { ...state, step: STEPS.JOINED };
};

const conversations = (state = initialState, action) => {
  switch (action.type) {
    case types.USER_LOGOUT:
      return { ...initialState };
    case types.USER_FETCH_CURRENT:
    case types.USER_LOGIN:
      return loginReducer(state, action);
    case types.LIVE_CONNECT_ON:
      return {
        ...reinitStateWithUser(state.userId),
        step: STEPS.CONNECTED,
        serverAcknowledgedConnection: true,
      };
    case types.LIVE_CONNECT_OFF:
      return reinitStateWithUser(state.userId);
    case conversationsTypes.INBOX_RECONNECT:
      return fetchConversationsReducer(state, action);
    case types.LIVE_CLIENT_CONNECTED:
      return clientConnectedReducer(state, action);
    case types.LIVE_JOIN_CONV:
      return liveJoinConvReducer(state, action);
    default:
      return state;
  }
};

export default conversations;
