const mergeReducer = (acc, next) => ({ ...acc, ...next });
const merge = (source, ...dest) => dest.reduce(mergeReducer, source);

const addParticipantsReducer = (state, action) => {
  if (action.status !== 'success') return state;

  const {
    payload: { conversationId, participants },
  } = action;
  const conversation = state.conversations[conversationId];

  if (!conversation) {
    return state;
  }

  return {
    ...state,
    conversations: {
      ...state.conversations,
      [conversationId]: {
        ...conversation,
        participants: merge(conversation.participants, ...participants),
      },
    },
  };
};

export default addParticipantsReducer;
