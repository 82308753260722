import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

const StyledEmojiButton = styled('button')`
  cursor: pointer;
  background-color: transparent;
  border-radius: ${theme.borderRadius.medium};
  border: none;
  padding: 2px 4px 4px 2px;

  &:hover {
    background-color: ${theme.color.designSystem.background.grey.hover};
  }
`;

export default StyledEmojiButton;
