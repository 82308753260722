import useLocalStorage from '@core/hooks/useLocalStorage';

type CategoryPreferences = {
  category: {
    label: string;
    order: number;
  };
  subCategories?: {
    [key: string]: boolean;
  };
  sort?: string;
};

/**
 * Hook to get and set the category preferences
 * @returns {[categoryPreferences: object, saveCategoryPreferences: function]}
 */
const useCategoryPreferences = () => {
  const [categoryPreferences, saveCategoryPreferences] = useLocalStorage(
    'categoryPreferences',
    {}
  );

  const setCategoryPreference = (category: CategoryPreferences['category']) => {
    saveCategoryPreferences((prev: CategoryPreferences) => ({
      ...prev,
      category,
      subCategories: {},
    }));
  };

  const setSubCategoriesPreference = (
    subCategory: CategoryPreferences['category']
  ) => {
    saveCategoryPreferences((prev: CategoryPreferences) => ({
      ...prev,
      subCategories: {
        ...prev.subCategories,
        [subCategory.label]: !prev.subCategories?.[subCategory.label],
      },
    }));
  };

  const setSortPreference = (sort: string) => {
    saveCategoryPreferences((prev: CategoryPreferences) => ({
      ...prev,
      sort,
    }));
  };

  return {
    categoryPreferences,
    setCategoryPreference,
    setSubCategoriesPreference,
    setSortPreference,
  };
};

export default useCategoryPreferences;
