import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const Container = styled('div')`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: ${theme.color.avatarBlue};
  color: white;
`;
