import setConversationRead from './setConversationRead.action';
import * as inboxSelectors from '../selectors';

const bitBefore = (date) => new Date(new Date(date) - 1).toISOString();

const setConversationUnread =
  (conversationId) => async (dispatch, getState) => {
    const state = getState();

    const lastMessageDate =
      inboxSelectors.conversationLatestMessageDate(state, conversationId) ||
      inboxSelectors.conversation(state, conversationId).createdAt;

    const newReadUpTo = bitBefore(lastMessageDate);
    await dispatch(setConversationRead(newReadUpTo, conversationId));
  };

export default setConversationUnread;
