import * as types from '../../constants/actions';

const connection = (state = true, action) => {
  switch (action.type) {
    case types.CONNECTION_OFF:
      return false;
    case types.CONNECTION_ON:
      return true;
    default:
      return state;
  }
};

export default connection;
