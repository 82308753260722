import React from 'react';
import PropTypes from 'prop-types';
import Emoji from '../Emoji';

import { EmojiListContainer } from './EmojiPicker.styled';

const EmojiList = ({ emojis, onSelect, isShortcut }) => (
  <EmojiListContainer>
    {emojis.map((emoji) => (
      <Emoji
        isShortcut={isShortcut}
        emoji={emoji}
        key={`${emoji.short_name}`}
        withWrapper
        size={22}
        onSelect={onSelect}
      />
    ))}
  </EmojiListContainer>
);

EmojiList.propTypes = {
  emojis: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSelect: PropTypes.func.isRequired,
  isShortcut: PropTypes.bool,
};

EmojiList.defaultProps = {
  isShortcut: false,
};

export default EmojiList;
