import React, { useEffect, useState } from 'react';
import { Action } from 'redux';
import { TextField } from '@material-ui/core';
import { Tooltip, IconButton, icons } from '@ublend-npm/aulaui-next';
import { ThunkDispatch } from 'redux-thunk';
import ErrorIcon from '@material-ui/icons/Error';
import { useDispatch, useSelector } from 'react-redux';

import Dialog, { DialogContent, DialogActions } from '../../common/Dialog';
import style from '../../../../styles/presentational/ClassRoom/Inbox/inboxCreateGroupStyle';
import AutocompleteUser from '../common/AutocompleteUser';
import { openGroups } from '../../../../actions/navigation';
import { AulaState } from '@core/types/state';
import { operations as creators } from '../../../../store/conversations';
import * as appCreators from '../../../../actions/inboxv2';

import * as TEXT from '../../../../constants/texts';

const tooltipTitle =
  'Use groups to split students either automatically or manually into projects.';

interface InboxCreateConversationProps {
  open: boolean;
  onClose: () => {};
}

const InboxCreateConversation = (props: InboxCreateConversationProps) => {
  const { open, onClose } = props;

  const dispatch: ThunkDispatch<AulaState, unknown, Action> = useDispatch();
  const { selectedSpace: spaceId } = useSelector(
    (state: AulaState) => state.assignments || {}
  );

  const [users, setUsers] = useState([]);
  const [groupName, setGroupName] = useState('');
  const [showGroupName, setShowGroupName] = useState(false);
  const [activeNext, setActiveNext] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const resetInitialState = () => {
    setUsers([]);
    setGroupName('');
    setShowGroupName(false);
    setActiveNext(false);
  };

  /** set display state */
  useEffect(() => {
    setShowGroupName(users.length > 1);

    const singleUser = users.length === 1;
    const multipleUsersAndValidGroupName =
      groupName.length > 2 && users.length > 1;
    setActiveNext(singleUser || multipleUsersAndValidGroupName);
  }, [users, groupName]);

  const handleChangeUser = (newUsers: []) => {
    setUsers(newUsers);
  };

  const handleChangeConversationName = (e) => {
    setGroupName(e.target.value);
  };

  const handleRequestClose = () => {
    if (onClose) {
      onClose();
      resetInitialState();
    }
  };

  const handleCreateConversation = async () => {
    if ((showGroupName && groupName && users.length) || users.length === 1) {
      setIsSaving(true);
      setActiveNext(false);

      const group = await dispatch(
        creators.createConversation({
          spaceId,
          title: groupName || '-',
          users,
          educatorOnly: false,
        })
      );

      if (!group) {
        setIsSaving(false);
        return;
      }

      if (group) {
        dispatch(appCreators.selectConversation(group.objectId));
      }

      setIsSaving(false);
      handleRequestClose();
      resetInitialState();
    }
  };

  const handleGoToGroups = () => {
    dispatch(openGroups(spaceId));
    handleRequestClose();
  };

  return (
    <Dialog
      id="create-conversation-dialog"
      open={open}
      onClose={handleRequestClose}
      maxWidth="md"
      style={{ zIndex: 1600 }}
    >
      <div style={style.headerContainer}>
        <h2
          style={style.header}
          id="create-conversation-dialog-dialogtitle"
          data-testid="create-conversation-dialog-title"
        >
          Create a conversation
        </h2>
        <div style={style.iconButtonContainer}>
          <IconButton
            label="Close Dialog"
            disableRipple
            onClick={handleRequestClose}
            icon={icons.Close}
            size="small"
          />
        </div>
      </div>
      <DialogContent>
        <div style={style.wrapper}>
          <div style={style.to}>To:</div>
          <div style={style.autocompleteWrapper}>
            <AutocompleteUser onChange={handleChangeUser} />
          </div>
          {showGroupName ? (
            <>
              <TextField
                placeholder={TEXT.INPUT_TEXT_INBOX_GROUP}
                label={TEXT.INPUT_LABEL_CONVERSATION_TITLE}
                onChange={handleChangeConversationName}
                style={style.groupNameField}
                aria-label={TEXT.INPUT_LABEL_CONVERSATION_TITLE}
              />
              <div style={style.groupInfo}>
                If you want to create a group, go to the &nbsp;
                <span
                  style={style.groupLink}
                  onClick={handleGoToGroups}
                  onKeyPress={handleGoToGroups}
                  role="link"
                  onKeyDown={() => null}
                  tabIndex={0}
                >
                  Groups &nbsp;
                </span>
                page.
                <Tooltip
                  title={tooltipTitle}
                  position="right"
                  style={style.tooltip}
                  classes={{ popper: style.popper }}
                >
                  <span>
                    <IconButton
                      size="small"
                      type="secondary"
                      label="groups information"
                      icon={ErrorIcon}
                    />
                  </span>
                </Tooltip>
              </div>
            </>
          ) : null}
        </div>
      </DialogContent>
      <DialogActions
        primary={{
          className: 'create-conversation-group',
          label: 'Next',
          onClick: handleCreateConversation,
          loading: isSaving,
          disabled: !activeNext,
          htmlAttributes: {
            'aria-label': 'Next',
            'data-testid': 'create-coversation-next-button',
          },
        }}
        secondary={{
          label: 'Cancel',
          onClick: handleRequestClose,
        }}
      />
    </Dialog>
  );
};

export default InboxCreateConversation;
