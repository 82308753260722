import * as actions from '../actionsTypes';

import fetchNotificationSettingsReducer from './fetchNotificationSettings.data.reducer';
import saveNotificationSettingsReducer from './saveNotificationSettings.data.reducer';

const initialState = {
  settings: {
    push: {
      messages: false,
      posts: false,
      comments: false,
    },
    email: {
      messages: false,
      posts: false,
      comments: false,
    },
  },
};

const reducerMap = {
  [actions.NOTIFICATION_SETTINGS_FETCH]: fetchNotificationSettingsReducer,
  [actions.NOTIFICATION_SETTINGS_SAVE]: saveNotificationSettingsReducer,
};

export default (state = initialState, action) => {
  const reducer = reducerMap[action.type];
  if (typeof reducer === 'undefined') return state;
  return reducer(state, action);
};
