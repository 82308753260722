import * as api from '../api/directMessages';
import { INBOX_CREATE_CONVERSATION } from '../actions';
import { fetchUsersSuccess } from '../../actions/users/fetchUsers.action';
import * as userSelectors from '../../selectors/user';

const getObjectId = (obj) => obj.objectId;

const sendCreateConversationStarted = (dispatch, payload) =>
  dispatch({
    type: INBOX_CREATE_CONVERSATION,
    status: 'started',
    payload,
  });

const sendCreateConversationSuccess = (dispatch, payload) =>
  dispatch({
    type: INBOX_CREATE_CONVERSATION,
    status: 'success',
    payload,
  });

const sendCreateConversationError = (dispatch, payload) =>
  dispatch({
    type: INBOX_CREATE_CONVERSATION,
    status: 'error',
    payload,
  });

const createConversation =
  ({ spaceId, educatorOnly, title, users }) =>
  async (dispatch, getState) => {
    const state = getState();
    const { objectId: userId } = userSelectors.currentUser(state);
    const participantIds = (users && [userId, ...users.map(getObjectId)]) || [];
    const payload = {
      title,
      participantIds,
      spaceId,
      educatorOnly,
    };
    sendCreateConversationStarted(dispatch, payload);
    try {
      const conversation = await api.conversations.createConversation({
        spaceId,
        educatorOnly: Boolean(educatorOnly),
        participantIds,
        title,
      });

      if (users) {
        fetchUsersSuccess(dispatch, {
          requestedUsers: users.map(getObjectId),
          users,
        });
      }

      await sendCreateConversationSuccess(dispatch, {
        ...payload,
        conversation,
      });
      return { objectId: conversation.id };
    } catch (error) {
      sendCreateConversationError(dispatch, { ...payload, error });
      // eslint-disable-next-line consistent-return
      return undefined;
    }
  };

export default createConversation;
