import * as actionTypes from '../../constants/actions';
import { newError } from '../errors';
import { NO_CLASS_ROOM_ERROR_MESSAGE } from './posts';
import { fetchTagPostsSuccess } from '../tags/tagPost';
import fetchReactionsSuccess from '../../reactions/operations/fetchReactionsSuccess.operation';
import { fetchTags } from '../tags/tag';
import getFeed from '../../api/posts/feed';
import { ANNOUNCEMENTS } from '../../constants/postFeedType';
import {
  STARTED_STATUS,
  SUCCESS_STATUS,
  ERROR_STATUS,
} from '../../constants/actionsStatus';
import { getUntilDate } from './utils';
import { buildFetchScheduledPostsSuccess } from './scheduledPosts.action';

const fetchAnnouncementsStart = (reset, spaceId, dispatch) => {
  dispatch({
    type: actionTypes.SPACE_FETCH_ANNOUNCEMENTS,
    status: STARTED_STATUS,
    reset,
    spaceId,
  });
};

const fetchAnnouncementsSuccess = (content, dispatch) => {
  dispatch({
    type: actionTypes.SPACE_FETCH_ANNOUNCEMENTS,
    status: SUCCESS_STATUS,
    content,
  });
};

const fetchAnnouncementsError = (error, dispatch) => {
  dispatch(newError(error, 'fetchAnnouncementsError'));
  dispatch({
    type: actionTypes.SPACE_FETCH_ANNOUNCEMENTS,
    status: ERROR_STATUS,
    error,
  });
};

export const fetchAnnouncements =
  ({
    classRoom,
    reset = false,
    latest = false,
    tagId,
    shouldFetchTags = false,
  }) =>
  async (dispatch) => {
    try {
      if (!classRoom) {
        const err = { message: NO_CLASS_ROOM_ERROR_MESSAGE };
        fetchAnnouncementsError(err, dispatch);
        return;
      }

      const spaceId = classRoom.objectId;
      const until = getUntilDate({
        reset,
        latest,
        posts: classRoom.announcements,
        oldestPostDate: classRoom.oldestAnnouncementDate,
      });

      fetchAnnouncementsStart(reset, spaceId, dispatch);
      if (shouldFetchTags) {
        dispatch(fetchTags(classRoom.objectId));
      }

      const [announcementPosts, scheduledAnnouncementPosts] = await Promise.all(
        [
          getFeed({ spaceId, until, tagId, feedType: ANNOUNCEMENTS }),
          getFeed({ spaceId, feedType: ANNOUNCEMENTS, scheduled: true }),
        ]
      );

      const { posts: announcements, reactions, tagPosts } = announcementPosts;
      fetchAnnouncementsSuccess(announcements, dispatch);
      fetchTagPostsSuccess(tagPosts, dispatch);
      dispatch(
        buildFetchScheduledPostsSuccess(
          actionTypes.FETCH_SCHEDULED_ANNOUNCEMENT,
          scheduledAnnouncementPosts.posts
        )
      );
      dispatch(fetchReactionsSuccess(reactions));
    } catch (error) {
      fetchAnnouncementsError(error, dispatch);
      throw error;
    }
  };
