import { combineReducers } from 'redux';
import clients from './clients';
import conversations from './conversations';

const liveReducer = combineReducers({
  clients,
  conversations,
});

export default liveReducer;
