import { Action } from 'redux';
import { ERROR_NEW, ERROR_CLEAN } from '../../constants/actions';
import Raven from '../../utils/raven';

export type NewErrorAction = Action<typeof ERROR_NEW> & {
  error: unknown;
  from: string;
};
export type CleanErrorAction = Action<typeof ERROR_CLEAN>;

export const newError = (error: any, from: string): NewErrorAction => {
  const errorInfo: NewErrorAction = {
    type: ERROR_NEW,
    error,
    from,
  };
  Raven.captureException(error, {
    extra: {
      errorCode: error.code,
      ...errorInfo,
      level: 'error',
    },
    tags: {
      context: 'actions',
    },
  });
  return errorInfo;
};

export const cleanError = (): CleanErrorAction => ({
  type: ERROR_CLEAN,
});
