const registerLive =
  ({ onReactionAdded, onReactionRemoved }) =>
  (socket) =>
  (dispatch) => {
    socket.on('Reaction:ReactionAdded', (data) =>
      dispatch(onReactionAdded(data))
    );
    socket.on('Reaction:ReactionRemoved', (data) =>
      dispatch(onReactionRemoved(data))
    );
  };

export default registerLive;
