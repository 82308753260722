import fetchAssignments from './fetchAssignments.operation';
import {
  currentUser as getCurrentUser,
  accessToken as getAccessToken,
} from '../../selectors/user';
import { currentClassRoomId as getCurrentClassRoomId } from '../../selectors/classroom';

const onAssignmentSaved =
  ({ assignment }) =>
  (dispatch, getState) => {
    const state = getState();
    const user = getCurrentUser(state);
    const spaceId = getCurrentClassRoomId(state);
    const assignmentSpaceId = assignment
      ? assignment.space || (assignment.classRoom || {}).objectId
      : null;

    if (assignmentSpaceId !== spaceId) {
      return;
    }

    const accessToken = getAccessToken(state, spaceId);

    fetchAssignments(
      dispatch,
      getState
    )({
      user,
      spaceId,
      accessToken,
    });
  };

export default onAssignmentSaved;
