import { DeleteFolderAction } from '../sharedActions';
import { MaterialAppReducer } from './state';

const deleteFolder: MaterialAppReducer<DeleteFolderAction> = (
  state,
  action
) => {
  switch (action.status) {
    case 'started':
      return {
        ...state,
        isDeletingFolder: true,
      };
    case 'success':
    case 'error':
      return {
        ...state,
        isDeletingFolder: false,
        deleteFolderDialogOpenFor: null,
      };
    default:
      return state;
  }
};

export default deleteFolder;
