import { ExportAllSectionsContract } from '@ublend-npm/aula-schema/types/materials';
import { CLASSROOM_EXPORT_MATERIAL_SECTIONS } from '../../constants/endpoints';
import request, { getData } from './request';

const exportSections = async (classRoomId: string, educatorOnly = false) => {
  const apiUrl = CLASSROOM_EXPORT_MATERIAL_SECTIONS(classRoomId, educatorOnly);
  const { url } = await request<ExportAllSectionsContract>({
    method: 'get',
    url: apiUrl,
  }).then(getData);
  return url;
};

export default exportSections;
