import { GetSectionContract } from '@ublend-npm/aula-schema/types/materials';
import { CancelToken } from 'axios';
import cancellableRequest from '../../api/cancellableRequest';
import { CLASSROOM_MATERIAL_SECTION } from '../../constants/endpoints';
import request from './request';
import toSection from './toSection';

const getSection =
  (cancelToken?: CancelToken) =>
  async (classRoomId: string, sectionId: string) => {
    const url = CLASSROOM_MATERIAL_SECTION(classRoomId, sectionId);
    const response = await request<GetSectionContract>({
      method: 'get',
      url,
      cancelToken,
    });
    return toSection(response.data);
  };

export default cancellableRequest(getSection).fn;
