import { GROUPS_DELETE_GROUP } from '../actions';
import { deleteGroup as deleteGroupApi } from '../api';

import refreshAccessToken from '../../actions/accessToken/refreshAccessToken.action';
import { currentClassRoomId as getCurrentClassRoomId } from '../../selectors/classroom';
import showToast from '../../toasts/operations/showToast.operation';
import { DELETE_GROUP_SUCCESS, DELETE_GROUP_ERROR } from './toasts';

const deleteGroupSuccess = (payload) => ({
  type: GROUPS_DELETE_GROUP,
  status: 'success',
  payload,
});

const deleteGroupError = (payload) => ({
  type: GROUPS_DELETE_GROUP,
  status: 'error',
  payload,
});

const deleteGroup = (groupId) => async (dispatch, getState) => {
  const payload = { groupId };
  try {
    const state = getState();
    const spaceId = getCurrentClassRoomId(state);
    const accessToken = await refreshAccessToken(
      dispatch,
      getState
    )({ spaceId });
    const { studentsWithNoGroup } = await deleteGroupApi(accessToken, groupId);
    dispatch(
      deleteGroupSuccess({
        ...payload,
        studentsWithNoGroup,
      })
    );
    dispatch(showToast(DELETE_GROUP_SUCCESS));
  } catch (error) {
    dispatch(showToast(DELETE_GROUP_ERROR));
    dispatch(deleteGroupError({ ...payload, error }));
  }
};

export default deleteGroup;
