import thunk from 'redux-thunk';
import { hashHistory } from 'react-router';
import { routerMiddleware } from 'react-router-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createLogger } from 'redux-logger';

import errorObserver from './errorObserver';
import locationObserver from './locationObserver';

import rootReducer from '../reducers';
import electron from './middlewares/electron';
import joinConversation from '../../core/middlewares/joinConversation.middleware';
import subcribeToSpaceLevelEvents from '../../core/middlewares/subcribeToSpaceLevelEvents.middleware';
import initDucks from './ducks';
import { setStore as setEndpointsStore } from '../../core/constants/endpoints';
import { setStore as setRoutingStore } from '../utils/routing';
import registerWindowListeners from '../utils/registerWindowListeners';
import cypress from './middlewares/cypress';

const ENV = (process && process.env) || {};

const customLogger = () => (next) => (action) => next(action);

const checkedReducer = (ducks) => {
  const root = rootReducer(ducks);
  return (state, action) => {
    if (action.type === 'USER_LOGOUT') {
      const { routing } = state;
      return root({ routing }, action);
    }

    return root(state, action);
  };
};

const reduxRoutermiddleware = routerMiddleware(hashHistory);

let enhancer = null;
const middleware =
  ENV.LOCAL && !ENV.DISABLE_REDUX_LOGGER
    ? [
        thunk,
        reduxRoutermiddleware,
        createLogger({
          level: 'log',
          collapsed: true,
          duration: true,
          diff: false,
        }),
        customLogger,
        joinConversation,
        subcribeToSpaceLevelEvents,
        electron,
      ]
    : [
        thunk,
        reduxRoutermiddleware,
        joinConversation,
        subcribeToSpaceLevelEvents,
        electron,
      ];

if (window.Cypress) {
  middleware.push(cypress);
}

if (ENV.REDUX_DEVTOOLS) {
  enhancer = composeWithDevTools(applyMiddleware(...middleware));
} else {
  enhancer = compose(applyMiddleware(...middleware));
}

let store = null;

export const getStore = () => store;
window.getStore = getStore;

export const configureStore = (initialState) => {
  const ducks = initDucks();
  const r = checkedReducer(ducks);
  store = createStore(r, initialState, enhancer);

  setEndpointsStore(store);
  setRoutingStore(store);

  locationObserver(store);
  errorObserver(store);

  store.dispatch(registerWindowListeners);

  if (process && process.env && process.env.LOCAL && module.hot) {
    module.hot.accept('../reducers', () =>
      // eslint-disable-next-line global-require
      store.replaceReducer(require('../reducers')(ducks))
    );
  }

  return store;
};
