import React from 'react';
import { render } from 'react-dom';
import browserCheck from '@ublend-npm/browser-check';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { fetchInstitution } from '@core/actions/institutions';
import App from './app';
import { configureStore } from './store';

if (module.hot) {
  module.hot.accept();
}

const defaultAnonymousUserKey = '4382d193-adc7-41ab-9af7-1000a4715915';

const getInstitution = () => {
  const host = window.location.hostname.split('.');

  return process.env.INSTITUTION || (host[0] === 'beta' ? host[1] : host[0]);
};

const getInstitutionData = async (store) => {
  await store.dispatch(fetchInstitution(getInstitution()));

  return store.getState().institution;
};

(async () => {
  if (browserCheck.isAllowedBrowser()) {
    const store = configureStore();
    const institutionData = await getInstitutionData(store);
    const {
      keys: { launchDarkly },
      general: { institution },
    } = institutionData;
    const LDProvider = await asyncWithLDProvider({
      clientSideID: launchDarkly?.clientSideId,
      context: {
        kind: 'user',
        key: defaultAnonymousUserKey,
        institution,
      },
    });

    if (process.env.NODE_ENV === 'local') {
      // eslint-disable-next-line global-require
      const { worker } = require('../test/mocks/browser');
      worker.start({
        onUnhandledRequest: 'bypass',
      });
    }

    render(
      <LDProvider>
        <App store={store} institutionData={institutionData} />
      </LDProvider>,
      document.getElementById('app')
    );
  } else {
    render(browserCheck.AutoWarning(), document.getElementById('app'));
  }
})();
