let currentUser = null;

export const setCurrentUser = (user) => {
  currentUser = user;
};

export const getCurrentUser = () => ({
  ...currentUser,
  id: currentUser.id || currentUser.objectId,
});
