import React from 'react';
import PropTypes from 'prop-types';

import { Button, ButtonContainer } from './UserStatus.styled';
import { ENTER, SPACE } from '@app/utils/keyCodes';

const StatusButtons = ({
  initialStatusText,
  initialEmojiShortName,
  statusText,
  emojiShortName,
  onSave,
  onUndo,
  isSaving,
}) => {
  const isUnchanged =
    initialStatusText === statusText &&
    initialEmojiShortName === emojiShortName;
  const isEmpty = !emojiShortName.length;

  if (isUnchanged && isEmpty) {
    return null;
  }

  let buttonTitle;
  if (isUnchanged) buttonTitle = 'Status set';
  else if (!initialStatusText.length || !initialEmojiShortName.length)
    buttonTitle = 'Set status';
  else buttonTitle = 'Update status';

  return (
    <ButtonContainer>
      {!isUnchanged && !isSaving && (
        <Button
          data-testid="undo-changes-button"
          onClick={onUndo}
          type="secondary"
          onKeyDown={(e) =>
            (e.keyCode === ENTER || e.keyCode === SPACE) && onUndo()
          }
        >
          Undo changes
        </Button>
      )}
      <Button
        data-testid="set-status-button"
        onClick={onSave}
        type="primary"
        loading={isSaving}
        disabled={isUnchanged || isSaving}
        onKeyDown={(e) =>
          (e.keyCode === ENTER || e.keyCode === SPACE) && onSave()
        }
      >
        {buttonTitle}
      </Button>
    </ButtonContainer>
  );
};

StatusButtons.propTypes = {
  initialStatusText: PropTypes.string.isRequired,
  initialEmojiShortName: PropTypes.string.isRequired,
  statusText: PropTypes.string.isRequired,
  emojiShortName: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onUndo: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
};

export default StatusButtons;
