import { analyticsTrackEvent } from '../../core/utils/analytics';
import * as analyticsTypes from '../../core/constants/analytics';

// Returns the section from the pathname, shown here in square brackets: /dashboard/864bde4b-b349-4479-bc08-0f6d8a1b81b2/[settings or journey or community etc]
const getSectionFromPath = (pathSegments) => pathSegments[3];
// Returns the subSection from the pathname, shown here in square brackets: /dashboard/864bde4b-b349-4479-bc08-0f6d8a1b81b2/journey/[materials or post etc]
const getSubSectionFromPath = (pathSegments) => pathSegments[4];

// Returns an array of query param keys for which the the value changed
const getChangedQueryParamsKeys = ({ prevQuery, nextQuery }) => {
  const enabledKeys = Object.keys({ ...prevQuery, ...nextQuery }).filter(
    (key) => key && prevQuery[key] !== nextQuery[key]
  );

  return enabledKeys;
};

const sectionToEventTypeMap = {
  settings: analyticsTypes.SETTINGS_VIEW,
};

const locationObserver = (store) => {
  let currentState = null;

  const handleChange = () => {
    const previousState = currentState;
    const nextState = store.getState().routing.locationBeforeTransitions;

    if (!nextState) return;

    // Calculate path changes
    const pathnameChanged =
      !currentState || nextState.pathname !== currentState.pathname;

    // Calculate query string changes
    const prevQuery = (previousState || {}).query || {};
    const nextQuery = (nextState || {}).query || {};
    const changedQueryParams = getChangedQueryParamsKeys({
      prevQuery,
      nextQuery,
    });
    const addedQueryParams = changedQueryParams.filter(
      (key) => !!nextQuery[key]
    );
    const queryChanged = changedQueryParams.length;

    // We only handle path and query string changes for now
    if (!(pathnameChanged || queryChanged)) return;

    currentState = nextState;

    if (pathnameChanged) {
      if (nextState.pathname) {
        const pathSegments = nextState.pathname.split('/');
        const section = getSectionFromPath(pathSegments);
        const subSection = getSubSectionFromPath(pathSegments);

        if (pathSegments[1] === 'dashboard' && pathSegments.length > 3) {
          const activeClassroom = pathSegments[2];
          let eventType = sectionToEventTypeMap[subSection];
          const properties = { activeClassroom };

          if (section === 'settings') {
            analyticsTrackEvent(sectionToEventTypeMap.settings, properties);
          }

          if (!subSection) {
            return;
          }

          if (activeClassroom !== 'feed') {
            if (subSection === 'assignments') {
              const hasAssignmentId =
                pathSegments[pathSegments.length - 1] === 'submissions';
              const assignmentId = pathSegments[pathSegments.length - 2];

              if (hasAssignmentId && assignmentId) {
                eventType = analyticsTypes.SUBMISSIONS_VIEW;
              }
            }

            // Tracking material view is done in components/presentational/ClassRoom/Material/Material.jsx
            // Tracking post view is done in components/presentational/ClassRoom/Feed/Post/Post.jsx
            if (
              eventType &&
              subSection !== 'materials' &&
              subSection !== 'post'
            ) {
              analyticsTrackEvent(eventType, properties);
            }
          }
        }
      }
    }

    if (queryChanged) {
      if (changedQueryParams.includes('inbox') && nextQuery.inbox) {
        analyticsTrackEvent(analyticsTypes.CONVERSATION_VIEW, {
          itemId: nextQuery.inbox,
        });
      }
      if (addedQueryParams.includes('notifications')) {
        analyticsTrackEvent(analyticsTypes.NOTIFICATIONS_VIEW);
      }
    }
  };

  const unsubscribe = store.subscribe(handleChange);
  handleChange();

  return unsubscribe;
};

export default locationObserver;
