import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { getCurrentUser } from '../../utils/currentUser';
import getAnalyticsHeaders from '../../utils/analyticsHeaders';
import { EndpointContract } from '@ublend-npm/aula-schema';

interface MaterialsRequestHeaders {
  'x-user-id': string;
}

const getCredentialHeaders = (): MaterialsRequestHeaders => {
  const user = getCurrentUser();
  return {
    'x-user-id': user.id,
  };
};

export const getData = <T>(response: AxiosResponse<T>) => response.data;

const request = async <T extends EndpointContract = EndpointContract>(
  config: AxiosRequestConfig<T['body']>
) => {
  const response = await axios<T['responseBody']>({
    ...config,
    withCredentials: true,
    headers: {
      ...config.headers,
      ...getCredentialHeaders(),
      ...getAnalyticsHeaders(),
    },
  });

  return response;
};

export default request;
