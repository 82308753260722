import sortByEndDate from './utils/sortByEndDate';
import filterAssignment from './utils/filterAssignment';

const convertAssignmentReducer = (state, action) => {
  if (action.status !== 'success') return state;

  const {
    payload: { assignment },
  } = action;

  const parentAssignment = state.assignments.find(
    (item) => item.id === assignment.parentAssignmentId
  );

  let remainingAssignments;
  if (assignment.id === parentAssignment.id) {
    remainingAssignments = state.assignments
      .filter(filterAssignment(parentAssignment))
      .filter(filterAssignment(assignment));
  } else {
    remainingAssignments = state.assignments.filter(
      filterAssignment(parentAssignment)
    );
  }

  parentAssignment.extensions = [
    ...(parentAssignment.extensions || []),
    assignment,
  ];

  // in case of update, push back object to array, then sort by due date
  remainingAssignments.push(parentAssignment);
  remainingAssignments.sort(sortByEndDate);

  return {
    ...state,
    assignments: remainingAssignments,
  };
};

export default convertAssignmentReducer;
