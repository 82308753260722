import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import EcoIcon from '@material-ui/icons/Eco';
import LocalPizzaIcon from '@material-ui/icons/LocalPizza';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FlagIcon from '@material-ui/icons/Flag';

export default [
  {
    name: 'People',
    Icon: EmojiEmotionsIcon,
  },
  {
    name: 'Nature',
    Icon: EcoIcon,
  },
  {
    name: 'Foods',
    Icon: LocalPizzaIcon,
  },
  {
    name: 'Activity',
    Icon: SportsSoccerIcon,
  },
  {
    name: 'Places',
    Icon: DirectionsBikeIcon,
  },
  {
    name: 'Objects',
    Icon: EmojiObjectsIcon,
  },
  {
    name: 'Symbols',
    Icon: FavoriteIcon,
  },
  {
    name: 'Flags',
    Icon: FlagIcon,
  },
];
