import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import accessTokens from '@core/reducers/accessTokens';
import classRoomUser from '@core/reducers/classRoomUser';
import comment from '@core/reducers/comment';
import connection from '@core/reducers/connection';
import data from '@core/reducers/data';
import devices from '@core/reducers/devices';
import emojis from '@core/reducers/emoji';
import errors from '@core/reducers/errors';
import institution from '@core/reducers/institution';
import live from '@core/reducers/live';
import lti from '@core/reducers/lti';
import navigationBar from '@core/reducers/navigationBar';
import omniSearch from '@core/reducers/omniSearch';
import posts from '@core/reducers/posts';
import search from '@core/reducers/search';
import socket from '@core/reducers/socket';
import space from '@core/reducers/space';
import spaceSelector from '@core/spaceSelector/reducers';
import ui from '@core/reducers/ui';
import user from '@core/reducers/user';
import users from '@core/reducers/users';

import signin from './signin';
import focus from './focus';
import signedCookie from './signedCookie';

const set = (o, key, value) => {
  // eslint-disable-next-line no-param-reassign
  o[key] = value;
  return o;
};

// Map ducks - shaped like { dataReducers, appReducers, operations, selections ... }
// into an object of duck name to data reducers like { nameOfDuck: dataReducers }
const extractDataReducers = (ducks) =>
  Object.entries(ducks)
    .map(([key, { dataReducer }]) => [key, dataReducer])
    .reduce((o, [key, value]) => set(o, key, value), {});

const rootReducer = (ducks) =>
  combineReducers({
    live,
    data: data(extractDataReducers(ducks)),
    ui: ui(),
    user,
    errors,
    comment,
    posts,
    materials: ducks.materials.appReducer,
    inboxv2: ducks.inboxv2.appReducer,
    groups: ducks.groups.appReducer,
    liveNotifications: ducks.liveNotifications.appReducer,
    toasts: ducks.toasts.appReducer,
    reactions: ducks.reactions.appReducer,
    assignments: ducks.assignments.appReducer,
    classRoom: space,
    classRoomUser,
    navigationBar,
    search,
    omniSearch,
    emojis,
    institution,
    signin,
    lti,
    connection,
    socket,
    devices,
    users,
    accessTokens,
    // React router redux binding
    routing: routerReducer,
    focus,
    signedCookie,
    spaceSelector,
  });

export default rootReducer;
