import { MaterialDataReducer } from './state';
import { FetchSectionsAction } from '../sharedActions';
import { reduceById } from '../utils';

const fetchSectionsDataReducer: MaterialDataReducer<FetchSectionsAction> = (
  state,
  action
) => {
  if (action.status !== 'success') {
    return state;
  }

  const { materials } = action.payload;

  return {
    ...state,
    ...materials.reduce(reduceById, {}),
  };
};

export default fetchSectionsDataReducer;
