import { newError } from '../errors';
import * as actionTypes from '../../constants/actions';
import { analyticsTrackEvent } from '../../utils/analytics';
import * as analytics from '../../constants/analytics';
import * as userSelectors from '../../selectors/user';
import patchUser from '../../api/users/patchUser';

const changeUserStatusStart = (dispatch) => {
  dispatch({
    type: actionTypes.USER_CHANGE_STATUS,
    status: 'started',
  });
};

const changeUserStatusSuccess = (statusData, dispatch) => {
  dispatch({
    type: actionTypes.USER_CHANGE_STATUS,
    status: 'success',
    statusData,
  });
};

const changeUserStatusError = (error, dispatch) => {
  dispatch(newError(error, 'userChangeStatus'));
  dispatch({
    type: actionTypes.USER_CHANGE_STATUS,
    status: 'error',
    error,
  });
};

const changeUserStatus = (data) => async (dispatch, getState) => {
  changeUserStatusStart(dispatch);
  try {
    const state = getState();
    await patchUser({
      userId: userSelectors.currentUserId(state),
      patch: data.status.emojiName ? data : { status: null },
    });
    changeUserStatusSuccess(data, dispatch);
    analyticsTrackEvent(analytics.USER_CHANGE_STATUS);
  } catch (error) {
    changeUserStatusError(error, dispatch);
    throw error;
  }
};

export default changeUserStatus;
