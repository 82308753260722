import { toggleHideAssignment as toggleHideAssignmentApi } from '../api';
import { TOGGLE_HIDE_ASSIGNMENT } from '../actions';
import { accessToken as getAccessToken } from '../../selectors/user';
import { Assignment } from '../types';
import { Dispatch } from 'redux';

const toggleHideAssignmentStarted = (
  dispatch: Dispatch,
  assignment: Assignment
) => dispatch({ type: TOGGLE_HIDE_ASSIGNMENT, status: 'started', assignment });

const toggleHideAssignmentSuccess = (dispatch: Dispatch) =>
  dispatch({ type: TOGGLE_HIDE_ASSIGNMENT, status: 'success' });

const toggleHideAssignmentError = (
  dispatch: Dispatch,
  assignment: Assignment
) => dispatch({ type: TOGGLE_HIDE_ASSIGNMENT, status: 'error', assignment });

const toggleHideAssignment =
  ({ spaceId, assignment }) =>
  async (dispatch, getState) => {
    toggleHideAssignmentStarted(dispatch, assignment);

    const state = getState();
    const accessToken = getAccessToken(state, spaceId) as string;

    if (!accessToken) {
      toggleHideAssignmentError(dispatch, assignment);
      return null;
    }

    try {
      const { data } = await toggleHideAssignmentApi({
        assignmentId: assignment.id,
        accessToken,
      });

      if (!data || !data.id) {
        throw new Error('failed to toggle assignment hidden status');
      }

      toggleHideAssignmentSuccess(dispatch);
    } catch (e) {
      toggleHideAssignmentError(dispatch, assignment);
    }
  };

export default toggleHideAssignment;
