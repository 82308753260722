import { REACTION } from '../../constants/endpoints';
import request from '../../api/request';

const deleteReaction = async (reactionId) => {
  const url = REACTION(reactionId);
  const {
    data: { reaction },
  } = await request({
    method: 'delete',
    url,
  });
  return reaction;
};

export default deleteReaction;
