import React from 'react';
import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

import BaseDialog from './Dialog';

const sizeTable = {
  xs: '352px',
  sm: '492px',
  md: '620px',
};

const Dialog = styled(({ classes, ...props }) => (
  <BaseDialog
    {...props}
    classes={{
      ...classes,
      paper: `dialogpaper ${(classes || {}).paper || ''}`,
    }}
  />
))`
  color: ${theme.color.mediumBlueDark};
  & * div.dialogpaper {
    max-width: ${(props) => sizeTable[props.maxWidth ? props.maxWidth : 'sm']};
    ${(props) => {
      return props.minHeight ? `min-height: ${props.minHeight}px` : '';
    }}
    ${(props) => {
      return props.height ? `height: ${props.height}px` : '';
    }}
  }
`;

export default Dialog;
