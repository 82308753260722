import debounce from 'lodash.debounce';
import { INBOX_FILTER_CONVERSATIONS } from '../actions';
import { conversations } from '../selectors';
import { analyticsTrackEvent } from '../../utils/analytics';
import {
  INBOX_MESSAGE_ITEM_TYPE,
  SEARCH_INBOX_DM,
} from '../../constants/analytics';

const filterConversationsStarted = (dispatch, payload) =>
  dispatch({
    type: INBOX_FILTER_CONVERSATIONS,
    status: 'started',
    payload,
  });

const filterConversationsSuccess = (dispatch, payload) =>
  dispatch({
    type: INBOX_FILTER_CONVERSATIONS,
    status: 'success',
    payload,
  });

const stringIsContained = ({ parent, child }) =>
  parent?.toLowerCase &&
  child?.toLowerCase &&
  parent.toLowerCase().trim().includes(child.toLowerCase().trim());

const debounceAnalyticsEvent = debounce((query) => {
  if (query) {
    analyticsTrackEvent(SEARCH_INBOX_DM, {
      itemType: INBOX_MESSAGE_ITEM_TYPE,
      search: query,
    });
  }
}, 1000);

const filterConversations = (query) => async (dispatch, getState) => {
  filterConversationsStarted(dispatch, { query });

  const state = getState();

  const conversationIds = Object.values(conversations(state)).reduce(
    (matches, { title, id }) =>
      stringIsContained({ parent: title, child: query })
        ? [...matches, id]
        : matches,
    []
  );

  filterConversationsSuccess(dispatch, { query, conversationIds });

  debounceAnalyticsEvent(query);
};

export default filterConversations;
