import React, { Component } from 'react';
import { Paper } from '@material-ui/core';
import style from './style';
import { ERROR_EDITOR } from '../../../../constants/texts';
import Raven from '../../../../utils/raven';

export { style as errorStyles };

function withErrorBoundary(
  WrappedComponent,
  { message = null, FallbackComponent = null, onError = null, ignore = false }
) {
  class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
      console.log(error);
      if (typeof onError === 'function') {
        try {
          onError.call(this, error, info ? info.componentStack : '');
        } catch (ignoredError) {
          // ignore error
        }
      }

      Raven.captureException(error, {
        extra: info,
        tags: {
          context: 'errorBoundary',
        },
      });
      this.setState({ hasError: true, error, info });
    }

    render() {
      const { wrappedRef, forwardedRef, ...rest } = this.props;
      if (this.state.hasError && !ignore) {
        if (FallbackComponent) {
          return <FallbackComponent error={message} info={this.state.info} />;
        }
        return (
          <Paper style={style.defaultErrorComponent} elevation={2}>
            {message || ERROR_EDITOR}
          </Paper>
        );
      }
      // TODO: remove wrappedRef prop when TH90 is fully deprecated
      return <WrappedComponent ref={wrappedRef || forwardedRef} {...rest} />;
    }
  }
  return React.forwardRef((props, ref) => {
    return <ErrorBoundary {...props} forwardedRef={ref} />;
  });
}

export default withErrorBoundary;
