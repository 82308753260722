import PropTypes from 'prop-types';
import React from 'react';
import { Container, ConversationIcon } from './InboxItemIcon.styled';

const InboxItemIcon = ({ conversationId, unread, ...otherProps }) => (
  <Container {...otherProps}>
    <ConversationIcon conversationId={conversationId} unread={unread} />
  </Container>
);

InboxItemIcon.propTypes = {
  conversationId: PropTypes.string.isRequired,
  unread: PropTypes.bool,
};

InboxItemIcon.defaultProps = {
  unread: false,
};

export default InboxItemIcon;
