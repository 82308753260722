import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, icons, ChipSelector } from '@ublend-npm/aulaui-next';
import * as NotificationType from '@core/constants/notificationTypes';
import {
  emptyNotificationMessages,
  groupNotificationsByFilterType,
  filterUnreadStatus,
  hasUnreadNotifications,
  isUnread,
  notificationFilters,
  hasNewNotificationOfType,
} from '@core/utils/notificationFilters';
import NotificationCell from './NotificationCell';
import {
  ActionButtons,
  Divider,
  NoNotification,
  NotificationActionButtonsContainer,
  NotificationTypeFilterWrapper,
  NotificationsList,
} from './NotificationsTable.styled';

const NotificationsTable = ({
  notifications,
  notificationTypeFilter,
  changeNotificationTypeFilter,
  onOpenNotificationsSettings,
  setRead,
  getNotifications,
  isFetchingNotifications,
}) => {
  const [initialLoadingStarted, setInitialLoadingStarted] = useState(false);
  const [notificationTypesRead, setNotificationTypesRead] = useState({
    [NotificationType.ALL]: true,
  });

  const [groupedNotifications, setGroupedNotifications] = useState(
    groupNotificationsByFilterType(notifications)
  );

  useEffect(() => {
    // Reset unread status if there are new notifications
    const newGroupedNotifications =
      groupNotificationsByFilterType(notifications);

    if (
      hasNewNotificationOfType(
        newGroupedNotifications,
        groupedNotifications,
        NotificationType.ALL
      )
    ) {
      const nextNotificationsTypesRead = notificationFilters.reduce(
        (accum, filterType) => ({
          ...accum,
          ...(hasNewNotificationOfType(
            groupedNotifications,
            newGroupedNotifications,
            filterType
          ) &&
            notificationTypeFilter !== filterType && {
              [filterType]: false,
            }),
        }),
        notificationTypesRead
      );

      setNotificationTypesRead(nextNotificationsTypesRead);
    }
  }, [
    notifications,
    groupedNotifications,
    notificationTypeFilter,
    notificationTypesRead,
  ]);

  useEffect(() => {
    setGroupedNotifications(groupNotificationsByFilterType(notifications));
  }, [notifications]);

  const filteredNotifications =
    groupedNotifications[notificationTypeFilter].filter(
      (n) => !!n.notification.actionData
    ) || [];
  const unreadFilteredNotifications = filteredNotifications.filter(isUnread);

  const filterTypeOptions = Object.keys(groupedNotifications).map(
    (filterType) => ({
      id: filterType,
      label: filterType,
      status:
        hasUnreadNotifications(groupedNotifications[filterType]) &&
        !notificationTypesRead[filterType]
          ? filterUnreadStatus[filterType]
          : undefined,
    })
  );

  const handleClickNotification = (id) => {
    const { id: notificationId } = notifications.find(
      ({ notification: { itemId } }) => itemId === id
    );
    setRead([{ id: notificationId }]);
  };

  const handleMarkAllAsRead = () => {
    const unreadNotificationsIds = unreadFilteredNotifications.map(({ id }) => {
      return { id };
    });
    setRead(unreadNotificationsIds);
  };

  const handleSelectFilterType = (type) => {
    setNotificationTypesRead({ ...notificationTypesRead, ...{ [type]: true } });
    changeNotificationTypeFilter(type);
  };

  useEffect(() => {
    if (isFetchingNotifications || initialLoadingStarted) {
      return;
    }
    setInitialLoadingStarted(true);
    getNotifications();
  }, [isFetchingNotifications, initialLoadingStarted, getNotifications]);

  return (
    <>
      <NotificationTypeFilterWrapper>
        <ChipSelector
          options={filterTypeOptions}
          selectedChipId={notificationTypeFilter}
          onChipSelected={handleSelectFilterType}
        />
      </NotificationTypeFilterWrapper>
      <Divider />
      <NotificationsList>
        {!isFetchingNotifications && filteredNotifications.length === 0 ? (
          <NoNotification>
            {emptyNotificationMessages[notificationTypeFilter]}
          </NoNotification>
        ) : (
          filteredNotifications.map(
            ({ id, createdAt, read, culprit, notification }) => (
              <NotificationCell
                key={id}
                time={createdAt}
                unread={!read}
                culprit={culprit}
                notification={notification}
                onClick={handleClickNotification}
              />
            )
          )
        )}
      </NotificationsList>
      <NotificationActionButtonsContainer>
        <ActionButtons>
          <Button
            iconLeft={icons.SettingsOutlinedIcon}
            onClick={onOpenNotificationsSettings}
          >
            Notification settings
          </Button>
          <Button
            type="primary"
            iconLeft={icons.CheckCircleOutlinedIcon}
            onClick={handleMarkAllAsRead}
            disabled={!unreadFilteredNotifications.length}
          >
            Mark all as read
          </Button>
        </ActionButtons>
      </NotificationActionButtonsContainer>
    </>
  );
};

NotificationsTable.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getNotifications: PropTypes.func.isRequired,
  setRead: PropTypes.func.isRequired,
  onOpenNotificationsSettings: PropTypes.func.isRequired,
  notificationTypeFilter: PropTypes.string,
  changeNotificationTypeFilter: PropTypes.func.isRequired,
  isFetchingNotifications: PropTypes.bool,
};

NotificationsTable.defaultProps = {
  isFetchingNotifications: false,
  notificationTypeFilter: 'All',
};

export default NotificationsTable;
