const without = (userId) => (id) => id !== userId;

const add = (typingIn, groupId, userId) => {
  const existingTypers = typingIn[groupId] || [];

  const alreadyIn = existingTypers.indexOf(userId) !== -1;

  if (alreadyIn) {
    return typingIn;
  }

  return {
    ...typingIn,
    [groupId]: [...existingTypers, userId],
  };
};

const remove = (typingIn, groupId, userId) => {
  const typers = (typingIn[groupId] || []).filter(without(userId));
  const duplicated = { ...typingIn };
  if (!typers.length) {
    delete duplicated[groupId];
  } else {
    duplicated[groupId] = typers;
  }

  return duplicated;
};

const liveTypingReducer = (state, action) => {
  const { groupId, userId } = action;

  switch (action.status) {
    case 'start':
      return {
        ...state,
        typingInConversations: add(
          state.typingInConversations,
          groupId,
          userId
        ),
      };
    case 'end':
      return {
        ...state,
        typingInConversations: remove(
          state.typingInConversations,
          groupId,
          userId
        ),
      };
    default:
      return state;
  }
};

export default liveTypingReducer;
