import { INBOX_CONVERSATIONS } from '../../../constants/endpoints';
import request from './request';

export default async (includeClosed = false) => {
  const config = {
    method: 'get',
    url: INBOX_CONVERSATIONS(includeClosed),
  };
  const response = await request(config);
  return response.data.conversations;
};
