import push from '../../utils/routing';

export const getMaterialsPath = (spaceId: string) =>
  `/dashboard/${spaceId}/journey/materials`;

export default (spaceId: string, materialSectionId?: string | null) => () => {
  const url = materialSectionId
    ? `${getMaterialsPath(spaceId)}/${materialSectionId}`
    : getMaterialsPath(spaceId);
  push(url);
};
