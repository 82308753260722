import { CLASSROOM_MODULE_HIDEUNHIDE_MATERIAL } from '../actions';
import { patchSection, patchFolder } from '../api';
import { getMaterial, currentSection } from '../selectors';
import { AulaReduxAction, AulaState, AulaDispatch } from '../../types/state';
import { getCurrentSpaceId } from '../../selectors/space';
import { Material } from '../types';
import { ActionStatus } from '../../constants/actionsStatus';
import { isLocalId } from '../../utils/localId';

const changeVisibilityAction = (status: ActionStatus, payload: unknown) => ({
  type: CLASSROOM_MODULE_HIDEUNHIDE_MATERIAL,
  status,
  payload,
});

const changeVisibility = async (
  dispatch: AulaDispatch,
  state: AulaState,
  material: Material,
  hidden: boolean
) => {
  const classRoom = getCurrentSpaceId(state);
  const optimisticPatch = { ...material, hidden };

  dispatch(changeVisibilityAction('started', { materialId: material.id }));
  try {
    const patchFunction = material.isFolder ? patchFolder : patchSection;
    const patch = {
      hidden,
      scheduledFor: null,
    };
    const updatedMaterial = !isLocalId(material.id)
      ? await patchFunction(classRoom, material.id, patch)
      : optimisticPatch;
    dispatch(changeVisibilityAction('success', { updatedMaterial }));
  } catch (error) {
    dispatch(changeVisibilityAction('error', error));
  }
};

type HideMaterialActionFactory = (materialId: string) => AulaReduxAction;
type UnhideMaterialActionFactory = (materialId: string) => AulaReduxAction;

export const hideMaterial: HideMaterialActionFactory =
  (materialId) => async (dispatch, getState) => {
    const state = getState();
    const material = materialId
      ? getMaterial(state, materialId)
      : currentSection(state);
    if (!material) {
      return;
    }

    await changeVisibility(dispatch, state, material, true);
  };

export const unHideMaterial: UnhideMaterialActionFactory =
  (materialId) => async (dispatch, getState) => {
    const state = getState();
    const material = getMaterial(state, materialId);
    if (!material) {
      return;
    }

    await changeVisibility(dispatch, state, material, false);
  };
