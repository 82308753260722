const removeFromStore = (store, ids) => {
  const updated = { ...store };
  ids.forEach((id) => delete updated[id]);
  return updated;
};

const leaveConversationReducer = (state, action) => {
  if (action.status !== 'success') return state;

  const { conversationId } = action.payload;
  const conversation = state.conversations[conversationId];

  if (!conversation) {
    return state;
  }

  return {
    ...state,
    conversations: removeFromStore(state.conversations, [conversationId]),
    messages: removeFromStore(state.messages, conversation.messages || []),
  };
};

export default leaveConversationReducer;
