type PipeFunction<T, R> = {
  (source: T): R;
};

function pipe<T, A, B, C>(
  fn1: PipeFunction<T, A>,
  fn2: PipeFunction<A, B>,
  fn3: PipeFunction<B, C>
): PipeFunction<T, C>;

function pipe<T, A, B, C, D>(
  fn1: PipeFunction<T, A>,
  fn2: PipeFunction<A, B>,
  fn3: PipeFunction<B, C>,
  fn4: PipeFunction<C, D>
): PipeFunction<T, D>;

function pipe(
  ...functions: PipeFunction<unknown, unknown>[]
): PipeFunction<unknown, unknown> {
  return (input) => functions.reduce((prev, fn) => fn(prev), input);
}

export default pipe;
