import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const Rectangle = styled('span')`
  display: inline-block;
  border-radius: ${theme.borderRadius.medium};
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  background-color: ${theme.color.grayDark};
  opacity: ${({ opacity }) => opacity || 1};
  margin-right: ${({ marginRight }) => `${marginRight || 0}px`};
`;

export const Filler = styled('span')`
  display: inline-block;
  flex-grow: 1;
`;

export const SpaceLoaderContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.04);
  height: 64px;
  box-sizing: border-box;
  padding: 12px;
`;

export const ListItemContainer = styled('li')`
  padding: ${({ title }) => (title ? '24px' : '0')} 12px 0 8px;
  box-sizing: border-box;
  height: ${({ title }) => (title ? '66px' : '32px')};
  vertical-align: middle;
  text-align: ${({ alignRight }) => (alignRight ? 'right' : 'left')};
`;
