import { GROUP_GROUP } from '../../constants/endpoints';
import request from './request';

const editGroup = async (accessToken, patch, groupId) => {
  const config = {
    method: 'patch',
    url: GROUP_GROUP(groupId),
    data: { group: patch },
  };

  const {
    data: { group: updatedGroup, studentsWithNoGroup },
  } = await request(accessToken, config);
  return { group: updatedGroup, studentsWithNoGroup };
};

export default editGroup;
