import * as actionTypes from '../../constants/actions';
import formatPost from '../../api/posts/formatPost';
import formatUser from '../../api/posts/formatUser';
import formatTag from '../../api/tags/formatTag';
import formatTagPost from '../../api/tags/formatTagPost';
import { createTagPostSuccess, deleteTagPostSuccess } from '../tags/tagPost';
import { createTagSuccess } from '../tags/tag';
import { Entities } from '../../utils/commentsEntityTypes';
import removeCommentFromQueryData from '../../comments/utils/removeCommentFromQueryData';
import addCommentToQueryData from '../../comments/utils/addCommentToQueryData';

export const registerLiveWithQueryClient =
  (queryClient) => (socket, dispatch, getState) => {
    const onPostAdded = (post) => {
      const {
        data: { classRooms },
      } = getState();
      const { id, space } = post;
      if (classRooms[space].posts.indexOf(id) > -1) {
        return;
      }
      dispatch({
        type: actionTypes.CLASSROOM_CREATE_POST,
        status: 'success',
        post: formatPost(post),
      });
    };

    const onPostDeleted = ({ id }) => {
      const {
        data: { posts },
      } = getState();
      const post = posts[id];
      if (!post) {
        return;
      }

      dispatch({
        type: actionTypes.CLASSROOM_DELETE_POST,
        status: 'success',
        post,
      });
    };

    const onPostUpdated = (post) => {
      dispatch({
        type: actionTypes.CLASSROOM_EDIT_POST,
        status: 'success',
        post: formatPost(post, post.tagPosts),
      });
    };

    const onCommentAdded = (comment) => {
      const {
        data: { comments, [Entities[comment.entityType]]: entities },
      } = getState();

      if (comments[comment.id]) {
        return;
      }
      if (!entities[comment.entityId]) {
        return;
      }

      if (queryClient) {
        addCommentToQueryData(queryClient, comment);
      }

      dispatch({
        type: actionTypes.COMMENTS_CREATE,
        status: 'success',
        payload: {
          comment: {
            ...comment,
            user: formatUser(comment.user),
          },
        },
      });
    };

    const onCommentDeleted = ({ id }) => {
      const {
        data: { comments },
      } = getState();
      const comment = comments[id];
      if (!comment) {
        return;
      }

      if (queryClient) {
        removeCommentFromQueryData(queryClient, comment);
      }

      dispatch({
        type: actionTypes.COMMENTS_DELETE,
        status: 'success',
        comment,
      });
    };

    const onCommentUpdated = (comment) => {
      dispatch({
        type: actionTypes.COMMENTS_EDIT,
        status: 'success',
        comment: {
          ...comment,
          user: formatUser(comment.user),
        },
      });
    };

    const onTagCreated = (tag) => createTagSuccess(formatTag(tag), dispatch);

    const onTagAddedToPost = (tagPost) =>
      createTagPostSuccess(formatTagPost(tagPost), dispatch);

    const onTagRemovedFromPost = (payload) =>
      deleteTagPostSuccess(payload, dispatch);

    socket.on('posts:PostAdded', onPostAdded);
    socket.on('posts:PostDeleted', onPostDeleted);
    socket.on('posts:PostUpdated', onPostUpdated);
    socket.on('posts:CommentAdded', onCommentAdded);
    socket.on('posts:CommentDeleted', onCommentDeleted);
    socket.on('posts:CommentUpdated', onCommentUpdated);
    socket.on('materials:CommentAdded', onCommentAdded);
    socket.on('materials:CommentDeleted', onCommentDeleted);
    socket.on('materials:CommentUpdated', onCommentUpdated);
    socket.on('Tag:TagCreated', onTagCreated);
    socket.on('Tag:TagAddedToPost', onTagAddedToPost);
    socket.on('Tag:TagRemovedFromPost', onTagRemovedFromPost);
  };

const registerLive = registerLiveWithQueryClient(null);

export default registerLive;
