import * as api from '../api/directMessages';
import { INBOX_REMOVE_PARTICIPANT_FROM_CONVERSATION } from '../actions';
import * as inboxSelectors from '../selectors';

const sendRemoveParticipantStarted = (dispatch, payload) =>
  dispatch({
    type: INBOX_REMOVE_PARTICIPANT_FROM_CONVERSATION,
    status: 'started',
    payload,
  });

const sendRemoveParticipantSuccess = (dispatch, payload) =>
  dispatch({
    type: INBOX_REMOVE_PARTICIPANT_FROM_CONVERSATION,
    status: 'success',
    payload,
  });

const sendRemoveParticipantError = (dispatch, payload) =>
  dispatch({
    type: INBOX_REMOVE_PARTICIPANT_FROM_CONVERSATION,
    status: 'error',
    payload,
  });

const removeParticipant = (participantId) => async (dispatch, getState) => {
  const state = getState();
  const conversationId = inboxSelectors.currentConversationId(state);
  const payload = { conversationId, participantId };
  sendRemoveParticipantStarted(dispatch, payload);
  try {
    await api.conversations.removeParticipant(conversationId, participantId);
    sendRemoveParticipantSuccess(dispatch, payload);
  } catch (error) {
    sendRemoveParticipantError(dispatch, { ...payload, error });
  }
};

export default removeParticipant;
