import * as actions from '../../constants/actions';
import { fetchUsers } from './users';

const initialState = {
  fetchingUsers: {},
};

const reducers = {
  [actions.FETCH_USERS]: fetchUsers,
};

const reducer = (state = initialState, action) => {
  if (!action) {
    return state;
  }

  const r = reducers[action.type];
  return r ? r(state, action) : state;
};

export default reducer;
