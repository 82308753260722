import { GetSectionVersionContract } from '@ublend-npm/aula-schema/types/materials';
import { CancelToken } from 'axios';
import cancellableRequest from '../../api/cancellableRequest';
import { CLASSROOM_MATERIAL_SECTION_GET_VERSION } from '../../constants/endpoints';
import request, { getData } from './request';
import toVersion from './toVersion';

const getVersion =
  (cancelToken?: CancelToken) =>
  async (classRoomId: string, sectionId: string, versionId: string) => {
    const url = CLASSROOM_MATERIAL_SECTION_GET_VERSION(
      classRoomId,
      sectionId,
      versionId
    );
    const data = await request<GetSectionVersionContract>({
      method: 'get',
      url,
      cancelToken,
    }).then(getData);
    return toVersion(data);
  };

export default cancellableRequest(getVersion).fn;
