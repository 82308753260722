import { GROUPS_ADD_GROUP } from '../actions';
import { addGroup as addGroupApi } from '../api';

import refreshAccessToken from '../../actions/accessToken/refreshAccessToken.action';
import { currentClassRoomId as getCurrentClassRoomId } from '../../selectors/classroom';
import showToast from '../../toasts/operations/showToast.operation';
import { ADD_GROUP_SUCCESS, ADD_GROUP_ERROR } from './toasts';

const addGroupSuccess = ({ group, studentsWithNoGroup }) => ({
  type: GROUPS_ADD_GROUP,
  status: 'success',
  payload: { group, studentsWithNoGroup },
});

const addGroupError = ({ group, error }) => ({
  type: GROUPS_ADD_GROUP,
  status: 'error',
  payload: { group, error },
});

const addGroup = (group) => async (dispatch, getState) => {
  try {
    const state = getState();
    const spaceId = getCurrentClassRoomId(state);
    const accessToken = await refreshAccessToken(
      dispatch,
      getState
    )({ spaceId });
    const { group: createdGroup, studentsWithNoGroup } = await addGroupApi(
      accessToken,
      group
    );
    dispatch(addGroupSuccess({ group: createdGroup, studentsWithNoGroup }));
    dispatch(showToast(ADD_GROUP_SUCCESS));
  } catch (error) {
    dispatch(showToast(ADD_GROUP_ERROR));
    dispatch(addGroupError({ group, error }));
  }
};

export default addGroup;
