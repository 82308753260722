import { RESET_ERROR_STATE } from '../actions';

const resetErrorStateStarted = (dispatch) =>
  dispatch({
    type: RESET_ERROR_STATE,
    status: 'success',
  });

const resetErrorState = () => (dispatch) => {
  resetErrorStateStarted(dispatch);
};

export default resetErrorState;
