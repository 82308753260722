import appReducer from './appReducers';
import dataReducer from './dataReducers';
import operations from './operations';
import * as selectors from './selectors';

export default {
  appReducer,
  dataReducer,
  operations,
  selectors,
  registerLive: () => {},
};
