import React, { useState } from 'react';
import { Accordion } from '@ublend-npm/aulaui-next';
import SpaceList from './SpaceList';
import { Space } from './types';

type SpaceListAccordionProps = {
  summary: string;
  spaces: Space[];
  onOpen: (subcategorySelected: boolean) => void;
  onClick: () => void;
  onStar: () => Promise<void>;
  isOpen?: boolean;
};

const summaryContainer = {
  fontWeight: 600,
};

const SpaceListAccordion = ({
  summary,
  spaces,
  onClick,
  onStar,
  onOpen,
  isOpen: defaultIsOpen = false,
}: SpaceListAccordionProps) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  const toggleOpen = () => {
    onOpen(!isOpen);
    setIsOpen((prev) => !prev);
  };

  return (
    <div style={{ paddingLeft: 10 }} data-testid="space-list-accordion">
      <Accordion
        isOpen={isOpen}
        summary={summary}
        onClick={toggleOpen}
        classes={{ summary: summaryContainer }}
      >
        <SpaceList spaces={spaces} onClick={onClick} onStar={onStar} inline />
      </Accordion>
    </div>
  );
};

export default SpaceListAccordion;
