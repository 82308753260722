import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@ublend-npm/aulaui-next';
import Divider from '@material-ui/core/Divider';

import {
  CurrentSpaceContainer,
  CurrentText,
  CurrentSpaceItem,
  PrimaryTextContainer,
  AvatarContainer,
} from './CurrentSpace.styled';

import AvatarUploader from '../../../common/AvatarUploader/AvatarUploader';

import SpaceProptypes from './Space.proptype';

const CurrentSpace = ({
  space,
  onAvatarClick,
  isLoading,
  isAdmin,
  tooltipPosition,
  avatarProps,
  avatarUploaderId,
  text,
}) => (
  <>
    <CurrentSpaceContainer data-testid="current-space">
      {text && <CurrentText>{text}</CurrentText>}
      <CurrentSpaceItem>
        <Tooltip
          title="Change image"
          position={tooltipPosition}
          enabled={isAdmin}
        >
          <AvatarContainer>
            <AvatarUploader
              inputId={avatarUploaderId}
              onUpload={onAvatarClick}
              name={space.name}
              url={space.avatar}
              size="medium"
              disabled={!isAdmin}
              isLoading={isLoading}
              {...avatarProps}
            />
          </AvatarContainer>
        </Tooltip>
        <PrimaryTextContainer>
          <span>{space.name}</span>
        </PrimaryTextContainer>
      </CurrentSpaceItem>
    </CurrentSpaceContainer>
    <Divider />
  </>
);

CurrentSpace.propTypes = {
  space: PropTypes.shape(SpaceProptypes).isRequired,
  onAvatarClick: PropTypes.func,
  isLoading: PropTypes.bool,
  isAdmin: PropTypes.bool,
  tooltipPosition: PropTypes.string,
  avatarProps: PropTypes.shape({}),
  avatarUploaderId: PropTypes.string.isRequired,
  text: PropTypes.string,
};

CurrentSpace.defaultProps = {
  onAvatarClick: () => {},
  isLoading: false,
  isAdmin: false,
  tooltipPosition: 'bottom',
  avatarProps: {},
  text: null,
};

export default CurrentSpace;
