import { GROUPS_GENERATE_GROUPS } from '../actions';
import { generateGroups as generateGroupsApi } from '../api';

import refreshAccessToken from '../../actions/accessToken/refreshAccessToken.action';
import { currentClassRoomId as getCurrentClassRoomId } from '../../selectors/classroom';
import showToast from '../../toasts/operations/showToast.operation';
import { GENERATE_GROUPS_SUCCESS, GENERATE_GROUPS_ERROR } from './toasts';

const generateGroupsStarted = (payload) => ({
  type: GROUPS_GENERATE_GROUPS,
  status: 'started',
  payload,
});

const generateGroupsSuccess = (payload) => ({
  type: GROUPS_GENERATE_GROUPS,
  status: 'success',
  payload,
});

const generateGroupsError = (payload) => ({
  type: GROUPS_GENERATE_GROUPS,
  status: 'error',
  payload,
});

const generateGroups =
  ({ set, generateParams }) =>
  async (dispatch, getState) => {
    const payload = { set, generateParams };
    dispatch(generateGroupsStarted(payload));
    try {
      const state = getState();
      const spaceId = getCurrentClassRoomId(state);
      const accessToken = await refreshAccessToken(
        dispatch,
        getState
      )({ spaceId });
      const { groups: createdGroups, studentsWithNoGroup } =
        await generateGroupsApi(accessToken, {
          set,
          generateParams: payload.generateParams,
        });
      dispatch(
        generateGroupsSuccess({ groups: createdGroups, studentsWithNoGroup })
      );
      dispatch(showToast(GENERATE_GROUPS_SUCCESS));
    } catch (error) {
      dispatch(generateGroupsError({ ...payload, error }));
      dispatch(showToast(GENERATE_GROUPS_ERROR));
    }
  };

export default generateGroups;
