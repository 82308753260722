import uuid from '../../utils/uuid';
import getNow from '../getNow';

const openSpaceSelector = (state) => ({
  ...state,
  openTimestamp: getNow(),
  openCorrelationId: uuid(),
});

export default openSpaceSelector;
