/* eslint-disable import/first */
import '../../../../styles/css/tags-input.css';
import React, { createRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import Autosuggest from 'react-autosuggest';
import debounce from 'lodash.debounce';
import TagsInput from 'react-tagsinput';
import PropTypes from 'prop-types';
import { theme as AulaUiTheme, Loader, Chip } from '@ublend-npm/aulaui-next';

import {
  AvatarSuggestion,
  NameSuggestion,
  AvatarTag,
  TagContainer,
  Tag,
  Educator,
  EducatorAvatar,
  LoaderWrapper,
} from './AutocompleteUser.styled';
import Avatar from '../../../containers/User/Avatar/Avatar';
import { usersSearch } from '../../../../../core/api/search';
import { isInstructor } from '../../../../utils/user';
import { ENTER, DELETE } from '../../../../utils/keyCodes';
import { currentUserId as getCurrentUserId } from '../../../../../core/selectors/user';

const theme = {
  container: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
  suggestionsContainerOpen: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
    width: 'calc(100% - 5px)',
    marginBottom: 5,
    maxHeight: 250,
    overflow: 'scroll',
  },
  suggestionsList: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
  },
  suggestion: {
    padding: 10,
    marginTop: 5,
    backgroundColor: AulaUiTheme.color.grey0,
    width: 'calc(100% - 20px)',
    cursor: 'pointer',
  },
  suggestionHighlighted: {
    backgroundColor: AulaUiTheme.color.grey3,
  },
};

const AutocompleteUserPresentational = ({
  blockList,
  onChange,
  currentUserId,
}) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [fetching, setFetching] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const tagRef = createRef();

  useEffect(() => {
    tagRef.current.input.input.focus();
  }, []);

  const handleChange = (selected) => {
    if (selected.find((user) => !user.objectId)) {
      return;
    }
    setSelectedUsers(selected);
    if (onChange) {
      onChange(selected);
    }
  };

  const handleUpdate = async ({ value }) => {
    // Prevent to fetch the data again when the search term is not changing
    if (searchTerm === value) {
      return;
    }

    setSearchTerm(value);
    setFetching(true);
    setUsers([]);
    const results = await usersSearch({ query: value });
    setUsers(
      results.map((user) => ({
        ...user,
        fullName: `${user.firstName} ${user.lastName}`,
        objectId: user.id,
      }))
    );
    setFetching(false);
    setTotalUsers(results.length);
  };

  const debouncedUpdate = useCallback(debounce(handleUpdate, 200), [
    searchTerm,
  ]);

  const renderSuggestionsContainer = ({ containerProps, children }) => (
    <div {...containerProps}>
      {fetching && (
        <LoaderWrapper>
          <Loader style={{ margin: 'auto' }} />
        </LoaderWrapper>
      )}
      {children}
    </div>
  );

  const AutocompleteInput = (props) => {
    const { addTag, ref, ...other } = props;

    const displayUsers = users.filter((user) => {
      // Already added
      if (selectedUsers.find((selected) => selected.id === user.id)) {
        return false;
      }

      if (blockList) {
        if (!blockList.includes(user.id)) {
          return user.id !== currentUserId;
        }

        return false;
      }

      return user.id !== currentUserId;
    });

    return (
      <Autosuggest
        ref={ref}
        suggestions={displayUsers}
        onSuggestionsClearRequested={() => {
          setTotalUsers(0);
        }}
        shouldRenderSuggestions={(value) => value && value.trim().length > 0}
        getSuggestionValue={(suggestion) => suggestion.fullName}
        renderSuggestionsContainer={renderSuggestionsContainer}
        renderSuggestion={(suggestion) => (
          <div id={`autocomplete-suggestion-name-${suggestion.objectId}`}>
            <AvatarSuggestion>
              <Avatar
                user={suggestion}
                size={30}
                isInstructor={isInstructor(suggestion.objectId)}
              />
            </AvatarSuggestion>
            <NameSuggestion data-testid="new-conversation-suggestion">
              {suggestion.fullName}
              {isInstructor(suggestion.objectId) && (
                <Educator>Educator</Educator>
              )}
            </NameSuggestion>
          </div>
        )}
        highlightFirstSuggestion
        inputProps={{
          ...other,
        }}
        containerProps={{
          'aria-label': `Number of users available ${totalUsers}`,
        }}
        onSuggestionsFetchRequested={debouncedUpdate}
        onSuggestionSelected={(e, { suggestion }) => {
          addTag(suggestion);
        }}
        theme={theme}
      />
    );
  };

  const renderSelectedUser = (tagProps) => {
    const { tag, key, onRemove } = tagProps;

    return (
      <Tag
        key={key}
        tabIndex="0"
        onKeyDown={(e) => {
          if (e.keyCode === ENTER || e.keyCode === DELETE) {
            onRemove(key);
          }
        }}
      >
        <Chip
          role="button"
          deleteAction={() => onRemove(key)}
          onClick={(e) => {
            e.stopPropagation();
          }}
          label={tag.fullName}
          icon={() => (
            <AvatarTag>
              <Avatar
                indicatorStyle={{
                  top: 1,
                  left: 1,
                  width: 7,
                  height: 7,
                  border: '1px solid #FFF',
                }}
                user={tag}
                size={24}
                isInstructor={isInstructor(tag.objectId)}
              />
            </AvatarTag>
          )}
        >
          <span>
            {isInstructor(tag.objectId) && (
              <EducatorAvatar>Educator</EducatorAvatar>
            )}
          </span>
        </Chip>
      </Tag>
    );
  };

  const renderLayout = (tagComponents, inputComponent) => (
    <div>
      <TagContainer
        margin={tagComponents.length}
        data-testid="new-conversation-selected-users-tags"
      >
        {tagComponents}
      </TagContainer>
      <div>{inputComponent}</div>
    </div>
  );

  return (
    <TagsInput
      ref={tagRef}
      renderInput={AutocompleteInput}
      renderTag={renderSelectedUser}
      renderLayout={renderLayout}
      value={selectedUsers}
      onChange={handleChange}
      tagDisplayProp="fullName"
      inputProps={{
        className: 'react-tagsinput-input',
        placeholder: 'Add one or more participants...',
        'aria-label': 'Add one or more participants',
      }}
    />
  );
};

AutocompleteUserPresentational.propTypes = {
  blockList: PropTypes.arrayOf(PropTypes.string),
  currentUserId: PropTypes.string,
  onChange: PropTypes.func,
};

AutocompleteUserPresentational.defaultProps = {
  blockList: [],
  currentUserId: '',
  onChange: () => {},
};

const mapStateToProps = (state) => ({
  currentUserId: getCurrentUserId(state),
});

const AutocompleteUser = connect(mapStateToProps)(
  AutocompleteUserPresentational
);

export default AutocompleteUser;
