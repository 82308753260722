import { fetchAssignments as fetchAssignmentsApi } from '../api';
import { FETCH_ASSIGNMENT } from '../actions';

const fetchAssignmentStarted = (dispatch, payload) =>
  dispatch({
    type: FETCH_ASSIGNMENT,
    status: 'started',
    payload,
  });

const fetchAssignmentSuccess = (dispatch, payload) =>
  dispatch({
    type: FETCH_ASSIGNMENT,
    status: 'success',
    payload,
  });

const fetchAssignmentError = (dispatch, payload) =>
  dispatch({
    type: FETCH_ASSIGNMENT,
    status: 'error',
    payload,
  });

const fetchAssignment =
  (dispatch) =>
  async (assignmentId, currentUser, spaceId, allSpaces = false) => {
    fetchAssignmentStarted(dispatch, { assignmentId });

    try {
      const response = await fetchAssignmentsApi(
        currentUser.objectId,
        spaceId,
        assignmentId,
        allSpaces
      );
      const { title } = response.objects[0];

      fetchAssignmentSuccess(dispatch, { title, assignmentId });
    } catch (error) {
      fetchAssignmentError(dispatch, { assignmentId });
    }
  };

export default (dispatch) => (assignmentId, currentUser, spaceId) =>
  fetchAssignment(dispatch, assignmentId, currentUser, spaceId);
