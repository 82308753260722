const registerLive =
  ({
    onGroupSetCreated,
    onGroupSetUpdated,
    onGroupSetDeleted,
    onGroupCreated,
    onGroupUpdated,
    onGroupDeleted,
  }) =>
  (socket) =>
  (dispatch) => {
    socket.on('GroupSet:Created', (payload) =>
      dispatch(onGroupSetCreated(payload))
    );
    socket.on('GroupSet:Updated', (payload) =>
      dispatch(onGroupSetUpdated(payload))
    );
    socket.on('GroupSet:Removed', (payload) =>
      dispatch(onGroupSetDeleted(payload))
    );
    socket.on('Group:Created', (payload) => dispatch(onGroupCreated(payload)));
    socket.on('Group:Removed', (payload) => dispatch(onGroupDeleted(payload)));
    socket.on('Group:Renamed', (payload) => dispatch(onGroupUpdated(payload)));
    socket.on('Group:AvatarChanged', (payload) =>
      dispatch(onGroupUpdated(payload))
    );
    socket.on('Group:StudentAdded', (payload) =>
      dispatch(onGroupUpdated(payload))
    );
    socket.on('Group:StudentRemoved', (payload) =>
      dispatch(onGroupUpdated(payload))
    );
    socket.on('Group:EducatorAdded', (payload) =>
      dispatch(onGroupUpdated(payload))
    );
    socket.on('Group:EducatorRemoved', (payload) =>
      dispatch(onGroupUpdated(payload))
    );
  };

export default registerLive;
