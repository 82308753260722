import { GROUP_GENERATE } from '../../constants/endpoints';
import request from './request';

const generateGroups = async (accessToken, { set, generateParams }) => {
  const config = {
    method: 'post',
    url: GROUP_GENERATE(set),
    data: {
      ...generateParams,
      theme: 'numbers',
    },
  };
  const {
    data: { groups, studentsWithNoGroup },
  } = await request(accessToken, config);
  return { groups, studentsWithNoGroup };
};

export default generateGroups;
