import styled from 'react-emotion';
import { LinearProgress } from '@material-ui/core';
import { theme } from '@ublend-npm/aulaui-next';

export const Progress = styled(LinearProgress)`
  position: absolute;
  top: -2px;
  left: 0px;
  right: 0px;
  z-index: 10000;
  display: ${(props) => (props.hidden ? 'none' : 'block')};
`;

export const Main = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100vh;
  overflow: hidden;
`;

export const ContentContainer = styled.div`
  padding: 0 !important;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const Content = styled.div`
  flex: 1;
`;

export const OmniSearch = styled.div`
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
  flex: 1;
`;

export const UserProfile = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const SkipContentButton = styled.div`
  position: absolute;

  padding: 0px ${theme.spacing.xxSmall};
  height: ${theme.spacing.xLarge};
  background: ${theme.color.designSystem.background.lightPurple.focus};
  border: 1px solid ${theme.color.blackOpacity87};
  box-sizing: border-box;
  border-radius: ${theme.spacing.xxSmall};

  left: 90px;
  top: 8px;

  font-family: ${theme.font.family.workSans};
  font-weight: ${theme.font.weight.semiBold};
  font-size: ${theme.font.size.copy};
  line-height: 14px;

  display: flex;
  align-items: center;
  color: ${theme.color.purple3};

  z-index: 99999;
  transform: translateY(-32px);
  :focus {
    transform: translateY(0px);
  }
`;
