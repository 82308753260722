import { MaterialDataReducer } from './state';
import { DisplayRevertModalAction } from '../sharedActions';

const displayRevertModalDataReducer: MaterialDataReducer<
  DisplayRevertModalAction
> = (state, action) => {
  if (action.status !== 'success') {
    return state;
  }

  const { id, versions } = action.payload.section;
  const section = state[id];

  return {
    ...state,
    [id]: { ...section, versions },
  };
};

export default displayRevertModalDataReducer;
