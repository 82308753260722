import { LtiState } from '@core/types/state';
import { LTI_FETCH_PROVIDERS, USER_LOGOUT } from '../../constants/actions';
import { Action, DuckReducers } from '@core/types/redux';
import { ActionStatus } from '@core/constants/actionsStatus';
import fetchProviders from './fetchProviders.reducer';

const initialState: LtiState = {
  status: 'idle',
  isErrored: false,
  providers: [],
};

type LtiActionType = typeof LTI_FETCH_PROVIDERS | typeof USER_LOGOUT;

const reducers: DuckReducers<LtiState, LtiActionType> = {
  [LTI_FETCH_PROVIDERS]: fetchProviders,
  [USER_LOGOUT]: () => ({ ...initialState }),
};

const ltiReducer = <T extends LtiActionType>(
  state = initialState,
  action: Action<T, ActionStatus, unknown>
) => {
  if (!action) {
    return state;
  }

  const r = reducers[action.type];

  return r ? r(state, action) : state;
};

export default ltiReducer;
