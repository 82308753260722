import push from '../utils/routing';

// PROVISIONAL!!!
const errorObserver = (store) => {
  let currentState = null;

  const handleChange = () => {
    const nextState = store.getState().errors;
    const pathState = store.getState().routing.locationBeforeTransitions;
    const { isLogged } = store.getState().user;
    const { isLoggingOut } = store.getState().user;

    if (nextState !== currentState && nextState && nextState) {
      currentState = nextState;
      if (
        nextState.last &&
        nextState.last.error &&
        nextState.last.error.code === 209 &&
        pathState.pathname !== '/logout' &&
        isLogged &&
        !isLoggingOut
      ) {
        push('/logout');
      }
    }
  };

  const unsubscribe = store.subscribe(handleChange);
  handleChange();

  return unsubscribe;
};

export default errorObserver;
