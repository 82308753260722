import { OMNISEARCH_CLEAR_RESULTS } from '../../constants/actions';

const openModal = (dispatch, loading) =>
  dispatch({
    type: OMNISEARCH_CLEAR_RESULTS,
    payload: { loading },
  });

export default (dispatch) =>
  ({ loading }) =>
    openModal(dispatch, loading);
