import filterAssignment from './utils/filterAssignment';

const deleteAssignmentReducer = (state, action) => {
  if (action.status !== 'success') return state;

  const {
    payload: { assignment },
  } = action;

  const isExtension = !!assignment.parentAssignmentId;

  // remove assignment if the deleted assignment is not an extension
  if (!isExtension) {
    const remainingAssignments = state.assignments.filter(
      filterAssignment(assignment)
    );

    return {
      ...state,
      assignments: remainingAssignments,
    };
  }

  // if the deleted assignment is an extension,
  // find the extension and remove it from assignment.extensions array
  const remainingAssignments = state.assignments.map((parentAssignment) => {
    if (parentAssignment.id !== assignment.parentAssignmentId) {
      return parentAssignment;
    }

    const { extensions } = parentAssignment;
    const remainingExtensions = extensions.filter(filterAssignment(assignment));

    return {
      ...parentAssignment,
      extensions: remainingExtensions,
    };
  });

  return {
    ...state,
    assignments: remainingAssignments,
  };
};

export default deleteAssignmentReducer;
