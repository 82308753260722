import { UpdateMaterialProgressAction } from '../sharedActions';
import { MaterialAppReducer } from './state';

const updateMaterialProgress: MaterialAppReducer<
  UpdateMaterialProgressAction
> = (state, action) => {
  const { materialId } = action.payload;

  switch (action.status) {
    case 'started':
      return {
        ...state,
        sectionIdsWithProgressUpdating: Array.from(
          new Set([...state.sectionIdsWithProgressUpdating, materialId])
        ),
      };
    case 'success':
    case 'error':
      return {
        ...state,
        sectionIdsWithProgressUpdating:
          state.sectionIdsWithProgressUpdating.filter(
            (id) => id !== materialId
          ),
      };
    default:
      return state;
  }
};

export default updateMaterialProgress;
