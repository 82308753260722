import { createAssignment as createAssignmentApi } from '../api';
import { CREATE_ASSIGNMENT } from '../actions';
import { accessToken as getAccessToken } from '../../selectors/user';

import { analyticsTrackEvent } from '../../utils/analytics';
import * as analyticEvents from '../../constants/analytics';

const createAssignmentStarted = (dispatch, payload) =>
  dispatch({
    type: CREATE_ASSIGNMENT,
    status: 'started',
    payload,
  });

const createAssignmentSuccess = (dispatch, payload) =>
  dispatch({
    type: CREATE_ASSIGNMENT,
    status: 'success',
    payload,
  });

const createAssignmentError = (dispatch, payload) =>
  dispatch({
    type: CREATE_ASSIGNMENT,
    status: 'error',
    payload,
  });

const createAssignment =
  ({ spaceId, assignment, accessToken }) =>
  async (dispatch, getState) => {
    createAssignmentStarted(dispatch, { spaceId });

    const state = getState();
    const currentAccessToken = accessToken || getAccessToken(state, spaceId);

    if (!currentAccessToken) {
      createAssignmentError(dispatch, { spaceId });
      return null;
    }

    try {
      const response = await createAssignmentApi(
        currentAccessToken,
        spaceId,
        assignment
      );

      if (!response.data || !response.data.assignment) {
        createAssignmentError(dispatch, {
          spaceId,
          assignmentCreationError: {
            errorCode: '500',
            errorMessage: 'fail to create assignment',
          },
        });
        return null;
      }

      const newAssignment = response.data.assignment;

      analyticsTrackEvent(analyticEvents.CREATE_ASSIGNMENT, {
        assignment: newAssignment,
        activeClassroom: spaceId,
      });

      createAssignmentSuccess(dispatch, {
        spaceId,
        assignment: newAssignment,
      });
      return newAssignment;
    } catch (e) {
      const errorCode =
        e.response &&
        e.response.data &&
        e.response.data.errors &&
        e.response.data.errors[0];
      const errorMessage = e.message;
      createAssignmentError(dispatch, {
        spaceId,
        assignmentCreationError: { errorCode, errorMessage },
      });
      return null;
    }
  };

export default createAssignment;
