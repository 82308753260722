import { GROUPS_DELETE_GROUP_SET } from '../actions';
import { groupSet as getGroupSet } from '../selectors';

const onGroupSetDeleted = (data) => (dispatch, getState) => {
  const { id: groupSetId } = data;
  const state = getState();
  const alreadyExists = !!getGroupSet(state, groupSetId);

  if (!alreadyExists) {
    return;
  }

  dispatch({
    type: GROUPS_DELETE_GROUP_SET,
    status: 'success',
    payload: { groupSetId },
  });
};

export default onGroupSetDeleted;
