const fetchConversations = (state, action) => {
  const { status } = action;
  switch (status) {
    case 'started': {
      return {
        ...state,
        fetchingConversations: true,
      };
    }
    case 'success': {
      const {
        payload: { conversations },
      } = action;
      return {
        ...state,
        fetchingConversations: false,
        conversationHasOlderMessages: conversations.reduce(
          (acc, { id }) => ({
            ...acc,
            [id]: true,
          }),
          {}
        ),
      };
    }
    case 'error': {
      return {
        ...state,
        fetchingConversations: false,
      };
    }
    default:
      return state;
  }
};

export default fetchConversations;
