import * as userSelectors from '../../../core/selectors/user';

const openZendeskHelp = () => (_, getState) => {
  const state = getState();
  const { firstName, lastName, email } = userSelectors.currentUser(state);
  window.zE.identify({ name: `${firstName} ${lastName}`, email });
  window.zE.activate();
};

export default openZendeskHelp;
