const fetchNotificationSettingsReducer = (state, action) => {
  switch (action.status) {
    case 'started':
      return {
        ...state,
        isFetchingNotificationsSettings: true,
        isErrored: false,
      };
    case 'success': {
      return {
        ...state,
        isFetchingNotificationsSettings: false,
        isFetched: true,
        isErrored: false,
      };
    }
    case 'error':
      return {
        ...state,
        isFetchingNotificationsSettings: false,
        isErrored: true,
      };
    default:
      return state;
  }
};

export default fetchNotificationSettingsReducer;
