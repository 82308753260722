import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';
import UnstyledConversationIcon from '../common/ConversationIcon.container';

export const ConversationIcon = styled(UnstyledConversationIcon)``;

export const Container = styled('div')`
  padding-left: ${theme.spacing.small};
  padding-right: ${theme.spacing.xSmall};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;
