import camelCase from 'lodash.camelcase';

export const formatObjectKeysToCamelCase = (obj: Record<string, unknown>) =>
  Object.keys(obj).reduce(
    (acc, objKeys) => ({
      ...acc,
      [camelCase(objKeys)]: obj[objKeys],
    }),
    {}
  );
