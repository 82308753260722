import showToast from '../../toasts/operations/showToast.operation';
import { AulaReduxAction } from '../../types/state';

type HideDiscussionActionFactory = () => AulaReduxAction;

const hideDiscussion: HideDiscussionActionFactory = () => (dispatch) => {
  dispatch(
    showToast({
      message: 'This discussion will be hidden once the page is saved',
    })
  );
};

export default hideDiscussion;
