import { objectGetKeyFromString } from './nestedKeys';

/*
 ** Get objects from storage
 */
const classNameStateMap = {
  _User: 'data.users',
  User: 'data.users',
  UBClassRoom: 'data.classRooms',
  UBClassRoomUser: 'data.classRoomUsers',
  UBClassRoomPost: 'data.posts',
  UBPost: 'data.posts',
  UBFile: 'data.files',
  UBComment: 'data.comments',
  UBNotification: 'data.notifications',
  UBInboxGroup: 'data.inboxGroups',
  UBInboxMessage: 'data.inboxMessages',
};

export const getStoredObject = (state, objectId, className) => {
  const storage = objectGetKeyFromString(state, classNameStateMap[className]);
  if (!storage) {
    return null;
  }
  return storage[objectId];
};

// Users
export const getUserFromUBClassRoomUserId = (state, id) => {
  const classRoomUser = getStoredObject(state, id, 'UBClassRoomUser');
  if (!classRoomUser) {
    return null;
  }
  return getStoredObject(state, classRoomUser.user, '_User');
};

// ClassRoom
export const getCurrentClassRoom = (state) =>
  getStoredObject(state, state.classRoom.current, 'UBClassRoom');

// ClassRoom Post
export const getClassRoomPost = (state, id) =>
  getStoredObject(state, id, 'UBPost');

// Comment
export const getComment = (state, id) =>
  getStoredObject(state, id, 'UBComment');

// ClassRoom Notifications
export const getClassRoomNotification = (state, id) =>
  getStoredObject(state, id, 'UBNotification');

// ClassRoom Inbox Group
export const getClassRoomInboxGroup = (state, id) =>
  getStoredObject(state, id, 'UBInboxGroup');

// ClassRoom Inbox Message
export const getClassRoomInboxMessage = (state, id) =>
  getStoredObject(state, id, 'UBInboxMessage');

// Files
export const getFile = (state, id) => getStoredObject(state, id, 'UBFile');

/**
 * Returns boolean if notification object is deleted or not
 * @param state: redux state tree
 * @param notification: notification object
 */
export const shouldOpenNotificationObject = (notification, state) => {
  let deleted = false;

  switch (notification.action) {
    case 'newMessage':
    case 'newPost': {
      const object = getStoredObject(
        state,
        notification.itemId,
        notification.itemType
      ).content;

      if (!object) {
        deleted = true;
      }
      break;
    }
    case 'newComment':
    case 'newCommentOnCommentedPost':
    case 'newCommentReply': {
      const object = getStoredObject(
        state,
        notification.parentItemId,
        notification.parentItemType
      );

      if (!object) {
        deleted = true;
      }
      break;
    }
    default: {
      deleted = false;
      break;
    }
  }

  return !deleted;
};
