import { GROUPS_OPEN_CLOSE_EDIT_GROUP_SET } from '../actions';

export const openEditGroupSet = (setId) => (dispatch) =>
  dispatch({
    type: GROUPS_OPEN_CLOSE_EDIT_GROUP_SET,
    payload: { editGroupSetDialogOpenFor: setId },
  });

export const closeEditGroupSet = (dispatch) =>
  dispatch({
    type: GROUPS_OPEN_CLOSE_EDIT_GROUP_SET,
    payload: { editGroupSetDialogOpenFor: null },
  });
