import { SUCCESS_STATUS } from '../../../../constants/actionsStatus';
import { ANNOUNCEMENTS } from '../../../../constants/postFeedType';
import { ASCENDING, getSortedDistinctPostIds } from '../../../../utils/posts';
/**
 *
 * @param {'scheduledPosts' | 'scheduledAnnouncements'} statePropertyKey
 * @returns A reducer for the given state property
 */
export const buildFetchScheduledPostsReducer =
  (statePropertyKey) => (state, action) => {
    switch (action.status) {
      case SUCCESS_STATUS: {
        const { scheduledPosts } = action;
        if (!scheduledPosts.length) {
          return { ...state };
        }

        const newScheduledPostIds = [];
        const posts = { ...state.posts };

        scheduledPosts.forEach((post) => {
          newScheduledPostIds.push(post.objectId);

          if (!posts[post.objectId]) {
            posts[post.objectId] = {
              ...post,
              ...(post.feedType !== ANNOUNCEMENTS && { comments: [] }),
              tagPosts: [],
            };
          }
        });

        const classRooms = { ...state.classRooms };
        const classRoomId = scheduledPosts[0].classRoom;
        const classRoom = classRooms[classRoomId] || {};
        if (!classRoom[statePropertyKey]) {
          classRoom[statePropertyKey] = [];
        }

        const newScheduledPosts = getSortedDistinctPostIds({
          existingIds: classRoom[statePropertyKey],
          newIds: newScheduledPostIds,
          order: ASCENDING,
          posts,
        });

        classRooms[classRoomId] = {
          ...classRoom,
          [statePropertyKey]: newScheduledPosts,
        };
        return {
          ...state,
          posts,
          classRooms,
        };
      }

      default:
        return {
          ...state,
        };
    }
  };
