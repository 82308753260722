import { appendAndReorderMessages, getMessageById, unique } from './utils';

const fetchMessageReducer = (state, action) => {
  if (action.status !== 'success') return state;
  const { conversationId, message, localId, hasNewerMessages } = action.payload;

  const conversation = state.conversations[conversationId];
  if (!conversation) {
    return state;
  }

  if (hasNewerMessages) {
    return {
      ...state,
      conversations: {
        ...state.conversations,
        [conversationId]: {
          ...conversation,
          latestMessage: message,
        },
      },
    };
  }

  const messages = (conversation.messages || []).map(
    getMessageById(state.messages)
  );

  // We need to filter out messages whose local id already exists in the store.
  // That means the message was originated in the user's local machine and we want
  // to avoid duplicated messages in the conversation.
  const updatedConversationMessages = appendAndReorderMessages(messages, [
    message,
  ]).filter((msg) => msg.id !== localId);
  const updatedConversationMessagesIds = unique(
    updatedConversationMessages.map((m) => m.id)
  );

  const lastMessage =
    updatedConversationMessages[updatedConversationMessages.length - 1];

  const newMessages = {
    ...state.messages,
    [message.id]: message,
  };
  delete newMessages[localId];

  return {
    ...state,
    conversations: {
      ...state.conversations,
      [conversationId]: {
        ...conversation,
        latestMessage: lastMessage,
        messages: updatedConversationMessagesIds,
      },
    },
    messages: newMessages,
  };
};

export default fetchMessageReducer;
