import { CLASSROOM_MODULE_FETCH_SECTIONS } from '../actions';
import { fetchSections as fetchSectionsApi } from '../api';
import { getCurrentSpaceId } from '../../selectors/space';
import { AulaReduxAction } from '../../types/state';
import { ActionStatus } from '../../constants/actionsStatus';

const fetchSectionsAction = (status: ActionStatus, payload?: unknown) => ({
  type: CLASSROOM_MODULE_FETCH_SECTIONS,
  status,
  payload,
});

type FetchSectionsActionFactory = (classId?: string) => AulaReduxAction;

const fetchSections: FetchSectionsActionFactory =
  (desiredSpaceId) => async (dispatch, getState) => {
    const state = getState();
    const spaceId = desiredSpaceId || getCurrentSpaceId(state);

    if (!spaceId) {
      return;
    }

    dispatch(fetchSectionsAction('started'));

    try {
      const materials = await fetchSectionsApi(spaceId);
      dispatch(
        fetchSectionsAction('success', { classRoom: spaceId, materials })
      );
    } catch (error: unknown) {
      dispatch(fetchSectionsAction('error', error));
    }
  };

export default fetchSections;
