import * as LDClient from 'launchdarkly-js-client-sdk';
import { isInstructor } from '../selectors/classroom';
import {
  institutionName,
  institutionShortName,
} from '../selectors/institution';
import { EDUCATOR_ROLE, STUDENT_ROLE } from '../constants/space';

const { current, space: getSpace } = require('../selectors/spaces');
const { user: getUser } = require('../selectors/user');
const { numberOfSpacesSelector } = require('../selectors/classroom');

/**
 * `identifyUserInAppcues` retrieve state data around the user, sends it
 * to Appcues and triggers the call to see if there is any flow
 * that needs to be displayed.
 * To learn more about Appcues, read this documentation:
 * https://www.notion.so/aulaatcoventry/Appcues-8bba8dc4e13c4e8cba8b522f0ac702a5
 *
 * The call is conditional on a feature flag because there is a limited
 * allows amount of MAU (monthly active users).
 */
const identifyUserInAppcues = (spaceIdFallback) => (_, getState) => {
  const state = getState();
  const user = getUser(state);
  const institution = institutionName(state);
  const shortInstitutionName = institutionShortName(state);
  const isEducator = isInstructor(state);
  const spaceId = current(getSpace(state)) || spaceIdFallback;
  const numberOfSpaces = numberOfSpacesSelector(state);
  const { user: { externalId, firstName, createdAt, objectId: userId } = {} } =
    user;
  const ldClientSideId = state?.institution?.keys?.launchDarkly?.clientSideId;

  if (ldClientSideId) {
    const LD = LDClient.initialize(ldClientSideId, {
      key: userId,
      custom: {
        spaceId,
        institution: shortInstitutionName,
        spaceRole: isEducator ? EDUCATOR_ROLE : STUDENT_ROLE,
        numberOfSpaces,
      },
    });

    LD.on('ready', () => {
      if (
        !!window.Appcues &&
        LD.variation('webapp-ft-appcues-enabled-202110', false) &&
        userId &&
        spaceId
      ) {
        const unixCreatedAt = +new Date(createdAt);
        window.Appcues.identify(userId, {
          createdAt: unixCreatedAt,
          createdAtString: createdAt,
          spaceId,
          isEducator,
          institution,
          firstName,
          externalId,
        });
      }
    });
  }
};

export const appcuesUrlChange = () => {
  if (!window.Appcues) {
    return;
  }

  window.Appcues.page();
};

export default identifyUserInAppcues;
