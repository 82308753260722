const saveNotificationSettingsReducer = (state, action) => {
  switch (action.status) {
    case 'started':
      return {
        ...state,
        isErrored: false,
        isSavingNotificationsSettings: true,
      };
    case 'success': {
      return {
        ...state,
        isSavingNotificationsSettings: false,
        isErrored: false,
      };
    }
    case 'error':
      return {
        ...state,
        isSavingNotificationsSettings: false,
        isErrored: true,
      };
    default:
      return state;
  }
};

export default saveNotificationSettingsReducer;
