import { GROUP_SET } from '../../constants/endpoints';
import request from './request';

const deleteGroupSet = (accessToken, groupSetId) =>
  request(accessToken, {
    method: 'delete',
    url: GROUP_SET(groupSetId),
  });

export default deleteGroupSet;
