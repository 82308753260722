import { READ_NOTIFICATION_ERROR } from '../../groups/operations/toasts';
import showToast from '../../toasts/operations/showToast.operation';
import { newError } from '../../actions/errors';
import * as actionTypes from '../../constants/actions';
import fetchNotificationsApi from '../api/fetch';
import setNotificationsReadApi from '../api/setRead';

export const showBadge = (hasUnread, dispatch) => {
  dispatch({
    type: actionTypes.NAVBAR_NOTIFICATIONS_BADGE,
    hasUnread,
  });
};

// Fetch notifications
const fetchNotificationsStart = (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_NOTIFICATIONS,
    status: 'started',
  });
};

const fetchNotificationsSuccess = (notifications, dispatch) => {
  dispatch({
    type: actionTypes.FETCH_NOTIFICATIONS,
    status: 'success',
    notifications,
  });
};

const fetchNotificationsError = (error, dispatch) => {
  dispatch(newError(error, 'fetchNotifications'));
  dispatch({
    type: actionTypes.FETCH_NOTIFICATIONS,
    status: 'error',
    error,
  });
};

export const fetchNotifications =
  (
    { unreadOnly, showNotificationsBadge } = {
      unreadOnly: false,
      showNotificationsBadge: false,
    }
  ) =>
  async (dispatch) => {
    fetchNotificationsStart(dispatch);

    try {
      const notifications = await fetchNotificationsApi({ unreadOnly });
      fetchNotificationsSuccess(notifications, dispatch);
      if (showNotificationsBadge) {
        showBadge(
          notifications.some((n) => !n.read),
          dispatch
        );
      }
    } catch (error) {
      fetchNotificationsError(error, dispatch);
    }
  };

const notificationSetReadStart = (notificationIds) => (dispatch) => {
  dispatch({
    type: actionTypes.NOTIFICATIONS_SET_READ,
    status: 'started',
    notificationIds,
  });
};

const notificationSetReadSuccess = (notificationIds) => (dispatch) => {
  dispatch({
    type: actionTypes.NOTIFICATIONS_SET_READ,
    status: 'success',
    notificationIds,
  });
};

const notificationSetReadError = (error, notificationIds) => (dispatch) => {
  dispatch({
    type: actionTypes.NOTIFICATIONS_SET_READ,
    status: 'error',
    error,
    notificationIds,
  });

  dispatch(showToast(READ_NOTIFICATION_ERROR));
};

export const clearNotificationsError = () => (dispatch) => {
  dispatch({
    type: actionTypes.CLEAR_NOTIFICATIONS_ERROR,
    status: 'success',
  });
};

export const setNotificationsAsRead = (notifications) => async (dispatch) => {
  if (!notifications.length) return;

  const notificationIds = notifications.map(({ id }) => id);

  dispatch(notificationSetReadStart(notificationIds));

  try {
    await setNotificationsReadApi(notificationIds);
    dispatch(notificationSetReadSuccess(notificationIds));
  } catch (e) {
    dispatch(notificationSetReadError(e, notificationIds));
  }
};

const notificationsSetReadByTypeStart = (
  notificationType,
  classRoomId,
  dispatch
) => {
  dispatch({
    type: actionTypes.NOTIFICATION_SET_READ_TYPE,
    status: 'started',
    notificationType,
    classRoomId,
  });
};

const notificationsSetReadByTypeSuccess = (
  notificationType,
  classRoomId,
  dispatch
) => {
  dispatch({
    type: actionTypes.NOTIFICATION_SET_READ_TYPE,
    status: 'success',
    notificationType,
    classRoomId,
  });
};

const notificationsSetReadByTypeError = (
  error,
  notificationType,
  classRoomId,
  dispatch
) => {
  dispatch(newError(error, 'notificationsSetReadByType'));
  dispatch({
    type: actionTypes.NOTIFICATION_SET_READ_TYPE,
    status: 'error',
    error,
    notificationType,
    classRoomId,
  });
};

export const setNotificationAsReadByType =
  (type, classRoomId) => (dispatch, getState) => {
    if (!type) {
      const err = { message: 'You need to provide the notifications type' };
      return notificationsSetReadByTypeError(err, type, classRoomId, dispatch);
    }

    if (!classRoomId) {
      return Promise.resolve();
    }

    const state = getState();
    if (state.data.classRooms[classRoomId]) {
      const notificationUserIds =
        state.data.classRooms[classRoomId].unread[type];
      if (!notificationUserIds || !notificationUserIds.length) {
        return null;
      }

      notificationsSetReadByTypeStart(type, classRoomId, dispatch);
    }

    return notificationsSetReadByTypeSuccess(type, classRoomId, dispatch);
  };
