import { FETCH_USERS } from '../../constants/actions';

const onUserUpdated = (payload) => async (dispatch) => {
  dispatch({
    type: FETCH_USERS,
    status: 'success',
    payload: {
      requestedUsers: [],
      users: [
        {
          objectId: payload.user.id,
          ...payload.user,
        },
      ],
    },
  });
};

export default onUserUpdated;
