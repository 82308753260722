import {
  SPACE_SELECTOR_DISMISSED,
  SPACE_SELECTOR_ITEM_TYPE,
} from '../../constants/analytics';
import { analyticsTrackEvent } from '../../utils/analytics';
import { DISMISS_SPACE_SELECTOR } from '../actions';
import { openTimestamp, openCorrelationId } from '../selectors';
import getNow from '../getNow';

const dismissSpaceSelector =
  (showArchived, sort, category) => (dispatch, getState) => {
    const state = getState();

    analyticsTrackEvent(SPACE_SELECTOR_DISMISSED, {
      itemType: SPACE_SELECTOR_ITEM_TYPE,
      selectorOpenFor: Math.round(getNow() - openTimestamp(state)),
      correlationId: openCorrelationId(state),
      categoriesEnabled: true,
      showArchived,
      sort,
      category,
    });

    dispatch({
      type: DISMISS_SPACE_SELECTOR,
    });
  };

export default dismissSpaceSelector;
