import { RevertSectionToVersionAction } from '../sharedActions';
import { MaterialDataReducer } from './state';

const revertSectionToVersionReducer: MaterialDataReducer<
  RevertSectionToVersionAction
> = (state, action) => {
  if (action.status !== 'success') {
    return state;
  }

  const { section } = action.payload;

  return {
    ...state,
    [section.id]: section,
  };
};

export default revertSectionToVersionReducer;
