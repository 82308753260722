import { INBOX_CONVERSATIONS } from '../../../constants/endpoints';
import request from './request';

const getMeta = ({ title, participantIds, spaceId, educatorOnly }) => {
  if (educatorOnly && spaceId) {
    return { educatorOnly, spaceId, isGroup: true };
  }
  const participants = participantIds.reduce(
    (acc, next) => ({ ...acc, [next]: {} }),
    {}
  );

  if (participantIds.length > 2) {
    return {
      title,
      isGroup: true,
      participants,
    };
  }
  return { isGroup: false, participants };
};

/**
 * @param {string[]} participants
 * @param {string} title
 */
export default async ({ participantIds, title, spaceId, educatorOnly }) => {
  const config = {
    url: INBOX_CONVERSATIONS(),
    method: 'post',
    data: {
      conversation: getMeta({
        title,
        participantIds,
        spaceId,
        educatorOnly,
      }),
    },
  };

  const response = await request(config);
  return response.data.conversation;
};
