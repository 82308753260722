import * as analyticsTypes from '../../constants/analytics';
import * as actionTypes from '../../constants/actions';

import { newError } from '../errors';
import { currentClassRoomId } from '../../selectors/classroom';
import { analyticsTrackEvent } from '../../utils/analytics';
import { fetchTagPostsSuccess } from '../tags/tagPost';
import fetchReactionsSuccess from '../../reactions/operations/fetchReactionsSuccess.operation';
import { fetchCommentsSuccess } from '../comment/fetch';
import { groupCommentByPostReducer } from '../space/utils';
import getFeed from '../../api/posts/feed';
import editPost from '../../api/posts/edit';
import showToast from '../../toasts/operations/showToast.operation';
import { SET_IMPORTANT_POST_SUCCESS } from '../../groups/operations/toasts';
import { getHoursBetweenDates } from '../../utils/dates';

const fetchImportantPostsStart = (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_IMPORTANT_POSTS,
    status: 'started',
  });
};

export const fetchImportantPostsSuccess = (importantPosts, dispatch) => {
  dispatch({
    type: actionTypes.FETCH_IMPORTANT_POSTS,
    status: 'success',
    importantPosts,
  });
};

const fetchImportantPostsError = (error, dispatch) => {
  dispatch(newError(error, 'fetchImportantPosts'));
  dispatch({
    type: actionTypes.FETCH_IMPORTANT_POSTS,
    status: 'error',
    error,
  });
};

export const fetchImportantPosts =
  ({ spaceId, after, tagId }) =>
  async (dispatch, getState) => {
    fetchImportantPostsStart(dispatch);
    const state = getState();
    /**
     * 1 - Create an array from object keys
     * 2 - Filter out non important posts
     * 3 - Get the min date out of the list
     * */
    const oldestImportantPostDate = Object.values(
      state.data.classRooms[spaceId].importantPosts
    ).reduce((max, id) => {
      const post = state.data.posts[id];
      const date = new Date(post.createdAt);
      return post.important && date.getTime() < max.getTime() ? date : max;
    }, new Date());
    const date = oldestImportantPostDate || new Date();

    try {
      const { tagPosts, comments, reactions, posts } = await getFeed({
        spaceId,
        until: date,
        after,
        tagId,
        important: true,
      });

      fetchImportantPostsSuccess(posts, dispatch);
      fetchTagPostsSuccess(tagPosts, dispatch);

      // accumulate all comments per post
      const commentPosts = comments.reduce(groupCommentByPostReducer, {});
      Object.keys(commentPosts).forEach((postId) => {
        if (postId) {
          fetchCommentsSuccess(postId, commentPosts[postId], dispatch);
        }
      });

      dispatch(fetchReactionsSuccess(reactions));
    } catch (e) {
      fetchImportantPostsError(e, dispatch);
    }
  };

// Set post as important
const setPostImportantStart = (id) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_POST_IMPORTANT,
    status: 'started',
    id,
  });
};

const setPostImportantSuccess = (importantPost) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_POST_IMPORTANT,
    status: 'success',
    importantPost,
  });
  const ageInHours = getHoursBetweenDates({
    startDate: importantPost.createdAt,
    endDate: new Date(),
  });
  if (ageInHours < 72) {
    dispatch(showToast(SET_IMPORTANT_POST_SUCCESS));
  }
};

const setPostImportantError =
  ({ error, id }) =>
  (dispatch) => {
    dispatch(newError(error, 'setPostImportant'));
    dispatch({
      type: actionTypes.SET_POST_IMPORTANT,
      status: 'error',
      error,
      id,
    });
  };

export const setPostImportant = (postId) => async (dispatch, getState) => {
  setPostImportantStart(postId)(dispatch);

  analyticsTrackEvent(analyticsTypes.MAKE_IMPORTANT, {
    activeClassroom: currentClassRoomId(getState()),
  });

  try {
    const post = await editPost({
      postId,
      important: true,
    });

    setPostImportantSuccess(post)(dispatch);
  } catch (error) {
    setPostImportantError({ error, id: postId })(dispatch);
  }
};

// Unset post as important
const unsetPostImportantStart = (id) => (dispatch) => {
  dispatch({
    type: actionTypes.UNSET_POST_IMPORTANT,
    status: 'started',
    id,
  });
};

const unsetPostImportantSuccess = (importantPost) => (dispatch) => {
  dispatch({
    type: actionTypes.UNSET_POST_IMPORTANT,
    status: 'success',
    importantPost,
  });
};

const unsetPostImportantError =
  ({ error, id }) =>
  (dispatch) => {
    dispatch(newError(error, 'unsetPostImportant'));
    dispatch({
      type: actionTypes.UNSET_POST_IMPORTANT,
      status: 'error',
      error,
      id,
    });
  };

export const unsetPostImportant = (postId) => async (dispatch, getState) => {
  unsetPostImportantStart(postId)(dispatch);

  analyticsTrackEvent(analyticsTypes.MAKE_NOT_IMPORTANT, {
    postId,
    activeClassroom: currentClassRoomId(getState()),
  });

  try {
    const post = await editPost({
      postId,
      important: false,
    });

    unsetPostImportantSuccess(post)(dispatch);
  } catch (error) {
    unsetPostImportantError({ error, id: postId })(dispatch);
  }
};
