import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Downshift from 'downshift';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import { ERROR_INBOX_GROUPS_LIST } from '../../../../constants/texts';
import * as types from '../types';
import { CONVERSATION_FILTER_ID } from '../constants';

import InboxMenu from './InboxMenu.container';
import withErrorBoundary from '../../common/ErrorBoundary/ErrorBoundary';
import withSuspense from '../../common/Suspense/Suspense';

const ConversationModal = withSuspense(
  React.lazy(() => import('../ConversationModal.container'))
);
class Inbox extends PureComponent {
  constructor(props) {
    super(props);

    this.changeGroup = this.changeGroup.bind(this);
    this.onDownShiftChange = this.onDownShiftChange.bind(this);
    this.downshiftRef = React.createRef();
    this.conversationIdBlock = `conversations-${Math.round(
      Math.random() * 1000
    )}`;
  }

  onDownShiftChange(conversationId) {
    if (!conversationId) {
      return;
    }
    const { openConversation } = this.props;
    openConversation(conversationId);
    this.changeGroup();
  }

  changeGroup() {
    if (this.downshiftRef.current) {
      this.downshiftRef.current.clearSelection();
    }
  }

  render() {
    const { selectedConversationLoaded, closeModal, location } = this.props;

    return (
      <div
        role="region"
        aria-label="conversations"
        id={this.conversationIdBlock}
      >
        <Downshift
          isOpen
          inputId={CONVERSATION_FILTER_ID}
          onChange={this.onDownShiftChange}
          ref={this.downshiftRef}
          labelId={this.conversationIdBlock}
        >
          {({
            getInputProps,
            getItemProps,
            getMenuProps,
            setItemCount,
            highlightedIndex,
          }) => (
            <div>
              <InboxMenu
                getInputProps={getInputProps}
                getItemProps={getItemProps}
                getMenuProps={getMenuProps}
                setItemCount={setItemCount}
                highlightedIndex={highlightedIndex}
                changeGroup={this.changeGroup}
              />
            </div>
          )}
        </Downshift>

        {selectedConversationLoaded && (
          <ConversationModal closeModal={closeModal} location={location} />
        )}
      </div>
    );
  }
}

Inbox.propTypes = {
  closeModal: PropTypes.func.isRequired,
  openConversation: PropTypes.func.isRequired,
  location: types.Location.isRequired,
  selectedConversationLoaded: PropTypes.bool,
};

Inbox.defaultProps = {
  selectedConversationLoaded: false,
};

export default withErrorBoundary(withLDConsumer()(Inbox), {
  message: ERROR_INBOX_GROUPS_LIST,
});
