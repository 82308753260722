/* eslint-disable max-len */
import * as userSelectors from '../../selectors/user';
import * as api from '../api/directMessages';
import { INBOX_FETCH_MESSAGE } from '../actions';

const fetchMessageStarted = (dispatch, payload) =>
  dispatch({
    type: INBOX_FETCH_MESSAGE,
    status: 'started',
    payload,
  });

const fetchMessageSuccess = (dispatch, payload) =>
  dispatch({
    type: INBOX_FETCH_MESSAGE,
    status: 'success',
    payload,
  });

const fetchMessageError = (dispatch, payload) =>
  dispatch({
    type: INBOX_FETCH_MESSAGE,
    status: 'error',
    payload,
  });

const fetchMessage =
  (conversationId, messageId) => async (dispatch, getState) => {
    const state = getState();
    const { objectId: currentUserId } = userSelectors.currentUser(state);
    const payload = { currentUserId, conversationId, messageId };

    fetchMessageStarted(dispatch, payload);
    try {
      const message = await api.conversations.fetchMessage(
        conversationId,
        messageId
      );
      fetchMessageSuccess(dispatch, { ...payload, message });
    } catch (error) {
      fetchMessageError(dispatch, { ...payload, error });
    }
  };

export default fetchMessage;
