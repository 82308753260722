import axios from 'axios';
import * as endpoints from '../constants/endpoints';

const fetchFilesSignedCookie = async () => {
  const { data } = await axios.get(endpoints.FILE_AUTH_COOKIE(), {
    withCredentials: true,
  });

  return data;
};

export default fetchFilesSignedCookie;
