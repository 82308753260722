import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip, IconButton } from '@ublend-npm/aulaui-next';
import { getSpaceById } from '@core/selectors/space/space.selectors';
import { AulaState } from '@core/types/state';

import {
  SpaceSelectorTextContainer,
  SpaceSelectorSecondaryText,
  SpaceSelectorPrimaryText,
  ActivityIndicator,
  getSelectorItemStyles,
  ListItem,
  Avatar,
  IconButtonContainer,
  SpaceItemContainer,
  StarredSpaceIcon,
  UnstarredSpaceIcon,
} from './SpaceSelectorItem.styled';

import { ENTER } from '../../../../../utils/keyCodes';

const SHOW_TOOLTIP_THRESHOLD = 17;

const listItemClasses = { text: 'listItemText' };

type SpaceSelectorItemProps = {
  spaceId: string;
  avatar?: string;
  name: string;
  archived?: boolean;
  hasActivity?: boolean;
  onClick: () => void;
  onStar?: () => Promise<void>;
};

const SpaceSelectorItem = ({
  spaceId,
  avatar,
  name,
  archived,
  hasActivity,
  onClick,
  onStar,
  ...props
}: SpaceSelectorItemProps) => {
  const { isStarred } = useSelector((state: AulaState) =>
    getSpaceById(state, spaceId)
  ) || { isStarred: false };
  const [isStarSaving, setIsStarSaving] = useState(false);

  const withTooltip = (child) => {
    if (name.length >= SHOW_TOOLTIP_THRESHOLD) {
      return (
        <Tooltip position="right" title={name}>
          {child}
        </Tooltip>
      );
    }

    return child;
  };

  const showStar = typeof onStar === 'function';

  const handleToggleStar = async () => {
    setIsStarSaving(true);

    if (onStar) {
      await onStar();
    }

    setIsStarSaving(false);
  };

  return withTooltip(
    <SpaceItemContainer>
      <ListItem
        size="medium"
        textTruncate
        leftDecoration={<Avatar size="medium" name={name} src={avatar} />}
        rightDecoration={hasActivity ? <ActivityIndicator /> : null}
        primaryText={
          <SpaceSelectorTextContainer>
            <SpaceSelectorPrimaryText>{name}</SpaceSelectorPrimaryText>
            {archived && (
              <SpaceSelectorSecondaryText>Archived</SpaceSelectorSecondaryText>
            )}
          </SpaceSelectorTextContainer>
        }
        classes={listItemClasses}
        css={getSelectorItemStyles({ showStar })}
        onClick={onClick}
        onKeyDown={(e) => {
          if (e.keyCode === ENTER) {
            onClick();
          }
        }}
        data-testid="space-selector-item"
        label={`Switch to the ${name} space`}
        {...props}
      />
      {showStar && (
        <IconButtonContainer>
          <IconButton
            label={`${isStarred ? 'Unstar' : 'Star'} the ${name} space`}
            onClick={handleToggleStar}
            size="xSmall"
            icon={() =>
              isStarred || isStarSaving ? (
                <StarredSpaceIcon isSaving={isStarSaving} />
              ) : (
                <UnstarredSpaceIcon />
              )
            }
            disabled={isStarSaving}
          />
        </IconButtonContainer>
      )}
    </SpaceItemContainer>
  );
};

export default React.memo(SpaceSelectorItem);
