import { theme } from '@ublend-npm/aulaui-next';

export default {
  overlay: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    backgroundColor: theme.color.overlay,
    zIndex: 9999,
    top: 0,
    left: 0,
  },
};
