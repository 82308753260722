const acceptedStatuses = ['started', 'error', 'success'];

const editMessage = (state, action) => {
  const { status } = action;
  if (!acceptedStatuses.includes(status)) return state;

  return {
    ...state,
    editingMessage: status === 'started',
  };
};

export default editMessage;
