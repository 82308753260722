import { FETCH_REACTIONS } from '../actions';

const fetchReactionsSuccess = (reactions) => (dispatch) =>
  dispatch({
    type: FETCH_REACTIONS,
    status: 'success',
    payload: { reactions },
  });

export default fetchReactionsSuccess;
