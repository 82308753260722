import { INBOX_RENAME_CONVERSATION } from '../actions';

const onTitleChanged = (data) => (dispatch) => {
  const { conversation: conversationId, title } = data;

  dispatch({
    type: INBOX_RENAME_CONVERSATION,
    status: 'success',
    payload: {
      conversationId,
      title,
    },
  });
};

export default onTitleChanged;
