import * as actionTypes from '../../constants/actions';
import { newError } from '../errors';
import { NO_CLASS_ROOM_ERROR_MESSAGE } from './posts';
import {
  STARTED_STATUS,
  SUCCESS_STATUS,
  ERROR_STATUS,
} from '../../constants/actionsStatus';
import { fetchTagPostsSuccess } from '../tags/tagPost';
import fetchReactionsSuccess from '../../reactions/operations/fetchReactionsSuccess.operation';
import { fetchTags } from '../tags/tag';
import { fetchCommentsSuccess } from '../comment/fetch';
import getFeed from '../../api/posts/feed';
import { fetchImportantPosts } from '../classRoom/importantPosts';
import { buildFetchScheduledPostsSuccess } from './scheduledPosts.action';
import { groupCommentByPostReducer, getUntilDate } from './utils';

const fetchCommonRoomStart = (reset, spaceId, dispatch) => {
  dispatch({
    type: actionTypes.SPACE_FETCH_COMMON_ROOM,
    status: STARTED_STATUS,
    reset,
    spaceId,
  });
};

const fetchCommonRoomSuccess = (content, dispatch) => {
  dispatch({
    type: actionTypes.SPACE_FETCH_COMMON_ROOM,
    status: SUCCESS_STATUS,
    content,
  });
};

const fetchCommonRoomError = (error, dispatch) => {
  dispatch(newError(error, 'fetchCommonRoomError'));
  dispatch({
    type: actionTypes.SPACE_FETCH_COMMON_ROOM,
    status: ERROR_STATUS,
    error,
  });
};

export const fetchCommonRoom =
  ({
    classRoom,
    reset = false,
    latest = false,
    tagId,
    shouldFetchTags = false,
  }) =>
  async (dispatch) => {
    if (!classRoom) {
      const err = { message: NO_CLASS_ROOM_ERROR_MESSAGE };
      fetchCommonRoomError(err, dispatch);
      return;
    }

    const spaceId = classRoom.objectId;
    const until = getUntilDate({
      reset,
      latest,
      posts: classRoom.posts,
      oldestPostDate: classRoom.oldestPostDate,
    });

    fetchCommonRoomStart(reset, spaceId, dispatch);
    dispatch(fetchImportantPosts({ spaceId, tagId }));
    if (shouldFetchTags) {
      dispatch(fetchTags(classRoom.objectId));
    }
    try {
      const [commonRoomPosts, scheduledCommonRoomPosts] = await Promise.all([
        getFeed({ spaceId, until, tagId }),
        getFeed({ spaceId, scheduled: true }),
      ]);

      const { tagPosts, posts, comments, reactions } = commonRoomPosts;
      fetchCommonRoomSuccess(posts, dispatch);
      fetchTagPostsSuccess(tagPosts, dispatch);

      dispatch(
        buildFetchScheduledPostsSuccess(
          actionTypes.FETCH_SCHEDULED_COMMON_ROOM,
          scheduledCommonRoomPosts.posts
        )
      );
      fetchTagPostsSuccess(scheduledCommonRoomPosts.tagPosts, dispatch);

      // accumulate all comments per post
      const commentPosts = comments.reduce(groupCommentByPostReducer, {});
      Object.keys(commentPosts).forEach((postId) => {
        if (postId) {
          fetchCommentsSuccess(postId, commentPosts[postId], dispatch);
        }
      });

      dispatch(fetchReactionsSuccess(reactions));
    } catch (error) {
      fetchCommonRoomError(error, dispatch);
      throw error;
    }
  };
