import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { SEARCH } from '../../../../../../core/constants/endpoints';
import SpaceSelectorItem from './SpaceSelectorItem';
import SpaceFilter from './SpaceFilter';
import { analyticsTrackEvent } from '../../../../../../core/utils/analytics';
import { SELECT_SPACE_STAFF_SPACE_SELECTOR } from '../../../../../../core/constants/analytics';
import { StaffSpacesContainer } from './StaffSpaces.styled';
import { List, GrayText } from './SpaceSelector.styled';

const StaffSpaces = ({
  onSpaceSelect,
  userId,
  sortOptions,
  selectedSortOption,
}) => {
  const [showArchived, setShowArchived] = useState(false);
  const [spaces, setSpaces] = useState([]);
  const [searchQuery, setSearchQuery] = useState();
  const [descriptionText, setDescriptionText] = useState(
    'Use the search function to find and view any space in your institution.'
  );

  const searchSpaces = async ({ query, hideArchived }) => {
    setDescriptionText('Searching');
    const res = await axios
      .get(`${SEARCH()}/v2/spaces/${query}`, {
        params: {
          hideArchived: hideArchived === true || undefined,
        },
        withCredentials: true,
        headers: {
          'x-user-id': userId,
        },
      })
      .catch((error) => {
        setDescriptionText('Failed to search spaces.');
        throw error;
      });

    if (!res.data.spaces.length) {
      setDescriptionText(
        'Your search didn’t match any space in your institution. Make sure the spelling is correct or try a different search.'
      );
    }
    setSpaces(res.data.spaces || []);
  };

  const onSearchBoxChange = (query) => {
    setSearchQuery(query);
  };
  const searchSpacesWithDebounce = useCallback(
    debounce(
      ({ query, hideArchived }) => searchSpaces({ query, hideArchived }),
      500
    ),
    []
  );

  useEffect(() => {
    if (searchQuery) {
      searchSpacesWithDebounce({
        query: searchQuery,
        hideArchived: !showArchived,
      });
    }
  }, [searchQuery]);

  useEffect(() => {
    if (searchQuery) {
      searchSpaces({ query: searchQuery, hideArchived: !showArchived });
    }
  }, [showArchived]);

  const handleArchivedToggleChange = () => setShowArchived((prev) => !prev);

  return (
    <>
      <StaffSpacesContainer>
        <SpaceFilter
          filter={searchQuery}
          onArchivedToggleChange={handleArchivedToggleChange}
          currentSortOption={selectedSortOption.description}
          sortOptions={sortOptions}
          onFilterChange={onSearchBoxChange}
          hasArchivedSpaces
        />
      </StaffSpacesContainer>
      {spaces.length ? (
        <List compact>
          {spaces
            .sort(selectedSortOption.comparator)
            .map(({ name, id, avatar, archived }) => (
              <SpaceSelectorItem
                name={name}
                avatar={avatar}
                archived={archived}
                onClick={() => {
                  analyticsTrackEvent(SELECT_SPACE_STAFF_SPACE_SELECTOR, {
                    newSpace: id,
                  });
                  onSpaceSelect(id);
                }}
              />
            ))}
        </List>
      ) : (
        <GrayText>{descriptionText}</GrayText>
      )}
    </>
  );
};

StaffSpaces.propTypes = {
  onSpaceSelect: PropTypes.func,
  userId: PropTypes.string.isRequired,
  sortOptions: PropTypes.shape([]).isRequired,
  selectedSortOption: PropTypes.shape({
    description: PropTypes.string,
    comparator: PropTypes.func,
  }).isRequired,
};

StaffSpaces.defaultProps = {
  onSpaceSelect: () => {},
};

export default React.memo(StaffSpaces);
