import { INBOX_OPEN_CLOSE_EDIT_MESSAGE } from '../actions';

const closeEditMessage = () => (dispatch) => {
  dispatch({
    type: INBOX_OPEN_CLOSE_EDIT_MESSAGE,
    payload: {},
  });
};

export default closeEditMessage;
