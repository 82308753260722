import { FETCH_SIGNED_FILE_COOKIE } from '../constants/actions';

const initialState = {
  cookieTimestamp: undefined,
};

const filesCookieReducer = (state = initialState, action) => {
  const { type } = action;
  if (type === FETCH_SIGNED_FILE_COOKIE) {
    return {
      ...state,
      cookieTimestamp: new Date(),
    };
  }

  return state;
};

export default filesCookieReducer;
