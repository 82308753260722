/* eslint-disable no-restricted-globals */
import parser from 'ua-parser-js';

const client = parser();

export default {
  Browser: client.browser.name,
  'Browser Version': client.browser.version,
  'Operating System': client.os.name,
  'Operating System Version': client.os.version,
  'Screen Width': screen.width,
  'Screen Height': screen.height,
  'Current URL': document.URL,
  Referrer: document.referrer,
  application: 'webapp',
};
