import { newError } from '../errors';
import * as types from '../../constants/actions';
import addTagToPost from '../../api/tags/addTagToPost';
import deleteTagFromPost from '../../api/tags/deleteTagFromPost';

export const fetchTagPostsSuccess = (fetchedTagPosts, dispatch) => {
  dispatch({
    type: types.FETCH_TAGS_POST,
    status: 'success',
    fetchedTagPosts,
  });
};

// Create tagPost
const createTagPostStarted = (dispatch) => {
  dispatch({
    type: types.CREATE_TAG_POST,
    status: 'started',
  });
};

export const createTagPostSuccess = (tagPost, dispatch) => {
  dispatch({
    type: types.CREATE_TAG_POST,
    status: 'success',
    tagPost,
  });
};

const createTagPostError = (error, dispatch) => {
  dispatch(newError(error, 'createTagPost'));
  dispatch({
    type: types.CREATE_TAG_POST,
    error,
  });
};

export const createTagPost = (tagId, postId) => async (dispatch) => {
  createTagPostStarted(dispatch);

  try {
    const tagPost = await addTagToPost({ tagId, postId });
    createTagPostSuccess(tagPost, dispatch);
    return tagPost;
  } catch (error) {
    createTagPostError(error, dispatch);
    throw error;
  }
};

// Delete tagPost
const deleteTagPostStarted = (dispatch) => {
  dispatch({
    type: types.DELETE_TAG_POST,
    status: 'started',
  });
};

export const deleteTagPostSuccess = (payload, dispatch) => {
  dispatch({
    type: types.DELETE_TAG_POST,
    status: 'success',
    payload,
  });
};

const deleteTagPostError = (error, dispatch) => {
  dispatch(newError(error, 'deleteTagPost'));
  dispatch({
    type: types.DELETE_TAG_POST,
    error,
  });
};

export const deleteTagPost =
  ({ tagId, postId }) =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const post = state.data.posts[postId];
      const tagPosts = (post || {}).tagPosts || [];
      const tagPost = tagPosts.find((t) => t.tagId === tagId);
      if (!tagPost) {
        return;
      }
      deleteTagPostStarted(dispatch);
      await deleteTagFromPost({
        tagId,
        postId,
      });
      const payload = { tagId, postId };
      deleteTagPostSuccess(payload, dispatch);
    } catch (error) {
      deleteTagPostError(error, dispatch);
      throw error;
    }
  };
