import * as analyticsTypes from '../../constants/analytics';
import { analyticsTrackEvent } from '../../utils/analytics';
import * as actionTypes from '../../constants/actions';

import { fetchCommonRoom } from './commonRoom.action';
import { fetchAnnouncements } from './announcements.action';

export const fetchSpaceContent =
  ({ classRoom, reset = false, latest = false, tagId }) =>
  async (dispatch) => {
    await Promise.all([
      dispatch(
        fetchCommonRoom({
          classRoom,
          reset,
          tagId,
          latest,
          shouldFetchTags: true,
        })
      ),
      dispatch(fetchAnnouncements({ classRoom, reset, tagId, latest })),
    ]);
  };

export const selectFeedType =
  ({ feedType, spaceId }) =>
  async (dispatch) => {
    const properties = {
      itemType: feedType,
      activeClassroom: spaceId,
    };
    analyticsTrackEvent(analyticsTypes.FEED_VIEW, properties);
    dispatch({
      type: actionTypes.SPACE_SELECT_FEED_TYPE,
      payload: { feedType },
    });
  };

export { fetchCommonRoom, fetchAnnouncements };
