import { SPACE_SELECTOR_TOGGLE_STAR } from '../../constants/actions';
import { currentUserId as getCurrentUserId } from '../../selectors/user';
import { getSpaceById } from '../../selectors/space';
import showToast from '../../toasts/operations/showToast.operation';
import { starSpace } from '../api';
import { STAR_SPACE_ERROR, UNSTAR_SPACE_ERROR } from './toasts';
import { analyticsTrackEvent } from '../../utils/analytics';
import {
  SPACE_SELECTOR_ITEM_TYPE,
  SPACE_SELECTOR_SPACE_STARRED,
  SPACE_SELECTOR_SPACE_UNSTARRED,
} from '../../constants/analytics';
import { openCorrelationId, openTimestamp } from '../selectors';
import getNow from '../getNow';

const starSpaceSuccess = ({ spaceId, isStarred }) => ({
  type: SPACE_SELECTOR_TOGGLE_STAR,
  status: 'success',
  payload: { spaceId, isStarred },
});

const toggleStarSpace =
  ({
    spaceId,
    search,
    sort,
    showArchived,
    category,
    subCategory,
  }: {
    spaceId: string;
    search?: string;
    sort?: string;
    showArchived?: boolean;
    category?: string;
    subCategory?: string;
  }) =>
  async (dispatch, getState) => {
    const state = getState();
    const currentUserId = getCurrentUserId(state);
    const { isStarred: isCurrentlyStarred } = getSpaceById(state, spaceId) || {
      isStarred: false,
    };

    try {
      await starSpace({
        userId: currentUserId,
        spaceId,
        isStarred: !isCurrentlyStarred,
      });
      dispatch(starSpaceSuccess({ spaceId, isStarred: !isCurrentlyStarred }));

      const eventName = !isCurrentlyStarred
        ? SPACE_SELECTOR_SPACE_STARRED
        : SPACE_SELECTOR_SPACE_UNSTARRED;

      analyticsTrackEvent(eventName, {
        itemType: SPACE_SELECTOR_ITEM_TYPE,
        userId: currentUserId,
        spaceId,
        selectorOpenFor: Math.round(getNow() - openTimestamp(state)),
        correlationId: openCorrelationId(state),
        search,
        sort,
        showArchived,
        categoriesEnabled: true,
        category,
        subCategory,
      });
    } catch (error) {
      dispatch(
        showToast(!isCurrentlyStarred ? STAR_SPACE_ERROR : UNSTAR_SPACE_ERROR)
      );
    }
  };

export default toggleStarSpace;
