import { CLASSROOM_MODULE_REVERT } from '../actions';
import { revertToVersion } from '../api';
import * as materialsSelectors from '../selectors';
import { AulaReduxAction } from '../../types/state';
import { getCurrentSpaceId } from '../../selectors/space';
import { ActionStatus } from '../../constants/actionsStatus';
import { Version } from '../types';

const revertAction = (status: ActionStatus, payload: unknown) => ({
  type: CLASSROOM_MODULE_REVERT,
  status,
  payload,
});

type RevertSectionToVersionActionFactory = (
  version: Version
) => AulaReduxAction;

const revert: RevertSectionToVersionActionFactory =
  (version) => async (dispatch, getState) => {
    const state = getState();
    const spaceId = getCurrentSpaceId(state);
    const section = materialsSelectors.currentSection(state);
    const startedPayload = { classRoom: spaceId, section, version: version.id };

    dispatch(revertAction('started', startedPayload));

    try {
      const updatedSection = await revertToVersion(
        spaceId,
        section.id,
        version.id
      );

      const successPayload = {
        classRoom: spaceId,
        section: updatedSection,
        version: updatedSection.version,
      };
      dispatch(revertAction('success', successPayload));
    } catch (error: unknown) {
      dispatch(revertAction('error', error));
    }
  };

export default revert;
