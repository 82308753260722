import { CLASSROOM_MODULE_LIVE_UPDATED_MATERIAL } from '../actions';
import { Material } from '../types';
import { AulaDispatch } from '../../types/state';

const liveUpdatedMaterial =
  (dispatch: AulaDispatch) =>
  (payload: { material: Material; localId: string }) =>
    dispatch({
      type: CLASSROOM_MODULE_LIVE_UPDATED_MATERIAL,
      payload: payload,
    });

export type LiveUpdatedMaterial = typeof liveUpdatedMaterial;

export default liveUpdatedMaterial;
