import { CLASSROOM_MODULE_IMPORT_SECTIONS } from '../actions';
import { createFolder, createSection } from '../api';
import { currentUserId } from '../../selectors/user';
import { importer } from '../importExport';
import toastOperation from '../../toasts/operations';
import { ActionStatus } from '../../constants/actionsStatus';
import { AulaReduxAction } from '@core/types/state';
import { getCurrentSpaceId } from '../../selectors/space';
import series from '../../utils/series';
import { Save } from '../importExport/v2Importer';

type SaveDeps = {
  classRoom: string;
  educatorOnly?: boolean;
};

const save: (_: SaveDeps) => Save =
  ({ classRoom, educatorOnly }) =>
  (section) =>
  () =>
    section.content
      ? createSection(classRoom, {
          ...section,
          title: section.title || '',
          educatorOnly,
        })
      : createFolder(classRoom, {
          ...section,
          title: section.title || '',
          educatorOnly,
        });

const importMaterialAction = (status: ActionStatus, payload: unknown) => ({
  type: CLASSROOM_MODULE_IMPORT_SECTIONS,
  status,
  payload,
});

type ImportMaterialsActionFactory = (_: {
  materials: string[];
  educatorOnly?: boolean;
}) => AulaReduxAction<void>;

const importMaterials: ImportMaterialsActionFactory =
  ({ materials, educatorOnly }) =>
  async (dispatch, getState) => {
    const state = getState();
    const userId = currentUserId(state);

    if (!userId) {
      return;
    }

    const spaceId = getCurrentSpaceId(state);

    dispatch(
      importMaterialAction('started', { classRoom: spaceId, materials })
    );

    try {
      const importMaterial = (fileContents: string) => () =>
        importer(
          save({ classRoom: spaceId, educatorOnly }),
          userId
        )(fileContents);

      const savedSections = (
        await series(materials.flatMap(importMaterial))
      ).flatMap((material) => material);

      dispatch(
        importMaterialAction('success', {
          classRoom: spaceId,
          sections: savedSections,
        })
      );
      dispatch(
        toastOperation.showToast({
          emoji: '✅',
          message: 'The materials were imported successfully.',
        })
      );
    } catch (error) {
      dispatch(importMaterialAction('error', error));
      dispatch(
        toastOperation.showToast({
          emoji: '🤔',
          message: 'There was an error when importing the materials.',
        })
      );
    }
  };

export default importMaterials;
