import * as utils from '../actions/socket/utils';

const joinConversation = (store) => (next) => (action) => {
  const {
    live: {
      conversations: { step: previousStep },
    },
  } = store.getState();
  next(action);
  const {
    live: {
      conversations: { step, conversations },
    },
  } = store.getState();

  const stepDidNotChanged = previousStep === step;
  if (stepDidNotChanged || step !== 'joining') return;

  store.dispatch(utils.joinConversation(conversations));
};

export default joinConversation;
