import React from 'react';
import PropTypes from 'prop-types';

import Settings from './RightPanel/Settings';
import UserStatus from './RightPanel/Status/UserStatus';
import Emoji from '../emoji/Emoji';
import EmojiUtils from '../../../../core/utils/emoji';
import TabTitle from './Tabs.styled';

export const getTabs = (status, flags) => {
  return [
    {
      title: (
        <TabTitle key="status_tab">
          Status
          {status?.emojiName ? (
            <Emoji emoji={EmojiUtils.getFromShortName(status.emojiName)} />
          ) : null}
        </TabTitle>
      ),
      key: 'status',
      component: UserStatus,
      visible: ({ ftUserStatusEnabled202107 }) => ftUserStatusEnabled202107,
    },
    {
      title: (
        <TabTitle key="notif-settings_tab">Notification Settings</TabTitle>
      ),
      key: 'notif-settings',
      component: Settings,
      visible: () => true,
    },
  ].filter((t) => t.visible(flags));
};

export const tabType = PropTypes.shape({
  title: PropTypes.shape({}),
  key: PropTypes.string.isRequired,
  component: PropTypes.elementType,
  onClick: PropTypes.func,
  TitleComponent: PropTypes.elementType,
});
