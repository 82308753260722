import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';
import { config as breakpoints } from '../../../../hooks/useBreakpoints';

export const Container = styled('nav')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: ${theme.color.white};
  box-shadow: ${({ shouldShowBottomShadow }) =>
    shouldShowBottomShadow ? 'inset 0px -1px 0px rgba(0, 0, 0, 0.12)' : 'none'};
`;

export const SpaceSelectorContainer = styled.div`
  width: 100%;
  display: flex;
  // Vertical padding has to decrease 20px from each size as warning's height is 40px
  padding: ${({ showWarning }) =>
    showWarning
      ? `9px 0 9px ${theme.spacing.xxxGiant}`
      : `29px 0 29px ${theme.spacing.xxxGiant}`};

  @media only screen and (max-width: ${breakpoints.md - 1}px) {
    padding: ${({ showWarning }) =>
      showWarning
        ? `9px 0 9px ${theme.spacing.xxLarge}`
        : `29px 0 ${theme.spacing.medium} ${theme.spacing.xxLarge}`};
  }
`;

export const TabsWrapper = styled('div')`
  padding-left: ${theme.spacing.xxxGiant};

  @media only screen and (max-width: ${breakpoints.md - 1}px) {
    padding-left: ${theme.spacing.xxLarge};
  }

  div > button {
    margin-right: 24px;
    color: ${theme.color.designSystem.blackOpacity60};
    opacity: 1;
  }

  #space-settings-tabs {
    min-height: 0;
  }

  #space-settings-tabs span.wrapper > span {
    padding: 0;
  }

  // removes default margin right for icon holder since we don't use icons
  #space-settings-tabs span.wrapper div {
    margin: 0;
  }
`;

export const Alert = styled('div')`
  width: 100%;
  min-height: 20px;
  background-color: ${theme.color.designSystem.background.light.information};
  padding: 10px ${theme.spacing.large};
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${theme.font.size.copy};
`;
