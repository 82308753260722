import { MaterialProgressStatus } from '@ublend-npm/aula-schema';
import { AulaState } from '../types/state';
import {
  isInstructor,
  accessingSpaceAsStaff,
  getIsStudent,
} from '../selectors/classroom';
import { Material, Section, LocalSection } from './types';
import { getCurrentSpaceId } from '../selectors/space';

const materialApp = (state: AulaState) => state.materials;
const materialData = (state: AulaState) => state.data.materials;

export const currentSectionId = (state: AulaState) => {
  const { routing } = state;

  if (routing) {
    const {
      locationBeforeTransitions: { pathname },
    } = routing;
    const materialComponentIndex = pathname.search('/materials/');
    if (materialComponentIndex !== -1) {
      return pathname
        .substring(materialComponentIndex)
        .replace('/materials/', '');
    }

    const educatorMaterialComponentIndex = pathname.search('/educatorArea/');
    if (educatorMaterialComponentIndex !== -1) {
      return pathname
        .substring(educatorMaterialComponentIndex)
        .replace('/educatorArea/', '');
    }
  }

  // eslint-disable-next-line consistent-return
  return undefined;
};

export const isDeletingSection = (state: AulaState) =>
  materialApp(state).isDeletingSection;

export const editing = (state: AulaState) => materialApp(state).editing;

export const isFetchingSections = (state: AulaState) =>
  materialApp(state).isFetchingSections;

export const isLoadingContent = (state: AulaState) =>
  materialApp(state).isLoadingContent;

export const isHidingMaterial = (state: AulaState) =>
  materialApp(state).isHidingMaterial;

export const isFetchingVersions = (state: AulaState) =>
  materialApp(state).isFetchingVersions;

export const isLoadingVersion = (state: AulaState) =>
  materialApp(state).isLoadingVersion;

export const isErrored = (state: AulaState) => materialApp(state).isErrored;

export const isRevertingSection = (state: AulaState) =>
  materialApp(state).isRevertingSection;

export const isSavingSection = (state: AulaState) =>
  materialApp(state).isSavingSection;

export const isImportingMaterials = (state: AulaState) =>
  materialApp(state).isImportingMaterials;

export const currentDeleteCandidate = (state: AulaState) =>
  materialApp(state).currentDeleteCandidate;

export const currentDeleteCandidateCheckNull = (state: AulaState) =>
  currentDeleteCandidate(state) !== null;

export const selectedContent = (state: AulaState) =>
  materialApp(state).selectedContent;

export const uneditedContent = (state: AulaState) =>
  materialApp(state).uneditedContent;

export const selectedVersion = (state: AulaState) =>
  materialApp(state).selectedVersion;

export const leaveDestinationPath = (state: AulaState) =>
  materialApp(state).leaveDestinationPath;

export const currentRevertCandidate = (state: AulaState) => {
  const { currentRevertCandidate } = materialApp(state);

  if (!currentRevertCandidate) {
    return undefined;
  }

  return materialData(state)[currentRevertCandidate];
};

export const hasFetchedSections = (state: AulaState) =>
  materialApp(state).hasFetchedSections;

export const shouldFetchSections = (state: AulaState) =>
  !(hasFetchedSections(state) || isFetchingSections(state) || isErrored(state));

export const isExpanded = (state: AulaState, folderId: string) =>
  !!materialApp(state).expandedFolders[folderId];

export const renameFolderDialogOpenFor = (state: AulaState) =>
  materialApp(state).renameFolderDialogOpenFor;

export const deleteFolderDialogOpenFor = (state: AulaState) =>
  materialApp(state).deleteFolderDialogOpenFor;

export const scheduleMaterialDialogOpenFor = (state: AulaState) =>
  materialApp(state).scheduleMaterialDialogOpenFor;

export const isSavingFolder = (state: AulaState) =>
  materialApp(state).isSavingFolder;
export const isDeletingFolder = (state: AulaState) =>
  materialApp(state).isDeletingFolder;

export const getMaterial = (
  state: AulaState,
  materialId: string | undefined | null
): Material | null | undefined => {
  if (!materialId) return null;
  return materialData(state)[materialId];
};

export const getSection = (
  state: AulaState,
  materialId: string | undefined | null
): Section | LocalSection | undefined => {
  const material = getMaterial(state, materialId);

  if (!material || material.isFolder) {
    return;
  }

  return material;
};

export const isFolder = (state: AulaState, materialId: string) =>
  !!getMaterial(state, materialId)?.isFolder;

export const currentSection = (state: AulaState) =>
  <Section>getMaterial(state, currentSectionId(state));

export const currentSectionTitle = (state: AulaState) =>
  currentSection(state)?.title || '';

const compareMaterialOrder = (a: Material, b: Material) => {
  if (a.order < b.order) return -1;
  if (a.order > b.order) return 1;
  return 0;
};

export const sectionsByParent = (
  state: AulaState,
  parentId: string | undefined | null
): Material[] => {
  const spaceId = getCurrentSpaceId(state);
  const materials = materialData(state);
  const parentMaterial = getMaterial(state, parentId);
  const currentUserIsInstructor = isInstructor(state);
  const userIsStaff = accessingSpaceAsStaff(state)(spaceId);

  const parentIsHidden = !!parentMaterial?.hidden;

  return Object.values<Material>(materials)
    .filter((material) => {
      const matchSpace = material.space === spaceId;
      const matchParent = parentId
        ? material.parent === parentId
        : !material.parent;
      const materialIsHidden = material.hidden || parentIsHidden;
      const visible =
        currentUserIsInstructor || userIsStaff || !materialIsHidden;

      return matchSpace && matchParent && visible;
    })
    .sort(compareMaterialOrder);
};

export const sectionIdsByParent = (
  state: AulaState,
  parentId: string | undefined,
  educatorOnly = false
): string[] =>
  sectionsByParent(state, parentId)
    .filter((material) => educatorOnly === !!material.educatorOnly)
    .map((material) => material.id);

export const topLevelMaterials = (state: AulaState) =>
  sectionsByParent(state, undefined);

export const topLevelMaterialIds = (state: AulaState) =>
  sectionIdsByParent(state, undefined);

export const topLevelMaterialEducatorOnlyIds = (state: AulaState) =>
  sectionIdsByParent(state, undefined, true);

export const getFirstMaterialId = (
  state: AulaState,
  { educatorOnly = false } = {}
) => {
  const materials = materialData(state);
  if (!materials) {
    return null;
  }

  const sectionsByParentRes = sectionsByParent(state, undefined).filter(
    (section) => Boolean(section.educatorOnly) === Boolean(educatorOnly)
  );
  if (sectionsByParentRes.length === 0) {
    return null;
  }

  const firstItem = sectionsByParentRes[0];
  if (!firstItem.isFolder) {
    return firstItem.id;
  }

  const sectionsInFolder = Object.values<Material>(materials)
    .filter((section) => Boolean(section.educatorOnly) === educatorOnly)
    .filter(({ parent }: Section) => parent === firstItem.id)
    .sort(compareMaterialOrder);

  if (sectionsInFolder.length === 0) {
    return null;
  }

  return sectionsInFolder[0].id;
};

export const getFirstEducatorMaterialId = (state: AulaState) =>
  getFirstMaterialId(state, { educatorOnly: true });

export const hasAtLeastOneChildSection = (state: AulaState) => {
  const materials = materialData(state);
  const sections = Object.keys(materials).filter(
    (id) => !materials[id].isFolder
  );

  return sections.length > 0;
};

export const fallbackSectionId = (
  state: AulaState,
  materialId: string,
  educatorOnly = false
) => {
  const current = currentSection(state);

  if (!current) {
    return null;
  }

  if (current.id !== materialId && current.parent !== materialId) {
    return current.id;
  }

  const material = getMaterial(state, materialId);

  const materials = sectionsByParent(state, material?.parent).filter(
    (section) => Boolean(section.educatorOnly) === Boolean(educatorOnly)
  );

  const materialIdx = materials.findIndex((m) => m.id === materialId);

  const notFolder = (m: Material): m is Section => !m.isFolder;

  const following = materials.slice(materialIdx + 1).find(notFolder);

  if (following) {
    return following.id;
  }

  const preceding = materials.slice(0, materialIdx).reverse().find(notFolder);
  if (preceding) {
    return preceding.id;
  }
  return getFirstMaterialId(state, { educatorOnly });
};

export const isUpdatingMaterialProgress = (
  state: AulaState,
  materialId: string
) => materialApp(state).sectionIdsWithProgressUpdating.includes(materialId);

const getIsUserProgressComplete = (section: Section | LocalSection) => {
  return section.userProgress?.status === MaterialProgressStatus.COMPLETE;
};

export const isSectionUserProgressComplete = (
  state: AulaState,
  materialId: string
) => {
  const section = getSection(state, materialId);

  if (!section) {
    return false;
  }
  return getIsUserProgressComplete(section);
};

export const isFolderSectionsUserProgressComplete = (
  state: AulaState,
  folderId: string
) => {
  const sections = sectionsByParent(state, folderId);

  if (sections.length === 0) {
    return false;
  }

  return !sections.some(({ id }) => !isSectionUserProgressComplete(state, id));
};

export type SectionsProgressInFolder = Readonly<{
  numberOfSections: number;
  numberOfSectionsCompleted: number;
}>;

export const getSectionsProgressInFolder = (
  state: AulaState,
  folderId: string
): SectionsProgressInFolder | undefined => {
  if (!getIsStudent(state)) {
    return;
  }
  const sections = sectionIdsByParent(state, folderId);
  const numberOfSections = sections.length;
  if (!numberOfSections) {
    return;
  }
  const sectionsCompleted = sections.filter((id) =>
    isSectionUserProgressComplete(state, id)
  );
  const numberOfSectionsCompleted = sectionsCompleted.length;
  return {
    numberOfSections,
    numberOfSectionsCompleted,
  };
};

export type AllSectionsUserProgress = Readonly<{
  numberOfSections: number;
  numberOfSectionsCompleted: number;
}>;

export const getAllSectionsUserProgress = (
  state: AulaState
): AllSectionsUserProgress | undefined => {
  const isStudent = getIsStudent(state);
  if (!isStudent) {
    return;
  }
  const materials = topLevelMaterials(state);
  if (!materials.length) {
    return {
      numberOfSections: 0,
      numberOfSectionsCompleted: 0,
    };
  }

  const allSections: Section[] = materials.reduce(
    (sectionAccumulator: Section[], material) => {
      const isFolder = material.isFolder;
      if (isFolder) {
        sectionAccumulator.push(
          ...(<Section[]>sectionsByParent(state, material.id))
        );
        return sectionAccumulator;
      }
      sectionAccumulator.push(<Section>material);
      return sectionAccumulator;
    },
    []
  );

  const sectionsCompleted = allSections.filter(getIsUserProgressComplete);
  const numberOfSections = allSections.length;
  const numberOfSectionsCompleted = sectionsCompleted.length;

  return {
    numberOfSections,
    numberOfSectionsCompleted,
  };
};
