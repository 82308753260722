import { POST } from '../../constants/endpoints';
import request from '../request';

const deletePost = async (postId) =>
  request({
    method: 'delete',
    url: POST(postId),
  });

export default deletePost;
