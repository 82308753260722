export const LOGIN_ERROR = 'Invalid email or password';

// Errors
export const ERROR_FEEDS =
  "It seems like we can't load your feed right now. 🤔";
export const ERROR_POST = "It seems like we can't load your post right now. 🤔";
export const ERROR_INBOX_MODAL =
  "It seems like we can't load this conversation's messages right now. 🤔";
export const ERROR_INBOX_GROUPS_LIST =
  "It seems like we can't load your messages right now. 🤔";
export const ERROR_MATERIALS =
  "It seems like we can't load your materials right now. 🤔";
export const ERROR_EDITOR = 'It seems like something went wrong here. 🤔';

// Buttons
export const BUTTON_LOGOUT = 'Log out';
export const BUTTON_WRITE_COMMENT = 'WRITE A COMMENT';
export const BUTTON_CREATE_POST = 'Post';
export const BUTTON_READ_MORE = 'Read more...';
export const BUTTON_SEE_COMMENTS = 'See comments...';
export const BUTTON_DOWNLOAD = 'Download';
export const BUTTON_DELETE = 'Delete';
export const BUTTON_PREVIEW = 'Preview';
export const BUTTON_SEND_MESSAGE = 'Send';
export const BUTTON_PRIVACY = 'Privacy Policy';
export const BUTTON_TERMS = 'Terms of Service';
export const MAKE_IMPORTANT = 'Pin post';
export const NOT_IMPORTANT = 'Unpin post';
export const BUTTON_CANCEL = 'Cancel';
export const BUTTON_ADD = 'Add';
export const BUTTON_BACK = '← Back';
export const BUTTON_ADD_PARTICIPANTS = 'Add participants';
export const BUTTON_SAVE = 'Save';

// Inbox
export const INBOX_BEGINNING_CONVERSATION = 'Beginning of your conversation';
export const INBOX_CONVERSATION_ACTION_ADD_PARTICIPANTS = 'Add participants';
export const INBOX_CONVERSATION_ACTION_LEAVE_CONVERSATION =
  'Leave conversation';
export const INBOX_CONVERSATION_CLOSE_CONVERSATION = 'Close conversation';
export const INBOX_CONVERSATION_ACTION_RENAME_CONVERSATION =
  'Rename conversation';
export const INBOX_CONVERSATION_ACTION_VIEW_PARTICIPANTS = 'View participants';
export const INBOX_CONVERSATION_ACTION_MUTE = 'Mute conversation';
export const INBOX_CONVERSATION_ACTION_UNMUTE = 'Unmute conversation';

export const INBOX_EDIT_MESSAGE_TITLE = 'Edit message';
export const INBOX_LEAVE_CONVERSATION_TITLE = 'Leave conversation?';
export const INBOX_NEW_MESSAGE_TOOLTIP = 'New message';
export const INBOX_OTHERS_TOOLTIP = 'View all participants';
export const INBOX_NO_CONTENT_TITLE = 'No messages here yet.';
export const EDUCATOR_AREA_PARTICIPANTS_TOOLTIP =
  'View educators in this space';

// Menu
export const MENU_ALL_FILES = 'All files';

// Navigation bar
export const MENU_FEED = 'Class Feed';
export const MENU_INBOX = 'Direct Messages';
export const MENU_MATERIALS = 'Class Material';
export const MENU_NOTIFICATIONS = 'Notifications';
export const MENU_IMPORTANT_POSTS_TOOLTIP = 'Pinned posts';
export const SEARCH_HINT = 'Search for posts or tags…';
export const NAVIGATION_BAR = {
  feed: 'Class Feed',
  materials: 'Class Material',
  notifications: 'Notifications',
};

export const menuTitles = [
  'Class Feed',
  'Notifications',
  'Direct Messages',
  'Class Material',
];

// Posts feed
export const OTHER_POSTS = 'Other posts';

// Input Labels
export const INPUT_LABEL_EMAIL = 'Email';
export const INPUT_LABEL_PASSWORD = 'Password';
export const INPUT_LABEL_HINT_PASSWORD = '(min. 8 characters)';
export const INPUT_LABEL_FIRST_NAME = 'First Name';
export const INPUT_LABEL_LAST_NAME = 'Last Name ';
export const INPUT_LABEL_CLASS_CODE = 'Class Key';
export const INPUT_LABEL_CLASS_CODE_HINT = '(e.g. abc123)';
export const INPUT_LABEL_CLASS_NAME = 'Class Name';
export const INPUT_LABEL_CLASS_NAME_HINT = '(e.g. Econ 101)';
export const INPUT_LABEL_CONVERSATION_TITLE = 'Conversation title';
export const INPUT_TEXT_CREATE_POST = 'Share something...';
export const INPUT_TEXT_CREATE_QUESTION = 'Ask a question...';
export const INPUT_TEXT_INBOX_GROUP = 'E.g. Study group 4...';
export const INPUT_TEXT_NEW_FOLDER = 'E.g. Term 2';
export const INPUT_TEXT_NEW_CONVERSATION_NAME = 'New conversation name';

// Tabs
export const TAB_CREATE_CLASS = 'Create a class';
export const TAB_JOIN_CLASS = 'Join a class';

// Radio
export const RADIO_IAM_INSTRUCTOR = "I'm an educator";
export const RADIO_IAM_STUDENT = "I'm a student";

// Text
export const DONE = 'Done';
export const MORE_FILES = ' more files';
export const ANONYMOUS_STUDENT = 'Anonymous student';
export const UPLOADER_DROP = 'Drop it here.';
export const SELECT = 'select';
export const INVITE_PARTICIPANTS = 'Invite participants';
export const INVITE_KEY = 'Invite your students by sharing this class key.';
export const INVITE_ALL = 'All they need to do is head to ';
export const INVITE_ENTER_KEY = 'and enter the class key.';
export const INVITE_CLASSKEY = 'Class key:';
export const INVITE_EMAIL =
  'To help them, we recommend you invite them by email.';
export const INVITE_SEND_EMAIL = 'Send email invite';
export const FEED_NOPOSTS =
  'The best way to engage your students on Aula is to tell them how you plan to use the platform. Let them know this is a safe space to ask questions and share relevant content with their learning community.';
export const CREATE = 'Create';
export const SAVE = 'Save';
export const SURE_DELETE_POST =
  'Are you sure that you want to delete this post?';
export const SURE_DELETE_MESSAGE =
  'Are you sure that you want to delete this message?';
export const SURE_DELETE_ASSIGNMENT =
  'Are you sure that you want to delete this assignment?';
export const SURE_DELETE_EXTENSION =
  'Students assigned to this extension will be reassigned to the main cohort.';
export const EXTENSION_NO_STUDENTS =
  'To assign students, hover over this extension in the left column and click on the 3 dots to edit the extension.';
export const EXTENSION_NO_STUDENTS_TITLE =
  'No students are assigned to this extension';

export const ASSIGNMENT_NO_STUDENTS =
  'Remove students from extensions to add them to the main cohort student list.';
export const ASSIGNMENT_NO_STUDENTS_TITLE =
  'All students are assigned to an extension';
export const ALL_FILES_DELETED =
  ', this will also delete all the files located in this folder.';
export const IMPORTANT_POSTS = 'important post';
export const LEAVE_CLASS = 'Are you sure you want to leave?';
export const TAG = 'Tag: ';
export const NO_TAGS_YET = 'There are no tags in the class yet…';
export const NEW_TAG = 'New tag: ';
export const SETTINGS = 'Settings';
export const CREATE_CLASS = 'and create a class.';
export const JOIN_CLASS = 'and join my class.';
export const LOGIN_TO = 'Log in to';
export const IAM_STUDENT = 'I am a student';
export const I_JOIN = 'I want to join an existing class.';
export const IAM_PROF = 'I am an educator';
export const I_CREATE = 'I want to create a new class.';
export const GET_HELP = 'Get help';
export const AULA_PRODUCT_PORTAL = "Aula's Product Portal";
export const AULA_PRODUCT_PORTAL_NOTE = 'Learn about new and upcoming features';
export const COV_DIGITAL_SERVICES = 'Digital Services';
export const COV_EDUCATION_TECHNOLOGY_SUPPORT = 'Education Technology Support';
export const COV_STUDENT_SUPPORT = 'Student Support';
export const COV_TECHNOLOGY_ENHANCED_LEARNING = 'Technology Enhanced Learning';
export const COV_PLATFORM_ACCESS_AND_SUPPORT = 'Platform Access & Support';
export const UWS_EDUCATION_TECHNOLOGY_SUPPORT =
  'Learning Technology and Design Support';
export const UWS_PLATFORM_ACCESS_AND_SUPPORT = 'Information Technology Support';
export const LAST_EDITED = 'Last edited';
export const TITLE_SORT = 'Title: A to Z';

// Class material
export const MATERIAL_GET_STARTED = 'Get started';
export const MATERIAL_GET_STARTED_STUDENT =
  'No materials have been created yet.';
export const MATERIAL_GET_STARTED_INSTRUCTOR =
  'No materials have been created yet. You can use the + button on the left to start creating sections and pages.';
export const MATERIAL_SAVED = 'SAVED';
export const MATERIAL_TITLE_HINT = 'Page name (E.g. Week 1 - The Big Bang)';
export const MATERIAL_CONTENT_PLACEHOLDER =
  'Here you can add text, files, links, videos, etc.';
export const MATERIAL_SECTION_MENU = 'Page options';
export const MATERIAL_DELETE = 'Delete page';
export const MATERIAL_EDIT = 'Edit page';
export const MATERIAL_EXPORTPAGE = 'Export page';
export const MATERIAL_IMPORTPAGE = 'Import page';
export const MATERIAL_FIND_PREVIOUS = 'Find a previous version';
export const MATERIAL_HIDE = 'Hide page';
export const MATERIAL_HIDE_FOLDER = 'Hide section';
export const MATERIAL_REVERT = 'Revert to this version';
export const MATERIAL_PUBLISH = 'Publish now';
export const MATERIAL_UNHIDE = 'Unhide page';
export const MATERIAL_UNHIDE_TOOLTIP =
  'This page is currently hidden from students. Click to unhide.';
export const MATERIAL_UNHIDE_FOLDER_TOOLTIP =
  'This section and all its pages are hidden from students. Click to unhide.';
export const MATERIAL_VERSIONS = 'Versions';
export const MATERIAL_VERSIONS_NO_VERSION =
  'Seems like there is no previous versions for this page.';
export const MATERIAL_IMPORT_EXPORT_TOOLTIP = 'More options';
export const MATERIAL_CREATE_FOLDER = 'Create section';
export const IMPORT_MATERIAL = 'Import material';
export const EXPORT_MATERIAL = 'Export material';
export const RESTORE_PAGE = 'Restore deleted page';
export const MATERIAL_EDITOR_PLACEHOLDER = 'Start writing or add a block...';
export const MATERIAL_SCHEDULE_TOOLTIP = 'Schedule for later';

export const MATERIAL_DIALOG_DELETE_TITLE = 'Delete this page?';
export const MATERIAL_DIALOG_DELETE_SUBTITLE =
  ' will be deleted. You can restore it by selecting "Restore deleted page" from the materials options menu.';
export const MATERIAL_SECTION_DIALOG_DELETE_TITLE =
  'Permanently delete this section?';
export const MATERIAL_SECTION_DIALOG_DELETE_SUBTITLE =
  ' will be permanently deleted. You can restore its pages individually by selecting “Restore deleted page” from materials options menu.';
export const MATERIAL_DIALOG_LEAVE_TITLE = 'Leave without saving?';
export const MATERIAL_DIALOG_LEAVE_SUBTITLE =
  "You'll lose your changes if you don't save first.";
export const MATERIAL_DIALOG_LEAVE_CANCEL_LABEL = 'Keep editing';
export const MATERIAL_DIALOG_LEAVE_CONFIRM_LABEL = 'Leave without saving';

export const MATERIAL_CURRENT_VERSION_BY = (name) =>
  `Current version by ${name}`;
export const MATERIAL_EDITED_BY_AT = (name, date) =>
  `Edited by ${name} - ${date}`;

export const DISCUSSION_TOPIC_EDIT = 'Edit discussion';

export const INSTRUCTOR = 'educator';
export const INSTRUCTORS = 'educators';
export const PARTICIPANT = 'participant';
export const PARTICIPANTS = 'Participants';

// No items
export const NO_COMMENTS = 'No comments yet. Be the first.';
export const NO_MATERIALS = 'Seems like there are no files here yet.';
export const NO_CONVERSATIONS_1 = 'No conversations yet.';
export const NO_CONVERSATIONS_2 =
  'Start a private or group conversation with anyone in your class.';

// Mobile
export const MOBILE_TITLE = 'Hey! You’re on your shiny mobile phone.';
export const MOBILE_TEXT_1 = 'That’s good news:';
export const MOBILE_TEXT_2 = 'Aula’s mobile apps are available on ';

// IE message
export const IE_HEY_THERE = 'Hey there!';
export const IE_REASON =
  'Unfortunately, Aula is not supported on Internet Explorer.';
export const IE_WHY_NOT = ' (why not?)';
export const IE_TO_USE_AULA = 'To use Aula, please try:';

// Assignments and extensions
export const EXTENSION_HIDE = 'Hide extension';
export const EXTENSION_HIDE_TOOLTIP =
  'This extension is currently visible to students assigned to it. Click to hide.';
export const EXTENSION_UNHIDE = 'Unhide extension';
export const EXTENSION_UNHIDE_TOOLTIP =
  'This extension is currently hidden from students. Click to unhide.';
export const ASSIGNMENT_HIDE_TOOLTIP =
  'This main cohort assignment is visible to students';
export const ASSIGNMENT_UNHIDE_TOOLTIP =
  'This main cohort assignment is hidden from students';
