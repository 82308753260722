import { FOCUS_WINDOW_CHANGE_FOCUS } from '../constants/actions';

const initialState = {
  focused: false,
};

const focusReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type !== FOCUS_WINDOW_CHANGE_FOCUS) {
    return state;
  }

  return {
    ...state,
    focused: payload.focused,
  };
};

export default focusReducer;
