import React from 'react';
import { css } from 'react-emotion';
import BaseDivider from '@material-ui/core/Divider';
import {
  icons,
  ListItem as BaseListItem,
  theme,
} from '@ublend-npm/aulaui-next';
import { ENTER } from '../../../../../utils/keyCodes';

type CategorySelectorProps = {
  categoryLabel: string;
  onClick: () => void;
};

const textContainer = css`
  color: ${theme.color.purple3};
  font-weight: 600;
  padding-left: 12px;
`;

const listContainer = css`
  list-style: none;
`;

const CategorySelector = ({
  categoryLabel,
  onClick,
}: CategorySelectorProps) => {
  return (
    <>
      <BaseDivider />
      <BaseListItem
        size="medium"
        textTruncate
        rightDecoration={
          <icons.ArrowForward style={{ color: theme.color.purple3 }} />
        }
        primaryText={categoryLabel}
        isPrimary
        onClick={onClick}
        onKeyDown={(e) => {
          if (e.keyCode === ENTER) {
            onClick();
          }
        }}
        classes={{ text: textContainer, container: listContainer }}
        data-testid="category-selector"
      />
    </>
  );
};

export default CategorySelector;
