import * as actions from '../actions';

import createSection from './createSection.app.reducer';
import deleteSection from './deleteSection.app.reducer';
import getSection from './getSection.app.reducer';
import getVersion from './getVersion.app.reducer';
import displayRevertModal from './displayRevertModal.app.reducer';
import fetchSections from './fetchSections.app.reducer';
import hideUnhideMaterial from './hideUnhideMaterial.app.reducer';
import importMaterials from './importMaterials.app.reducer';
import revertSectionToVersion from './revertSectionToVersion.app.reducer';
import saveSection from './saveSection.app.reducer';
import leaveWithoutSaving from './leaveWithoutSaving.app.reducer';
import setEditing from './setEditing.app.reducer';
import toggleFolder from './toggleFolder.app.reducer';
import openCloseRenameFolder from './openCloseRenameFolder.app.reducer';
import openCloseDeleteFolder from './openCloseDeleteFolder.app.reducer';
import editFolder from './editFolder.app.reducer';
import deleteFolder from './deleteFolder.app.reducer';
import openCloseScheduleMaterial from './openCloseScheduleMaterial.app.reducer';
import editMaterial from './editMaterial.app.reducer';
import { MaterialsAppActionType, MaterialsAppState } from './state';
import { Action, DuckReducers } from '../../types/redux';
import { ActionStatus } from '../../constants/actionsStatus';
import updateMaterialProgress from './updateMaterialProgress.app.reducer';

const initialState: MaterialsAppState = {
  editing: false,
  hasFetchedSections: false,
  isDeletingSection: false,
  isFetchingSections: false,
  isLoadingContent: false,
  sectionId: undefined,
  isLoadingVersion: false,
  isFetchingVersions: false,
  isHidingMaterial: false,
  isImportingMaterials: false,
  isRevertingSection: false,
  isErrored: false,
  isSavingSection: false,
  hasSavingSectionErrored: false,
  sections: null,
  currentDeleteCandidate: null,
  selectedContent: null,
  uneditedContent: null,
  currentRevertCandidate: null,
  leaveDestinationPath: null,
  expandedFolders: {},
  renameFolderDialogOpenFor: null,
  isSavingFolder: false,
  deleteFolderDialogOpenFor: null,
  isDeletingFolder: false,
  scheduleMaterialDialogOpenFor: null,
  sectionIdsWithProgressUpdating: [],
};

const reset = (): MaterialsAppState => ({ ...initialState });

const reducers: DuckReducers<MaterialsAppState, MaterialsAppActionType> = {
  [actions.CLASSROOM_MODULE_CREATE_SECTION]: createSection,
  [actions.CLASSROOM_MODULE_DELETE_SECTION]: deleteSection,
  [actions.CLASSROOM_MODULE_GET_SECTION]: getSection,
  [actions.CLASSROOM_MODULE_FETCH_SECTIONS]: fetchSections,
  [actions.CLASSROOM_MODULE_HIDEUNHIDE_MATERIAL]: hideUnhideMaterial,
  [actions.CLASSROOM_MODULE_IMPORT_SECTIONS]: importMaterials,
  [actions.CLASSROOM_MODULE_SAVE_CURRENT_SECTION]: saveSection,
  [actions.CLASSROOM_MODULE_LEAVE_WITHOUT_SAVING]: leaveWithoutSaving,
  [actions.CLASSROOM_MODULE_RESET]: reset,
  [actions.CLASSROOM_MODULE_SET_EDITING]: setEditing,
  [actions.CLASSROOM_MODULE_GET_VERSION]: getVersion,
  [actions.CLASSROOM_MODULE_DISPLAY_REVERT_MODAL]: displayRevertModal,
  [actions.CLASSROOM_MODULE_REVERT]: revertSectionToVersion,
  [actions.CLASSROOM_MODULE_TOGGLE_FOLDER]: toggleFolder,
  [actions.CLASSROOM_OPEN_CLOSE_RENAME_FOLDER]: openCloseRenameFolder,
  [actions.CLASSROOM_OPEN_CLOSE_DELETE_FOLDER]: openCloseDeleteFolder,
  [actions.CLASSROOM_EDIT_FOLDER]: editFolder,
  [actions.CLASSROOM_DELETE_FOLDER]: deleteFolder,
  [actions.CLASSROOM_OPEN_CLOSE_SCHEDULE_MATERIAL]: openCloseScheduleMaterial,
  [actions.CLASSROOM_MODULE_EDIT_MATERIAL]: editMaterial,
  [actions.CLASSROOM_MODULE_UPDATE_SECTION_PROGRESS]: updateMaterialProgress,
};

const reducer = <T extends MaterialsAppActionType>(
  state = initialState,
  action: Action<T, ActionStatus, unknown>
) => {
  if (!action) {
    return state;
  }

  const r = reducers[action.type];
  return r ? r(state, action) : state;
};

export default reducer;
