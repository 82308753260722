const expectedStatuses = ['started', 'success', 'error'];

const editMessageReducer = (state, action) => {
  if (!expectedStatuses.includes(action.status)) return state;

  const { messageId, editedAt, previousContent, updatedContent } =
    action.payload;
  switch (action.status) {
    case 'started':
    case 'success':
      return {
        ...state,
        messages: {
          ...state.messages,
          [messageId]: {
            ...state.messages[messageId],
            content: updatedContent,
            editedAt,
          },
        },
      };
    case 'error':
      return {
        ...state,
        messages: {
          ...state.messages,
          [messageId]: {
            ...state.messages[messageId],
            content: previousContent,
            editedAt,
          },
        },
      };
    default:
      return state;
  }
};

export default editMessageReducer;
