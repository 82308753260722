import { PROVIDERS } from './constants';

const { HANDIN, QUIZZES } = PROVIDERS;

const filterAssignment =
  (assignment) =>
  ({ id, objectId, externalId }) => {
    if (assignment.provider === HANDIN) {
      return externalId !== assignment.externalId;
    }

    // Existing quizzes use `id` instead of `objectId`
    if (assignment.provider === QUIZZES && assignment.id && id) {
      return id !== assignment.id;
    }

    return objectId !== assignment.objectId;
  };

export default filterAssignment;
