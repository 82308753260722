import * as actions from '../actions';
import fetchReactionsReducer from './fetchReactions.data.reducer';
import createReactionReducer from './createReaction.data.reducer';
import removeReactionReducer from './removeReaction.data.reducer';

const initialState = {};

const dataReducers = {
  [actions.FETCH_REACTIONS]: fetchReactionsReducer,
  [actions.CREATE_REACTION]: createReactionReducer,
  [actions.REMOVE_REACTION]: removeReactionReducer,
};

const reducer = (state = initialState, action) => {
  if (!action) {
    return state;
  }

  const r = dataReducers[action.type];
  return r ? r(state, action) : state;
};

export default reducer;
