import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Zendesk from 'react-zendesk';
import ZendeskConfig from '../../../../core/config/zendesk';
import './Zendesk.style.css';

const getZendeskSettings = ({ suppressContactForm }) => ({
  color: {
    theme: '#6204AA',
  },
  launcher: {
    chatLabel: {
      '*': 'Help',
    },
  },
  webWidget: {
    contactOptions: {
      enabled: true,
      contactButton: { '*': 'Get in touch' },
      chatLabelOnline: { '*': 'Live Chat' },
      chatLabelOffline: { '*': 'Live Chat is unavailable' },
    },
    contactForm: {
      suppress: suppressContactForm,
    },
    helpCenter: {
      suppress: false,
      chatButton: {
        '*': 'Chat with us now',
      },
    },
    chat: {
      suppress: false,
      profileCard: {
        avatar: true,
        rating: false,
        title: true,
      },
    },
  },
});

const handleLoadEvents = () => {
  window.zE('webWidget', 'hide');
  window.zE('webWidget', 'helpCenter:setSuggestions', {
    search: 'aula',
  });
};

const ZendeskWrapper = (props) => {
  const { suppressContactForm } = props;
  useEffect(() => {
    window.zESettings = {
      ...window.zESettings,
      webWidget: {
        ...window.zESettings.webWidget,
        contactForm: {
          ...window.zESettings.webWidget.contactForm,
          suppress: suppressContactForm,
        },
      },
    };
  }, [suppressContactForm]);
  return (
    <div style={{ width: '100%', position: 'relative' }}>
      <Zendesk
        zendeskKey={ZendeskConfig.APPID}
        {...getZendeskSettings({ suppressContactForm })}
        onLoaded={handleLoadEvents}
      />
    </div>
  );
};

ZendeskWrapper.propTypes = {
  suppressContactForm: PropTypes.bool.isRequired,
};

export default ZendeskWrapper;
