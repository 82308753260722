import sortByEndDate from './utils/sortByEndDate';

const createAssignmentReducer = (state, action) => {
  if (action.status !== 'success') return state;
  const {
    payload: { assignment, spaceId },
  } = action;

  const assignments = [...state.assignments];
  let newAssignment = assignment;

  if (assignment.parentAssignmentId) {
    const modifiedAssignment = assignments.find(
      (item) => item.id === assignment.parentAssignmentId
    );
    const extensions = modifiedAssignment.extensions || [];
    // BE endpoint is not returning all the fields, however they can be taken from the parent
    // we need to rerender the page to hide the Convert button
    // e.g. createdAt, updatedAt, space
    extensions.push({
      ...modifiedAssignment,
      ...assignment,
      objectId: assignment.id,
    });

    newAssignment = {
      ...modifiedAssignment,
      extensions,
    };
  }

  const updatedAssignments = [
    ...state.assignments,
    {
      ...newAssignment,
      classRoom: {
        objectId: spaceId,
      },
    },
  ].sort(sortByEndDate);

  return {
    ...state,
    assignments: updatedAssignments,
  };
};

export default createAssignmentReducer;
