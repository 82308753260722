import * as api from '../api/directMessages';
import { INBOX_SET_CONVERSATION_CLOSED } from '../actions';
import toastOperations from '../../toasts/operations';

const sendConversationClosedStarted = (dispatch, payload) =>
  dispatch({
    type: INBOX_SET_CONVERSATION_CLOSED,
    status: 'started',
    payload,
  });

const sendConversationClosedSuccess = (dispatch, payload) =>
  dispatch({
    type: INBOX_SET_CONVERSATION_CLOSED,
    status: 'success',
    payload,
  });

const sendConversationClosedError = (dispatch, payload) => {
  dispatch({
    type: INBOX_SET_CONVERSATION_CLOSED,
    status: 'error',
    payload,
  });
  dispatch(
    toastOperations.showToast({
      message: "We couldn't close the conversation, please try again.",
    })
  );
};

const setConversationClosed = (conversationId) => async (dispatch) => {
  const payload = { conversationId };
  sendConversationClosedStarted(dispatch, payload);
  try {
    await api.conversations.closeConversation(conversationId);
    sendConversationClosedSuccess(dispatch, payload);
  } catch (error) {
    sendConversationClosedError(dispatch, { ...payload, error });
  }
};

export default setConversationClosed;
