import { editAssignment as editAssignmentApi } from '../api';
import { EDIT_ASSIGNMENT, DELETE_ASSIGNMENT } from '../actions';
import { accessToken as getAccessToken } from '../../selectors/user';

import { analyticsTrackEvent } from '../../utils/analytics';
import * as analyticEvents from '../../constants/analytics';

const editAssignmentStarted = (dispatch, payload) =>
  dispatch({
    type: EDIT_ASSIGNMENT,
    status: 'started',
    payload,
  });

const editAssignmentSuccess = (dispatch, payload) => {
  dispatch({
    type: EDIT_ASSIGNMENT,
    status: 'success',
    payload,
  });

  return payload.assignment;
};

export const deleteAssignmentSuccess = (dispatch, payload) => {
  dispatch({
    type: DELETE_ASSIGNMENT,
    status: 'success',
    payload,
  });

  return null;
};

const editAssignmentError = (dispatch, payload) =>
  dispatch({
    type: EDIT_ASSIGNMENT,
    status: 'error',
    payload,
  });

const editAssignment =
  ({ spaceId, assignment, accessToken }) =>
  async (dispatch, getState) => {
    editAssignmentStarted(dispatch, { assignment });

    const state = getState();
    const currentAccessToken = accessToken || getAccessToken(state, spaceId);

    if (!currentAccessToken) {
      editAssignmentError(dispatch, { spaceId });
      return null;
    }

    try {
      const response = await editAssignmentApi(
        currentAccessToken,
        spaceId,
        assignment
      );

      if (!response.data || !response.data.assignment) {
        throw new Error('fail to update / delete assignment');
      }

      // after successfully delete an assignment, return null
      if (assignment.status === 'trash') {
        analyticsTrackEvent(analyticEvents.DELETE_ASSIGNMENT, {
          assignment: {
            objectId: assignment.objectId || assignment.externalId,
          },
          activeClassroom: spaceId,
        });

        deleteAssignmentSuccess(dispatch, {
          spaceId,
          assignment,
        });
        return assignment;
      }

      // after successfully update an assignment, return edited assignment
      analyticsTrackEvent(analyticEvents.EDIT_ASSIGNMENT, {
        assignment: response.data.assignment,
        activeClassroom: spaceId,
      });

      return editAssignmentSuccess(dispatch, {
        spaceId,
        assignment: response.data.assignment,
      });
    } catch (e) {
      const error =
        e.response &&
        e.response.data &&
        e.response.data.errors &&
        e.response.data.errors[0];
      const errorCode = error && error.code;
      if (
        errorCode === 'extension-submissions-exist' ||
        errorCode === 'parent-assignment-submissions-exist'
      ) {
        editAssignmentError(dispatch, {
          spaceId,
          assignmentUpdateError: {
            errorCode,
            detail: error.detail,
          },
        });
      } else {
        editAssignmentError(dispatch, { spaceId });
      }
      return null;
    }
  };

export default editAssignment;
