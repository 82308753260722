import * as types from '../../constants/actions';
// eslint-disable-next-line import/no-unresolved,import/extensions

const initialState = {
  loader: {
    isLoading: false,
    showError: false,
    showLogo: false,
  },
  importantPostsOpen: false,
  conversationId: null,
  navBarNoticiationsBadge: false,
};

const startupReducer = (state = initialState, action) => {
  switch (action.status) {
    case 'started':
      return {
        ...state,
        loader: {
          ...state.loader,
          isLoading: true,
          showLogo: true,
        },
      };
    case 'success':
      return {
        ...state,
        loader: {
          ...state.loader,
          isLoading: false,
          showError: false,
          showLogo: false,
        },
      };
    case 'error':
      return {
        ...state,
        loader: {
          ...state.loader,
          isLoading: true,
          showError: true,
        },
      };

    default:
      return state;
  }
};

const UIReducer =
  (appSpecificUIReducer = (s) => s) =>
  (state = initialState, action) => {
    if (!action) {
      return state;
    }

    const appUpdatedState = appSpecificUIReducer(state, action);

    switch (action.type) {
      case types.STARTUP_FETCH_FILES_COOKIE:
      case types.STARTUP_FETCH_DATA:
        return startupReducer(appUpdatedState, action);
      case types.SHOW_IMPORTANT_POSTS:
        return {
          ...appUpdatedState,
          importantPostsOpen: !appUpdatedState.importantPostsOpen,
        };
      case types.NAVBAR_NOTIFICATIONS_BADGE:
        return {
          ...appUpdatedState,
          navBarNoticiationsBadge: action.hasUnread,
        };
      default:
        return appUpdatedState;
    }
  };

export default UIReducer;
