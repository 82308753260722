import { newError } from '../errors';
import * as actionTypes from '../../constants/actions';
import { analyticsTrackEvent } from '../../utils/analytics';
import * as analytics from '../../constants/analytics';
import * as userSelectors from '../../selectors/user';
import patchUser from '../../api/users/patchUser';

const changeUserAvatarStart = (dispatch) => {
  dispatch({
    type: actionTypes.USER_CHANGE_AVATAR,
    status: 'started',
  });
};

const changeUserAvatarSuccess = (url, dispatch) => {
  dispatch({
    type: actionTypes.USER_CHANGE_AVATAR,
    status: 'success',
    url,
  });
};

const changeUserAvatarError = (error, dispatch) => {
  dispatch(newError(error, 'userChangeAvatar'));
  dispatch({
    type: actionTypes.USER_CHANGE_AVATAR,
    status: 'error',
    error,
  });
};

const changeUserAvatar =
  (uploadHandler) => (file) => async (dispatch, getState) => {
    changeUserAvatarStart(dispatch);
    try {
      const state = getState();
      const url = await uploadHandler({
        file,
      });
      await patchUser({
        userId: userSelectors.currentUserId(state),
        patch: { avatar: url },
      });
      changeUserAvatarSuccess(url, dispatch);
      analyticsTrackEvent(analytics.USER_CHANGE_AVATAR);
    } catch (error) {
      changeUserAvatarError(error, dispatch);
      throw error;
    }
  };

export default changeUserAvatar;
