import { CLASSROOM_MODULE_CREATE_FOLDER } from '../actions';
import { createFolder as createFolderApi } from '../api';
import toFolder from '../api/toFolder';
import { MATERIAL_UNTITLED_SECTION } from '../constants';
import { topLevelMaterials } from '../selectors';
import { openRenameFolder } from './openCloseRenameFolder.operation';
import { AulaReduxAction } from '../../types/state';
import { Folder as SavedFolder, LocalFolder, Material } from '../types';
import { getCurrentSpaceId } from '../../selectors/space';
import { ActionStatus } from '../../constants/actionsStatus';
import { generateLocalOrder } from '../utils';
import { generateLocalId } from '../../utils/localId';

type Folder = SavedFolder | LocalFolder;

const isUntitledFolder = (item: Material): item is Folder => {
  const { title, isFolder } = item;
  return !!isFolder && title.startsWith(MATERIAL_UNTITLED_SECTION);
};

const isUniqueTitleIn = (folders: Folder[], title: string) =>
  !folders.some((item) => item.title === title);

const createFolderAction = (status: ActionStatus, payload: unknown) => ({
  type: CLASSROOM_MODULE_CREATE_FOLDER,
  status,
  payload,
});

type CreateFolderActionFactory = (educatorOnly?: boolean) => AulaReduxAction;

const createFolderOperation: CreateFolderActionFactory =
  (educatorOnly = false) =>
  async (dispatch, getState) => {
    const state = getState();
    const topLevel = topLevelMaterials(state);
    const topLevelUntitled = topLevel.filter(isUntitledFolder);

    let title = MATERIAL_UNTITLED_SECTION;
    let i = 1;

    while (!isUniqueTitleIn(topLevelUntitled, title)) {
      title = `${MATERIAL_UNTITLED_SECTION} (${i})`;
      i += 1;
    }

    const localId = generateLocalId();
    const classRoomId = getCurrentSpaceId(state);

    const folder = {
      title,
      hidden: !educatorOnly,
      educatorOnly,
      id: localId,
      order: generateLocalOrder(topLevel),
      space: classRoomId,
    };

    dispatch(
      createFolderAction('started', {
        folder: toFolder(folder),
      })
    );

    try {
      const savedFolder = await createFolderApi(classRoomId, folder, localId);
      dispatch(openRenameFolder(localId));
      dispatch(
        createFolderAction('success', {
          localId,
          folder: savedFolder,
        })
      );
      dispatch(openRenameFolder(savedFolder.id));
    } catch (error) {
      dispatch(
        createFolderAction('error', {
          localId,
          error,
        })
      );
    }
  };

export default createFolderOperation;
