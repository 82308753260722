import {
  STARTED_STATUS,
  SUCCESS_STATUS,
  ERROR_STATUS,
} from '../../../constants/actionsStatus';
import { ANNOUNCEMENTS } from '../../../constants/postFeedType';
import { getSortedDistinctPostIds, DESCENDING } from '../../../utils/posts';

const getResetSpacePosts = (
  classRooms,
  spaceId,
  postsKey,
  scheduledPostsKey
) => {
  const copiedClassRooms = { ...classRooms };
  copiedClassRooms[spaceId] = Object.assign({}, copiedClassRooms[spaceId], {
    [postsKey]: [],
    [scheduledPostsKey]: [],
    ...(postsKey === 'posts' ? { importantPosts: [] } : {}),
  });
  return copiedClassRooms;
};

const getSpaceErrorState = (state) => ({
  ...state,
  hasInitialData: false,
  isFetchingInitialData: false,
});

const getUpdatedPosts = (posts, content, reset) => {
  // Note. reset will clear all posts regardless of whether they are announcements or not
  const copiedPosts = Object.assign({}, reset ? [] : posts);
  for (let i = 0; i < content.length; i += 1) {
    copiedPosts[content[i].objectId] = Object.assign(
      {},
      copiedPosts[content[i].objectId],
      content[i],
      {
        classRoom: content[i].classRoom,
        ...(content[i].feedType !== ANNOUNCEMENTS && { comments: [] }),
        tagPosts: [],
        user: content[i].user || {
          firstName: 'Deleted User',
          lastName: '',
        },
      }
    );
  }
  return copiedPosts;
};

const getUpdatedSpaces = (posts, content, classRooms, postsKey) => {
  const copiedClassRooms = { ...classRooms };
  if (content.length > 0) {
    const classRoomId = content[0].classRoom;
    const actionPosts = content.map((post) => post.objectId);
    const oldClassRoomPosts = copiedClassRooms[classRoomId][postsKey];

    const updatedPostIds = getSortedDistinctPostIds({
      existingIds: oldClassRoomPosts,
      newIds: actionPosts,
      order: DESCENDING,
      posts,
    });

    const oldestPostDate = new Date(content[content.length - 1].createdAt);
    copiedClassRooms[classRoomId] = {
      ...copiedClassRooms[classRoomId],
      [postsKey]: updatedPostIds,
      ...(postsKey === 'posts'
        ? { oldestPostDate }
        : { oldestAnnouncementDate: oldestPostDate }),
    };
  }
  return copiedClassRooms;
};

export const fetchCommonRoomReducer = (state, action) => {
  switch (action.status) {
    case STARTED_STATUS:
      if (action.reset) {
        return {
          ...state,
          classRooms: getResetSpacePosts(
            state.classRooms,
            action.spaceId,
            'posts',
            'scheduledPosts'
          ),
        };
      }
      return state;
    case SUCCESS_STATUS: {
      const posts = getUpdatedPosts(state.posts, action.content, action.reset);
      const classRooms = getUpdatedSpaces(
        posts,
        action.content,
        state.classRooms,
        'posts'
      );
      return {
        ...state,
        posts,
        classRooms,
      };
    }
    case ERROR_STATUS:
      return getSpaceErrorState(state);
    default:
      return state;
  }
};

export const fetchAnnouncementsReducer = (state, action) => {
  switch (action.status) {
    case STARTED_STATUS:
      if (action.reset) {
        return {
          ...state,
          classRooms: getResetSpacePosts(
            state.classRooms,
            action.spaceId,
            'announcements',
            'scheduledAnnouncements'
          ),
        };
      }
      return state;
    case SUCCESS_STATUS: {
      const posts = getUpdatedPosts(state.posts, action.content, action.reset);
      const classRooms = getUpdatedSpaces(
        posts,
        action.content,
        state.classRooms,
        'announcements'
      );
      return {
        ...state,
        posts,
        classRooms,
      };
    }
    case ERROR_STATUS:
      return getSpaceErrorState(state);
    default:
      return state;
  }
};
