import { AulaState } from '@core/types/state';

const institution = (state: AulaState) => state.institution;

export const getLaunchDarklyFeatures = (state: AulaState) =>
  institution(state)?.features || {};

export const getIsEngagementInsightsEnabled = (state: AulaState): boolean => {
  const features = getLaunchDarklyFeatures(state);
  return !!features.webAppFtEngagementInsights202310;
};

export const institutionShortName = (state: AulaState) =>
  institution(state).general.institution;

export const institutionName = (state: AulaState) => institution(state).name;

export const baseDomain = (state: AulaState) => {
  const {
    institution: {
      general: { base_domain: domain },
    },
  } = state;
  return domain;
};

export const getLaunchDarklyKeys = (state: AulaState) =>
  institution(state)?.keys?.launchDarkly;
