import { ANNOUNCEMENTS } from '../../../constants/postFeedType';
import {
  isScheduledPost,
  comparePostsByDate,
  computeNewImportantPostCount,
  computeNewImportantPosts,
} from '../../../utils/posts';

/* eslint-disable-next-line import/prefer-default-export */
export const updateSpacePosts = ({ classRoom, post, posts }) => {
  const updatedClassRoom = { ...classRoom };
  const scheduled = isScheduledPost(post);
  const postFeed = post.feedType === ANNOUNCEMENTS ? 'announcements' : 'posts';
  const scheduledPostFeed =
    post.feedType === ANNOUNCEMENTS
      ? 'scheduledAnnouncements'
      : 'scheduledPosts';
  const listToAdd = scheduled ? scheduledPostFeed : postFeed;
  const listToRemove = scheduled ? postFeed : scheduledPostFeed;
  const order = scheduled ? 1 : -1;

  updatedClassRoom[listToAdd] = [
    ...new Set([post.objectId, ...updatedClassRoom[listToAdd]]),
  ]
    .map((id) => (id === post.objectId ? post : posts[id]))
    .sort(comparePostsByDate(order))
    .map((p) => p.objectId);

  updatedClassRoom[listToRemove] = updatedClassRoom[listToRemove].filter(
    (id) => id !== post.objectId
  );

  updatedClassRoom.importantPostCount = computeNewImportantPostCount({
    posts,
    newPost: post,
    importantPostCount: classRoom.importantPostCount,
  });

  updatedClassRoom.importantPosts = computeNewImportantPosts({
    posts,
    newPost: post,
    importantPosts: classRoom.importantPosts,
  });

  return updatedClassRoom;
};
