import clientInfo from '../../app/utils/clientInfo';
import platform from './platform';

const getAnalyticsHeaders = () => ({
  'analytics-client-info': JSON.stringify({
    platform,
    ...clientInfo,
  }),
});

export default getAnalyticsHeaders;
