import { AulaState, Space } from '../../types/state';

export const getSpace = (state: AulaState) => state.classRoom;

export const getAllSpaces = (state: AulaState) => state.data.classRooms;

export const getCurrentSpaceId = (state: AulaState) => getSpace(state).current;

export const getSpaceById = (state: AulaState, spaceId: string): Space | null =>
  getAllSpaces(state)[spaceId] || null;

export const getCurrentSpace = (state: AulaState) =>
  getSpaceById(state, getCurrentSpaceId(state));

export const getCurrentSpaceName = (state: AulaState) => {
  const space = getCurrentSpace(state);
  return space && space.name;
};

const sortedByName = ({ name: nameA }, { name: nameB }) =>
  nameA.localeCompare(nameB, 'en', { numeric: true });

export const getAlphabeticallySortedSpaces = (spaces: Record<string, Space>) =>
  Object.values(spaces).sort(sortedByName);

export const getInitialSpaceId = (state: AulaState) => {
  const spaces = getAlphabeticallySortedSpaces(getAllSpaces(state));
  const firstNonArchived = spaces.find((s) => !s.archived);
  const selectedSpace = firstNonArchived || spaces[0];
  return selectedSpace?.objectId || null;
};
