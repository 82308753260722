const generateGroupsReducer = (state, action) => {
  if (action.status !== 'success') {
    return state;
  }

  const {
    payload: { groups, studentsWithNoGroup },
  } = action;

  if (groups.length === 0) {
    return state;
  }

  const firstGroup = groups[0];
  const updatedSet = {
    ...state.sets[firstGroup.set],
    studentsWithNoGroup,
  };
  updatedSet.groups = groups.map((group) => group.id);

  const newGroups = groups.reduce((acc, group) => {
    acc[group.id] = group;
    return acc;
  }, {});

  return {
    ...state,
    groups: {
      ...state.groups,
      ...newGroups,
    },
    sets: {
      ...state.sets,
      [updatedSet.id]: updatedSet,
    },
  };
};

export default generateGroupsReducer;
