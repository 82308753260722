import mapKeys from 'lodash.mapkeys';

export const fetchTagsReducer = (state, action) => {
  switch (action.status) {
    case 'success': {
      const {
        payload: { tags, spaceId },
      } = action;
      const updatedTags = [
        ...Object.keys(state.tags).map((id) => state.tags[id]),
        ...tags,
      ].filter((tag) => tag.classRoom.objectId === spaceId);
      const updatedTagMap = mapKeys(updatedTags, 'objectId');
      return {
        ...state,
        tags: updatedTagMap,
      };
    }
    default:
      return state;
  }
};

export const fetchTagPostsReducer = (state, action) => {
  switch (action.status) {
    case 'success': {
      const posts = { ...state.posts };

      const tagsMap = {};
      action.fetchedTagPosts.forEach((tagPost) => {
        const previousTags = tagsMap[tagPost.post.objectId] || [];
        tagsMap[tagPost.post.objectId] = [
          ...new Set([
            ...previousTags,
            { tagId: tagPost.tag.objectId, tagPostId: tagPost.objectId },
          ]),
        ];
      });

      Object.keys(tagsMap).forEach((postId) => {
        const post = posts[postId];
        if (post) {
          posts[postId] = {
            ...post,
            tagPosts: tagsMap[postId],
          };
        }
      });

      return {
        ...state,
        posts,
      };
    }
    default:
      return state;
  }
};

export const createTagReducer = (state, action) => {
  const { status, tag } = action;
  switch (status) {
    case 'success': {
      const tagId = tag.objectId;

      return {
        ...state,
        tags: {
          ...state.tags,
          [tagId]: { ...tag },
        },
      };
    }
    default:
      return state;
  }
};

export const createTagPostReducer = (state, action) => {
  const { status, tagPost } = action;
  switch (status) {
    case 'success': {
      const tagId = tagPost.tag.objectId;
      const postId = tagPost.post.objectId;
      const post = state.posts[postId];
      const tagPosts = post.tagPosts || [];
      const hasTag = tagPosts.findIndex((t) => t.tagId === tagId) !== -1;

      if (hasTag) {
        return state;
      }

      const tagPostId = tagPost.objectId;
      return {
        ...state,
        posts: {
          ...state.posts,
          [postId]: {
            ...post,
            tagPosts: [...tagPosts, { tagId, tagPostId }],
          },
        },
      };
    }
    default:
      return state;
  }
};

export const deleteTagPostReducer = (state, action) => {
  switch (action.status) {
    case 'success': {
      const {
        payload: { tagId, postId },
      } = action;
      const post = state.posts[postId];

      return {
        ...state,
        posts: {
          ...state.posts,
          [postId]: {
            ...post,
            tagPosts: post.tagPosts.filter(
              (tagPost) => tagPost.tagId !== tagId
            ),
          },
        },
      };
    }
    default:
      return state;
  }
};
