const addGroupsReducer = (state, action) => {
  if (action.status !== 'success') {
    return state;
  }

  const {
    payload: { groups, studentsWithNoGroup },
  } = action;

  const updatedGroups = {
    ...state.groups,
  };

  groups.forEach((group) => {
    updatedGroups[group.id] = group;
  });

  const setId = groups[0].set;
  const set = state.sets[setId];
  const updatedSet = { ...set };

  groups.forEach(({ id }) => {
    if (!updatedSet.groups.includes(id)) {
      updatedSet.groups = [...updatedSet.groups, id];
    }
  });

  const allStudents = Object.values(updatedGroups).reduce(
    (accStudents, { students }) => [...accStudents, ...(students || [])],
    []
  );
  updatedSet.studentsWithNoGroup = studentsWithNoGroup.filter(
    (id) => !allStudents.includes(id)
  );

  return {
    ...state,
    groups: updatedGroups,
    sets: {
      ...state.sets,
      [setId]: updatedSet,
    },
  };
};

export default addGroupsReducer;
