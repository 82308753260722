import React from 'react';
import PropTypes from 'prop-types';
import SideDialog from '../common/SideDialog';
import NotificationsTable from './NotificationsTable.container';

const NotificationsDialog = ({ open, onClose }) => {
  const title = 'Notifications';

  return (
    <SideDialog
      title={title}
      open={open}
      onClose={onClose}
      width="600px"
      testAttribute="notifications-dialog"
      contentStyle={{ overflow: 'hidden' }}
      keepMounted
    >
      <NotificationsTable />
    </SideDialog>
  );
};

NotificationsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NotificationsDialog;
