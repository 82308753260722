import N from 'notifyjs';
import platform from '../../core/utils/platform';
import { EntityTypesByCollections } from '../../core/utils/commentsEntityTypes';
import getCommentEntityUrl from '../../core/utils/getCommentEntityUrl';
import { setNotificationsAsRead } from '../../core/notifications/actions/index';

import notificationIcon from '../../static/notification-icon.png';

const Notify = N.default;

const notifyInternal = (
  { title, body, path, notficationUserId },
  dispatch,
  getState
) => {
  const currentPlatform = platform;
  const bodyObject = {
    body,
    timeout: 8,
    icon: currentPlatform !== 'desktop' ? notificationIcon : undefined,
    notifyClick: () => {
      if (currentPlatform === 'desktop') return;

      dispatch(setNotificationsAsRead([{ id: notficationUserId }]));

      window.focus();
      window.open(
        `https://${getState().institution.general.base_domain}/#${path}`,
        '_self'
      );
    },
  };
  const myNotification = new Notify(title, bodyObject);
  myNotification.show();
};

class DesktopNotifications {
  constructor() {
    this.hasPermission = !Notify.needsPermission;
    if (!this.hasPermission) {
      this.isSupported = Notify.isSupported();
    } else {
      this.isSupported = true;
    }
  }

  askPermissions() {
    return new Promise((resolve, reject) => {
      if (this.isSupported && this.hasPermission) {
        resolve();
      } else if (this.isSupported && !this.hasPermission) {
        Notify.requestPermission(
          () => {
            this.hasPermission = true;
            resolve();
          },
          () => {
            this.hasPermission = false;
            reject();
          }
        );
      } else {
        reject();
      }
    });
  }

  notify(notification, dispatch, getState) {
    this.askPermissions().then(
      () => {
        notifyInternal(notification, dispatch, getState);
      },
      () => {
        // eslint-disable-next-line no-console
        console.log('Desktop notifications permission denied or not supported');
      }
    );
  }

  notifyFromNotificationObject(
    { id: notficationUserId, notification },
    dispatch,
    getState
  ) {
    const {
      space: spaceId,
      itemId,
      parentItemId,
      action,
      title,
      parentItemType,
    } = notification;
    let url = '';
    switch (action) {
      case 'newMessage': {
        const {
          routing: {
            locationBeforeTransitions: { pathname },
          },
        } = getState();
        url = `${pathname}?inbox=${parentItemId}`;
        break;
      }
      case 'newPost': {
        url = `/dashboard/${spaceId}/community/post/${itemId}`;
        break;
      }
      case 'newCommentOnCommentedPost':
      case 'newComment':
      case 'newCommentReply':
        // runs for above as well (no break)
        url = getCommentEntityUrl({
          spaceId,
          entityId: parentItemId,
          entityType: EntityTypesByCollections[parentItemType],
        });
        break;
      default: {
        url = '/';
        break;
      }
    }
    this.notify(
      { notficationUserId, title, body: 'Click here to view.', path: url },
      dispatch,
      getState
    );
  }
}

const DesktopNotificationsSingleton = new DesktopNotifications();

export default DesktopNotificationsSingleton;
