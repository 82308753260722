import { INBOX_PARTICIPATION } from '../../../constants/endpoints';
import request from './request';

/**
 * @param {string} conversationId
 * @param {string} userId
 */
export default async (conversationId, userId) => {
  const config = {
    url: INBOX_PARTICIPATION(conversationId, userId),
    method: 'delete',
  };
  await request(config);
};
