import { isCancel } from 'axios';
import { fetchSubmissionsForExtensions as fetchSubmissionsApi } from '../api';
import { FETCH_ALL_SUBMISSIONS_INCLUDING_EXTENSIONS } from '../actions';

const fetchAllSubmissionsIncludingExtensionsStarted = (dispatch, payload) =>
  dispatch({
    type: FETCH_ALL_SUBMISSIONS_INCLUDING_EXTENSIONS,
    status: 'started',
    payload,
  });

const fetchAllSubmissionsIncludingExtensionsSuccess = (dispatch, payload) =>
  dispatch({
    type: FETCH_ALL_SUBMISSIONS_INCLUDING_EXTENSIONS,
    status: 'success',
    payload,
  });

const fetchAllSubmissionsIncludingExtensionsError = (dispatch, payload) =>
  dispatch({
    type: FETCH_ALL_SUBMISSIONS_INCLUDING_EXTENSIONS,
    status: 'error',
    payload,
  });

const fetchSubmissionsIncludingExtensions =
  (accessToken) => async (assignmentId) => {
    const {
      data: { submissions },
    } = await fetchSubmissionsApi({
      accessToken,
      assignmentId,
    });
    return { [assignmentId]: submissions };
  };

const fetchAllSubmissionsIncludingExtensions =
  ({ accessToken, assignmentsIds }) =>
  async (dispatch) => {
    if (!accessToken || assignmentsIds.length === 0) {
      return;
    }
    fetchAllSubmissionsIncludingExtensionsStarted(dispatch, { assignmentsIds });
    try {
      const fetchSubmissionsPromise =
        fetchSubmissionsIncludingExtensions(accessToken);
      // TODO: will be refactored in this issue https://github.com/AulaEducation/aula/issues/11802
      const submissionsForAssignments = await Promise.all(
        assignmentsIds.map((id) => {
          return fetchSubmissionsPromise(id);
        })
      );
      fetchAllSubmissionsIncludingExtensionsSuccess(dispatch, {
        submissionsForAssignments,
      });
    } catch (error) {
      if (!isCancel(error)) {
        fetchAllSubmissionsIncludingExtensionsError(dispatch, {
          assignmentsIds,
          error,
        });
      }
    }
  };

export default fetchAllSubmissionsIncludingExtensions;
