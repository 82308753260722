export default {
  base: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    textAlign: 'center',
    fontWeight: '700',
  },
  letter: {
    color: 'white',
  },
};
