import debounce from 'lodash.debounce';

const WAIT_MS = 5000;
const DEBOUNCE_OPTIONS = {
  leading: true,
  trailing: true,
};

const registerLive =
  ({ onAssignmentDeleted, onAssignmentSaved, onSubmissionUpdated }) =>
  (socket) =>
  (dispatch) => {
    const debouncedOnAssignmentSaved = debounce(
      (data) => dispatch(onAssignmentSaved(data)),
      WAIT_MS,
      DEBOUNCE_OPTIONS
    );
    const debouncedOnSubmissionUpdated = debounce(
      (data) => dispatch(onSubmissionUpdated(data)),
      WAIT_MS,
      DEBOUNCE_OPTIONS
    );

    socket.on('AssessmentItem:Assignment:Deleted', (data) =>
      dispatch(onAssignmentDeleted(data))
    );
    socket.on('AssessmentItem:Assignment:Saved', debouncedOnAssignmentSaved);
    socket.on('Assignment:Graded', debouncedOnSubmissionUpdated);
    socket.on('Assignment:Submitted', debouncedOnSubmissionUpdated);
    socket.on('Handin:Assignment:GradesReleased', debouncedOnAssignmentSaved);
    socket.on(
      'AssessmentItem:Assignment:GradesReleased',
      debouncedOnAssignmentSaved
    );
  };

export default registerLive;
