import { Material } from '../types';
import { MaterialDataReducer } from './state';
import {
  EditFolderAction,
  EditMaterialAction,
  HideUnhideMaterialAction,
} from '../sharedActions';

const getUpdatedMaterial = <T extends Material>(
  existing: T,
  newMaterial: T
) => ({
  ...existing,
  ...newMaterial,
});

const editMaterialReducer: MaterialDataReducer<
  EditMaterialAction | HideUnhideMaterialAction | EditFolderAction
> = (state, action) => {
  if (action.status !== 'success') {
    return state;
  }

  const { updatedMaterial } = action.payload;

  return {
    ...state,
    [updatedMaterial.id]: getUpdatedMaterial(
      state[updatedMaterial.id],
      updatedMaterial
    ),
  };
};

export default editMaterialReducer;
