import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const CurrentSpaceContainer = styled.div`
  padding-top: ${theme.spacing.xxSmall};
  padding-bottom: ${theme.spacing.xxSmall};
  padding-left: ${theme.spacing.medium};
  padding-right: ${theme.spacing.medium};
`;

export const CurrentText = styled.span`
  font-family: ${theme.font.family.workSans};
  font-size: 20px;
  font-weight: ${theme.font.weight.bold};
  line-height: ${theme.font.lineHeight.large};
  color: ${theme.color.blackAlpha(0.6)};
`;

export const CurrentSpaceItem = styled.div`
  padding-top: ${theme.spacing.xSmall};
  padding-bottom: ${theme.spacing.xSmall};
  display: flex;
  gap: ${theme.spacing.small};
  align-items: center;
  background-color: ${(props) =>
    props.highlighted ? theme.color.beige : 'inherit'};
`;

export const PrimaryTextContainer = styled.div`
  font-family: ${theme.font.family.workSans};
  font-size: ${theme.font.size.large};
  font-weight: ${theme.font.weight.semiBold};
  line-height: ${theme.font.lineHeight.large};
`;

export const AvatarContainer = styled('div')`
  width: 32px;
  height: 32px;
`;
