import { AulaReduxAction } from '../../types/state';
import { CLASSROOM_OPEN_CLOSE_RENAME_FOLDER } from '../actions';

type OpenRenameFolderActionFactory = (folderId: string) => AulaReduxAction;
type CloseRenameFolderActionFactory = () => AulaReduxAction;

export const openRenameFolder: OpenRenameFolderActionFactory =
  (folderId) => (dispatch) =>
    dispatch({
      type: CLASSROOM_OPEN_CLOSE_RENAME_FOLDER,
      payload: { renameFolderDialogOpenFor: folderId },
    });

export const closeRenameFolder: CloseRenameFolderActionFactory =
  () => (dispatch) =>
    dispatch({
      type: CLASSROOM_OPEN_CLOSE_RENAME_FOLDER,
      payload: { renameFolderDialogOpenFor: null },
    });
