import * as types from '../../constants/actions';

const socket = (state = { id: null }, action) => {
  switch (action.type) {
    case types.LIVE_CONNECT_OFF:
      return { id: null };
    case types.LIVE_CONNECT_ON:
      return { id: action.socketId };
    default:
      return state;
  }
};

export default socket;
