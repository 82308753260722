const getEnvironmentFromInstitutionUrl = (url) => {
  let environment = 'production';

  const httpVersion = url.startsWith('https') ? 'https://' : 'http://';
  const urlSplitOnHttp = url.split(httpVersion);
  if (urlSplitOnHttp.length < 2) {
    return environment;
  }

  const firstPartOfUrl = urlSplitOnHttp[1].split('.')[0];
  if (firstPartOfUrl.startsWith('pa-')) {
    environment = 'development';
  }

  if (firstPartOfUrl === 'local') {
    environment = 'local';
  }

  return environment;
};

export default getEnvironmentFromInstitutionUrl;
