import * as actions from '../actions';

import openSpaceSelectorReducer from './openSpaceSelector.reducer';
import selectSpaceSelectorReducer from './selectSpaceSelector.reducer';
import dismissSpaceSelectorReducer from './dismissSpaceSelector.reducer';

const initialState = {
  openTimestamp: null, // Date | null
  openCorrelationId: null, // string | null
};

const reducerMap = {
  [actions.OPEN_SPACE_SELECTOR]: openSpaceSelectorReducer,
  [actions.SELECT_SPACE_SELECTOR]: selectSpaceSelectorReducer,
  [actions.DISMISS_SPACE_SELECTOR]: dismissSpaceSelectorReducer,
};

export default (state = initialState, action) => {
  const reducer = reducerMap[action.type];
  if (!reducer) return state;
  return reducer(state, action);
};
