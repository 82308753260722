import { CREATE_REACTION } from '../actions';

const onReactionAdded = (payload) => async (dispatch) =>
  dispatch({
    type: CREATE_REACTION,
    status: 'success',
    payload,
  });

export default onReactionAdded;
