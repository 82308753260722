import { connect } from 'react-redux';
import { Avatar } from './ConversationIcon.styled';
import { selectors } from '../../../../store/conversations';
import { online } from '../../../../../core/selectors/live';

const { conversation, recipientId } = selectors;

const mapStateToProps = (state, ownProps) => {
  const c = conversation(state, ownProps.conversationId);
  const otherId = recipientId(state, ownProps.conversationId);

  return {
    name: c.title,
    src: c.icon,
    size: 'small',
    online: online(state, otherId),
    colourSeed: otherId,
  };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Avatar);
