import { EditFolderAction } from '../sharedActions';
import { MaterialAppReducer } from './state';

const editFolder: MaterialAppReducer<EditFolderAction> = (state, action) => {
  switch (action.status) {
    case 'started':
      return {
        ...state,
        isSavingFolder: true,
      };
    case 'success':
    case 'error':
      return {
        ...state,
        renameFolderDialogOpenFor: null,
        isSavingFolder: false,
      };
    default:
      return state;
  }
};

export default editFolder;
