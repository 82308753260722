import {
  UpdateSectionBody,
  UpdateSectionContract,
} from '@ublend-npm/aula-schema/types/materials';
import { CLASSROOM_MATERIAL_SECTION } from '../../constants/endpoints';
import request, { getData } from './request';
import toSection from './toSection';

const patchSection = async (
  classRoomId: string,
  sectionId: string,
  section: UpdateSectionBody | undefined,
  restore = false,
  correlationId?: string
) => {
  const url = CLASSROOM_MATERIAL_SECTION(classRoomId, sectionId);
  const data = await request<UpdateSectionContract>({
    method: 'patch',
    headers: {
      'x-correlation-id': correlationId,
    },
    url,
    data: { section, restore },
  }).then(getData);
  return toSection(data);
};

export default patchSection;
