import { GROUP_SETS } from '../../constants/endpoints';
import request from './request';

const addGroupSet = async (accessToken, { space, name, isHidden }) => {
  const config = {
    method: 'post',
    url: GROUP_SETS({}),
    data: {
      set: {
        space,
        name,
        isHidden,
      },
    },
  };

  const {
    data: { set },
  } = await request(accessToken, config);
  return set;
};

export default addGroupSet;
