import { GET_SPACE } from '../../constants/endpoints';
import request from '../request';

const getSpace = async ({ spaceId }) => {
  const {
    data: { space },
  } = await request({
    method: 'get',
    url: GET_SPACE({ spaceId }),
  });

  return space;
};

export default getSpace;
