export const classRoomUserReducer = (state, action) => {
  switch (action.status) {
    case 'success': {
      const users = action.classRoomUsers.reduce(
        (userMap, classRoomUser) => ({
          ...userMap,
          [classRoomUser.user.objectId]: {
            ...classRoomUser.user,
            id: classRoomUser.user.objectId,
          },
        }),
        {}
      );

      return {
        ...state,
        users: { ...state.users, ...users },
      };
    }

    default:
      return state;
  }
};

export const userLoginReducer = (state, action) => {
  if (action.status !== 'success') return state;
  const { user } = action;

  return {
    ...state,
    users: {
      ...state.users,
      [user.id]: user,
    },
  };
};
