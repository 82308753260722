import { SUCCESS_STATUS } from '../../../constants/actionsStatus';

const buildClassRoomArrayFromStateAndAction = (state, action) =>
  Object.assign({}, state.classRooms, {
    [action.classRoomUser.classRoom.objectId]: Object.assign(
      {},
      action.classRoomUser.classRoom,
      {
        selfClassRoomUser: action.classRoomUser.objectId,
        posts: [],
        scheduledPosts: [],
        scheduledAnnouncements: [],
        importantPosts: [],
        announcements: [],
        instructors: [],
        participants: [],
        participantsHistory: {},
        participantsHistoryCount: 0,
        materials: [],
        materialsv2: [],
        notifications: [],
        inboxGroups: [],
        unread: false,
      }
    ),
  });

const buildClassRoomArrayFromClassRoomUsers = (
  currentClassRooms,
  classRoomUsers
) => {
  const newClassRooms = classRoomUsers
    .filter((cu) => !!cu.classRoom)
    .reduce(
      (previous, current) => ({
        ...previous,
        [current.classRoom.objectId]: {
          ...current.classRoom,
          selfClassRoomUser: current.objectId,
          posts: [],
          announcements: [],
          importantPosts: [],
          scheduledPosts: [],
          scheduledAnnouncements: [],
          instructors: current.classRoom.instructors,
          participants: [],
          participantsHistory: {},
          participantsHistoryCount: 0,
          materials: [],
          materialsv2: [],
          notifications: [],
          inboxGroups: [],
          unread: false,
          categories: current.classRoom.categories || [],
        },
      }),
      {}
    );

  const combinedClassRooms = { ...currentClassRooms, ...newClassRooms };
  return combinedClassRooms;
};

export const fetchClassRoomsReducer = (state, action) => {
  switch (action.status) {
    case SUCCESS_STATUS: {
      return {
        ...state,
        classRooms: buildClassRoomArrayFromClassRoomUsers(
          state.classRooms,
          action.classRoomUsers
        ),
      };
    }
    default:
      return state;
  }
};

export const createClassRoomReducer = (state, action) => {
  switch (action.status) {
    case SUCCESS_STATUS:
      return {
        ...state,
        classRooms: buildClassRoomArrayFromStateAndAction(state, action),
      };
    default:
      return state;
  }
};

export const joinClassRoomReducer = (state, action) => {
  switch (action.status) {
    case SUCCESS_STATUS:
      return {
        ...state,
        classRooms: buildClassRoomArrayFromStateAndAction(state, action),
      };
    default:
      return state;
  }
};

export const changeClassroomAvatarReducer = (state, action) => {
  switch (action.status) {
    case SUCCESS_STATUS: {
      const classRoom = state.classRooms[action.classroomId];

      return {
        ...state,
        classRooms: {
          ...state.classRooms,
          [action.classroomId]: {
            ...classRoom,
            avatar: action.url,
          },
        },
      };
    }

    default:
      return state;
  }
};

export const leaveClassRoomReducer = (state, action) => {
  switch (action.status) {
    case SUCCESS_STATUS: {
      const classRoomId = action.classRoomUser.objectId;
      const classRooms = { ...state.classRooms };
      delete classRooms[classRoomId];

      return {
        ...state,
        classRooms,
      };
    }

    default:
      return state;
  }
};
