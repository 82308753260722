import {
  INBOX_LEAVE_CONVERSATION,
  INBOX_REMOVE_PARTICIPANT_FROM_CONVERSATION,
} from '../actions';
import { currentUserId as getCurrentUserId } from '../../selectors/user';

const onParticipantLeft = (data) => (dispatch, getState) => {
  const { conversation: conversationId, participant: participantId } = data;

  const state = getState();
  const currentUserId = getCurrentUserId(state);

  dispatch({
    type:
      participantId === currentUserId
        ? INBOX_LEAVE_CONVERSATION
        : INBOX_REMOVE_PARTICIPANT_FROM_CONVERSATION,
    status: 'success',
    payload: {
      conversationId,
      participantId,
    },
  });
};

export default onParticipantLeft;
