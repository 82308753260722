import { connect } from 'react-redux';
import ErrorBar from '../../presentational/Errors/ErrorBar';
import { cleanError } from '../../../../core/actions/errors';

const mapStateToProps = (state) => {
  return {
    error: state.errors ? state.errors.last : undefined,
  };
};

const mapDispatchToProps = (dispatch) => ({
  cleanError: () => {
    dispatch(cleanError());
  },
});

const ErrorBarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorBar);

export default ErrorBarContainer;
