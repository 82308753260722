import React from 'react';
import { Route } from 'react-router';
import logout from '@app/utils/logout';

const logoutRoute = (
  <Route
    path="logout"
    onEnter={() => logout({ returnAfterLogin: false, makeLogoutApiCall: true })}
  />
);

export default logoutRoute;
