import { SaveSectionAction } from '../sharedActions';
import { MaterialDataReducer } from './state';

const saveSectionDataReducer: MaterialDataReducer<SaveSectionAction> = (
  state,
  action
) => {
  switch (action.status) {
    case 'started':
      const { patch, sectionId } = action.payload;

      const material = state[sectionId];

      if (material.isFolder) {
        return state;
      }

      return {
        ...state,
        [sectionId]: {
          ...state[sectionId],
          ...patch,
          // patch just contains id but we save the whole user object
          writer: material.writer,
        },
      };
    case 'success': {
      const { oldSection, section } = action.payload;

      const updatedMaterials = {
        ...state,
        [section.id]: {
          ...oldSection,
          ...section,
        },
      };

      if (oldSection && oldSection.id !== section.id) {
        delete updatedMaterials[oldSection.id];
      }

      return updatedMaterials;
    }
    case 'error':
    default:
      const { oldSection } = action.payload;
      return {
        ...state,
        [oldSection.id]: oldSection,
      };
  }
};

export default saveSectionDataReducer;
