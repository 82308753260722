import React from 'react';
import styled from 'react-emotion';
import BaseIconButton from '@material-ui/core/IconButton';
import { theme, Button as BaseButton } from '@ublend-npm/aulaui-next';

export const TitleButton = styled(BaseIconButton)`
  position: absolute;
  right: 24px;
`;

const DialogButton = styled(BaseButton)`
  line-height: 18px;
  margin: 0px;
`;

export const PrimaryButton = styled(({ danger, children, ...props }) => (
  <DialogButton size="small" type="primary" {...props}>
    {children}
  </DialogButton>
))`
  margin-left: ${theme.spacing.medium};
  ${(props) =>
    props.danger
      ? `
    background-color: ${theme.color.red};
    :hover {
      background-color: ${theme.color.red};
    }
    :disabled {
      background-color: ${theme.color.red};
    }
  `
      : ''}
`;

export const SecondaryButton = styled((props) => (
  <DialogButton size="small" type="secondary" {...props} />
))`
  margin-left: auto;
`;

export const TextButton = styled((props) => (
  <DialogButton size="small" type="text" {...props} />
))`
  ${(props) => props.tertiary && 'margin-right: auto;'};
  ${(props) => props.primary && 'margin-left: 24px;'};
`;

export const ConfirmationButtonsContainer = styled('div')`
  display: flex;
  flex-direction: ${(props) => (props.invert ? 'row-reverse' : 'row')};
  gap: ${(props) => (props.invert ? '8px' : '0px')};
`;

export const ConfirmationMessageContainer = styled('div')`
  font-size: ${theme.font.size.copy};
  color: ${(props) =>
    props.type && props.type === 'info'
      ? theme.color.designSystem.blackOpacity87
      : theme.color.red};
  flex: 1;
  flex-shrink: 1;
`;

const redConfirmButton = styled(BaseButton)`
  color: ${theme.color.red};
  border-color: ${theme.color.red};
  :hover {
    color: ${theme.color.red};
  }
  :focus {
    border-color: ${theme.color.red};
  }
`;

export const ConfirmButton = styled(({ children, ...props }) => (
  <DialogButton size="small" type="secondary" {...props}>
    {children}
  </DialogButton>
))`
  margin-right: 8px;
  ${(props) => (props.type !== 'info' ? redConfirmButton : null)}
`;
