import { SUCCESS_STATUS } from '../../constants/actionsStatus';

export const buildFetchScheduledPostsSuccess = (
  actionType,
  scheduledPosts
) => ({
  type: actionType,
  status: SUCCESS_STATUS,
  scheduledPosts,
});
