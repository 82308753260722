/* eslint-env browser */
import Analytics from '@ublend-npm/aula-analytics';
import clientInfo from '../../app/utils/clientInfo';

import platform from './platform';
import { ANALYTICS as analyticsEndpoint } from '../constants/endpoints';
import { getHoursBetweenDates } from './dates';
import * as analyticsTypes from '../constants/analytics';
import { COMMON_ROOM } from '../constants/postFeedType';
import { EDUCATOR_ROLE, STUDENT_ROLE } from '../constants/space';
import {
  isInstructorInSpace,
  accessingSpaceAsStaff,
} from '../selectors/classroom';
import { getUserRole } from '../selectors/user';

const removeUnnecessaryFields = ({
  firstName,
  lastName,
  email,
  avatar,
  username,
  externalId,
  ...remainingProps
}) => remainingProps;

/**
 * @param {{ hash }} location window.location object
 * @returns {SpaceData}
 */
const spaceDataFromLocation = (location) => {
  const matches = location.hash.match(/#\/dashboard\/(.*?)\//);
  return {
    activeClassroom: matches ? matches[1] : undefined,
  };
};

const spaceDataFromState = (getState, { userId, classRoomId }) => {
  const state = getState();
  const isInstructor = isInstructorInSpace(state)(userId, classRoomId);
  const isAdmin = getUserRole(state) === 'Admin';
  const accessingAsStaff = accessingSpaceAsStaff(state)(classRoomId);
  return {
    activeClassroom: classRoomId,
    isInstructor,
    isAdmin,
    accessingAsStaff,
  };
};

export const analyticsChangeClassRoom = (getState) => {
  const {
    classRoom: { current: classRoomId },
    user: userObj,
  } = getState();

  const spaceData =
    classRoomId && userObj
      ? spaceDataFromState(getState, {
          userId: userObj.user.objectId,
          classRoomId,
        })
      : spaceDataFromLocation(window.location);

  let role;
  if (spaceData.isInstructor !== undefined) {
    if (spaceData.accessingAsStaff) {
      role = 'staff';
    } else if (spaceData.isInstructor) {
      role = EDUCATOR_ROLE;
    } else {
      role = STUDENT_ROLE;
    }
  }

  Analytics.setClassRoom({
    ...spaceData,
    ...(role ? { role } : {}),
  });
};

export const analyticsChangeUser = (getState) => {
  const {
    user: { user },
  } = getState();
  const analyticsUser = user
    ? {
        userId: user.objectId,
      }
    : {};
  Analytics.setUser(analyticsUser);
};

export const analyticsChangeInstitution = (getState) => {
  const {
    institution: {
      general: { institution },
    },
  } = getState();

  if (Analytics.setInstitution) {
    Analytics.setInstitution(institution);
  }
  if (Analytics.setEndpoint) {
    Analytics.setEndpoint(analyticsEndpoint());
  }
  analyticsChangeUser(getState);
  analyticsChangeClassRoom(getState);
};

export const analyticsTrackEvent = (event, properties, user) =>
  Analytics.track({
    event,
    activeClassroom: undefined,
    ...(properties ? removeUnnecessaryFields(properties) : {}),
    ...(user ? removeUnnecessaryFields(user) : {}),
  });

/**
 * initializes the analytics with default params and user properties
 * @param {function} getState: function to get redux current state
 */
export const init = async (getState) => {
  const {
    institution: {
      general: { institution },
    },
  } = getState();

  await Analytics.init(institution, {}, platform, {}, { ...clientInfo });
  analyticsChangeInstitution(getState);
};

export const sendViewPostAnalytic = ({
  activeClassroom,
  postId,
  post,
} = {}) => {
  if (postId && post && activeClassroom) {
    const { important, feedType } = post;

    // Was the post created within the last 72 hours?
    const postAgeInHours = getHoursBetweenDates({
      startDate: post.createdAt,
      endDate: new Date(),
    });
    const isRecentPost = postAgeInHours >= 0 && postAgeInHours <= 72;

    analyticsTrackEvent(analyticsTypes.POST_VIEW, {
      activeClassroom,
      itemId: postId, // Keeping itemId as well, so that current dashboards for LI are not disturbed
      postId,
      itemType: feedType || COMMON_ROOM,
      isImportantPost: important,
      isRecentImportantPost: important && isRecentPost,
    });
  }
};

export default Analytics;
