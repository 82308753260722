// TODO: remove this reducers from ./commentGeneric during refactoring of posts: https://app.zenhub.com/workspaces/delivery-squad-60f6c36efdb7aa000f7bff44/issues/aulaeducation/aula/13108
// eslint-disable-next-line import/prefer-default-export
export const fetchCommentsReducer = (state, action) => {
  switch (action.status) {
    case 'started':
      return state;
    case 'success': {
      const groupedReplies = action.comments.reduce((acc, comment) => {
        if (!comment.root) {
          return acc;
        }
        return {
          ...acc,
          [comment.root]: [...(acc[comment.root] || []), comment].sort(),
        };
      }, {});

      const postComments = action.comments.reduce(
        (acc, comment) => ({
          ...acc,
          [comment.id]: {
            ...comment,
            post: action.postId,
            user: comment.user || {
              firstName: 'Deleted user',
              lastName: '',
            },
          },
        }),
        {}
      );
      const compareDates = (a, b) =>
        new Date(a.createdAt) - new Date(b.createdAt);
      const postCommentsWithReplies = Object.keys(postComments).reduce(
        (acc, commentId) => ({
          ...acc,
          [commentId]: {
            ...postComments[commentId],
            replies: (groupedReplies[commentId] || [])
              .sort(compareDates)
              .map(({ id }) => id),
          },
        }),
        {}
      );

      const updatedPost = {
        ...state.posts[action.postId],
        comments: Object.keys(postCommentsWithReplies)
          .reduce((acc, commentId) => {
            const comment = postCommentsWithReplies[commentId];
            if (comment.root) {
              return acc;
            }
            return [...acc, comment];
          }, [])
          .sort(compareDates)
          .map((comment) => comment.id),
      };

      return {
        ...state,
        comments: {
          ...state.comments,
          ...postCommentsWithReplies,
        },
        posts: {
          ...state.posts,
          [action.postId]: updatedPost,
        },
      };
    }
    case 'error':
      return {
        ...state,
        isErrored: true,
        error: action.error,
      };

    default:
      return state;
  }
};
