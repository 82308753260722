import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const Container = styled('div')`
  width: 100%;
  height: 100%;
  background: ${theme.color.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.leftPanel {
    overflow-x: hidden;

    .leftPanel__navigation {
      height: 50%;
      overflow: auto;
    }

    .leftPanel__main,
    .leftPanel__footer {
      flex-shrink: 0;
    }
  }
`;

export const TopHalf = styled('div')``;

export const CloseButtonContainer = styled('div')`
  margin-left: 12px;
  margin-top: 12px;
  margin-right: 16px;
  display: flex;
  width: fit-content;
`;
