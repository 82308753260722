export const byCreatedAtSorter = (a, b) => {
  if (a.createdAt < b.createdAt) return -1;
  if (a.createdAt > b.createdAt) return 1;
  return 0;
};

export const byMessageIdReducer = (curr, message) => {
  // eslint-disable-next-line no-param-reassign
  curr[message.id] = message;
  return curr;
};

export const getMessageById = (store) => (id) => store[id];

export const unique = (a) => [...new Set(a)];

export const appendAndReorderMessages = (currentMessages, newMessages) =>
  [...currentMessages, ...newMessages].sort(byCreatedAtSorter, []);
