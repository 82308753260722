import axios from 'axios';
import { getHeaders } from './utils';
import { AULA_ASSIGNMENTS } from '../../constants/endpoints';
import cancellableRequest from '../../api/cancellableRequest';

const fetch =
  (cancelToken) =>
  ({
    accessToken,
    spaceId,
    page = 0,
    assignmentId = undefined,
    externalId = undefined,
  }) => {
    return axios.get(AULA_ASSIGNMENTS(), {
      params: {
        spaceId,
        assignmentId,
        page,
        externalId,
        displayLimit: 1000,
      },
      headers: getHeaders(accessToken),
      cancelToken,
    });
  };

export const nonCancellableFetch = fetch();

export const fetchAssignment = ({ accessToken, assignmentId }) =>
  axios.get(`${AULA_ASSIGNMENTS(assignmentId)}/${assignmentId}`, {
    headers: getHeaders(accessToken),
  });

export default cancellableRequest(fetch).fn;
