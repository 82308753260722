import styled, { css } from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const StatusInputContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xSmall};
  align-items: flex-end;
`;

export const TextFieldStyle = css`
  width: 100%;
  font-family: ${theme.font.family.workSans};
  & [class*='adornedStart'] {
    padding-left: ${theme.spacing.xSmall};
  }
  & [class*='adornedEnd'] {
    padding-right: ${theme.spacing.xSmall};
  }
`;

export const LimitCounter = styled('div')`
  font-weight: ${theme.font.weight.regular};
  font-size: ${theme.font.size.small};
  line-height: ${theme.font.lineHeight.small};
  color: ${theme.color.designSystem.blackOpacity60};
`;
