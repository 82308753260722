import { NOTIFICATIONS_NEW_NOTIFICATION } from '../actionsTypes';
import { fetchNotifications } from '../actions/index';
import * as selectors from '../selectors';
import { notificationActions } from '../../constants/notifications';

const onNewNotification =
  ({ displayNotification }) =>
  (notification) =>
  (dispatch, getState) => {
    const state = getState();
    const alreadyExists =
      selectors.lastNotificationId(state) === notification.id;
    const isFeedNotification =
      notification.notification.action !== notificationActions.newMessage;

    if (alreadyExists) {
      return;
    }

    dispatch({
      type: NOTIFICATIONS_NEW_NOTIFICATION,
      status: 'success',
      payload: { notification },
    });

    // by fetching notifications - the state will be updated with the correct
    // payload format, and the badge will subsequently be updated
    dispatch(
      fetchNotifications({ showNotificationsBadge: isFeedNotification })
    );

    displayNotification({
      notification,
      dispatch,
      getState,
      selectors,
    });
  };

export default onNewNotification;
