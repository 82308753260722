import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import { currentUser as getCurrentUser } from '../../../../../core/selectors/user';
import { toggleUserProfile as toggleUserProfileAction } from '../../../../actions/navigation';

import ConversationFilter from './ConversationFilter';

class ConversationFilterContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
    };
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
  }

  onBlur() {
    this.setState({ focused: false });
  }

  onChange(event) {
    const { onChange } = this.props;
    const filterValue = event.target.value;
    onChange(filterValue);
  }

  onFocus() {
    this.setState({ focused: true });
  }

  reset() {
    const { onChange } = this.props;
    onChange('');
  }

  render() {
    const { focused } = this.state;
    const { getInputProps, status, toggleUserProfile, flags, filterValue } =
      this.props;

    return (
      <ConversationFilter
        focused={focused}
        filterValue={filterValue}
        onBlur={this.onBlur}
        onChange={this.onChange}
        onFocus={this.onFocus}
        getInputProps={getInputProps}
        status={status}
        toggleUserProfile={toggleUserProfile}
        flags={flags}
      />
    );
  }
}

ConversationFilterContainer.propTypes = {
  onChange: PropTypes.func.isRequired,
  getInputProps: PropTypes.func.isRequired,
  toggleUserProfile: PropTypes.func.isRequired,
  flags: PropTypes.shape({
    ftUserStatusEnabled202107: PropTypes.bool,
  }).isRequired,
  status: PropTypes.shape({
    text: PropTypes.string,
    emojiName: PropTypes.string,
  }),
  filterValue: PropTypes.string,
};

ConversationFilterContainer.defaultProps = {
  status: undefined,
  filterValue: '',
};

const mapStateToProps = (state, { flags }) => {
  const { status } = getCurrentUser(state);
  return {
    status,
    flags,
  };
};

const mapDispatchToProps = {
  toggleUserProfile: toggleUserProfileAction,
};

export default withLDConsumer()(
  connect(mapStateToProps, mapDispatchToProps)(ConversationFilterContainer)
);
