import { INBOX_HIGHLIGHT_MESSAGE } from '../actions';
import { isMessageHighlighted } from '../selectors';

const HIGHLIGHTED_TIME_MS = 1500;
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const highlightMessage = (messageId) => async (dispatch, getState) => {
  dispatch({
    type: INBOX_HIGHLIGHT_MESSAGE,
    status: 'success',
    payload: { messageId },
  });

  await delay(HIGHLIGHTED_TIME_MS);

  if (isMessageHighlighted(getState(), messageId)) {
    dispatch({
      type: INBOX_HIGHLIGHT_MESSAGE,
      status: 'success',
      payload: { messageId: null },
    });
  }
};

export default highlightMessage;
