import axios from 'axios';
import * as types from '../../constants/actions';

const fetchInstitutionStarted = () => ({
  type: types.FETCH_INSTITUTION,
  status: 'started',
});

export const fetchInstitutionSuccess = (results, dispatch) => {
  dispatch({
    type: types.FETCH_INSTITUTION,
    status: 'success',
    results: results.data,
  });
};

const fetchInstitutionFailed = (error, dispatch) => {
  dispatch({
    type: types.FETCH_INSTITUTION,
    status: 'error',
    error,
  });
};

const checkInstitutionStarted = (dispatch) => {
  dispatch({
    type: types.CHECK_INSTITUTION,
    status: 'started',
  });
};

const checkInstitutionSuccess = (results, dispatch) => {
  dispatch({
    type: types.CHECK_INSTITUTION,
    status: 'success',
    results: results.data,
    email: results.email,
  });
};

export const checkInstitutions = (email, cb) => (dispatch) => {
  checkInstitutionStarted(dispatch);

  const url = `https://apiv2.general.aula.education/institution/email/check?email=${email}`;

  axios
    .get(url)
    .then((results) => {
      checkInstitutionSuccess({ ...results, email }, dispatch);
      cb(true, results.data);
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('errors', error);
      cb(false);
    });
};

export const fetchInstitution = (institution) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(fetchInstitutionStarted());
    axios
      .post(`https://apiv2.${institution}.aula.education/conf/read`, {
        modules: ['general', 'launchDarkly'],
      })
      .then((results) => {
        fetchInstitutionSuccess(results, dispatch);
        resolve(results.data.parse);
      })
      .catch((error) => {
        fetchInstitutionFailed(error, dispatch);
        reject(error);
      });
  });
