import * as actionTypes from '../../constants/actions';
import { fetchSpaceContent } from '../space';
import { analyticsTrackEvent } from '../../utils/analytics';
import { currentClassRoomId } from '../../selectors/classroom';
import * as analyticsTypes from '../../constants/analytics';

const filterByTag = (tagId, classRoom) => (dispatch, getState) => {
  const state = getState();
  const {
    data: { classRooms },
  } = state;
  const activeClassroom = currentClassRoomId(state);

  analyticsTrackEvent(analyticsTypes.CLICK_TAG, {
    tag: tagId,
    activeClassroom,
  });

  const space = classRoom || classRooms[activeClassroom];
  dispatch({
    type: actionTypes.FEED_FILTER_TAG,
    status: 'started',
  });

  dispatch(
    fetchSpaceContent({
      classRoom: space,
      reset: true,
      tagId,
    })
  );
  dispatch({
    type: actionTypes.FEED_FILTER_TAG,
    status: 'added',
    tagId,
  });
};

export default filterByTag;
