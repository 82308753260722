import * as actionTypes from '../../constants/actions';

export default (socket, dispatch, getState) => {
  socket.on('clients', (clients) => {
    dispatch({
      type: actionTypes.LIVE_CLIENTS_REFRESHED,
      status: 'success',
      clients,
    });
  });

  socket.on('client:disconnect', (clientId) => {
    if (!getState().live.clients[clientId]) return;

    dispatch({
      type: actionTypes.LIVE_CLIENT_DISCONNECTED,
      status: 'success',
      clientId,
    });
  });

  socket.on('client:connect', (clientId) => {
    if (getState().live.clients[clientId]) return;

    dispatch({
      type: actionTypes.LIVE_CLIENT_CONNECTED,
      status: 'success',
      clientId,
    });
  });
};
