import React from 'react';
import PropTypes from 'prop-types';

import BaseDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

import { TitleButton } from './Buttons.styled';

const DialogTitle = ({ children, onClose, closeIcon, ...props }) => (
  <BaseDialogTitle disableTypography {...props}>
    <div className="dialog-title-area">{children}</div>
    {closeIcon ? (
      <TitleButton
        aria-label={`Closes '${children}' dialog`}
        disableRipple
        onClick={onClose}
        icon={CloseIcon}
        type="secondary"
        size="medium"
      />
    ) : null}
  </BaseDialogTitle>
);

DialogTitle.defaultProps = {
  closeIcon: true,
};

DialogTitle.propTypes = {
  closeIcon: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default React.memo(DialogTitle);
