import * as actionTypes from '../../../constants/actions';
import {
  STARTED_STATUS,
  SUCCESS_STATUS,
  ERROR_STATUS,
} from '../../../constants/actionsStatus';
import { newError } from '../../errors';
import updatePost from '../../../api/posts/edit';
import { NO_POST_ID_MESSAGE } from './constants';

const editSpacePostStart = (dispatch) => {
  dispatch({
    type: actionTypes.CLASSROOM_EDIT_POST,
    status: STARTED_STATUS,
  });
};

const editSpacePostSuccess = (post, dispatch) => {
  dispatch({
    type: actionTypes.CLASSROOM_EDIT_POST,
    status: SUCCESS_STATUS,
    post,
  });
};

const editSpacePostError = (error, dispatch) => {
  dispatch(newError(error, 'editSpacePost'));
  dispatch({
    type: actionTypes.CLASSROOM_EDIT_POST,
    status: ERROR_STATUS,
    error,
  });
};

export const editSpacePost =
  ({ postId, content, scheduledFor }) =>
  async (dispatch) => {
    if (!postId) {
      const err = { message: NO_POST_ID_MESSAGE };
      return editSpacePostError(err, dispatch);
    }

    editSpacePostStart(dispatch);

    try {
      const post = await updatePost({
        postId,
        content,
        scheduledFor,
      });

      return editSpacePostSuccess(post, dispatch);
    } catch (error) {
      return editSpacePostError(error, dispatch);
    }
  };
