import fetchUsersByIds from '../../api/users/fetchUsersByIds';
import { fetchUsersSuccess } from '../../actions/users/fetchUsers.action';

const getUser = async (dispatch, userStore, id) => {
  if (userStore[id]) return userStore[id];

  const user = (await fetchUsersByIds({ userIds: [id] }))[0];
  fetchUsersSuccess(dispatch, { requestedUsers: [id], users: [user] });
  return user;
};

export default getUser;
