export default (state, action) => {
  if (!['started', 'error', 'success'].includes(action.status)) return state;

  const {
    payload: { conversationId, userId, readUpTo },
  } = action;
  const { conversations } = state;
  const conversation = conversations[conversationId];
  const { participants = {} } = conversation;
  const participation = participants[userId] || {};

  return {
    ...state,
    conversations: {
      ...conversations,
      [conversationId]: {
        ...conversation,
        participants: {
          ...conversation.participants,
          [userId]: {
            ...participation,
            readUpTo,
          },
        },
      },
    },
  };
};
