export default {
  light: {
    fontFamily: 'Work Sans',
    fontWeight: 400,
  },
  regular: {
    fontFamily: 'Work Sans',
    fontWeight: 500,
  },
  bold: {
    fontFamily: 'Work Sans',
    fontWeight: 700,
  },
  mono: {
    fontFamily: 'Hack',
  },
};
