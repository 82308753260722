import { NOTIFICATION_TYPE_FILTER_CHANGE } from '../actionsTypes';

const changeNotificationTypeFilter = (type) => (dispatch) => {
  dispatch({
    type: NOTIFICATION_TYPE_FILTER_CHANGE,
    payload: { type },
  });
};

export default changeNotificationTypeFilter;
