import { MaterialAppReducer } from './state';
import { CreateSectionAction } from '../sharedActions';

const createSectionAppReducer: MaterialAppReducer<CreateSectionAction> = (
  state
) => ({
  ...state,
  editing: true,
});

export default createSectionAppReducer;
