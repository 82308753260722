const getSubCategories = (spaces, categoryLabel) =>
  spaces
    .filter((space) => space.categories.length > 1)
    .filter((space) => space.categories[0].label === categoryLabel)
    .map((space) => space.categories[1])
    .filter(
      (subCategory, index, subCategories) =>
        // filter for unique values by returning only the first occurence of each subCategory
        subCategories.findIndex((c) => c.label === subCategory.label) === index
    )
    .sort((a, b) => a.order - b.order);

export default getSubCategories;
