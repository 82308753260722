import { GenericAction } from '@core/types/redux';
import { CLASSROOM_MODULE_TOGGLE_FOLDER } from '../actions';
import { MaterialAppReducer } from './state';

export type ToggleFolderAction = GenericAction<
  typeof CLASSROOM_MODULE_TOGGLE_FOLDER,
  { folderId: string }
>;

const toggleFolder: MaterialAppReducer<ToggleFolderAction> = (
  state,
  action
) => {
  const {
    payload: { folderId },
  } = action;
  const { expandedFolders } = state;
  return {
    ...state,
    expandedFolders: {
      ...expandedFolders,
      [folderId]: !expandedFolders[folderId],
    },
  };
};

export default toggleFolder;
