import { STAR_SPACE } from '@core/constants/endpoints';
import axios from 'axios';
import getAnalyticsHeaders from '../../utils/analyticsHeaders';

const starSpace = async ({ userId, spaceId, isStarred }) => {
  const config = {
    method: isStarred ? 'POST' : 'DELETE',
    url: STAR_SPACE({ spaceId }),
  };

  const headers = {
    'x-user-id': userId,
    ...getAnalyticsHeaders(),
  };

  try {
    await axios({ ...config, headers, withCredentials: true });
  } catch (e) {
    throw new Error(e.message);
  }
};

export default starSpace;
