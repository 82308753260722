export type CommentEntityType = 'post' | 'material';

const CommentsEntityTypes: Record<string, CommentEntityType> = {
  post: 'post',
  material: 'material',
};

export type Entity = 'posts' | 'materials';

export const Entities: Record<CommentEntityType, Entity> = {
  post: 'posts',
  material: 'materials',
};

export const EntityTypesByCollections = {
  UBClassRoomPost: CommentsEntityTypes.post,
  UBClassRoomMaterialModule: CommentsEntityTypes.material,
};

export default CommentsEntityTypes;
