import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const Container = styled('div')`
  width: 100%;
  height: 125px;
  background: ${theme.color.white};
  /* border-bottom: 1px solid purple; */
`;

export const Link = styled('a')`
  color: ${theme.color.grey11};
  font-size: 14px;
  font-weight: ${theme.font.weight.medium};
  line-height: 20px;
  height: 32px;
  display: flex;
  align-items: center;
  margin-left: 16px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${theme.color.black};
    text-decoration: none;
  }
`;

export const Divider = styled('div')`
  height: 1px;
  background: ${theme.color.gray};
  margin: 8px 0;
`;
