import React from 'react';
import {
  BasicOnboardingContainer,
  Title,
  Description,
  Link,
} from './StarSpaceOnboarding.styled';

const LearnMoreLink = () => (
  <Link
    href="https://aula.zendesk.com/hc/en-us/articles/15852659347218"
    target="_blank"
  >
    Learn more
  </Link>
);

const StarSpaceOnboarding = () => {
  return (
    <BasicOnboardingContainer>
      <Title>No starred spaces</Title>
      <Description center>
        Add your most important spaces to the starred tab for quick access by
        tapping the star icon next to a space name
      </Description>
      <LearnMoreLink />
    </BasicOnboardingContainer>
  );
};

export default StarSpaceOnboarding;
