import { GenericAction } from '../../types/redux';
import { CLASSROOM_MODULE_SET_EDITING } from '../actions';
import { MaterialAppReducer } from './state';

type SetEditingAction = GenericAction<
  typeof CLASSROOM_MODULE_SET_EDITING,
  { editing: boolean }
>;

const setEditing: MaterialAppReducer<SetEditingAction> = (state, action) => ({
  ...state,
  editing: action.payload.editing,
  uneditedContent: action.payload.editing ? state.selectedContent : null,
});

export default setEditing;
