const deleteGroupReducer = (state, action) => {
  if (action.status !== 'success') {
    return state;
  }

  const {
    payload: { groupId, studentsWithNoGroup },
  } = action;

  const group = state.groups[groupId];
  if (!group) {
    return state;
  }

  const updatedGroups = { ...state.groups };
  delete updatedGroups[groupId];

  const updatedSet = {
    ...state.sets[group.set],
    studentsWithNoGroup,
  };
  updatedSet.groups = updatedSet.groups.filter(
    (currentGroupId) => currentGroupId !== groupId
  );

  return {
    ...state,
    sets: {
      ...state.sets,
      [group.set]: updatedSet,
    },
    groups: updatedGroups,
  };
};

export default deleteGroupReducer;
