import { StatusAction } from '../../types/redux';
import { CLASSROOM_MODULE_CREATE_FOLDER } from '../actions';
import { Folder } from '../types';
import { MaterialDataReducer, MaterialsDataState } from './state';

const addFolder = (state: MaterialsDataState, folder: Folder) => ({
  ...state,
  [folder.id]: folder,
});

const removeFolder = (state: MaterialsDataState, folderId?: string) => {
  if (!folderId) {
    return state;
  }

  const newState = { ...state };
  delete newState[folderId];
  return newState;
};

type StartedCreateFolderAction = StatusAction<
  typeof CLASSROOM_MODULE_CREATE_FOLDER,
  'started',
  { folder: Folder }
>;

type ErrorCreateFolderAction = StatusAction<
  typeof CLASSROOM_MODULE_CREATE_FOLDER,
  'error',
  { folder?: undefined; localId?: string }
>;

type SuccessCreateFolderAction = StatusAction<
  typeof CLASSROOM_MODULE_CREATE_FOLDER,
  'success',
  { folder: Folder; localId?: string }
>;

export type CreateFolderAction =
  | StartedCreateFolderAction
  | SuccessCreateFolderAction
  | ErrorCreateFolderAction;

const createFolderDataReducer: MaterialDataReducer<CreateFolderAction> = (
  state,
  action
) => {
  switch (action.status) {
    case 'started':
      return addFolder(state, action.payload.folder);
    case 'success':
      return removeFolder(
        addFolder(state, action.payload.folder),
        action.payload.localId
      );
    case 'error':
      return removeFolder(state, action.payload.localId);
    default:
      return state;
  }
};

export default createFolderDataReducer;
