const saveNotificationSettingsReducer = (state, action) => {
  switch (action.status) {
    case 'started':
    case 'success': {
      const {
        payload: { notificationSettings },
      } = action;
      return {
        ...state,
        settings: notificationSettings,
      };
    }
    case 'error': {
      const {
        payload: { oldSettings },
      } = action;
      return {
        ...state,
        settings: oldSettings,
      };
    }
    default:
      return state;
  }
};

export default saveNotificationSettingsReducer;
