import appReducer from './appReducers';
import dataReducer from './dataReducers';
import operations from './operations';
import registerLive from './registerLive';
import * as selectors from './selectors';

export { type GroupsAppState } from './appReducers/state';

export default {
  appReducer,
  dataReducer,
  operations,
  selectors,
  registerLive: registerLive(operations),
};
