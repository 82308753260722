import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  COV_STUDENT_SUPPORT,
  COV_DIGITAL_SERVICES,
  COV_EDUCATION_TECHNOLOGY_SUPPORT,
  COV_PLATFORM_ACCESS_AND_SUPPORT,
  COV_TECHNOLOGY_ENHANCED_LEARNING,
  UWS_EDUCATION_TECHNOLOGY_SUPPORT,
  UWS_PLATFORM_ACCESS_AND_SUPPORT,
} from '../constants/texts';

const useCustomZendeskItems = () => {
  const {
    webAppFtCoventryHelpWidgetEnabled202211: showCoventryOnlyItems,
    webAppFtUwsHelpWidgetEnabled202308: showUWSOnlyItems,
  } = useFlags();

  const hasMultipleWidgetsEnabled = showCoventryOnlyItems && showUWSOnlyItems;

  // In the case that both flags are enabled, we will show the default Zendesk widget
  if (hasMultipleWidgetsEnabled) {
    return {
      educatorItems: [],
      studentItems: [],
      showCustomItems: false,
    };
  }

  if (showCoventryOnlyItems) {
    return {
      educatorItems: [
        {
          key: 'COV_EDUCATION_TECHNOLOGY_SUPPORT',
          text: COV_EDUCATION_TECHNOLOGY_SUPPORT,
          id: 'cov-education-technology-support',
          fn: () => () => {
            window.open(
              'https://coventryuniversity.service-now.com/itservices?id=sc_category&sys_id=18262fb01b7e1110fadbfddf1d4bcbe5&catalog_id=e0d08b13c3330100c8b837659bba8fb4',
              '_blank'
            );
          },
          info: 'Get help now with Education & Assessment tools',
          showIcon: true,
        },
        {
          key: 'COV_PLATFORM_ACCESS_AND_SUPPORT',
          text: COV_PLATFORM_ACCESS_AND_SUPPORT,
          id: 'cov-platform-access-and-support',
          fn: () => () => {
            window.open(
              'https://coventryuniversity.service-now.com/itservices?id=sc_category&sys_id=18262fb01b7e1110fadbfddf1d4bcbe5&catalog_id=e0d08b13c3330100c8b837659bba8fb4',
              '_blank'
            );
          },
          info: 'Contact the Platform Team with your access issues or requests',
          showIcon: true,
        },
        {
          key: 'COV_TECHNOLOGY_ENHANCED_LEARNING',
          text: COV_TECHNOLOGY_ENHANCED_LEARNING,
          id: 'cov-technology-enhanced-learning',
          fn: () => () => {
            window.open(
              'https://teach.coventry.domains/uncategorized/education-technologists-who-are-they-and-what-do-they-do',
              '_blank'
            );
          },
          info: 'Work with an EdTech to enhance your course',
          showIcon: true,
        },
      ],
      studentItems: [
        {
          key: 'COV_STUDENT_SUPPORT',
          text: COV_STUDENT_SUPPORT,
          id: 'cov-student-support',
          fn: () => () => {
            window.open('https://wayfinder.coventry.ac.uk/', '_blank');
          },
          info: 'Advice on enrolment and student services',
          showIcon: true,
        },
        {
          key: 'COV_DIGITAL_SERVICES',
          text: COV_DIGITAL_SERVICES,
          id: 'cov-digital-services',
          fn: () => () => {
            window.open('http://www.coventry.ac.uk/digitalservices', '_blank');
          },
          info: 'Technical support after you have enrolled',
          showIcon: true,
        },
      ],
      showCustomItems: true,
    };
  }

  if (showUWSOnlyItems) {
    const uwsItems = [
      {
        key: 'UWS_PLATFORM_ACCESS_AND_SUPPORT',
        text: UWS_PLATFORM_ACCESS_AND_SUPPORT,
        id: 'uws-platform-access-and-support',
        fn: () => () => {
          window.open(
            'https://uws.topdesk.net/tas/public/ssp/content/serviceflow?unid=7d45e549343a492fa1c759a31d2e03d8&from=b1d417c9-eeab-4841-b653-f5b2b0660a83',
            '_blank'
          );
        },
        info: 'Contact IT Helpdesk for technical issues regarding space creation and adding students or staff into spaces.',
        showIcon: true,
      },
      {
        key: 'UWS_EDUCATION_TECHNOLOGY_SUPPORT',
        text: UWS_EDUCATION_TECHNOLOGY_SUPPORT,
        id: 'uws-education-technology-support',
        fn: () => () => {
          window.open(
            'https://uws.topdesk.net/tas/public/ssp/content/serviceflow?unid=723f12c51df8423b93b6b17970f3d8bd',
            '_blank'
          );
        },
        info: 'Contact Learning Transformation for help using Aula and the learning, teaching and assessment tools within.',
        showIcon: true,
      },
    ];

    return {
      educatorItems: uwsItems,
      studentItems: uwsItems,
      showCustomItems: true,
    };
  }

  return {
    educatorItems: [],
    studentItems: [],
    showCustomItems: false,
  };
};

export default useCustomZendeskItems;
