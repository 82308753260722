import { GROUPS_OPEN_CLOSE_EDIT_GROUP } from '../actions';

export const openEditGroup = (groupId) => (dispatch) =>
  dispatch({
    type: GROUPS_OPEN_CLOSE_EDIT_GROUP,
    payload: { editGroupDialogOpenFor: groupId },
  });

export const closeEditGroup = (dispatch) =>
  dispatch({
    type: GROUPS_OPEN_CLOSE_EDIT_GROUP,
    payload: { editGroupDialogOpenFor: null },
  });
