import big from 'big.js';
import { CLASSROOM_MODULE_REORDER_MATERIAL } from '../actions';
import { patchSection, patchFolder } from '../api';
import * as materialsSelectors from '../selectors';
import { AulaReduxAction, AulaState } from '../../types/state';
import { getCurrentSpaceId } from '../../selectors/space';
import { ActionStatus } from '../../constants/actionsStatus';
import { isLocalId } from '../../utils/localId';

const newOrder = (
  state: AulaState,
  {
    destId,
    parentId,
    dropType,
  }: { destId?: string; parentId?: string; dropType: string }
): string => {
  const materials = materialsSelectors.sectionsByParent(state, parentId);

  const destMaterial = materialsSelectors.getMaterial(state, destId);

  if ((!destId && parentId) || !destMaterial) {
    // No dest material but a parent indicates the section has been
    // dropped just above the "Add new page" button inside a folder.
    // We just need to drop it at the end of the specified folder.
    const lastMaterial = materials[materials.length - 1];
    const lastOrder = lastMaterial ? lastMaterial.order : 4.5;
    return big(lastOrder).add(9.5).div(2).toFixed();
  }

  const materialIdx = materials.findIndex((m) => m.id === destId);

  const materialOrder = big(destMaterial.order);

  const beforeOrder = big(
    materialIdx > 0 ? materials[materialIdx - 1].order : '0.5'
  );
  const afterOrder = big(
    materialIdx < materials.length - 1
      ? materials[materialIdx + 1].order
      : '9.5'
  );
  const selectedOrder = dropType === 'before' ? beforeOrder : afterOrder;
  return materialOrder.add(selectedOrder).div(2).toFixed();
};

const reorderMaterialAction = (status: ActionStatus, payload: unknown) => ({
  type: CLASSROOM_MODULE_REORDER_MATERIAL,
  status,
  payload,
});

type ReorderMaterialActionFactory = (_: {
  sourceId: string;
  destId: string;
  parentId?: string;
  dropType: string;
}) => AulaReduxAction;

const reorderMaterial: ReorderMaterialActionFactory =
  ({ sourceId, destId, parentId, dropType }) =>
  async (dispatch, getState) => {
    const state = getState();

    const spaceId = getCurrentSpaceId(state);
    const srcMaterial = materialsSelectors.getMaterial(state, sourceId);
    const isSourceFolder = materialsSelectors.isFolder(state, sourceId);

    const patch = {
      parent: parentId || null,
      order: newOrder(state, {
        destId,
        parentId,
        dropType,
      }),
    };

    dispatch(
      reorderMaterialAction('started', {
        materialId: sourceId,
        patch,
      })
    );
    try {
      if (isLocalId(sourceId)) {
        dispatch(
          reorderMaterialAction('success', {
            materialId: sourceId,
            updatedMaterial: {
              ...srcMaterial,
              ...patch,
            },
          })
        );
        return;
      }
      const patchPromise = isSourceFolder
        ? patchFolder(spaceId, sourceId, patch)
        : patchSection(spaceId, sourceId, patch);
      const updatedMaterial = await patchPromise;
      dispatch(
        reorderMaterialAction('success', {
          materialId: sourceId,
          updatedMaterial,
        })
      );
    } catch (error: unknown) {
      dispatch(
        reorderMaterialAction('error', {
          error,
          materialId: sourceId,
          oldMaterial: srcMaterial,
        })
      );
    }
  };

export default reorderMaterial;
