import React from 'react';
import styled, { css } from 'react-emotion';
import {
  theme,
  Avatar as BaseAvatar,
  ListItem as BaseListItem,
  icons,
} from '@ublend-npm/aulaui-next';

export const ListItem = styled(({ ...props }) => (
  <BaseListItem
    {...props}
    classes={{ primary: 'primary', secondary: 'secondary' }}
  />
))`
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${theme.color.designSystem.background.grey.hover};
    cursor: pointer;
  }

  &:focus {
    outline: Highlight auto;
    outline: -webkit-focus-ring-color auto;
    outline-offset: -1px;
  }
`;

export const ActivityIndicator = styled.span`
  height: 6px;
  width: 6px;
  background-color: ${theme.color.error};
  border-radius: 50%;
  flex-shrink: 0;

  font-family: ${theme.font.family.workSans};
  font-weight: ${theme.font.weight.semiBold};
  font-size: ${theme.font.size.large};
  line-height: ${theme.font.lineHeight.copy};
  margin-right: 16px;
`;

export const getSelectorItemStyles = ({ showStar }) => css`
  display: flex;
  border: none;
  width: 100%;
  background-color: ${theme.color.offWhite};
  padding-right: ${showStar ? '34px' : '0'};
  padding-left: ${theme.spacing.medium};

  & [class$='textAfterDecoration'] {
    margin-left: 12px;
    margin-right: ${showStar ? '24px' : '12px'};
  }
`;

export const Avatar = styled(BaseAvatar)`
  flex-shrink: 0;
`;

export const SpaceSelectorTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 40px;
`;

export const SpaceSelectorPrimaryText = styled('span')`
  font-family: ${theme.font.family.workSans};
  font-weight: ${theme.font.weight.medium};
  font-size: ${theme.font.size.large};
  line-height: ${theme.font.lineHeight.copy};
  color: ${theme.color.blackAlpha(0.87)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SpaceSelectorSecondaryText = styled('span')`
  font-family: ${theme.font.family.workSans};
  font-weight: ${theme.font.weight.regular};
  font-size: ${theme.font.size.copy};
  line-height: ${theme.font.lineHeight.copy};
  color: ${theme.color.blackAlpha(0.6)};
`;

export const IconButtonContainer = styled('div')`
  position: absolute;
  right: 0;
  top: 50%;

  & button {
    margin-right: 16px;
    transform: translateY(-50%);
  }
`;

export const SpaceItemContainer = styled('div')`
  position: relative;
`;

export const StarredSpaceIcon = styled(icons.StarIcon)`
  color: ${theme.color.purple3};
  width: 20px;
  height: 20px;
  ${(props) => (props.isSaving ? 'opacity: 0.5;' : '')}
`;

export const UnstarredSpaceIcon = styled(icons.StarOutlineIcon)`
  color: ${theme.color.blackAlpha(0.6)};
  width: 20px;
  height: 20px;
`;
