import {
  SPACE_SELECTOR_ITEM_TYPE,
  SPACE_SELECTOR_SEARCH,
} from '../../constants/analytics';
import { analyticsTrackEvent } from '../../utils/analytics';
import { openTimestamp, openCorrelationId } from '../selectors';
import getNow from '../getNow';

const searchSpaceSelector =
  (search, sort, showArchived, category) => (_dispatch, getState) => {
    const state = getState();

    analyticsTrackEvent(SPACE_SELECTOR_SEARCH, {
      itemType: SPACE_SELECTOR_ITEM_TYPE,
      selectorOpenFor: Math.round(getNow() - openTimestamp(state)),
      correlationId: openCorrelationId(state),
      search,
      sort,
      showArchived,
      categoriesEnabled: true,
      category,
    });
  };

export default searchSpaceSelector;
