import * as actions from '../../constants/actions';

import openCloseModal from './openCloseModal.reducer';
import fetchResults from './fetchResults.reducer';
import clearResults from './clearResults.reducer';

const tabs = [
  {
    id: 'all',
    title: 'All results',
    type: 'content',
    results: [],
    resultsSet: new Set(),
    moreToLoad: true,
  },
  {
    id: 'posts',
    title: 'Posts',
    type: 'posts',
    results: [],
    resultsSet: new Set(),
    moreToLoad: true,
  },
  {
    id: 'comments',
    title: 'Comments',
    type: 'comments',
    results: [],
    resultsSet: new Set(),
    moreToLoad: true,
  },
  {
    id: 'messages',
    title: 'Direct Messages',
    type: 'messages',
    results: [],
    resultsSet: new Set(),
    moreToLoad: true,
  },
  {
    id: 'materials',
    title: 'Materials',
    type: 'materials',
    results: [],
    resultsSet: new Set(),
    moreToLoad: true,
  },
  {
    id: 'files',
    title: 'Files',
    type: 'files',
    results: [],
    resultsSet: new Set(),
    moreToLoad: true,
  },
];

const initialState = {
  open: false,
  loading: false,
  results: undefined,
  tabs,
  query: undefined,
};

const reducers = {
  [actions.OMNISEARCH_OPEN_MODAL]: openCloseModal,
  [actions.OMNISEARCH_CLOSE_MODAL]: openCloseModal,
  [actions.OMNISEARCH_FETCH_RESULTS]: fetchResults,
  [actions.OMNISEARCH_CLEAR_RESULTS]: clearResults,
};

const reducer = (state = initialState, action) => {
  if (!action) {
    return state;
  }

  const r = reducers[action.type];
  return r ? r(state, action, initialState) : state;
};

export default reducer;
