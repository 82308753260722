import * as actionTypes from '../../../constants/actions';
import {
  STARTED_STATUS,
  SUCCESS_STATUS,
  ERROR_STATUS,
} from '../../../constants/actionsStatus';
import { newError } from '../../errors';
import { getClassRoomPost } from '../../../utils/data';
import deletePostApi from '../../../api/posts/delete';
import { unsetPostImportant } from '../../classRoom/importantPosts';
import showToast from '../../../toasts/operations/showToast.operation';
import { NO_POST_ID_MESSAGE } from './constants';

const deleteSpacePostStart = (id) => (dispatch) => {
  dispatch({
    type: actionTypes.CLASSROOM_DELETE_POST,
    status: STARTED_STATUS,
    id,
  });
};

const getDeletePostSuccessToast = () => ({
  emoji: '🗑️',
  message: 'Post deleted',
});

const deleteSpacePostSuccess = (post) => (dispatch) => {
  dispatch({
    type: actionTypes.CLASSROOM_DELETE_POST,
    status: SUCCESS_STATUS,
    post,
  });
  dispatch(showToast(getDeletePostSuccessToast()));
};

const deleteSpacePostError =
  ({ id, error }) =>
  (dispatch) => {
    dispatch(newError(error, 'deleteSpacePost'));
    dispatch({
      type: actionTypes.CLASSROOM_DELETE_POST,
      status: ERROR_STATUS,
      error,
      id,
    });
  };

export const deleteSpacePost = (objectId) => async (dispatch, getState) => {
  if (!objectId) {
    const err = { message: NO_POST_ID_MESSAGE };
    return deleteSpacePostError({ id: objectId, error: err })(dispatch);
  }

  const post = getClassRoomPost(getState(), objectId);
  post.className = 'UBClassRoomPost';

  deleteSpacePostStart(objectId)(dispatch);

  if (post.important) {
    await dispatch(unsetPostImportant(post.objectId));
  }

  try {
    await deletePostApi(objectId);
    return deleteSpacePostSuccess(post)(dispatch);
  } catch (error) {
    return deleteSpacePostError({ error, id: objectId })(dispatch);
  }
};
