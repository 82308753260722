import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import TimeAgo from 'react-timeago';
import { Tooltip } from '@ublend-npm/aulaui-next';
import { ANNOUNCEMENTS } from '@core/constants/postFeedType';
import Avatar from '../../containers/User/Avatar/Avatar';
import {
  AvatarContainer,
  Container,
  ContentLabel,
  Divider,
  EducatorBadge,
  FooterLabel,
  HeaderLabel,
  InfoContainer,
  NewBadge,
  NewBadgeButton,
  NewBadgeContainer,
  NotificationContainer,
  SpaceSpan,
} from './NotificationCellStyle';
import push from '../../../utils/routing';
import { EntityTypesByCollections } from '../../../../core/utils/commentsEntityTypes';
import getCommentEntityUrl from '../../../../core/utils/getCommentEntityUrl';
import { ENTER, SPACE } from '../../../utils/keyCodes';

const timeFormatter = (value, unit, suffix) => {
  if (value <= 60 && unit === 'second') {
    return 'Just Now';
  }
  let unitPluralized = unit;
  if (value !== 1) {
    unitPluralized += 's';
  }
  return `${value} ${unitPluralized} ${suffix}`;
};

const NotificationCell = ({ notification, onClick, culprit, time, unread }) => {
  const [hover, setHover] = useState(false);
  const containerRef = useRef(null);

  const handleNotificationReadClick = (event) => {
    const { itemId } = notification;
    event.stopPropagation();
    onClick(itemId);
  };

  const handleNotificationReadKeyPress = (event) => {
    if (event.charCode === ENTER || event.charCode === SPACE) {
      containerRef.current.focus();
      handleNotificationReadClick(event);
    }
  };

  const handleNotificationClick = () => {
    const { action, itemId, parentItemId, spaceId, parentItemType } =
      notification;
    onClick(itemId);

    switch (action) {
      case 'newPost': {
        push(`dashboard/${spaceId}/community/post/${itemId}`);
        break;
      }
      case 'newCommentOnCommentedPost':
      case 'newComment':
      case 'newCommentReply': {
        push(
          getCommentEntityUrl({
            spaceId,
            entityType: EntityTypesByCollections[parentItemType],
            entityId: parentItemId,
          })
        );
        break;
      }
      default:
        break;
    }
  };

  const handleNotificationKeyPress = (event) => {
    if (event.charCode === ENTER || event.charCode === SPACE) {
      handleNotificationClick();
    }
  };

  const renderDescription = (action, data) => {
    switch (action) {
      case 'newPost':
        return `created a${
          data.feedType === ANNOUNCEMENTS ? 'n announcement' : ''
        } post:`;
      case 'newComment':
        return `commented on your post`;
      case 'newCommentReply':
        return `replied to your comment`;
      case 'newCommentOnCommentedPost':
        return `commented on ${data.author}'s post`;
      default:
        return '';
    }
  };

  return (
    <>
      <Container
        unread={unread}
        hover={hover}
        onClick={handleNotificationClick}
        onMouseOver={() => setHover(true)}
        onFocus={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        onBlur={() => setHover(false)}
      >
        <NotificationContainer
          role="button"
          onKeyPress={handleNotificationKeyPress}
          tabIndex={0}
          innerRef={containerRef}
        >
          <AvatarContainer>
            <Avatar
              user={culprit}
              isInstructor={culprit.isInstructor}
              size={45}
            />
          </AvatarContainer>
          <InfoContainer>
            <HeaderLabel>
              <span>
                <strong>
                  {notification.actionData.culprit}
                  {culprit.isInstructor ? (
                    <EducatorBadge> (educator)</EducatorBadge>
                  ) : null}{' '}
                </strong>
                {renderDescription(
                  notification.action,
                  notification.actionData
                )}
              </span>
            </HeaderLabel>
            <ContentLabel>
              <span>{notification.content.replace(/<[^>]+>/g, ' ')}</span>
            </ContentLabel>
            <FooterLabel>
              <TimeAgo date={time} minPeriod={60} formatter={timeFormatter} />
              {notification.actionData.space ? (
                <>
                  <span> in </span>
                  <SpaceSpan>{notification.actionData.space}</SpaceSpan>
                </>
              ) : null}
            </FooterLabel>
          </InfoContainer>
        </NotificationContainer>
        <NewBadgeContainer>
          {unread ? (
            <Tooltip title="Mark as read" position="top">
              <NewBadgeButton
                aria-label="Mark notification as read"
                onClick={handleNotificationReadClick}
                onKeyPress={handleNotificationReadKeyPress}
              >
                <NewBadge />
              </NewBadgeButton>
            </Tooltip>
          ) : null}
        </NewBadgeContainer>
      </Container>
      <Divider />
    </>
  );
};

NotificationCell.propTypes = {
  time: PropTypes.string.isRequired,
  unread: PropTypes.bool.isRequired,
  culprit: PropTypes.shape({
    isInstructor: PropTypes.bool,
  }).isRequired,
  notification: PropTypes.shape({
    action: PropTypes.string,
    itemId: PropTypes.string,
    parentItemId: PropTypes.string,
    spaceId: PropTypes.string,
    parentItemType: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
    actionData: PropTypes.shape({
      culprit: PropTypes.string,
      space: PropTypes.string,
    }),
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default NotificationCell;
