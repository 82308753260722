import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const ConversationTitle = styled('p')`
  flex: 1;
  color: ${({ bold }) => (bold ? theme.color.blackAlpha(0.87) : 'black')};
  font-weight: ${({ bold }) =>
    bold ? theme.font.weight.semiBold : theme.font.weight.regular};
  font-size: ${theme.font.size.copy};
  line-height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0 ${theme.spacing.xSmall} 0 0;
`;

export const TimeIndicator = styled('p')`
  margin: 0;
  color: ${({ bold }) => (bold ? theme.color.orange : 'black')};
  font-weight: ${({ bold }) =>
    bold ? theme.font.weight.bold : theme.font.weight.regular};
  font-size: ${theme.font.size.small};
  line-height: 1;
`;
