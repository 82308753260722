const deleteGroupSetReducer = (state, action) => {
  if (action.status !== 'success') {
    return state;
  }

  const {
    payload: { groupSetId },
  } = action;

  if (!state.sets[groupSetId]) {
    return state;
  }

  const updatedSets = { ...state.sets };
  delete updatedSets[groupSetId];

  const updatedGroups = { ...state.groups };
  const groupsToDelete = new Set(state.sets[groupSetId].groups);
  groupsToDelete.forEach((group) => delete updatedGroups[group]);

  return {
    ...state,
    sets: updatedSets,
    groups: updatedGroups,
  };
};

export default deleteGroupSetReducer;
