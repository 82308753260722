import { deleteSubmission as deleteSubmissionApi } from '../api';
import { DELETE_SUBMISSION } from '../actions';
import { accessToken as getAccessToken } from '../../selectors/user';
import toastOperation from '../../toasts/operations';

import { analyticsTrackEvent } from '../../utils/analytics';
import * as analyticEvents from '../../constants/analytics';
import fetchGroupSets from '../../groups/operations/fetchGroupSets.operation';

const deleteSubmissionStarted = (payload) => (dispatch) =>
  dispatch({
    type: DELETE_SUBMISSION,
    status: 'started',
    payload,
  });

const deleteSubmissionSuccess = (payload) => (dispatch) =>
  dispatch({
    type: DELETE_SUBMISSION,
    status: 'success',
    payload,
  });

const deleteSubmissionError = (payload) => (dispatch) =>
  dispatch({
    type: DELETE_SUBMISSION,
    status: 'error',
    payload,
  });

const deleteSubmission =
  ({ assignmentExternalId, assignmentId, studentId, groupId, spaceId }) =>
  async (dispatch, getState) => {
    const payload = {
      studentId,
      assignmentId,
      groupId,
      assignmentExternalId,
    };
    dispatch(deleteSubmissionStarted(payload));

    const state = getState();
    const currentAccessToken = getAccessToken(state, spaceId);

    if (!currentAccessToken) {
      return dispatch(deleteSubmissionError(payload));
    }

    try {
      await deleteSubmissionApi({
        accessToken: currentAccessToken,
        ...payload,
      });

      analyticsTrackEvent(analyticEvents.DELETE_SUBMISSION, {
        ...payload,
        activeClassroom: spaceId,
      });

      dispatch(deleteSubmissionSuccess(payload));

      // fetch group sets to update the groups lock status
      return dispatch(fetchGroupSets(spaceId));
    } catch (e) {
      dispatch(
        toastOperation.showToast({
          emoji: '🤔',
          message: 'There was an error while deleting the submission.',
        })
      );
      return dispatch(deleteSubmissionError(payload));
    }
  };

export default deleteSubmission;
