import sortByEndDate from './utils/sortByEndDate';
import filterAssignment from './utils/filterAssignment';

const editAssignmentReducer = (state, action) => {
  if (action.status !== 'success') return state;

  const {
    payload: { assignment },
  } = action;
  const remainingAssignments = state.assignments.filter(
    filterAssignment(assignment)
  );

  // in case of delete, return an array with removed object
  if (assignment.status === 'trash') {
    return remainingAssignments;
  }

  // in case of update, push back object to array, then sort by due date
  remainingAssignments.push(assignment);
  remainingAssignments.sort(sortByEndDate);

  return {
    ...state,
    assignments: remainingAssignments,
  };
};

export default editAssignmentReducer;
