import { LTI_FETCH_PROVIDERS } from '@core/constants/actions';
import { PayloadOnSuccessAction, DuckReducer } from '@core/types/redux';
import { LtiState } from '@core/types/state';
import { LtiProvider } from '@ublend-npm/aula-schema';

export type FetchProvidersAction = PayloadOnSuccessAction<
  typeof LTI_FETCH_PROVIDERS,
  {
    providers: LtiProvider[];
  }
>;

const fetchProviders: DuckReducer<LtiState, FetchProvidersAction> = (
  state,
  action
) => {
  switch (action.status) {
    case 'started':
      return {
        ...state,
        isErrored: false,
        status: 'loading',
      };
    case 'success':
      return {
        ...state,
        providers: action.payload.providers,
        isErrored: false,
        status: 'success',
      };
    case 'error':
      return {
        ...state,
        isErrored: true,
        status: 'error',
      };
    default:
      return state;
  }
};

export default fetchProviders;
