import { newError } from '../errors';
import * as types from '../../constants/actions';
import createTagApi from '../../api/tags/createTag';
import getSpaceTagsApi from '../../api/tags/getSpaceTags';

export const fetchTagsSuccess = (payload, dispatch) => {
  dispatch({
    type: types.FETCH_TAGS,
    status: 'success',
    payload,
  });
};

// Create tag
const createTagStarted = (dispatch) => {
  dispatch({
    type: types.CREATE_TAG,
    status: 'started',
  });
};

export const createTagSuccess = (tag, dispatch) => {
  dispatch({
    type: types.CREATE_TAG,
    status: 'success',
    tag,
  });
};

const createTagError = (error, dispatch) => {
  dispatch(newError(error, 'createTag'));
  dispatch({
    type: types.CREATE_TAG,
    error,
  });
};

export const createTag = (name, spaceId) => async (dispatch) => {
  createTagStarted(dispatch);

  try {
    const tag = await createTagApi({ name, spaceId });
    createTagSuccess(tag, dispatch);
    return tag;
  } catch (error) {
    createTagError(error, dispatch);
    throw error;
  }
};

export const fetchTags = (spaceId) => async (dispatch) => {
  if (!spaceId) {
    throw new Error('No space id');
  }

  try {
    const tags = await getSpaceTagsApi({ spaceId });
    fetchTagsSuccess({ tags, spaceId }, dispatch);
  } catch (e) {
    throw e;
  }
};
