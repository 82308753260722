export const createCookie = (name, value, expiresTime, path, domain) => {
  let expires = expiresTime;
  let cookie = `${name}=${escape(value)};`;

  if (expires) {
    // If it's a date
    if (expires instanceof Date) {
      // If it isn't a valid date
      if (Number.isNaN(expires.getTime())) {
        expires = new Date();
      }
    } else {
      expires = new Date(
        new Date().getTime() + parseInt(expires, 10) * 1000 * 60 * 60 * 24
      );
    }
    cookie += `expires=${expires.toGMTString()};`;
  }

  if (path) {
    cookie += `path=${path};`;
  }
  if (domain) {
    cookie += `domain=${domain};`;
  }
  document.cookie = cookie;
};

export const getCookie = (name) => {
  const regexp = new RegExp(`(?:^${name}|;\\s*${name})=(.*?)(?:;|$)`, 'g');
  const result = regexp.exec(document.cookie);
  return result === null ? null : result[1];
};
