import { connectionOn, connectionOff } from '../../core/actions/connection';

const registerWindowListeners = (dispatch) => {
  window.addEventListener('online', () => {
    dispatch(connectionOn());
  });
  window.addEventListener('offline', () => {
    dispatch(connectionOff());
  });
};

export default registerWindowListeners;
