import React from 'react';
import styled from 'react-emotion';

import { theme } from '@ublend-npm/aulaui-next';
import BasePopover from '@material-ui/core/Popover';
import BaseOutlinedInput from '@material-ui/core/OutlinedInput';

export const Popover = styled((props) => (
  <BasePopover {...props} classes={{ paper: 'EmojiPicker' }} />
))`
  z-index: 1600;

  div.EmojiPicker {
    width: 370px;
    height: 420px;
    background-color: ${theme.color.white};
    border-radius: 4px;
  }
`;

export const Container = styled('div')`
  padding-top: 16px;
  display: flex;
  flex-direction: column;
`;

export const Title = styled('div')`
  margin-bottom: 12px;
  padding-left: 16px;
  padding-right: 12px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: ${theme.color.black};
`;

export const SearchContainer = styled('div')`
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const Content = styled('div')`
  padding-left: 16px;
  padding-right: 12px;
  display: flex;
  flex-direction: column;
`;

export const OutlinedInput = styled((props) => (
  <BaseOutlinedInput
    {...props}
    inputProps={{ 'aria-label': props.label }}
    classes={{
      input: 'EmojiPickerInput',
      focused: 'EmojiPickerFocused',
      notchedOutline: 'EmojiPickerOutline',
    }}
  />
))`
  display: flex;

  input.EmojiPickerInput {
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: ${theme.font.family.workSans};
    font-size: 14px;
    &::-webkit-input-placeholder {
      opacity: 0.87;
    }
    &::-moz-placeholder {
      opacity: 0.87;
    }
    &:-moz-placeholder {
      opacity: 0.87;
    }
    &:-ms-input-placeholder {
      opacity: 0.87;
    }
  }
  &.EmojiPickerFocused > fieldset {
    border-color: purple !important;
  }
`;

export const ShortcutContainer = styled('div')`
  margin-bottom: 28px;
`;

export const CategoryContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const CategoryTitleRow = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  color: ${theme.color.grey11};
`;

export const CategoryIconContainer = styled('div')`
  display: flex;
  margin-right: 4px;
`;

export const CategoryTitle = styled('span')`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
`;

export const Separator = styled('div')`
  border-bottom: solid 1px ${theme.color.grey3};
`;

export const EmojiListContainer = styled('span')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const NoResultsContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.color.grey11};
  font-size: 14px;
  padding-top: 40px;
`;
