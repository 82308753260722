import { GENERAL } from '@app/constants/endpoints';

const redirectToGeneral = ({ returnAfterLogin = false }) => {
  const redirect = encodeURIComponent(window.location.href);

  const loginUrl = returnAfterLogin
    ? `${GENERAL}/?redirect=${redirect}`
    : `${GENERAL}/#/select`;

  return window.location.replace(loginUrl);
};

export default redirectToGeneral;
