import axios from 'axios';
import { LOGOUT } from '../../constants/endpoints';

const logoutApiCall = ({ email, deviceId }) => {
  return axios.post(
    LOGOUT(),
    {
      email,
      deviceId,
    },
    { withCredentials: true }
  );
};

export default logoutApiCall;
