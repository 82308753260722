import { connect } from 'react-redux';
import App from './App';
import { onWindowFocusChanged } from '../../actions/focus';
import { appcuesUrlChange } from '../../../core/utils/appcues';
import { shouldShowInitialDataLoadingOverlay } from '../../../core/selectors/classroom';

import {
  isFetchingSections,
  isHidingMaterial,
  isSavingSection,
  isRevertingSection,
  isImportingMaterials,
} from '../../../core/materials/selectors';

import { isEditingAssignment } from '../../../core/assignments/selectors';

const shouldDisplayLoader = (state) =>
  isFetchingSections(state) ||
  isHidingMaterial(state) ||
  isSavingSection(state) ||
  isRevertingSection(state) ||
  isImportingMaterials(state) ||
  isEditingAssignment(state);

const mapStateToProps = (state) => ({
  connection: state.connection,
  displayInlineLoader: shouldDisplayLoader(state),
  dataLoadingOverlayOpened: shouldShowInitialDataLoadingOverlay(state),
  appcuesUrlChange,
});

const mapDispatchToProps = {
  onWindowFocusChanged: (focused) => onWindowFocusChanged(focused),
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
