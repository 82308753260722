const filterByCategory = (spaces, category, subCategory) => {
  const matches = (categories) => {
    if (!categories.length) {
      return false;
    }

    if (categories.length === 1 || !subCategory) {
      return categories[0].label === category.label;
    }

    return (
      categories[0].label === category.label &&
      categories[1].label === subCategory.label
    );
  };

  return spaces.reduce(
    (acc, space) => (matches(space.categories) ? [...acc, space] : acc),
    []
  );
};

export default filterByCategory;
