import { Folder as ApiFolder } from '@ublend-npm/aula-schema/types/materials';
import { Folder, LocalFolder } from '../types';

/** A folder that has not been saved in
 * the Redux State or sent to the API yet. */
type UnsavedFolder = Omit<ApiFolder, 'createdAt'>;

function toFolder(data: UnsavedFolder): LocalFolder;
function toFolder(data: ApiFolder): Folder;

function toFolder(data: ApiFolder | UnsavedFolder): Folder | LocalFolder {
  return {
    ...data,
    isFolder: true,
    hidden: !!data.hidden,
    educatorOnly: !!data.educatorOnly,
  };
}

export default toFolder;
