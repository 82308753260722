import React from 'react';
import BaseSwitch from '@material-ui/core/Switch';
import { theme } from '@ublend-npm/aulaui-next';

import { withStyles } from '@material-ui/core/styles';

const Switch = withStyles({
  colorSwitchBase: {
    '&$colorChecked': {
      color: `${theme.color.purple3}`,
      '& + $colorBar': {
        backgroundColor: `${theme.color.purple3}`,
      },
    },
  },
  colorChecked: {},
  colorBar: {},
})((props) => (
  <BaseSwitch
    {...props}
    classes={{
      switchBase: props.classes.colorSwitchBase,
      checked: props.classes.colorChecked,
      track: props.classes.colorBar,
    }}
  />
));

export default Switch;
