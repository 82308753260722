// Startup
export const STARTUP_FETCH_DATA = 'STARTUP_FETCH_DATA';
export const STARTUP_FETCH_FILES_COOKIE = 'STARTUP_FETCH_FILES_COOKIE';

export const LOCATION_CHANGE = '@@router/LOCATION_CHANGE';

// User
export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGOUT_DEVICE = 'USER_LOGOUT_DEVICE';
export const USER_FETCH_CURRENT = 'USER_FETCH_CURRENT';
export const USER_CHANGE_AVATAR = 'USER_CHANGE_AVATAR';
export const USER_CHANGE_STATUS = 'USER_CHANGE_STATUS';
export const USER_FETCH_DEVICES = 'USER_FETCH_DEVICES';
export const UPDATE_UNIVERSAL_LINK_TOKEN = 'UPDATE_UNIVERSAL_LINK_TOKEN';
export const SET_NOTIFICATION_SETTINGS_VISIBLE_TAB =
  'SET_NOTIFICATION_SETTINGS_VISIBLE_TAB';

export const FETCH_USERS = 'FETCH_USERS';

// Nav bar
export const NAVBAR_CHANGE_BUTTONS = 'NAVBAR_CHANGE_BUTTONS';
export const NAVBAR_CHANGE_SEARCHBAR = 'NAVBAR_CHANGE_SEARCHBAR';
export const NAVBAR_NOTIFICATIONS_BADGE = 'NAVBAR_NOTIFICATIONS_BADGE';

// Classroom
export const CLASSROOM_CHANGE_AVATAR = 'CLASSROOM_CHANGE_AVATAR';

// Feed
export const CLASSROOM_CHANGE = 'CLASSROOM_CHANGE';
export const CLASSROOM_FETCH_JOINED = 'CLASSROOM_FETCH_JOINED';
export const SPACE_FETCH_COMMON_ROOM = 'SPACE_FETCH_COMMON_ROOM';
export const SPACE_FETCH_ANNOUNCEMENTS = 'SPACE_FETCH_ANNOUNCEMENTS';
export const CLASSROOM_FETCH_POST = 'CLASSROOM_FETCH_POST';
export const CLASSROOM_CREATE_POST = 'CLASSROOM_CREATE_POST';
export const CLASSROOM_EDIT_POST = 'CLASSROOM_EDIT_POST';
export const CLASSROOM_DELETE_POST = 'CLASSROOM_DELETE_POST';
export const SET_POST_IMPORTANT = 'SET_POST_IMPORTANT';
export const UNSET_POST_IMPORTANT = 'UNSET_POST_IMPORTANT';
export const FETCH_IMPORTANT_POSTS = 'FETCH_IMPORTANT_POSTS';
export const FETCH_SCHEDULED_COMMON_ROOM = 'FETCH_SCHEDULED_COMMON_ROOM';
export const FETCH_SCHEDULED_ANNOUNCEMENT = 'FETCH_SCHEDULED_ANNOUNCEMENT';
export const CLASSROOM_OPEN_CLOSE_SCHEDULE_POST =
  'CLASSROOM_OPEN_CLOSE_SCHEDULE_POST';
export const CLASSROOM_UPDATE_ONLINE_USERS = 'CLASSROOM_UPDATE_ONLINE_USERS';
export const FEED_FILTER_TAG = 'FEED_FILTER_TAG';
export const FILTERING_BY_TAGS = 'FILTERING_BY_TAGS';
export const SPACE_SELECT_FEED_TYPE = 'SPACE_SELECT_FEED_TYPE';
export const SPACE_SELECTOR_TOGGLE_STAR = 'SPACE_SELECTOR_TOGGLE_STAR';

// Notifications
export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
export const NOTIFICATIONS_SET_READ = 'NOTIFICATION_SET_READ';
export const CLEAR_NOTIFICATIONS_ERROR = 'CLEAR_NOTIFICATIONS_ERROR';

export const NOTIFICATION_SET_READ_TYPE = 'NOTIFICATION_SET_READ_TYPE';

// Inbox
export const CLASSROOM_FETCH_INBOX_GROUPS = 'CLASSROOM_FETCH_INBOX_GROUPS';
export const CLASSROOM_FETCH_INBOX_GROUP_PARTICIPANTS =
  'CLASSROOM_FETCH_INBOX_GROUP_PARTICIPANTS';
export const CLASSROOM_CHANGE_INBOX_GROUP = 'CLASSROOM_CHANGE_INBOX_GROUP';
export const CLASSROOM_CHANGE_INBOX_GROUP_NAME =
  'CLASSROOM_CHANGE_INBOX_GROUP_NAME';
export const CLASSROOM_FETCH_INBOX_MESSAGES = 'CLASSROOM_FETCH_INBOX_MESSAGES';
export const CLASSROOM_FETCH_INBOX_MESSAGE = 'CLASSROOM_FETCH_INBOX_MESSAGE';
export const CLASSROOM_RESET_HASFETCHEDALLMESSAGES =
  'CLASSROOM_RESET_HASFETCHEDALLMESSAGES';
export const CLASSROOM_SEND_INBOX_MESSAGE = 'CLASSROOM_SEND_INBOX_MESSAGE';
export const CLASSROOM_FLAG_INBOX_GROUP = 'CLASSROOM_FLAG_INBOX_GROUP';
export const CLASSROOM_CREATE_INBOX_GROUP = 'CLASSROOM_CREATE_INBOX_GROUP';
export const INBOX_ADD_USERS_TO_GROUP = 'INBOX_ADD_USERS_TO_GROUP';
export const INBOX_REMOVE_USER_FROM_GROUP = 'INBOX_REMOVE_USER_FROM_GROUP';
export const INBOX_DELETE_MESSAGE = 'INBOX_DELETE_MESSAGE';
export const INBOX_LEAVE_GROUP = 'INBOX_LEAVE_GROUP';
export const CHANGE_INBOX_GROUP_LAST_MESSAGE =
  'CHANGE_INBOX_GROUP_LAST_MESSAGE';

// Comments
export const COMMENTS_FETCH = 'COMMENTS_FETCH';
export const GENERIC_COMMENTS_FETCH = 'GENERIC_COMMENTS_FETCH';
export const COMMENTS_CREATE = 'COMMENTS_CREATE';
export const COMMENTS_EDIT = 'COMMENTS_EDIT';
export const COMMENTS_DELETE = 'COMMENTS_DELETE';

// Class materials
export const CLASSROOM_MODULE_CREATE_SECTION =
  'CLASSROOM_MODULE_CREATE_SECTION';
export const CLASSROOM_MODULE_DELETE_SECTION =
  'CLASSROOM_MODULE_DELETE_SECTION';
export const CLASSROOM_MODULE_GET_SECTION = 'CLASSROOM_MODULE_GET_SECTION';
export const CLASSROOM_MODULE_FETCH_SECTIONS =
  'CLASSROOM_MODULE_FETCH_SECTIONS';
export const CLASSROOM_MODULE_IMPORT_SECTIONS =
  'CLASSROOM_MODULE_IMPORT_SECTIONS';
export const CLASSROOM_MODULE_LIVE_DELETED_MATERIAL =
  'CLASSROOM_MODULE_LIVE_DELETED_MATERIAL';
export const CLASSROOM_MODULE_LIVE_UPDATED_MATERIAL =
  'CLASSROOM_MODULE_LIVE_UPDATED_MATERIAL';
export const CLASSROOM_MODULE_SAVE_CURRENT_SECTION =
  'CLASSROOM_MODULE_SAVE_CURRENT_SECTION';
export const CLASSROOM_MODULE_SCHEDULE_CURRENT_SECTION =
  'CLASSROOM_MODULE_SCHEDULE_CURRENT_SECTION';
export const CLASSROOM_MODULE_REORDER_MATERIAL =
  'CLASSROOM_MODULE_REORDER_MATERIAL';

// ClassRoomUser
export const CLASSROOMUSER_CREATE = 'CLASSROOMUSER_CREATE';

// Participants
export const CLASSROOM_FETCH_PARTICIPANTS = 'CLASSROOM_FETCH_PARTICIPANTS';
export const CLASSROOM_FETCH_PARTICIPANTS_HISTORY =
  'CLASSROOM_FETCH_PARTICIPANTS_HISTORY';
export const CLASSROOM_SEARCH_PARTICIPANTS = 'CLASSROOM_SEARCH_PARTICIPANTS';
export const CLASSROOM_REMOVE_PARTICIPANT = 'CLASSROOM_REMOVE_PARTICIPANT';
export const CLASSROOM_PROMOTE_PARTICIPANT = 'CLASSROOM_PROMOTE_PARTICIPANT';
export const CLASSROOM_UNPROMOTE_PARTICIPANT =
  'CLASSROOM_UNPROMOTE_PARTICIPANT';
export const CLASSROOM_ADD_PARTICIPANTS = 'CLASSROOM_ADD_PARTICIPANTS';

// Error
export const ERROR_NEW = 'ERROR_NEW';
export const ERROR_CLEAN = 'ERROR_CLEAN';

// Search
export const SEARCH_INIT_INDEX = 'SEARCH_INIT_INDEX';
export const SEARCH_INDEX = 'SEARCH_INDEX';
export const SEARCH_USER = 'SEARCH_USER';
export const SEARCH_INDEX_CLEAR = 'SEARCH_INDEX_CLEAR';

// OmniSearch
export const OMNISEARCH_OPEN_MODAL = 'OMNISEARCH_OPEN_MODAL';
export const OMNISEARCH_CLOSE_MODAL = 'OMNISEARCH_CLOSE_MODAL';
export const OMNISEARCH_CLEAR_RESULTS = 'OMNISEARCH_CLEAR_RESULTS';
export const OMNISEARCH_FETCH_RESULTS = 'OMNISEARCH_FETCH_RESULTS';

// Analytics
export const ANALYTICS_TRACK_EVENT = 'ANALYTICS_TRACK_EVENT';
export const ANALYTICS_TRACK_USER = 'ANALYTICS_TRACK_USER';

// Roles
export const CLASSROOM_CREATE_ROLES = 'CLASSROOM_CREATE_ROLES';

// Tags
export const FETCH_TAGS_POST = 'FETCH_TAGS_POST';
export const CREATE_TAG_POST = 'CREATE_TAG_POST';
export const DELETE_TAG_POST = 'DELETE_TAG_POST';
export const FETCH_TAGS = 'FETCH_TAGS';
export const CREATE_TAG = 'CREATE_TAG';
export const GET_SPACE_TAGS = 'GET_SPACE_TAGS';
export const TAG_FILTER_CLEARED = 'TAG_FILTER_CLEARED';

// UI
export const SHOW_IMPORTANT_POSTS = 'SHOW_IMPORTANT_POSTS';

// EMOJIS
export const FETCH_EMOJIS = 'FETCH_EMOJIS';

// INSTITUTIONS
export const FETCH_INSTITUTION = 'FETCH_INSTITUTION';
export const CHECK_INSTITUTION = 'CHECK_INSTITUTION';

// LOCATION
export const FETCH_LOCATION = 'FETCH_LOCATION';

// SOCKET
export const LIVE_CONNECT_START = 'LIVE_CONNECT_START';
export const LIVE_CONNECT_ON = 'LIVE_CONNECT_ON';
export const LIVE_CONNECT_OFF = 'LIVE_CONNECT_FFF';
export const LIVE_CLIENTS_REFRESHED = 'LIVE_CLIENTS_REFRESHED';
export const LIVE_CLIENT_CONNECTED = 'LIVE_CLIENT_CONNECTED';
export const LIVE_CLIENT_DISCONNECTED = 'LIVE_CLIENT_DISCONNECTED';
export const LIVE_JOIN_CONV = 'LIVE_JOIN_CONV';
export const LIVE_JOIN_CLASS = 'LIVE_JOIN_CLASS';

// CONNECTION
export const CONNECTION_ON = 'CONNECTION_ON';
export const CONNECTION_OFF = 'CONNECTION_OFF';

// LTI
export const LTI_FETCH_PROVIDERS = 'LTI_FETCH_PROVIDERS';

// ACCESS_TOKEN
export const FETCH_ACCESS_TOKEN_STARTED = 'FETCH_ACCESS_TOKEN_STARTED';
export const FETCH_ACCESS_TOKEN_SUCCESS = 'FETCH_ACCESS_TOKEN_SUCCESS';
export const FETCH_ACCESS_TOKEN_ERROR = 'FETCH_ACCESS_TOKEN_ERROR';

// Feature Flags
export const SPACE_SET_FEATURES = 'SPACE_SET_FEATURES';
