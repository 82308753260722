import { AulaState } from '@core/types/state';
import * as selectors from '../selectors';
import { MaterialFormat } from './v2Importer';

const exporter = (state: AulaState): MaterialFormat => ({
  version: 'v2',
  material: [
    {
      ...selectors.currentSection(state),
      content: selectors.selectedContent(state)!,
    },
  ],
});

export default exporter;
