import { getCurrentSpaceId } from '@core/selectors/space';
import {
  getAssignment,
  getExtension,
  getSubmissionFilterGroup,
} from '../selectors';
import {
  currentUser as getCurrentUser,
  accessToken as getAccessToken,
  currentUserId as getCurrentUserId,
} from '../../selectors/user';
import { isInstructorInSpace } from '../../selectors/classroom';
import fetchAssignments from './fetchAssignments.operation';
import fetchSubmissions from './fetchSubmissions.operation';
import showToast from '../../toasts/operations/showToast.operation';

const onSubmissionUpdated =
  ({ assignmentId, grade }) =>
  (dispatch, getState) => {
    const state = getState();
    const user = getCurrentUser(state);
    const assignment =
      getAssignment(state, assignmentId) || getExtension(state, assignmentId);
    const spaceId = getCurrentSpaceId(state);
    const assignmentSpaceId = assignment
      ? assignment.space || (assignment.classRoom || {}).objectId
      : null;

    if (assignmentSpaceId !== spaceId) {
      return;
    }

    const accessToken = getAccessToken(state, spaceId);
    const userId = getCurrentUserId(state);
    const isEducator = isInstructorInSpace(state)(userId, spaceId);

    if (isEducator) {
      const group = getSubmissionFilterGroup(state, assignmentId);
      dispatch(fetchSubmissions({ accessToken, assignmentId, group }));

      if (grade) {
        dispatch(
          showToast({
            emoji: '✅',
            message: 'Grade updated',
          })
        );
      }
    }
    fetchAssignments(
      dispatch,
      getState
    )({
      user,
      spaceId,
      accessToken,
    });
  };

export default onSubmissionUpdated;
