import { INBOX_SET_CONVERSATION_CLOSED } from '../actions';

const onConversationClosed = (data) => (dispatch) => {
  const { conversation: conversationId } = data;

  dispatch({
    type: INBOX_SET_CONVERSATION_CLOSED,
    status: 'started', // in optimisic update approach, the success state is provided at 'started'
    payload: {
      conversationId,
    },
  });
};

export default onConversationClosed;
