import omit from 'object.omit';
import { NOTIFICATION_SETTINGS_SAVE } from '../actionsTypes';
import { patchSettings } from '../api';
import * as selectors from '../selectors';

const saveNotificationSettingsStart = (dispatch, payload) => {
  dispatch({
    type: NOTIFICATION_SETTINGS_SAVE,
    status: 'started',
    payload,
  });
};

const saveNotificationSettingsSuccess = (dispatch, payload) => {
  dispatch({
    type: NOTIFICATION_SETTINGS_SAVE,
    status: 'success',
    payload,
  });
};

const saveNotificationSettingsError = (dispatch, payload) => {
  dispatch({
    type: NOTIFICATION_SETTINGS_SAVE,
    status: 'error',
    payload,
  });
};

const saveNotificationSettings =
  (settingsPatch) => async (dispatch, getState) => {
    const oldSettings = selectors.notificationSettings(getState());
    const optimisticallyUpdatedSettings = {
      ...oldSettings,
      ...settingsPatch,
    };
    saveNotificationSettingsStart(dispatch, {
      notificationSettings: optimisticallyUpdatedSettings,
    });

    try {
      const updatedSettings = await patchSettings(settingsPatch);
      const payload = { notificationSettings: updatedSettings };
      saveNotificationSettingsSuccess(dispatch, payload);
    } catch (error) {
      const payload = { error, oldSettings };
      saveNotificationSettingsError(dispatch, payload);
    }
  };

export const toggleMuteConversation =
  (conversationId) => async (dispatch, getState) => {
    const state = getState();
    const muted = selectors.isConversationMuted(state, conversationId);
    const mutedConversations = selectors.mutedConversations(state);
    const updatedMutedConversations = muted
      ? omit(mutedConversations, conversationId)
      : { ...mutedConversations, [conversationId]: true };
    dispatch(
      saveNotificationSettings({
        mutedConversations: updatedMutedConversations,
      })
    );
  };

export const toggleMuteSpace = (spaceId) => async (dispatch, getState) => {
  const state = getState();
  const muted = selectors.isSpaceMuted(state, spaceId);
  const mutedSpaces = selectors.mutedSpaces(state);
  const updatedMutedSpaces = muted
    ? omit(mutedSpaces, spaceId)
    : { ...mutedSpaces, [spaceId]: true };
  dispatch(
    saveNotificationSettings({
      mutedSpaces: updatedMutedSpaces,
    })
  );
};

export default saveNotificationSettings;
