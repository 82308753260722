import React from 'react';
import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';
import BaseBadge from '@material-ui/core/Badge';

const badgeSizeTable = {
  small: 10,
  medium: 16,
  large: 20,
};

const fontSizeTable = {
  small: '12',
  medium: '12',
  large: '14',
};

// eslint-disable-next-line import/prefer-default-export
export const Badge = styled(
  React.forwardRef((props, ref) => (
    <BaseBadge ref={ref} {...props} classes={{ badge: 'AulaBadge' }} />
  )),
  { shouldForwardProp: (prop) => prop !== 'size' }
)`
  & span.AulaBadge {
    top: 9px;
    right: 9px;
    color: ${theme.color.white};
    background-color: ${theme.color.error};
    width: ${({ size }) => badgeSizeTable[size]}px;
    height: ${({ size }) => badgeSizeTable[size]}px;
    min-width: 0px;
    min-height: 0px;
    font-size: ${({ size }) => fontSizeTable[size]}px;
  }
`;
