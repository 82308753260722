import { NOTIFICATIONS } from '../../constants/endpoints';
import request from '../../api/request';

const setNotificationsReadApi = async (notificationIds) => {
  await request({
    data: {
      notificationIds,
    },
    method: 'patch',
    url: NOTIFICATIONS(),
  });
};

export default setNotificationsReadApi;
