const colors = ['#7A1BBE', '#007D79', '#198038', '#0043CE', '#CF0019'];

export const generateColor = (objectId) => {
  if (!objectId) {
    return colors[0];
  }

  let sum = 0;
  for (let i = 0; i < objectId.length; i += 1) {
    sum += objectId.charCodeAt(i);
  }
  return colors[sum % colors.length];
};
