import { DeleteSectionAction } from '../sharedActions';
import { MaterialAppReducer } from './state';

const deleteSection: MaterialAppReducer<DeleteSectionAction> = (
  state,
  action
) => {
  switch (action.status) {
    case 'initiated':
      return {
        ...state,
        currentDeleteCandidate: action.payload.materialId,
      };
    case 'aborted':
    case 'error':
      return {
        ...state,
        isDeletingSection: false,
        currentDeleteCandidate: null,
      };
    case 'request':
      return {
        ...state,
        isDeletingSection: true,
      };
    case 'success':
      return {
        ...state,
        editing: false,
        currentDeleteCandidate: null,
        isDeletingSection: false,
      };
    default:
      return state;
  }
};

export default deleteSection;
