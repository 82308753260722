const noticicationsTypes = [
  {
    label: 'Comments',
    value: 'comments',
  },
  {
    label: 'Posts',
    value: 'posts',
  },
  {
    label: 'Messages',
    value: 'messages',
  },
];

export default noticicationsTypes;
