import { GetSectionVersionsContract } from '@ublend-npm/aula-schema/types/materials';
import { CLASSROOM_MATERIAL_SECTION_VERSIONS } from '../../constants/endpoints';
import request, { getData } from './request';
import toVersion from './toVersion';

const getVersions = async (classRoomId: string, sectionId: string) => {
  const url = CLASSROOM_MATERIAL_SECTION_VERSIONS(classRoomId, sectionId);
  const data = await request<GetSectionVersionsContract>({
    method: 'get',
    url,
  }).then(getData);
  return data.versions.map(toVersion);
};

export default getVersions;
