import axios from 'axios';
import { getHeaders } from './utils';
import { SUBMISSIONS } from '../../constants/endpoints';

const fetchSubmissionsForExtensions = ({
  accessToken,
  assignmentId,
  group,
}) => {
  return axios.get(
    `${SUBMISSIONS(assignmentId)}?page=0&displayLimit=2000&fetchCount=1${
      group ? `&group=${group}` : ''
    }`,
    {
      headers: getHeaders(accessToken),
    }
  );
};

export default fetchSubmissionsForExtensions;
