import { TAGS } from '../../constants/endpoints';
import request from '../request';
import formatTag from './formatTag';

const getSpaceTags = async ({ spaceId }) => {
  const {
    data: { tags },
  } = await request({
    method: 'get',
    url: TAGS(spaceId),
  });

  return tags.map((tag) => formatTag(tag));
};

export default getSpaceTags;
