import { FETCH_FEED } from '../../constants/endpoints';
import { COMMON_ROOM } from '../../constants/postFeedType';
import request from '../request';

const getFeed = async ({
  spaceId,
  until,
  after,
  tagId,
  important,
  scheduled,
  feedType = COMMON_ROOM,
}) => {
  const { data, status } = await request({
    method: 'get',
    url: FETCH_FEED({
      spaceId,
      until,
      after,
      tagId,
      important,
      scheduled,
      feedType,
    }),
  });
  if (status !== 200) throw new Error(`Feed fetch non 200: ${status}`);
  return data;
};

export default getFeed;
