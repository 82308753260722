import { SetSectionVersionContract } from '@ublend-npm/aula-schema/types/materials';
import { CLASSROOM_MATERIAL_SECTION_VERSION } from '../../constants/endpoints';
import request, { getData } from './request';
import toSection from './toSection';

const revertToVersion = async (
  classRoomId: string,
  sectionId: string,
  versionId: string
) => {
  const url = CLASSROOM_MATERIAL_SECTION_VERSION(classRoomId, sectionId);
  const payload = { version: versionId };
  const data = await request<SetSectionVersionContract>({
    method: 'put',
    url,
    data: payload,
  }).then(getData);
  return toSection(data);
};

export default revertToVersion;
