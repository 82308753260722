const fetchAllSubmissionsIncludingExtensionsReducer = (state, action) => {
  if (action.status !== 'success') return state;

  const {
    payload: { submissionsForAssignments },
  } = action;

  const assignmentsSubmissionsById = submissionsForAssignments.reduce(
    (acc, item) => {
      return { ...acc, ...item };
    },
    {}
  );

  return {
    ...state,
    submissions: {
      ...state.submissions,
      ...assignmentsSubmissionsById,
    },
  };
};

export default fetchAllSubmissionsIncludingExtensionsReducer;
