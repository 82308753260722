import {
  STARTED_STATUS,
  SUCCESS_STATUS,
  ERROR_STATUS,
} from '../../../constants/actionsStatus';
import { ANNOUNCEMENTS } from '../../../constants/postFeedType';

export const fetchSpacePostReducer = (state, action) => {
  switch (action.status) {
    case STARTED_STATUS:
      return {
        ...state,
        isFetchingData: true,
      };
    case SUCCESS_STATUS:
      return {
        ...state,
        posts: {
          ...state.posts,
          [action.post.objectId]: {
            ...(action.post.feedType !== ANNOUNCEMENTS && { comments: [] }),
            tagPosts: [],
            user: action.post.user,
            ...state.posts[action.post.objectId],
            ...action.post,
          },
        },
        isFetchingData: false,
      };
    case ERROR_STATUS:
      return {
        ...state,
        isFetchingData: false,
      };

    default:
      return state;
  }
};
