import { DeleteSectionContract } from '@ublend-npm/aula-schema/types/materials';
import { CLASSROOM_MATERIAL_SECTION } from '../../constants/endpoints';
import request from './request';

const deleteSection = async (classRoomId: string, sectionId: string) => {
  const url = CLASSROOM_MATERIAL_SECTION(classRoomId, sectionId);
  const response = await request<DeleteSectionContract>({
    method: 'delete',
    url,
  });
  return response.status === 204;
};

export default deleteSection;
