const reduceStarted = (state, action) => {
  const { conversationId } = action.payload;

  const conversations = {
    ...state.conversations,
    [conversationId]: {
      ...state.conversations[conversationId],
      closed: false,
    },
  };

  return {
    ...state,
    conversations,
  };
};

const reduceSuccess = (state) => {
  return state;
};

const reduceError = (state, action) => {
  const { conversationId } = action.payload;

  const conversations = {
    ...state.conversations,
    [conversationId]: {
      ...state.conversations[conversationId],
      closed: true,
    },
  };

  return {
    ...state,
    conversations,
  };
};

const reopenConversationReducer = (state, action) => {
  switch (action.status) {
    case 'started':
      return reduceStarted(state, action);
    case 'success':
      return reduceSuccess(state, action);
    case 'error':
      return reduceError(state, action);
    default:
      return state;
  }
};

export default reopenConversationReducer;
