import { CONNECTION_ON, CONNECTION_OFF } from '../../constants/actions';
import { fetchSpaceContent } from '../space';
import { consumeMessageQueue } from '../../conversations/operations/sendMessage.action';
import messageQueue from '../../conversations/operations/messageQueue';
import { getCurrentSpace } from '../../selectors/space';

export const connectionOn = () => (dispatch, getState) => {
  const state = getState();

  if (state.connection) {
    return;
  }

  const classRoom = getCurrentSpace(state);
  const reset = true;
  dispatch({
    type: CONNECTION_ON,
    status: 'on',
  });

  // there is no point in fetching space content if current space is unknown/undefined
  if (classRoom) {
    dispatch(fetchSpaceContent({ classRoom, reset }));
  }
  dispatch(consumeMessageQueue(messageQueue)());
};

export const connectionOff = () => (dispatch, getState) => {
  if (!getState().connection) {
    return;
  }

  dispatch({
    type: CONNECTION_OFF,
    status: 'off',
  });
};
