import axios from 'axios';
import { TOGGLE_HIDE_ASSIGNMENT } from '../../constants/endpoints';
import { getHeaders } from './utils';

const toggleHide = async ({
  assignmentId,
  accessToken,
}: {
  assignmentId: string;
  accessToken: string;
}) => {
  return axios.patch(
    TOGGLE_HIDE_ASSIGNMENT({ assignmentId }),
    {},
    {
      headers: getHeaders(accessToken),
    }
  );
};

export default toggleHide;
