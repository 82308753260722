import onNewNotification from './onNewNotification.operation';
import fetchNotificationSettings from './fetchNotificationSettings.operation';
import saveNotificationSettings, {
  toggleMuteConversation,
  toggleMuteSpace,
} from './saveNotificationSettings.operation';

export default ({ displayNotification }) => ({
  onNewNotification: onNewNotification({ displayNotification }),
  fetchNotificationSettings,
  saveNotificationSettings,
  toggleMuteConversation,
  toggleMuteSpace,
});
