const addGroupSet = (state, action) => {
  switch (action.status) {
    case 'success':
    case 'error':
      return {
        ...state,
        createGroupSetDialogOpen: false,
      };
    default:
      return state;
  }
};

export default addGroupSet;
