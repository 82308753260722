import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import {
  goToFeed,
  goToJourney,
  openParticipants,
} from '../../../../actions/navigation';
import * as classRoomSelectors from '../../../../../core/selectors/classroom';
import { spaceBarTab, isSettingsTabOpen } from '../../../../selectors/routing';
import SpaceBar from './SpaceBar';
import AllTabs from './AllTabs';

const shouldDisplaySpaceLoaders = createSelector(
  classRoomSelectors.classRooms,
  classRoomSelectors.currentClassRoomId,
  (classRooms, current) => !(current && classRooms[current])
);

const mapStateToProps = (state) => ({
  spaceId: classRoomSelectors.currentClassRoomId(state),
  shouldDisplaySpaceLoaders: shouldDisplaySpaceLoaders(state),
  tabs: AllTabs,
  currentTabId: spaceBarTab(state),
  shouldDisplayTabsPanel: !isSettingsTabOpen(state),
  accessingAsStaff: classRoomSelectors.accessingCurrentSpaceAsStaff(state),
});

const TabToAction = {
  community: goToFeed,
  journey: goToJourney,
  people: openParticipants,
};

const mapDispatchToProps = (dispatch) => ({
  onTabChange: (tabs, newTabIndex, spaceId) => {
    // prevents overriding spaceId of the URL with undefined
    if (!spaceId) {
      return;
    }
    const tabId = tabs[newTabIndex].id;
    const action = TabToAction[tabId];
    dispatch(action(spaceId));
  },
});

const mergeProps = (
  { tabs, spaceId, ...otherStateProps },
  { onTabChange, ...otherDispatchProps },
  ownProps
) => ({
  ...otherStateProps,
  ...otherDispatchProps,
  ...ownProps,
  tabs,
  onTabChange: (_event, newTabIndex) => onTabChange(tabs, newTabIndex, spaceId),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(SpaceBar);
