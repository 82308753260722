import { isCancel } from 'axios';

import { OMNISEARCH_FETCH_RESULTS } from '../../constants/actions';
import { omniSearch } from '../../api/search';
import { analyticsTrackEvent } from '../../utils/analytics';
import * as analyticsTypes from '../../constants/analytics';
import { currentClassRoomId } from '../../selectors/classroom';

const fetchResultsStarted = (dispatch, payload) =>
  dispatch({
    type: OMNISEARCH_FETCH_RESULTS,
    status: 'started',
    payload,
  });

const fetchResultsSuccess = (dispatch, payload) =>
  dispatch({
    type: OMNISEARCH_FETCH_RESULTS,
    status: 'success',
    payload,
  });

const fetchResultsError = (dispatch, payload) =>
  dispatch({
    type: OMNISEARCH_FETCH_RESULTS,
    status: 'error',
    payload,
  });

const fetchResults =
  ({ query, type, skip, pageSize = 30, selectedTabIndex }) =>
  async (dispatch, getState) => {
    const state = getState();
    const payload = {
      query,
      type,
      skip,
      pageSize,
      selectedTabIndex,
    };

    fetchResultsStarted(dispatch, payload);

    analyticsTrackEvent(analyticsTypes.OMNISEARCH_QUERY, {
      activeClassroom: currentClassRoomId(state),
      query,
      searchType: type,
      selectedTabIndex,
      skip,
    });

    try {
      const response = await omniSearch({ ...payload });
      fetchResultsSuccess(dispatch, {
        ...payload,
        results: response.data.hits,
      });
    } catch (error) {
      if (isCancel(error)) {
        return;
      }

      fetchResultsError(dispatch, { ...payload, error });
    }
  };

export default (dispatch) => (payload) => dispatch(fetchResults(payload));
