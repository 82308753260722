import { OMNISEARCH_OPEN_MODAL } from '../../constants/actions';
import { currentClassRoomId } from '../../selectors/classroom';
import { analyticsTrackEvent } from '../../utils/analytics';
import * as analyticsTypes from '../../constants/analytics';

const openModal = (dispatch, payload) =>
  dispatch({
    type: OMNISEARCH_OPEN_MODAL,
    payload,
  });

const openModalAction = (dispatch, getState) => () => {
  openModal(dispatch, { open: true });

  analyticsTrackEvent(analyticsTypes.OMNISEARCH_OPEN_MODAL, {
    activeClassroom: currentClassRoomId(getState()),
  });
};

export default (dispatch) => () => dispatch(openModalAction)();
