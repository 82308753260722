import { connect } from 'react-redux';
import { setNotificationsTabVisible } from '@core/actions/user';
import {
  fetchNotifications,
  setNotificationsAsRead,
} from '@core/notifications/actions';
import { navigationBarChangeButtons } from '@core/actions/navigationBar';
import {
  notifications,
  notificationTypeFilter,
} from '@core/notifications/selectors';
import changeNotificationTypeFilter from '@core/notifications/operations/changeNotificationTypeFilter.operation';
import { classRoomName } from '@core/selectors/classroom';
import NotificationsTable from './NotificationsTable';
import {
  toggleNotifications,
  toggleUserProfile,
} from '../../../actions/navigation';

const mapStateToProps = (state) => {
  if (!state.classRoom.current) {
    return {
      classRoomId: '',
      currentSpaceName: '',
      notifications: [],
      fetching: false,
    };
  }

  const classRoomId = state.classRoom.current;

  return {
    classRoomId,
    currentSpaceName: classRoomName(state),
    notifications: notifications(state),
    notificationTypeFilter: notificationTypeFilter(state),
    isFetchingNotifications: state.data.isFetchingNotifications,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getNotifications: () => {
    dispatch(fetchNotifications());
  },
  setRead: (unreadNotifications) => {
    dispatch(setNotificationsAsRead(unreadNotifications));
  },
  customNavBar: (buttons) => {
    dispatch(navigationBarChangeButtons(buttons));
  },
  onOpenNotificationsSettings: () => {
    dispatch(setNotificationsTabVisible(true));
    dispatch(toggleNotifications());
    dispatch(toggleUserProfile());
  },
  changeNotificationTypeFilter: (notificationType) => {
    dispatch(changeNotificationTypeFilter(notificationType));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsTable);
