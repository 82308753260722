import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import { Avatar } from '@ublend-npm/aulaui-next';
import accepts from 'attr-accept';

import CircularProgress from '../../CircularProgress';
import { ENTER } from '../../../../utils/keyCodes';

import {
  Content,
  inputBoxCss,
  LoaderContainer,
  Label,
} from './AvatarUploader.styled';

const noop = () => {};

const AvatarUploader = ({
  isLoading,
  name,
  url,
  onUpload,
  size,
  online,
  disabled,
  inputId,
}) => {
  const fileUploadRef = useRef(null);

  const handleAvatarClick = useCallback(
    (event) => {
      // When using an <input onChange={this.handleFileUpload} type="file"> in IconMenu and MenuItem.
      // The dialog gets opened as expected, however when selecting a file
      // and pressing the open button in the dialog, the onChange event is not fired.
      // https://github.com/mui-org/material-ui/issues/6806
      // The issue is occurring in CurrentSpace component
      // to avoid this kind of behavior, event propagation has been stopped.
      event.stopPropagation();
      if (fileUploadRef.current) {
        fileUploadRef.current.click();
      }
    },
    [fileUploadRef]
  );

  const handleAvatarChange = (event) => {
    const file = event.target.files[0];
    const isImage = accepts(file, 'image/*');

    if (isImage) {
      onUpload(file);
    }
  };

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <LoaderContainer role="progressbar">
          <CircularProgress size={20} />
        </LoaderContainer>
      );
    }

    return <Avatar size={size} online={online} name={name} src={url} />;
  };

  const inputBox = disabled ? null : (
    <input
      id={inputId}
      ref={fileUploadRef}
      className={inputBoxCss}
      onChange={handleAvatarChange}
      onClick={stopPropagation}
      type="file"
      accept="image/*"
      tabIndex="-1"
    />
  );

  const tabIndex = disabled ? '' : '0';

  return (
    <React.Fragment>
      <Content
        aria-label="Avatar"
        role={disabled ? '' : 'button'}
        disabled={disabled}
        onClick={!disabled && !isLoading ? handleAvatarClick : noop}
        onKeyDown={(e) => e.keyCode === ENTER && handleAvatarClick(e)}
        tabIndex={tabIndex}
      >
        {renderContent()}
      </Content>
      {inputId ? <Label htmlFor={inputId}>Upload avatar</Label> : null}
      {inputBox}
    </React.Fragment>
  );
};

AvatarUploader.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large', 'xLarge']),
  online: PropTypes.bool,
  isLoading: PropTypes.bool,
  name: PropTypes.string,
  url: PropTypes.string,
  onUpload: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  inputId: PropTypes.string,
};

AvatarUploader.defaultProps = {
  isLoading: false,
  size: 'medium',
  online: false,
  url: '',
  name: '',
  disabled: false,
  inputId: null,
};

export default AvatarUploader;
