import styled, { css } from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const Container = styled('div')`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  width: 70vw;
  max-width: 920px;
  height: 58vh;
  background: ${theme.color.white};
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 4px;
  overflow: hidden;

  @media screen and (max-width: 50em) {
    width: 80vw;
  }

  @media screen and (max-height: 50em) {
    height: 80vh;
  }
`;

export const LeftContainer = styled('div')`
  width: 30%;
  height: 100%;
  border-right: 1px solid ${theme.color.gray};
`;

export const RightContainer = styled('div')`
  width: 70%;
  height: 100%;
`;

export const modalPaperClass = css`
  background-color: transparent;
`;
