import { GROUPS_DELETE_GROUP } from '../actions';
import { group as getGroup } from '../selectors';

const onGroupDeleted = (data) => (dispatch, getState) => {
  const { id: groupId, studentsWithNoGroup } = data;
  const state = getState();
  const alreadyExists = !!getGroup(state, groupId);

  if (!alreadyExists) {
    return;
  }

  dispatch({
    type: GROUPS_DELETE_GROUP,
    status: 'success',
    payload: { groupId, studentsWithNoGroup },
  });
};

export default onGroupDeleted;
