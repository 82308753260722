import { TOASTS_SHOW_TOAST } from '../actions';

const showToast = (toast) => (dispatch) => {
  dispatch({
    type: TOASTS_SHOW_TOAST,
    status: 'success',
    payload: { toast },
  });
};

export default showToast;
