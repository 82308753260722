import { CLASSROOM_DELETE_FOLDER } from '../actions';
import { deleteFolder as apiDeleteFolder } from '../api';
import * as materialSelectors from '../selectors';
import { AulaReduxAction } from '../../types/state';
import { ActionStatus } from '../../constants/actionsStatus';
import { Action } from 'redux';
import { getCurrentSpaceId } from '../../selectors/space';

const deleteFolderAction = (status: ActionStatus, payload: unknown) => ({
  type: CLASSROOM_DELETE_FOLDER,
  status,
  payload,
});

type DeleteFolderActionFactory = (
  folderId: string,
  selectSection: (
    fallbackSectionId: string | null,
    educatorOnly: boolean
  ) => Action,
  educatorOnly: boolean
) => AulaReduxAction;

const deleteFolder: DeleteFolderActionFactory =
  (folderId, selectSection, educatorOnly) => async (dispatch, getState) => {
    const state = getState();
    const payload = { materialId: folderId };
    dispatch(deleteFolderAction('started', payload));

    try {
      const classRoomId = getCurrentSpaceId(state);
      await apiDeleteFolder(classRoomId, folderId);

      const childrenSectionIds = materialSelectors
        .sectionsByParent(state, folderId)
        .map(({ id }) => id);
      dispatch(
        deleteFolderAction('success', { ...payload, childrenSectionIds })
      );

      const parentIds = materialSelectors.topLevelMaterialIds(state);
      const isLastFolder = parentIds.length === 1 && parentIds[0] === folderId;
      const fallbackSectionId = isLastFolder
        ? null
        : materialSelectors.fallbackSectionId(state, folderId);

      dispatch(selectSection(fallbackSectionId, educatorOnly));
    } catch (error: unknown) {
      dispatch(deleteFolderAction('error', { ...payload, error }));
    }
  };

export default deleteFolder;
