import onReactionAdded from './onReactionAdded.operation';
import onReactionRemoved from './onReactionRemoved.operation';
import fetchReactionSuccess from './fetchReactionsSuccess.operation';
import toggleReaction from './toggleReaction.operation';

export default {
  fetchReactionSuccess,
  toggleReaction,
  onReactionAdded,
  onReactionRemoved,
};
