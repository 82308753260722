import { POST } from '../../constants/endpoints';
import request from '../request';
import formatPost from './formatPost';

const updatePost = async ({ postId, content, important, scheduledFor }) => {
  const {
    data: { post, tagPosts },
  } = await request({
    method: 'patch',
    url: POST(postId),
    data: { content, important, scheduledFor },
  });

  return formatPost(post, tagPosts);
};

export default updatePost;
