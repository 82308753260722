import { editAssignment as editAssignmentApi } from '../api';
import { CONVERT_ASSIGNMENT } from '../actions';
import { accessToken as getAccessToken } from '../../selectors/user';

import { analyticsTrackEvent } from '../../utils/analytics';
import * as analyticEvents from '../../constants/analytics';

const convertAssignmentStarted = (dispatch, payload) =>
  dispatch({
    type: CONVERT_ASSIGNMENT,
    status: 'started',
    payload,
  });

const convertAssignmentSuccess = (dispatch, payload) =>
  dispatch({
    type: CONVERT_ASSIGNMENT,
    status: 'success',
    payload,
  });

const convertAssignmentError = (dispatch, payload) =>
  dispatch({
    type: CONVERT_ASSIGNMENT,
    status: 'error',
    payload,
  });

const convertAssignmentToExtension =
  ({ spaceId, assignment, accessToken }) =>
  async (dispatch, getState) => {
    convertAssignmentStarted(dispatch, { spaceId });

    const state = getState();
    const currentAccessToken = accessToken || getAccessToken(state, spaceId);

    if (!currentAccessToken) {
      convertAssignmentError(dispatch, { spaceId });
      return null;
    }

    try {
      const response = await editAssignmentApi(
        currentAccessToken,
        spaceId,
        assignment
      );

      if (!response.data || !response.data.assignment) {
        convertAssignmentError(dispatch, {
          spaceId,
          assignmentCreationError: {
            errorCode: '500',
            errorMessage: 'fail to create assignment',
          },
        });
        return null;
      }

      const newAssignment = response.data.assignment;

      analyticsTrackEvent(analyticEvents.CONVERT_ASSIGNMENT, {
        assignment: newAssignment,
        activeClassroom: spaceId,
      });

      convertAssignmentSuccess(dispatch, {
        spaceId,
        assignment: newAssignment,
      });
      return newAssignment;
    } catch (e) {
      const error =
        e.response &&
        e.response.data &&
        e.response.data.errors &&
        e.response.data.errors[0];
      const errorCode = error && error.code;
      if (errorCode === 'parent-assignment-submissions-exist') {
        convertAssignmentError(dispatch, {
          spaceId,
          assignmentCreationError: {
            errorCode,
            detail: error.detail,
          },
        });
      } else {
        const errorMessage = e.message;
        convertAssignmentError(dispatch, {
          spaceId,
          assignmentCreationError: { errorCode, errorMessage },
        });
      }
      return null;
    }
  };

export default convertAssignmentToExtension;
