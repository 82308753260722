import styled, { css } from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const Container = styled('div')`
  width: 100%;
  /* 200px - 2(padding-top + bottom)  */
  /* height: 176px; */
  /* border-bottom: 1px solid red; */
  padding: 12px;
`;

export const UserName = styled('div')`
  margin-top: 12px;
  font-size: 14px;
  font-weight: ${theme.font.weight.bold};
`;

export const Email = styled('div')`
  font-size: 14px;
  color: ${theme.color.grey11};
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ProfileUpdateContainer = styled('div')`
  margin-top: 24px;
  /* 12px padding on both sites */
  width: calc(100% - 24px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 20px;
  word-break: break-all;
  text-align: center;
`;

export const ImageUpload = css`
  cursor: pointer;
`;
