import React from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem } from '@ublend-npm/aulaui-next';
import TextButton from '../../../common/Buttons/TextButton';

import contentSortImage from '../../../../../../static/content-sort.svg';
import Filter from '../../../common/Filter/Filter';
import Switch from '../../../common/Switch';

import {
  TextButtonStyle,
  Container,
  SpaceFilterContainer,
  ArchiveToggleContainer,
  AdditionalOptionsContainer,
  OptionsHeader,
  OptionsHeaderText,
} from './SpaceFilter.styled';

const id = Math.random().toString(36).substr(2, 9);

const SpaceFilter = ({
  topPadding,
  filter,
  onFilterChange,
  sortOptions,
  currentSortOption,
  onArchivedToggleChange,
  hasArchivedSpaces,
}) => (
  <Container>
    <SpaceFilterContainer topPadding={topPadding}>
      <Filter
        data-testid="space-filter"
        filter={filter}
        onChange={onFilterChange}
        title="Search by name"
      />
      <AdditionalOptionsContainer hasArchivedSpaces={hasArchivedSpaces}>
        {hasArchivedSpaces && (
          <ArchiveToggleContainer data-testid="space-show-archived">
            <span>Show archived</span>
            <Switch
              inputProps={{ 'aria-label': 'Show archived' }}
              onChange={onArchivedToggleChange}
            />
          </ArchiveToggleContainer>
        )}
        <div id={`space-sort-options-${id}`} data-testid="space-sort">
          <Menu
            data-testid="space-sort-options"
            renderTarget={({ handleTargetClick }) => (
              <TextButton
                label={
                  <OptionsHeader>
                    <OptionsHeaderText>{currentSortOption}</OptionsHeaderText>
                    <img
                      src={contentSortImage}
                      alt="Sort spaces"
                      width={20}
                      height={20}
                    />
                  </OptionsHeader>
                }
                onClick={handleTargetClick}
                buttonStyles={TextButtonStyle}
              />
            )}
            menuListComponent="ul"
            menuListAttributes={{
              role: 'listbox',
              'aria-label': 'Select a tool',
            }}
            ariaLabelledby={`space-sort-options-${id}`}
            onSelect={({ item }) => item()}
            renderChildren={({ getItemProps }) =>
              Object.keys(sortOptions).map((option) => (
                <MenuItem
                  key={option}
                  size="medium"
                  primaryText={sortOptions[option].description}
                  {...getItemProps({
                    item: sortOptions[option].setSortOption,
                    onClick: (e) => e.stopPropagation(),
                  })}
                />
              ))
            }
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'bottom',
            }}
            transformOrigin={{
              horizontal: 'right',
              vertical: 'top',
            }}
          />
        </div>
      </AdditionalOptionsContainer>
    </SpaceFilterContainer>
  </Container>
);

SpaceFilter.propTypes = {
  topPadding: PropTypes.bool,
  filter: PropTypes.string.isRequired,
  sortOptions: PropTypes.shape([]),
  currentSortOption: PropTypes.string.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onArchivedToggleChange: PropTypes.func.isRequired,
  hasArchivedSpaces: PropTypes.bool.isRequired,
};

SpaceFilter.defaultProps = {
  topPadding: false,
  sortOptions: [],
};

export default SpaceFilter;
