import appReducer from './appReducers';
import dataReducer from './dataReducers';
import operations from './operations';
import registerLive from './registerLive';
import * as selectors from './selectors';

const notifications = (services) => {
  const protoDuck = {
    appReducer,
    dataReducer,
    operations: operations(services),
    selectors,
  };

  return {
    ...protoDuck,
    registerLive: registerLive(protoDuck.operations),
  };
};

export default notifications;
