const addGroupSetReducer = (state, action) => {
  if (action.status !== 'success') {
    return state;
  }

  const {
    payload: { groupSet },
  } = action;

  return {
    ...state,
    sets: {
      ...state.sets,
      [groupSet.id]: groupSet,
    },
  };
};

export default addGroupSetReducer;
