import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const Container = styled('div')`
  /* width: 100%; */
  background: ${theme.color.white};
  padding: 16px;
`;

export const Heading = styled('div')`
  width: 54px;
  height: 14px;
  color: ${theme.color.grayDark};
  font-size: 14px;
  font-weight: ${theme.font.weight.medium};
  letter-spacing: 0;
  line-height: 14px;
`;
