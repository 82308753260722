import * as selectors from '../../selectors/routing';
import push from '../../utils/routing';

const toggleUserProfile = () => (_, getState) => {
  const state = getState();
  const location = selectors.location(state);

  const {
    query: { 'user-profile': currentlyDisplayed, ...query },
  } = location;

  const newQuery = currentlyDisplayed ? query : { ...query, 'user-profile': 1 };
  push({ ...location, query: newQuery });
};

export default toggleUserProfile;
