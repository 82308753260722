import { TOGGLE_REACTION } from '../actions';
import createReaction from './createReaction.operation';
import removeReaction from './removeReaction.operation';
import { reactionForItem, hasCurrentUserReacted } from '../selectors';

const toggleReactionStarted = (dispatch, payload) =>
  dispatch({
    type: TOGGLE_REACTION,
    status: 'started',
    payload,
  });

export const toggleReactionSuccess = (dispatch, payload) =>
  dispatch({
    type: TOGGLE_REACTION,
    status: 'success',
    payload,
  });

const toggleReactionError = (dispatch, payload) =>
  dispatch({
    type: TOGGLE_REACTION,
    status: 'error',
    payload,
  });

const toggleReaction = (reactionParams) => async (dispatch, getState) => {
  toggleReactionStarted(dispatch, reactionParams);
  const state = getState();
  const reaction = reactionForItem(state, reactionParams);
  const hasReacted = hasCurrentUserReacted(state, reaction);

  try {
    const reactionPromise = hasReacted
      ? dispatch(removeReaction(reaction.id))
      : dispatch(createReaction(reactionParams));
    await reactionPromise;
    toggleReactionSuccess(dispatch, reactionParams);
  } catch (error) {
    toggleReactionError(dispatch, {
      ...reactionParams,
      error,
    });
  }
};

export default toggleReaction;
