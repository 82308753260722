import { NOTIFICATION_SETTINGS } from '../../constants/endpoints';
import request from '../../api/request';
import formatSettings from './formatSettings';

const patchSettings = async (settingsPatch) => {
  const url = NOTIFICATION_SETTINGS();
  const formattedPatch = {
    ...settingsPatch,
    ...(settingsPatch.mutedSpaces
      ? { mutedSpaces: Object.keys(settingsPatch.mutedSpaces) }
      : {}),
    ...(settingsPatch.mutedConversations
      ? { mutedConversations: Object.keys(settingsPatch.mutedConversations) }
      : {}),
  };
  const {
    data: { notificationSettings },
  } = await request({
    method: 'patch',
    url,
    data: { notificationSettings: formattedPatch },
  });
  return formatSettings(notificationSettings);
};

export default patchSettings;
