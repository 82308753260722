import { MaterialDataReducer } from './state';
import { ImportMaterialsAction } from '../sharedActions';
import { reduceById } from '../utils';

const importMaterialsDataReducer: MaterialDataReducer<ImportMaterialsAction> = (
  state,
  action
) => {
  if (action.status !== 'success') {
    return state;
  }

  const { sections } = action.payload;

  return {
    ...state,
    ...sections.reduce(reduceById, {}),
  };
};

export default importMaterialsDataReducer;
