import { MaterialAppReducer } from './state';
import { SaveSectionAction } from '../sharedActions';

const saveSectionAppReducer: MaterialAppReducer<SaveSectionAction> = (
  state,
  action
) => {
  switch (action.status) {
    case 'started':
      return {
        ...state,
        isSavingSection: true,
      };
    case 'success':
      return {
        ...state,
        editing: false,
        isSavingSection: false,
        selectedContent: action.payload.section.content,
      };
    case 'error':
      return {
        ...state,
        editing: false,
        isSavingSection: false,
        hasSavingSectionErrored: true,
      };
    default:
      return state;
  }
};

export default saveSectionAppReducer;
