import {
  INBOX_ADD_PARTICIPANTS_TO_CONVERSATION,
  INBOX_CREATE_CONVERSATION,
} from '../actions';
import { users } from '../../selectors/users';
import { currentUserId as getCurrentUserId } from '../../selectors/user';
import { isParticipant } from '../selectors';
import getUser from './utils';

const onParticipantAdded = (data) => async (dispatch, getState) => {
  const { conversation, participant } = data;

  const state = getState();
  const alreadyAParticipant = !!isParticipant(
    state,
    conversation.id,
    participant
  );

  if (alreadyAParticipant) {
    return;
  }

  if (participant === getCurrentUserId(state)) {
    dispatch({
      type: INBOX_CREATE_CONVERSATION,
      status: 'success',
      payload: { conversation },
    });
  } else {
    const user = await getUser(dispatch, users(state), participant);
    dispatch({
      type: INBOX_ADD_PARTICIPANTS_TO_CONVERSATION,
      status: 'success',
      payload: {
        conversationId: conversation.id,
        participants: [
          {
            [participant]: user,
          },
        ],
      },
    });
  }
};

export default onParticipantAdded;
