import { connect } from 'react-redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { setNotificationsTabVisible } from '@core/actions/user';
import { toggleUserProfile } from '../../../actions/navigation';
import { location } from '../../../selectors/routing';
import {
  currentUser as getCurrentUser,
  isVisibleNotificationSettingsTab,
} from '../../../../core/selectors/user';

import UserProfile from './UserProfile';
import { getTabs } from './Tabs';

const mapStateToProps = (state, { flags }) => {
  const { status } = getCurrentUser(state);
  const tabs = getTabs(status, flags);
  return {
    shouldShowUserProfile: location(state).search.includes('user-profile'),
    tabs,
    isVisibleNotificationSettingsTab: isVisibleNotificationSettingsTab(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  closeModal: () => {
    dispatch(toggleUserProfile());
    dispatch(setNotificationsTabVisible(false));
  },
});

export default withLDConsumer()(
  connect(mapStateToProps, mapDispatchToProps)(UserProfile)
);
