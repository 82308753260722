import { DeleteFolderContract } from '@ublend-npm/aula-schema/types/materials';
import { CLASSROOM_MATERIAL_FOLDER } from '../../constants/endpoints';
import request from './request';

const deleteFolder = async (classRoomId: string, folderId: string) => {
  const url = CLASSROOM_MATERIAL_FOLDER(classRoomId, folderId);
  const response = await request<DeleteFolderContract>({
    method: 'delete',
    url,
  });
  return response.status === 204;
};

export default deleteFolder;
