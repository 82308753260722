import * as types from '../../constants/actions';

const initialState = {
  isErrored: false,
  isSearching: false,
  indicesResult: {},
};

const searchIndexReducer = (state = initialState, action) => {
  switch (action.status) {
    case 'started':
      return {
        ...state,
        isSearching: true,
      };
    case 'success':
      return {
        ...state,
        indicesResult: {
          ...state.indicesResult,
          [action.indexName]: { ...action.results },
        },
        isSearching: false,
        isErrored: false,
      };
    case 'error':
      return {
        ...state,
        isSearching: false,
        isErrored: true,
      };

    default:
      return state;
  }
};

const searchReducer = (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case types.SEARCH_INDEX:
      return searchIndexReducer(state, action);
    case types.SEARCH_INDEX_CLEAR:
      return {
        ...state,
        indicesResult: {},
        isSearching: false,
        isErrored: false,
      };
    default:
      return state;
  }
};

export default searchReducer;
