import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

const Wrapper = styled.span`
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  color: ${(props) =>
    props.disabled
      ? theme.color.designSystem.blackOpacity38
      : theme.color.designSystem.purple70};
  cursor: pointer;
  line-height: 20px;
  padding: 5px;
  transition: opacity 0.2s;
  :hover,
  :active {
    text-decoration: ${(props) => (props.disabled ? 'none' : 'underline')};
  }
  :focus {
    background-color: rgba(105, 41, 196, 0.17);
  }
`;

export default Wrapper;
