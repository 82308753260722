import {
  createTempReaction,
  tempReactionId,
  updateReactions,
  deleteReaction,
  reactionWithUser,
} from './utils';

const createReactionReducer = (state, action) => {
  const { status, payload } = action;
  switch (status) {
    case 'started': {
      const { oldReaction, params, user } = payload;
      const reaction = oldReaction || createTempReaction(params);
      const local = true;
      return updateReactions(
        state,
        reactionWithUser(reaction, user),
        local,
        user
      );
    }
    case 'success': {
      const { reaction, user, local } = payload;
      const params = {
        itemId: reaction.itemId,
        itemType: reaction.itemType,
        emojiName: reaction.emojiName,
      };
      return updateReactions(
        deleteReaction(state, tempReactionId(params)),
        reaction,
        local,
        user
      );
    }
    case 'error': {
      const { oldReaction, params, user } = payload;
      const stateWithoutTempReaction = params
        ? deleteReaction(state, tempReactionId(params))
        : state;
      const local = true;
      return oldReaction
        ? updateReactions(stateWithoutTempReaction, oldReaction, local, user)
        : stateWithoutTempReaction;
    }
    default:
      return state;
  }
};

export default createReactionReducer;
