import { connect } from 'react-redux';

import UserBar from './UserBar';
import {
  toggleUserProfile,
  toggleOmniSearch,
  toggleNotifications,
  openZendeskHelp,
} from '../../../../actions/navigation';
import * as userSelectors from '../../../../../core/selectors/user';
import {
  isInstructor,
  accessingCurrentSpaceAsStaff,
} from '../../../../../core/selectors/classroom';
import { selectors as notificationSelectors } from '../../../../store/notifications';
import hideZendeskHelp from '../../../../actions/navigation/hideZendeskHelp.action';

const mapStateToProps = (state) => {
  const user = userSelectors.currentUser(state);

  const { firstName: avatarName, avatar: avatarSrc } = user;

  const isEducator = isInstructor(state, user.objectId);
  const isStaff = accessingCurrentSpaceAsStaff(state);

  return {
    avatarName,
    avatarSrc,
    hasUnreadNotifications: Boolean(
      notificationSelectors.hasUnreadNotifications(state)
    ),
    isStaffOrEducator: isStaff || isEducator,
  };
};

const mapDispatchToProps = {
  onAvatarClick: toggleUserProfile,
  onSearchClick: toggleOmniSearch,
  onNotificationsClick: toggleNotifications,
  onHelpClick: openZendeskHelp,
  onPortalMenuOpen: hideZendeskHelp,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserBar);
