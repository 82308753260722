import {
  CreateSectionBody,
  CreateSectionContract,
} from '@ublend-npm/aula-schema/types/materials';
import { CLASSROOM_POST_MATERIAL_SECTIONS } from '../../constants/endpoints';
import request, { getData } from './request';
import toSection from './toSection';

const createSection = async (
  classRoomId: string,
  section: CreateSectionBody,
  localId?: string,
  correlationId?: string
) => {
  const url = CLASSROOM_POST_MATERIAL_SECTIONS(classRoomId);
  const data = await request<CreateSectionContract>({
    method: 'post',
    headers: {
      'x-correlation-id': correlationId,
    },
    url,
    data: { section, localId },
  }).then(getData);
  return toSection(data);
};

export type CreateSection = typeof createSection;

export default createSection;
