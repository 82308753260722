import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

type DescriptionProps = {
  center?: boolean;
  dark?: boolean;
};

export const BasicOnboardingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${theme.spacing.small};
`;

export const Title = styled.div`
  font-weight: ${theme.font.weight.semiBold};
  font-size: ${theme.font.size.large};
  margin-bottom: ${theme.spacing.small};
`;

export const Description = styled<DescriptionProps, 'div'>('div')`
  font-family: ${theme.font.family.workSans};
  font-size: ${theme.font.size.copy};
  line-height: ${theme.font.lineHeight.copy};
  color: ${(props) =>
    props.dark
      ? theme.color.designSystem.black
      : theme.color.designSystem.blackOpacity60};
  max-width: 90%;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  margin-bottom: ${theme.spacing.small};
`;

export const Link = styled('a')`
  font-size: ${theme.font.size.copy};
  color: ${theme.color.purple};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: ${theme.color.black};
    text-decoration: none;
  }
`;
