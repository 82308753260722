/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import BaseDialogActions from '@material-ui/core/DialogActions';
import { Button as BaseButton } from '@ublend-npm/aulaui-next';

import {
  TextButton,
  PrimaryButton,
  ConfirmButton,
  SecondaryButton,
  ConfirmationButtonsContainer,
  ConfirmationMessageContainer,
} from './Buttons.styled';

const DialogActions = ({
  primary,
  secondary,
  tertiary,
  className,
  classes,
}) => {
  const [confirmOpen, setConfirmOpen] = useState(null);
  const openPrimaryConfirmation = () => setConfirmOpen('primary');
  const openSecondaryConfirmation = () => setConfirmOpen('secondary');
  const openTertiaryConfirmation = () => setConfirmOpen('tertiary');
  const closeConfirmation = () => setConfirmOpen(null);
  const buttonRef = useRef();

  useEffect(() => {
    if (['secondary', 'tertiary'].includes(confirmOpen)) {
      if (buttonRef.current) {
        buttonRef.current.focus();
      }
    }
  }, [confirmOpen]);

  if (!primary) {
    return <div />;
  }

  if (confirmOpen) {
    let actionToConfirm = primary;
    if (confirmOpen === 'secondary') {
      actionToConfirm = secondary;
    }
    if (confirmOpen === 'tertiary') {
      actionToConfirm = tertiary;
    }
    const { confirmation, onClick, loading, ...otherProps } = actionToConfirm;
    return (
      <BaseDialogActions className={className} classes={classes}>
        <ConfirmationMessageContainer
          type={confirmation.type}
          aria-label={confirmation.warningLabel}
        >
          {confirmation.warningLabel}
        </ConfirmationMessageContainer>
        <ConfirmationButtonsContainer invert={confirmation.invertButtons}>
          <ConfirmButton
            type={confirmation.type}
            variant="contained"
            onClick={() => {
              onClick();
              closeConfirmation();
            }}
            loading={loading}
            {...otherProps}
          >
            {confirmation.acceptLabel}
          </ConfirmButton>
          <BaseButton
            variant="outlined"
            onClick={closeConfirmation}
            ref={buttonRef}
            size="small"
            type="secondary"
          >
            {confirmation.cancelLabel}
          </BaseButton>
        </ConfirmationButtonsContainer>
      </BaseDialogActions>
    );
  }

  const primaryActionProps = {
    className: primary.className,
    variant: 'contained',
    onClick: primary.confirmation ? openPrimaryConfirmation : primary.onClick,
    danger: primary.danger,
    disabled: primary.disabled || primary.loading,
    loading: primary.loading,
    primary,
  };

  const primaryAction = primary.textButton ? (
    <TextButton {...primaryActionProps} {...primary.htmlAttributes}>
      {primary.label}
    </TextButton>
  ) : (
    <PrimaryButton {...primaryActionProps} {...primary.htmlAttributes}>
      {primary.label}
    </PrimaryButton>
  );

  const SecondaryComponent =
    secondary && secondary.textButton ? TextButton : SecondaryButton;
  const secondaryAction = secondary ? (
    <SecondaryComponent
      className={secondary.className}
      variant="outlined"
      onClick={
        secondary.confirmation ? openSecondaryConfirmation : secondary.onClick
      }
      disabled={secondary.disabled || secondary.loading}
      loading={secondary.loading}
      {...secondary.htmlAttributes}
    >
      {secondary.label}
    </SecondaryComponent>
  ) : null;

  const tertiaryAction = tertiary ? (
    <TextButton
      className={tertiary.className}
      variant="outlined"
      onClick={
        tertiary.confirmation ? openTertiaryConfirmation : tertiary.onClick
      }
      disabled={tertiary.disabled || tertiary.loading}
      loading={tertiary.loading}
      tertiary
      {...tertiary.htmlAttributes}
    >
      {tertiary.label}
    </TextButton>
  ) : null;

  return (
    <BaseDialogActions className={className} classes={classes}>
      {tertiaryAction}
      {secondaryAction}
      {primaryAction}
    </BaseDialogActions>
  );
};

const ActionProperties = PropTypes.shape({
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  danger: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  confirmation: PropTypes.shape({
    type: PropTypes.string,
    acceptLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    warningLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    invertButtons: PropTypes.bool,
  }),
  textButton: PropTypes.bool,
  htmlAttributes: PropTypes.object,
});

DialogActions.propTypes = {
  className: PropTypes.string,
  primary: ActionProperties.isRequired,
  secondary: ActionProperties,
  tertiary: ActionProperties,
  classes: PropTypes.object,
};

DialogActions.defaultProps = {
  className: null,
  secondary: null,
  tertiary: null,
  classes: null,
};

export default DialogActions;
