import { SPACE } from '../../constants/endpoints';
import request from '../request';

const patchSpace = async (spaceId, patch) => {
  const {
    data: { space },
  } = await request({
    method: 'PATCH',
    url: SPACE(spaceId),
    data: patch,
  });

  return space;
};

export default patchSpace;
