import { GenericAction } from '../../types/redux';
import { CLASSROOM_MODULE_LIVE_DELETED_MATERIAL } from '../actions';
import { Material } from '../types';
import { MaterialDataReducer } from './state';

export type LiveDeletedMaterialAction = GenericAction<
  typeof CLASSROOM_MODULE_LIVE_DELETED_MATERIAL,
  { material: Material }
>;

const liveDeletedMaterialReducer: MaterialDataReducer<
  LiveDeletedMaterialAction
> = (state, action) => {
  const { material } = action.payload;

  const updatedMaterials = { ...state };
  delete updatedMaterials[material.id];

  return updatedMaterials;
};

export default liveDeletedMaterialReducer;
