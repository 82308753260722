import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const Container = styled('div')`
  width: 100%;
  /* 260 + 8px(padding added by MaterialUIList) */
  height: 108px;
  background: ${theme.color.white};
  /* border-bottom: 1px solid green; */
  overflow: hidden;
`;
