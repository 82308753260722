import { GROUPS_ADD_GROUP, GROUPS_EDIT_GROUP } from '../actions';
import { group as getGroup } from '../selectors';

const onGroupUpdated =
  ({ group, studentsWithNoGroup = [] }) =>
  (dispatch, getState) => {
    const state = getState();
    const alreadyExists = !!getGroup(state, group.id);
    const type = alreadyExists ? GROUPS_EDIT_GROUP : GROUPS_ADD_GROUP;

    dispatch({
      type,
      status: 'success',
      payload: { group, studentsWithNoGroup },
    });
  };

export default onGroupUpdated;
