import { INBOX_OPEN_CLOSE_EDIT_MESSAGE } from '../actions';

const openEditMessage = (messageId) => (dispatch) => {
  if (!messageId) return;

  dispatch({
    type: INBOX_OPEN_CLOSE_EDIT_MESSAGE,
    payload: {
      messageId,
    },
  });
};

export default openEditMessage;
