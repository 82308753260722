import styled from 'react-emotion';
import BaseVolumeOffIcon from '@material-ui/icons/VolumeOff';
import { theme, styleUtils } from '@ublend-npm/aulaui-next';
import sizes from '../../../../styles/sizes';

const { inboxGroupsMenuWidth } = sizes;

const getContainerColor = ({ selected, highlighted }) => {
  if (selected) {
    return theme.color.designSystem.background.grey.selected;
  }

  if (highlighted) {
    return theme.color.designSystem.background.grey.hover;
  }

  return 'white';
};

export const Camera = styled('div')`
  overflow: hidden;
  position: relative;
`;

export const Container = styled('button')`
  height: 44px;
  cursor: pointer;
  font-family: ${theme.font.family.workSans};
  font-size: ${theme.font.size.copy};
  outline: none;
  padding: 0;
  padding-right: ${theme.spacing.small};
  border: none;
  text-align: left;
  width: ${inboxGroupsMenuWidth}px;
  background-color: ${(props) => getContainerColor(props)};

  &:hover,
  &:focus {
    background-color: ${theme.color.grayLight};
  }

  // Close conversation button is always shown to screen-readers but only shown visually displayed when the
  // conversation row is hovered or has or contains focus
  &:not(:hover):not(:focus-within) {
    .close-conversation-button {
      ${styleUtils.visuallyHidden()};
    }
  }
`;

export const Row = styled('div')`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SneakPeek = styled('p')`
  padding-top: 8px;
  padding-bottom: 0px;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 0;
  margin-bottom: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const UnreadBorder = styled('div')`
  position: absolute;
  top: 4px;
  bottom: 4px;
  left: 0;
  width: 4px;
  background: ${theme.color.orange};
  display: ${({ shown }) => (shown ? 'block' : 'none')};
`;

export const VolumeOffIcon = styled(BaseVolumeOffIcon)`
  color: ${theme.color.grey11};
  font-size: 20px;
  margin-right: ${theme.spacing.xSmall};
`;

export const ClosedBadge = styled('span')`
  background-color: ${theme.color.grey0};
  border-radius: 4px;
  border: 1px solid ${theme.color.grey14};
  color: ${theme.color.grey13};
  font-size: ${theme.font.size.small};
  padding: 4px 8px;
`;

export const NewMessageIndicator = styled.span`
  width: 10px;
  height: 10px;
  background: ${theme.color.error};
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  color: ${theme.color.white};
  font-size: ${theme.font.size.small};
  margin-right: ${theme.spacing.xSmall};
`;
