import queryKeys from '../hooks/queryKeys';

const removeCommentFromQueryData = (queryClient, comment) => {
  const { id, entityType, entityId } = comment;
  const queryKey = queryKeys.comments(entityType, entityId);
  queryClient.setQueryData(queryKey, (data) => {
    return {
      comments: (data?.comments || []).filter((c) => c.id !== id),
      reactions: data?.reactions || [],
    };
  });
};

export default removeCommentFromQueryData;
