import React from 'react';

const withSuspense = (View) => (props) =>
  (
    <React.Suspense fallback={<></>}>
      <View {...props} />
    </React.Suspense>
  );

export default withSuspense;
