import * as operations from './inbox';
import messageQueue from './messageQueue';

export default () => {
  const {
    sendMessage: protoSendMessage,
    consumeMessageQueue: protoConsumeMessageQueue,
    ...otherOperations
  } = operations;

  const sendMessage = protoSendMessage(messageQueue);
  const consumeMessageQueue = protoConsumeMessageQueue(messageQueue);

  return {
    ...otherOperations,
    sendMessage,
    consumeMessageQueue,
  };
};
