import { connect } from 'react-redux';
import { theme } from '@ublend-npm/aulaui-next';
import OverlayLoaderPresentational from '../presentational/OverlayLoader';

const mapStateToProps = (state) => {
  const {
    ui: {
      loader: { isLoading },
    },
  } = state;
  const backgroundColor = isLoading ? theme.color.purple : theme.color.overlay;

  const props = {
    open: isLoading,
    backgroundColor,
    logo: state.ui.loader.showLogo,
    shouldUpdate: state.classRoom.isFetchingFilteredPosts,
  };

  if (state.ui.loader.showError) {
    props.loaderColor = theme.color.text;
  }

  return props;
};

const OverlayLoader = connect(mapStateToProps)(OverlayLoaderPresentational);

export default OverlayLoader;
