import { TOASTS_DISMISS_TOAST } from '../actions';

const dismissToast = () => (dispatch) => {
  dispatch({
    type: TOASTS_DISMISS_TOAST,
    status: 'success',
    payload: {},
  });
};

export default dismissToast;
