import { INBOX_OPEN_CLOSE_VIEW_PARTICIPANTS } from '../actions';

const openViewParticipants = (addParticipant) => (dispatch) =>
  dispatch({
    type: INBOX_OPEN_CLOSE_VIEW_PARTICIPANTS,
    payload: {
      addParticipantOpened: !!addParticipant,
      viewParticipantsOpened: true,
    },
  });

export default openViewParticipants;
