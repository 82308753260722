import { GROUPS_ADD_GROUPS } from '../actions';
import { addGroup as addGroupApi } from '../api';

import refreshAccessToken from '../../actions/accessToken/refreshAccessToken.action';
import { currentClassRoomId as getCurrentClassRoomId } from '../../selectors/classroom';
import { analyticsTrackEvent } from '../../utils/analytics';
import { CREATE_GROUPS_VIA_CSV_UPLOAD } from '../../constants/analytics';
import showToast from '../../toasts/operations/showToast.operation';
import {
  ADD_GROUPS_SUCCESS,
  ADD_GROUPS_ERROR,
  ADD_GROUPS_PARTIAL_SUCCESS,
} from './toasts';

const addGroupsStarted = () => ({
  type: GROUPS_ADD_GROUPS,
  status: 'started',
});

const addGroupsSuccess = ({ groups, studentsWithNoGroup }) => ({
  type: GROUPS_ADD_GROUPS,
  status: 'success',
  payload: { groups, studentsWithNoGroup },
});

const addGroupsError = () => ({
  type: GROUPS_ADD_GROUPS,
  status: 'error',
});

const addGroups = (groups) => async (dispatch, getState) => {
  dispatch(addGroupsStarted());

  try {
    const state = getState();
    const spaceId = getCurrentClassRoomId(state);
    const accessToken = await refreshAccessToken(
      dispatch,
      getState
    )({ spaceId });

    // Create groups
    const results = await Promise.all(
      groups.map((group) =>
        addGroupApi(accessToken, group).catch((error) => ({
          error,
        }))
      )
    );

    const validResults = results.filter((res) => res && !res.error);

    if (validResults.length === 0) {
      throw new Error();
    }

    const studentsAdded = validResults.reduce(
      (added, { group: { students } }) => [
        ...added,
        ...students.map(({ id }) => id),
      ],
      []
    );

    const studentsWithoutGroup = validResults.reduce(
      (accStudentsWithNoGroup, { studentsWithNoGroup }) => [
        ...accStudentsWithNoGroup,
        ...studentsWithNoGroup.filter(({ id }) => !studentsAdded.includes(id)),
      ],
      []
    );

    dispatch(
      addGroupsSuccess({
        groups: validResults.map(({ group }) => group),
        studentsWithNoGroup: studentsWithoutGroup,
      })
    );

    analyticsTrackEvent(CREATE_GROUPS_VIA_CSV_UPLOAD, {
      activeClassroom: spaceId,
    });

    if (validResults.length < results.length) {
      // There was an error with one or more of the group creations
      return dispatch(showToast(ADD_GROUPS_PARTIAL_SUCCESS));
    }

    return dispatch(showToast(ADD_GROUPS_SUCCESS));
  } catch (error) {
    dispatch(addGroupsError());
    return dispatch(showToast(ADD_GROUPS_ERROR));
  }
};

export default addGroups;
