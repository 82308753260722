import { ANNOUNCEMENTS, COMMON_ROOM } from '@core/constants/postFeedType';
import Paths from '@app/constants/paths';
import DesktopNotifications from './desktopNotifications';
import { windowHasFocus } from '../selectors/focus';

const communityFeedLocations = [
  Paths.ANNOUNCEMENTS,
  Paths.COMMON_ROOM,
  Paths.FEED,
];
const feedTypePaths = {
  [ANNOUNCEMENTS]: Paths.ANNOUNCEMENTS,
  [COMMON_ROOM]: Paths.COMMON_ROOM,
};

const getLocationId = (location, query) => {
  const paths = location.split('/');

  if (query.inbox) {
    return `inbox=${query.inbox}`;
  }

  const lastPathSegment = paths[paths.length - 1];

  if (communityFeedLocations.includes(lastPathSegment)) {
    return lastPathSegment;
  }

  return '';
};

const CommentActions = new Set([
  'newComment',
  'newCommentOnCommentedPost',
  'newCommentReply',
]);
const PostActions = new Set(['newPost']);
const ConversationActions = new Set(['newMessage']);

const shouldBlockBasedOnLocation = ({
  notification,
  location,
  query,
  hasFocus,
}) => {
  if (!hasFocus) {
    return false;
  }

  const { action, parentItemId } = notification;
  const isPostAction = PostActions.has(action);
  const locationId = getLocationId(location, query);

  if (
    isPostAction &&
    (locationId === Paths.FEED ||
      locationId === feedTypePaths[notification.actionData.feedType])
  ) {
    return true;
  }

  const isConversationAction = ConversationActions.has(action);
  if (
    isConversationAction &&
    locationId.indexOf('inbox=') === 0 &&
    locationId.split('=')[1] === parentItemId
  ) {
    return true;
  }

  return false;
};

const shouldBlockBasedOnMuteSettings = ({ notification, settings }) => {
  const { action, classRoom, culpritIsEducator, parentItemId } = notification;
  const { push } = settings;

  const isCommentAction = CommentActions.has(action);
  const isPostAction = PostActions.has(action);
  const isSpaceAction = isCommentAction || isPostAction;
  const isConversationAction = ConversationActions.has(action);

  if (isCommentAction && !push.comments) {
    return true;
  }
  if (isPostAction && !push.posts) {
    return true;
  }
  if (isConversationAction && !push.messages) {
    return true;
  }
  const spaceIsMuted = classRoom && !!settings.mutedSpaces[classRoom.objectId];
  if (isSpaceAction && spaceIsMuted && !culpritIsEducator) {
    return true;
  }

  const conversationIsMuted = !!settings.mutedConversations[parentItemId];
  if (isConversationAction && conversationIsMuted) {
    return true;
  }

  return false;
};

const displayNotification = ({
  notification,
  dispatch,
  getState,
  selectors,
}) => {
  const state = getState();
  const locationObject = state.routing.locationBeforeTransitions;
  const currentSpaceId = state.classRoom.current;

  const blockBasedOnLocation = shouldBlockBasedOnLocation({
    notification: notification.notification,
    location: locationObject.pathname,
    query: locationObject.query,
    currentSpaceId,
    hasFocus: windowHasFocus(state),
  });

  if (blockBasedOnLocation) {
    return;
  }

  const blockBasedOnMuted = shouldBlockBasedOnMuteSettings({
    notification: notification.notification,
    settings: selectors.notificationSettings(getState()),
  });

  if (blockBasedOnMuted) {
    return;
  }

  DesktopNotifications.notifyFromNotificationObject(
    notification,
    dispatch,
    getState
  );
};

export default displayNotification;
