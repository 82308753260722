import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { currentUser, isChangingStatus } from '@core/selectors/user';
import changeUserStatus from '@core/actions/user/status';
import { Container, Heading, Description } from './UserStatus.styled';
import StatusInput from './StatusInput';
import StatusButtons from './StatusButtons';

const UserStatus = () => {
  const dispatch = useDispatch();
  const { status } = useSelector(currentUser);
  const isSaving = useSelector(isChangingStatus);

  const initialStatusText = status?.text || '';
  const initialEmojiShortName = status?.emojiName || '';

  const [statusText, setStatusText] = useState(initialStatusText);
  const [emojiShortName, setEmojiShortName] = useState(initialEmojiShortName);

  const setStatus = () => {
    const data = {
      status: {
        text: statusText,
        emojiName: emojiShortName,
      },
    };
    dispatch(changeUserStatus(data));
  };

  const undoChanges = () => {
    setStatusText(initialStatusText);
    setEmojiShortName(initialEmojiShortName);
  };

  return (
    <Container>
      <Heading>Status</Heading>
      <Description>
        Write something about you, your availability, or your preferred ways to
        be contacted
      </Description>
      <StatusInput
        statusText={statusText}
        emojiShortName={emojiShortName}
        setStatusText={setStatusText}
        setEmojiShortName={setEmojiShortName}
      />
      <StatusButtons
        initialStatusText={initialStatusText}
        initialEmojiShortName={initialEmojiShortName}
        statusText={statusText}
        emojiShortName={emojiShortName}
        onSave={setStatus}
        isSaving={isSaving}
        onUndo={undoChanges}
      />
    </Container>
  );
};

export default React.memo(UserStatus);
