import { SUCCESS_STATUS } from '../../../constants/actionsStatus';
import { ANNOUNCEMENTS } from '../../../constants/postFeedType';
import { updateSpacePosts } from './updateSpacePosts';

/* eslint-disable-next-line import/prefer-default-export */
export const createPostReducer = (state, action) => {
  switch (action.status) {
    case SUCCESS_STATUS: {
      const post = {
        ...action.post,
        ...(action.post.feedType !== ANNOUNCEMENTS && { comments: [] }),
        tagPosts: action.post.tagPosts || [],
      };
      const classRoom = state.classRooms[post.classRoom];

      return {
        ...state,
        classRooms: {
          ...state.classRooms,
          [post.classRoom]: updateSpacePosts({
            classRoom,
            post,
            posts: state.posts,
          }),
        },
        posts: {
          ...state.posts,
          [post.objectId]: post,
        },
      };
    }
    default:
      return state;
  }
};
