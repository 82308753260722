import PropTypes from 'prop-types';
import React from 'react';
import { IconButton, icons } from '@ublend-npm/aulaui-next';

import ConversationDescription from './ConversationDescription.container';
import ConversationIcon from './InboxItemIcon';
import {
  Camera,
  Container,
  Row,
  VolumeOffIcon,
  NewMessageIndicator,
  ClosedBadge,
} from './InboxItem.styled';

const stopPropagation = (event) => event.stopPropagation();

const InboxItem = ({
  changeGroup,
  conversationId,
  featSetConversationUnreadEnabled,
  selected,
  setConversationUnread,
  setConversationClosed,
  unread,
  getItemProps,
  index,
  highlighted,
  muted,
  conversation,
}) => {
  return (
    <Camera {...getItemProps({ item: conversationId, index })}>
      <Container
        onClick={changeGroup}
        selected={selected}
        highlighted={highlighted}
        tabIndex={0}
        role="button"
      >
        <Row>
          <ConversationIcon
            conversationId={conversationId}
            onClick={
              featSetConversationUnreadEnabled ? stopPropagation : undefined
            }
            onDoubleClick={
              featSetConversationUnreadEnabled
                ? setConversationUnread
                : undefined
            }
          />
          <ConversationDescription
            conversationId={conversationId}
            unread={unread}
          />
          {muted && <VolumeOffIcon />}
          {unread && <NewMessageIndicator />}
          {conversation?.closed && <ClosedBadge>closed</ClosedBadge>}
          {!conversation?.boundToSpaceGroup && !conversation?.closed && (
            <IconButton
              className="close-conversation-button"
              label="Close conversation"
              onClick={(e) => {
                e.stopPropagation();
                setConversationClosed(conversationId);
              }}
              size="xSmall"
              icon={icons.Close}
            />
          )}
        </Row>
      </Container>
    </Camera>
  );
};

InboxItem.propTypes = {
  changeGroup: PropTypes.func.isRequired,
  conversationId: PropTypes.string.isRequired,
  conversation: PropTypes.shape({
    educatorOnly: PropTypes.bool,
    boundToSpaceGroup: PropTypes.bool,
    closed: PropTypes.bool,
  }).isRequired,
  featSetConversationUnreadEnabled: PropTypes.bool,
  selected: PropTypes.bool,
  setConversationUnread: PropTypes.func.isRequired,
  setConversationClosed: PropTypes.func.isRequired,
  unread: PropTypes.bool,
  getItemProps: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  highlighted: PropTypes.bool.isRequired,
  muted: PropTypes.bool.isRequired,
};

InboxItem.defaultProps = {
  featSetConversationUnreadEnabled: false,
  selected: false,
  unread: false,
};

export default React.memo(InboxItem);
