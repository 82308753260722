const formatSettings = (settings) => ({
  ...settings,
  mutedSpaces: settings.mutedSpaces.reduce(
    (acc, spaceId) => ({
      ...acc,
      [spaceId]: true,
    }),
    {}
  ),
  mutedConversations: settings.mutedConversations.reduce(
    (acc, conversationId) => ({
      ...acc,
      [conversationId]: true,
    }),
    {}
  ),
});

export default formatSettings;
