import * as types from '../../constants/actions';

const initialState = {
  isFetching: false,
  hasFetched: false,
  isErrored: false,
};

const fetchDevicesReducer = (state, action) => {
  switch (action.status) {
    case 'started':
      return {
        ...state,
        isFetching: true,
      };
    case 'success':
      return {
        ...initialState,
        isFetching: false,
        hasFetched: true,
      };
    case 'error':
      return {
        ...state,
        isFetching: false,
        hasFetched: false,
        isErrored: true,
      };

    default:
      return state;
  }
};

const devicesReducer = (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case types.USER_FETCH_DEVICES:
      return fetchDevicesReducer(state, action);
    default:
      return state;
  }
};

export default devicesReducer;
