import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const StaffSpacesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${theme.spacing.small};
  padding-bottom: ${theme.spacing.large};
`;

export const AvatarContainer = styled('div')`
  margin-right: ${theme.spacing.medium};
`;
