import { useState, useEffect } from 'react';

/**
 * Hook which returns the value and the set value function.
 * Use this to add data to local storage.
 *
 * e.g.
 * const [name, setName] = useLocalStorage('name', 'Marie');
 *
 * @param {string} key   - key
 * @param {string|object} initialValue - inital value of the data
 * @returns {[object, Function]} - data and set function received for that key.
 */

const useLocalStorage = (key, initialValue) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      setStoredValue(value instanceof Function ? (prev) => value(prev) : value);
      // Save to local storage
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(storedValue));
  }, [storedValue, key]);
  return [storedValue, setValue];
};

export default useLocalStorage;
