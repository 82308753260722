import React from 'react';
import PropTypes from 'prop-types';

import SearchIcon from '@material-ui/icons/Search';

import { Container, Input } from './Filter.styled';

const Filter = ({ title, onChange, filter, ...otherProps }) => {
  const onFilterChange = (event) => onChange(event.target.value);

  return (
    <Container {...otherProps}>
      <SearchIcon fontSize="small" className="searchIcon" />
      <Input
        placeholder={title}
        inputProps={{
          'aria-label': title,
        }}
        disableUnderline
        value={filter}
        onChange={onFilterChange}
      />
    </Container>
  );
};

Filter.propTypes = {
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  filter: PropTypes.string,
};

Filter.defaultProps = {
  filter: '',
};

export default Filter;
