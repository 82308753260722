import React from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import { Tooltip } from '@ublend-npm/aulaui-next';
import {
  container,
  content,
  focused as focusedClass,
  icon,
  searchInput,
} from './ConversationFilter.styled';
import EmojiUtils from '../../../../../core/utils/emoji';
import EmojiButton from '../../common/EmojiButton';

const concatClassNames = (...classNames) =>
  classNames.filter((name) => !!name).join(' ');

const PLACEHOLDER = 'Search for conversations';

const ConversationFilter = ({
  focused,
  onBlur,
  onChange,
  onFocus,
  getInputProps,
  status,
  toggleUserProfile,
  flags,
  filterValue,
}) => (
  <div className={container}>
    <div className={concatClassNames(content, focused && focusedClass)}>
      <SearchIcon className={icon} />
      <input
        className={searchInput}
        aria-label={PLACEHOLDER}
        type="text"
        placeholder={PLACEHOLDER}
        {...getInputProps({
          onBlur,
          onFocus,
          onChange,
          value: filterValue,
        })}
      />
    </div>
    {flags.ftUserStatusEnabled202107 && status?.emojiName && (
      <Tooltip title="Update status" position="top">
        <span>
          <EmojiButton
            onClick={toggleUserProfile}
            emoji={EmojiUtils.getFromShortName(status.emojiName)}
          />
        </span>
      </Tooltip>
    )}
  </div>
);

ConversationFilter.propTypes = {
  focused: PropTypes.bool.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  getInputProps: PropTypes.func.isRequired,
  toggleUserProfile: PropTypes.func.isRequired,
  flags: PropTypes.shape({
    ftUserStatusEnabled202107: PropTypes.bool,
  }).isRequired,
  status: PropTypes.shape({
    text: PropTypes.string,
    emojiName: PropTypes.string,
  }),
  filterValue: PropTypes.string.isRequired,
};

ConversationFilter.defaultProps = {
  status: undefined,
};

export default ConversationFilter;
