import * as actionTypes from '../../constants/actions';
import {
  analyticsChangeUser,
  analyticsChangeClassRoom,
} from '../../utils/analytics';

/**
 * fetchedUser is used in core/actions/startup/index.js
 * when grabbing startup data. Currently it's unclear
 * why we fetch the user again.
 */
export const fetchedUser = (user) => ({
  type: actionTypes.USER_FETCH_CURRENT,
  user,
});

export const logout = () => (dispatch, getState) =>
  new Promise((resolve) => {
    if (!getState().user.isLogged) {
      const err = 'User not logged in'; // new Error('User not logged in');
      resolve(err);
      // logoutError(err, dispatch);
      return;
    }

    dispatch({
      type: actionTypes.USER_LOGOUT,
      status: 'started',
    });

    dispatch({
      type: actionTypes.USER_LOGOUT,
      status: 'success',
    });
    analyticsChangeUser(getState);
    analyticsChangeClassRoom(getState);
  });

export const setNotificationsTabVisible = (isVisible) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_NOTIFICATION_SETTINGS_VISIBLE_TAB,
    isVisible,
  });
};
