import * as types from '../../constants/actions';

const initialState = {
  isCreatingComment: false,
  isFetchingComment: false,
  isFetchingComments: false,
  isFetchingCommentsDone: true,
  isErrored: false,
};

const fetchCommentsReducer = (state, action) => {
  switch (action.status) {
    case 'started':
      return {
        ...state,
        isFetchingComments: true,
      };
    case 'success':
      return {
        ...state,
        isFetchingComments: false,
        isFetchingCommentsDone: action.comments.length === 0,
      };
    case 'error':
      return {
        ...state,
        isFetchingComments: false,
        isErrored: true,
      };
    default:
      return state;
  }
};

const createCommentReducer = (state, action) => {
  switch (action.status) {
    case 'started':
      return {
        ...state,
        isCreatingComment: true,
      };
    case 'success':
    case 'error':
      return {
        ...state,
        isCreatingComment: false,
      };
    default:
      return state;
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.COMMENTS_FETCH:
      return fetchCommentsReducer(state, action);
    case types.COMMENTS_CREATE:
      return createCommentReducer(state, action);
    default:
      return state;
  }
};
