import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';
import BaseDialogTitle from './DialogTitle';

const DialogTitle = styled(BaseDialogTitle)`
  font-size: ${theme.font.size.large};
  display: flex;
  flex-direction: row;
  padding: 20px 24px 16px 24px;
  & > .dialog-title-area {
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    font-weight: ${theme.font.weight.semiBold};
    color: ${theme.color.designSystem.blackOpacity87};
  }
`;

export default DialogTitle;
