import { GetAllLtiProvidersContract } from '@ublend-npm/aula-schema';
import { LTI_PROVIDERS } from '../../constants/endpoints';
import request from '../request';

const fetch = async () => {
  const {
    data: { providers },
  } = await request<GetAllLtiProvidersContract>({
    method: 'get',
    url: LTI_PROVIDERS(),
  });

  return providers;
};

export default fetch;
