export default ({
    onReconnect,
    onConversationReadUpTo,
    onMessage,
    onMessageEdited,
    onParticipantAdded,
    onParticipantLeft,
    onConversationReopened,
    onConversationClosed,
    onTitleChanged,
    onTyping: protoOnTyping,
  }) =>
  (socket) =>
  (dispatch) => {
    // The 'clients' event is sent after the connection with live-server is
    // properly validated. We should not try to reconnect before that.
    // See https://github.com/AulaEducation/aula/issues/4759
    socket.on('clients', (payload) => dispatch(onReconnect(payload)));
    socket.on('Conversation:ReadUpTo', (payload) =>
      dispatch(onConversationReadUpTo(payload))
    );
    socket.on('Conversation:MessageAdded', (payload) =>
      dispatch(onMessage(payload))
    );
    socket.on('Conversation:MessageEdited', (payload) =>
      dispatch(onMessageEdited(payload))
    );
    socket.on('Conversation:ParticipantAdded', (payload) =>
      dispatch(onParticipantAdded(payload))
    );
    socket.on('Conversation:ParticipantLeft', (payload) =>
      dispatch(onParticipantLeft(payload))
    );
    socket.on('Conversation:ParticipantDismissed', (payload) =>
      dispatch(onParticipantLeft(payload))
    );
    socket.on('Conversation:TitleChanged', (payload) =>
      dispatch(onTitleChanged(payload))
    );
    socket.on('Conversation:Reopened', (payload) =>
      dispatch(onConversationReopened(payload))
    );
    socket.on('Conversation:Closed', (payload) =>
      dispatch(onConversationClosed(payload))
    );
    const onTyping = protoOnTyping();
    socket.on('typing', (payload) => dispatch(onTyping(payload)));
  };
