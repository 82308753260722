import { CLASSROOM_MODULE_DELETE_SECTION } from '../actions';
import { deleteSection as apiDeleteSection } from '../api';
import * as selectors from '../selectors';
import { ActionStatus } from '../../constants/actionsStatus';
import { AulaReduxAction } from '../../types/state';
import { Action } from 'redux';
import { getCurrentSpaceId } from '../../selectors/space';
import { isLocalId } from '@core/utils/localId';

const deleteSectionAction = (status: ActionStatus, payload: unknown) => ({
  type: CLASSROOM_MODULE_DELETE_SECTION,
  status,
  payload,
});

type DeleteSectionActionFactory = (_: {
  status: ActionStatus;
  selectSection?: (
    fallbackSectionId: string | null,
    educatorOnly?: boolean
  ) => Action;
  educatorOnly?: boolean;
}) => AulaReduxAction;

const deleteSection: DeleteSectionActionFactory =
  ({ status = 'initiated', selectSection, educatorOnly }) =>
  async (dispatch, getState) => {
    const state = getState();
    const classRoomId = getCurrentSpaceId(state);
    const sectionId = selectors.currentSectionId(state);

    if (!sectionId) {
      return;
    }

    const fallbackSectionId = selectors.fallbackSectionId(
      state,
      sectionId,
      educatorOnly
    );

    const payload = { materialId: sectionId };

    dispatch(deleteSectionAction(status, payload));

    if (status === 'initiated' || status === 'aborted') {
      return;
    }

    try {
      if (!isLocalId(sectionId)) {
        await apiDeleteSection(classRoomId, sectionId);
      }
      dispatch(deleteSectionAction('success', payload));

      if (selectSection) {
        dispatch(selectSection(fallbackSectionId, educatorOnly));
      }
    } catch (error: unknown) {
      dispatch(deleteSectionAction('error', { ...payload, error }));
    }
  };

export default deleteSection;
