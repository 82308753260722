import axios from 'axios';
import { USERS } from '../../constants/endpoints';

const fetchUserBySessionToken = async () => {
  const { data: sessionData } = await axios({
    method: 'get',
    url: `${USERS()}/getBySessionToken`,
    withCredentials: true,
  });

  if (!sessionData.user) {
    throw new Error('No user returned');
  }

  const user = {
    ...sessionData.user,
    // We need these props for backwards compatibility with parse.
    // We were relying on them in a few different places in our state
    // so this will guarantee they still exist. Another option would
    // be to refactor the places where those are used, but to limit
    // the scope of changes, this seemed like a better idea.
    objectId: sessionData.user.id,
    username: sessionData.user.email,
  };

  return user;
};

export default fetchUserBySessionToken;
