import Fuse from 'fuse.js';
import lookup from 'country-code-lookup';
import { getStore } from '../../app/store';
import emojiList from '../../app/utils/emoji';

class EmojiUtils {
  constructor() {
    this.blackList = ['middle_finger'];
    this.emojiListWhiteListed = emojiList
      .filter((e) => !this.blackList.find((b) => b === e.short_name))
      .sort((a, b) => a.sort_order - b.sort_order);

    this.categories = [
      'People',
      'Nature',
      'Foods',
      'Activity',
      'Places',
      'Objects',
      'Symbols',
      'Flags',
      'Custom',
    ];

    this.cachedEmojis = null;
    this.cachedEmojisCategory = null;
    this.cachedFuse = null;
    this.currentClassRoom = null;

    this.flagRegExp = new RegExp('^flag-[a-z]{2}$');
  }

  getStore() {
    if (!this.store) {
      this.store = getStore();
    }

    return this.store;
  }

  getState() {
    const store = this.getStore();
    if (store) {
      return store.getState();
    }

    return null;
  }

  hasClassRoomChanged() {
    const state = this.getState();
    if (state) {
      if (
        !state.classRoom.current ||
        state.classRoom.current !== this.currentClassRoom
      ) {
        return true;
      }

      return false;
    }

    return true;
  }

  get() {
    const state = this.getState();
    if (state) {
      if (this.cachedEmojis && !this.hasClassRoomChanged()) {
        return this.cachedEmojis;
      }

      if (state.emojis.fetched) {
        if (!this.cachedEmojis || this.hasClassRoomChanged()) {
          this.cachedEmojis = [
            ...state.emojis.emojis
              .map((e) => ({
                ...e,
                short_name: e.shortName,
              }))
              .filter((e) => {
                if (!e.classRoom) {
                  return true;
                }
                if (e.classRoom && e.classRoom === state.classRoom.current) {
                  return true;
                }

                return false;
              }),
            ...this.emojiListWhiteListed,
          ];
          this.currentClassRoom = state.classRoom.current;
        }
        return this.cachedEmojis;
      }
    }

    return this.emojiListWhiteListed;
  }

  getByCategory() {
    if (this.cachedEmojisCategory && !this.hasClassRoomChanged()) {
      return this.cachedEmojisCategory;
    }

    const emojis = this.get();
    const sortedByCategory = emojis.reduce((r, e) => {
      const category = e.category || 'Custom';

      if (r[category]) {
        return {
          ...r,
          [category]: [...r[category], e],
        };
      }

      return {
        ...r,
        [category]: [e],
      };
    }, {});

    if (this.cachedEmojis) {
      this.cachedEmojisCategory = sortedByCategory;
    }

    return sortedByCategory;
  }

  getFromShortName(shortName) {
    return this.get().find((e) => e.short_name === shortName);
  }

  getAriaLabel(shortName) {
    if (this.flagRegExp.test(shortName)) {
      const entity = lookup.byIso(shortName.split('-')[1]);
      if (entity != null) return `Flag of ${entity.country}`;
    } else {
      return shortName.replace(/_/g, ' ').replace(/-/g, ' ');
    }
    return shortName;
  }

  search(searchValue) {
    let searchResults = [];

    if (!this.cachedFuse) {
      const fuseOptions = {
        shouldSort: true,
        threshold: 0.1,
        distance: 1000,
        keys: ['short_name'],
      };

      const fuse = new Fuse(this.get(), fuseOptions);
      if (this.cachedEmojis && !this.cachedFuse) {
        this.cachedFuse = fuse;
      }
      searchResults = fuse.search(searchValue);
    } else {
      searchResults = this.cachedFuse.search(searchValue);
    }

    return searchResults;
  }
}

const emojiUtils = new EmojiUtils();

export default emojiUtils;
