import React from 'react';
import styled from 'react-emotion';
import BaseTabs from '@material-ui/core/Tabs';
import BaseTab from '@material-ui/core/Tab';
import { theme } from '@ublend-npm/aulaui-next';

export const Tabs = styled((props) => (
  <BaseTabs {...props} classes={{ indicator: 'indicator', fixed: 'fixed' }} />
))`
  & .indicator {
    background-color: ${theme.color.purple};
    min-width: 40px;
  }

  & .fixed {
    height: 40px;
  }
`;

export const Tab = styled((props) => (
  <BaseTab
    {...props}
    classes={{
      root: props.classes ? props.classes : '',
      selected: 'selected',
      wrapper: 'wrapper',
    }}
  />
))`
  text-transform: none;
  padding: 0px;
  height: 40px;
  min-width: 40px;
  min-height: 0px;
  font-family: ${theme.font.family.workSans};
  font-size: ${theme.font.size.copy};
  font-weight: ${theme.font.weight.semiBold};

  &.selected {
    color: ${theme.color.designSystem.purple70};
  }

  &.showFocus:focus {
    background: ${theme.color.designSystem.background.lightPurple.focus};
  }

  & > span.wrapper > span {
    padding-left: 12px;
    padding-right: 12px;
  }
`;
