export const toggleStarredSpace = (state, action) => {
  if (action.status !== 'success') {
    return state;
  }

  const { spaceId, isStarred } = action.payload;

  return {
    ...state,
    classRooms: {
      ...state.classRooms,
      [spaceId]: {
        ...state.classRooms[spaceId],
        isStarred,
      },
    },
  };
};
