import inboxv2 from './conversations';
import groups from './groups';
import materials from './materials';
import liveNotifications from './notifications';
import toasts from './toasts';
import reactions from './reactions';
import assignments from './assignments';

export default () => ({
  inboxv2,
  groups,
  materials,
  liveNotifications,
  toasts,
  reactions,
  assignments,
});
