import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SpaceFilterContainer = styled.div`
  padding-top: ${({ topPadding }) => (topPadding ? '16px' : 0)};
  padding-left: ${theme.spacing.medium};
  padding-right: ${theme.spacing.medium};
`;

export const ArchiveToggleContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: ${theme.font.family.workSans};
  font-weight: ${theme.font.weight.regular};
  font-size: ${theme.font.size.copy};
  line-height: ${theme.font.lineHeight.copy};
  padding: 4px 0;
`;

export const AdditionalOptionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${(props) =>
    props.hasArchivedSpaces ? `-${theme.spacing.xxSmall}` : 0};
  padding-bottom: ${(props) =>
    props.hasArchivedSpaces ? 0 : theme.spacing.small};
  padding-top: ${(props) =>
    props.hasArchivedSpaces ? 0 : theme.spacing.small};
`;

export const TextButtonStyle = {
  fontFamily: 'Work Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '16px',
  padding: 0,
};

export const OptionsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const OptionsHeaderText = styled.span`
  padding-right: ${theme.spacing.xxSmall};
`;
