import PropTypes from 'prop-types';
import React from 'react';
import { Group, GroupIconContainer } from './ConversationIcon.styled';
import OneToOneConversationIcon from './OneToOneConversationIcon.container';
import EducatorConversationIcon from './EducatorConversationIcon';

const ConversationIcon = ({
  className,
  conversationId,
  isGroup,
  educatorOnly,
  title,
}) => {
  if (educatorOnly) {
    return <EducatorConversationIcon />;
  }
  if (isGroup) {
    return (
      <GroupIconContainer title={title}>
        <Group className={className} />
      </GroupIconContainer>
    );
  }
  return (
    <OneToOneConversationIcon
      className={className}
      conversationId={conversationId}
    />
  );
};

ConversationIcon.propTypes = {
  className: PropTypes.string,
  conversationId: PropTypes.string.isRequired,
  isGroup: PropTypes.bool,
  educatorOnly: PropTypes.bool,
  title: PropTypes.string,
};

ConversationIcon.defaultProps = {
  className: '',
  isGroup: true,
  educatorOnly: false,
  title: '',
};

export default ConversationIcon;
