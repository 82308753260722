const locationChanged = (state, action) => {
  if (!action) {
    return state;
  }

  // We don't need to set groupsLoaded to false when we open or close a conversation
  if (action.payload.pathname.includes(state.selectedGroupSetId)) {
    return state;
  }

  const groupsUrlPattern = /\/dashboard\/[A-Za-z0-9-]+\/people\/groups/;

  if (groupsUrlPattern.test(action.payload.pathname)) {
    const groupIdRegex =
      /\/dashboard\/[A-Za-z0-9-]+\/people\/groups\/([A-Za-z0-9-]+)/;

    const match = action.payload.pathname.match(groupIdRegex);

    const groupId = match && match[1];
    return {
      ...state,
      groupsLoaded: false,
      selectedGroupSetId: groupId,
    };
  }

  return state;
};

export default locationChanged;
