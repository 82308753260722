const registerLive =
  ({ onUserUpdated }) =>
  (socket) =>
  (dispatch) => {
    socket.on('User:UserUpdated', (payload) =>
      dispatch(onUserUpdated(payload))
    );
  };

export default registerLive;
