import { INBOX_OPEN_CLOSE_RENAME_GROUP } from '../actions';

const closeRenameGroup = () => (dispatch) =>
  dispatch({
    type: INBOX_OPEN_CLOSE_RENAME_GROUP,
    payload: {
      renameGroupOpened: false,
    },
  });

export default closeRenameGroup;
