const fetchAssignments = (state, action) => {
  switch (action.status) {
    case 'started':
      return {
        ...state,
        isFetching: true,
        isErrored: false,
      };
    case 'success':
      return {
        ...state,
        hasFetchedAssignments: true,
        isFetching: false,
        isErrored: false,
      };
    case 'error':
      return {
        ...state,
        isFetching: false,
        isErrored: true,
      };
    default:
      return state;
  }
};

export default fetchAssignments;
