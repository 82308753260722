const formatReaction = (reaction) => {
  const formatted = { ...reaction };
  formatted.id = reaction.objectId || reaction.id;
  delete formatted.objectId;
  return formatted;
};

const fetchReactionsReducer = (state, action) => {
  const {
    status,
    payload: { reactions },
  } = action;
  if (status !== 'success') {
    return state;
  }

  if (!reactions.length) {
    return state;
  }

  const reactionsById = reactions.reduce((acc, reaction) => {
    const formatted = formatReaction(reaction);
    return {
      ...acc,
      [formatted.id]: formatted,
    };
  }, {});

  return {
    ...state,
    ...reactionsById,
  };
};

export default fetchReactionsReducer;
