import { CLASSROOM_MODULE_LIVE_DELETED_MATERIAL } from '../actions';
import { Material } from '../types';
import { AulaDispatch } from '../../types/state';

export type LiveDeletedMaterial = typeof liveDeletedMaterial;

const liveDeletedMaterial =
  (dispatch: AulaDispatch) => (materialId: Pick<Material, 'id'>) =>
    dispatch({
      type: CLASSROOM_MODULE_LIVE_DELETED_MATERIAL,
      payload: { material: materialId },
    });

export default liveDeletedMaterial;
