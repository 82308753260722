import { FileFormatError, FileFormatVersionError } from './errors';
import v2Importer, {
  JsonMaterial,
  MaterialFormat,
  Save,
  V2Importer,
} from './v2Importer';

const inCorrectFormat = (
  parsedMaterial: MaterialFormat | JsonMaterial[]
): MaterialFormat => {
  if (Array.isArray(parsedMaterial)) {
    return {
      version: 'v1',
      material: parsedMaterial,
    };
  }

  if (parsedMaterial.version === 'v2') {
    return parsedMaterial;
  }

  throw new FileFormatVersionError();
};

export type FileImporter = (fileContent: string) => ReturnType<V2Importer>;

const rawImporter =
  (save: Save, userId: string) => async (fileContent: string) => {
    try {
      const parsedMaterial = JSON.parse(fileContent);

      return v2Importer(save, userId, inCorrectFormat(parsedMaterial));
    } catch (error) {
      throw new FileFormatError();
    }
  };

export { rawImporter };
export default rawImporter;
