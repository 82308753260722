import { operations } from '../../store/conversations';
import selectConversation from './selectConversation.action';

const createConversationWithOneUser = (user) => async (dispatch) => {
  const { objectId: conversationId } = await dispatch(
    operations.createConversation({
      title: '-',
      users: [user],
    })
  );
  return dispatch(selectConversation(conversationId));
};

export default createConversationWithOneUser;
