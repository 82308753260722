import { getStore } from '../store';
import {
  isInstructor as isInstructorSelector,
  isInstructorInSpace as isInstructorInSpaceSelector,
} from '../../core/selectors/classroom';

export const isInstructor = (userId) => {
  const state = getStore().getState();

  return isInstructorSelector(state, userId);
};

export const isInstructorInSpace = (userId, spaceId) => {
  const state = getStore().getState();

  return isInstructorInSpaceSelector(state)(userId, spaceId);
};

/**
 * Returns array of spaces in which user is instructor
 */
export const spacesInWhichInstructor = () => {
  const state = getStore().getState();

  return state.classRoomUser.self
    .map(
      (objectId) =>
        state.data.classRooms[state.data.classRoomUsers[objectId].classRoom]
    )
    .filter(
      (classRoom) =>
        !!classRoom &&
        isInstructorInSpace(state.user.user.objectId, classRoom.objectId)
    );
};

export const getFullName = (userData) => {
  const { firstName = '', lastName = '', email = '', username = '' } = userData;
  const hasName =
    (typeof firstName === 'string' && firstName.length > 0) ||
    (typeof lastName === 'string' && lastName.length > 0);
  return hasName ? `${firstName} ${lastName}` : email || username;
};
