const deleteSubmission = (state, action) => {
  const {
    payload: { assignmentId },
  } = action;
  const submission = {
    ...state.submissions[assignmentId],
  };
  switch (action.status) {
    case 'started':
      submission.deleting = true;
      break;
    case 'success': {
      submission.deleting = false;
      break;
    }
    case 'error':
      submission.deleting = false;
      break;
    default:
      return state;
  }

  return {
    ...state,
    submissions: {
      ...state.submissions,
      [assignmentId]: submission,
    },
  };
};

export default deleteSubmission;
