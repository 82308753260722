import axios from 'axios';
import { setCurrentUser } from '../../utils/currentUser';
import { newError } from '../errors';
import * as actionTypes from '../../constants/actions';
import * as endpoints from '../../constants/endpoints';
import fetchUserBySessionToken from '../../api/users/fetchUserBySessionToken';

export const loginStart = () => ({
  type: actionTypes.USER_LOGIN,
  status: 'started',
});

export const loginSuccess = (user, dispatch) => {
  dispatch({
    type: actionTypes.USER_LOGIN,
    status: 'success',
    user,
  });
};

export const loginError = (error, dispatch) => {
  dispatch(newError(error, 'login'));
  dispatch({
    type: actionTypes.USER_LOGIN,
    status: 'error',
    error,
  });
};

export const loginWithSecureAuthCookie = () => (dispatch) =>
  new Promise(async (resolve) => {
    dispatch(loginStart());

    try {
      const user = await fetchUserBySessionToken();

      setCurrentUser(user);
      loginSuccess(user, dispatch);

      resolve(user);
    } catch (fetchUserByTokenError) {
      loginError(fetchUserByTokenError, dispatch);

      resolve({
        error: fetchUserByTokenError.message,
      });
    }
  });

const requestLoginStart = () => ({
  type: actionTypes.REQUEST_LOGIN,
  status: 'started',
});

const requestLoginSuccess = (dispatch) => {
  dispatch({
    type: actionTypes.REQUEST_LOGIN,
    status: 'success',
  });
};

const requestLoginError = (error, dispatch) => {
  dispatch(newError(error, 'login'));
  dispatch({
    type: actionTypes.REQUEST_LOGIN,
    status: 'error',
    error,
  });
};

export const requestLogin = (email) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(requestLoginStart());
    axios
      .post(endpoints.LOGIN_CODE_REQUEST(), {
        email,
      })
      .then(
        () => {
          requestLoginSuccess(dispatch);
          resolve();
        },
        (err) => {
          requestLoginError(err, dispatch);
          reject(err);
        }
      );
  });
