import { GROUPS_OPEN_CLOSE_GENERATE_GROUPS } from '../actions';

export const openGenerateGroups = (setId) => (dispatch) =>
  dispatch({
    type: GROUPS_OPEN_CLOSE_GENERATE_GROUPS,
    payload: { generateGroupsDialogOpenFor: setId },
  });

export const closeGenerateGroups = (dispatch) =>
  dispatch({
    type: GROUPS_OPEN_CLOSE_GENERATE_GROUPS,
    payload: { generateGroupsDialogOpenFor: null },
  });
