export const FETCH_ASSIGNMENT = 'FETCH_ASSIGNMENT';
export const FETCH_ASSIGNMENTS = 'FETCH_ASSIGNMENTS';
export const CREATE_ASSIGNMENT = 'CREATE_ASSIGNMENT';
export const CONVERT_ASSIGNMENT = 'CONVERT_ASSIGNMENT';
export const EDIT_ASSIGNMENT = 'EDIT_ASSIGNMENT';
export const CLOSE_ASSIGNMENT = 'CLOSE_ASSIGNMENT';
export const REOPEN_ASSIGNMENT = 'REOPEN_ASSIGNMENT';
export const DELETE_ASSIGNMENT = 'DELETE_ASSIGNMENT';
export const SEARCH_ASSIGNMENT = 'SEARCH_ASSIGNMENT';
export const CHANGE_ASSIGNMENTS_SELECTED_SPACE =
  'CHANGE_ASSIGNMENTS_SELECTED_SPACE';
export const FETCH_SUBMISSIONS = 'FETCH_SUBMISSIONS';
export const FETCH_ALL_SUBMISSIONS_INCLUDING_EXTENSIONS =
  'FETCH_ALL_SUBMISSIONS_INCLUDING_EXTENSIONS';
export const DELETE_SUBMISSION = 'DELETE_SUBMISSION';
export const RESET_ERROR_STATE = 'RESET_ERROR_STATE';
export const TOGGLE_HIDE_ASSIGNMENT = 'TOGGLE_HIDE_ASSIGNMENT';
