import styled from 'react-emotion';
import { TableCell } from '@material-ui/core';
import { theme } from '@ublend-npm/aulaui-next';

export const Container = styled('div')``;

export const HeaderCell = styled(TableCell)`
  font-size: 14px;
  font-weight: ${theme.font.weight.medium};
  color: ${theme.color.grey11};
  padding-left: 12px;
  border: none;
`;

export const CheckBoxCell = styled(TableCell)`
  font-size: 14px;
  font-weight: ${theme.font.weight.medium};
  color: ${theme.color.grey11};
  border: none;
  width: 72px;
  text-align: center;
  padding: 0;
`;

export const RowCell = styled(TableCell)`
  font-size: 14px;
  font-weight: ${theme.font.weight.medium};
  border: none;
  color: ${theme.color.black};
  padding-left: 12px;
`;

export const Caption = styled('caption')`
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;
