import React from 'react';
import PropTypes from 'prop-types';

import { Badge as StyledBadge } from './Badge.styled';

const Badge = React.forwardRef((props, ref) => (
  <StyledBadge ref={ref} {...props} overlap="rectangular" />
));

Badge.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

Badge.defaultProps = {
  size: 'medium',
};

export default Badge;
