import { push } from 'react-router-redux';
import { operations } from '../../store/conversations';

const selectConversation = (conversationId) => (dispatch, getState) => {
  const { pathname } = getState().routing.locationBeforeTransitions;

  dispatch(operations.highlightMessage(null));
  dispatch(operations.dismissReplyToMessage());
  dispatch(
    push({
      pathname,
      query: {
        inbox: conversationId,
      },
    })
  );
};

export default selectConversation;
