import { GenericAction } from '../../types/redux';
import { CLASSROOM_OPEN_CLOSE_DELETE_FOLDER } from '../actions';
import { MaterialAppReducer } from './state';

export type OpenCloseDeleteFolderAction = GenericAction<
  typeof CLASSROOM_OPEN_CLOSE_DELETE_FOLDER,
  { deleteFolderDialogOpenFor: string }
>;

const openCloseDeleteFolder: MaterialAppReducer<OpenCloseDeleteFolderAction> = (
  state,
  action
) => ({
  ...state,
  deleteFolderDialogOpenFor: action.payload.deleteFolderDialogOpenFor,
});

export default openCloseDeleteFolder;
