import { newError } from '../errors';
import * as actionTypes from '../../constants/actions';
import { analyticsTrackEvent } from '../../utils/analytics';
import * as analytics from '../../constants/analytics';
import { currentClassRoomId } from '../../selectors/classroom';
import uploadAvatar from '../../utils/uploadAvatar';
import patchSpace from '../../api/spaces/patchSpace';

const changeClassroomAvatarStart = (dispatch) => {
  dispatch({
    type: actionTypes.CLASSROOM_CHANGE_AVATAR,
    status: 'started',
  });
};

const changeClassroomAvatarSuccess = (objectId, url, dispatch) => {
  dispatch({
    type: actionTypes.CLASSROOM_CHANGE_AVATAR,
    status: 'success',
    classroomId: objectId,
    url,
  });
};

const changeClassroomAvatarError = (error, dispatch) => {
  dispatch(newError(error, 'classroomChangeAvatar'));
  dispatch({
    type: actionTypes.CLASSROOM_CHANGE_AVATAR,
    status: 'error',
    error,
  });
};

const changeClassroomAvatar = (file) => async (dispatch, getState) => {
  changeClassroomAvatarStart(dispatch);
  try {
    const state = getState();
    const url = await uploadAvatar({
      file,
    });
    const spaceId = currentClassRoomId(state);
    await patchSpace(spaceId, { avatar: url });
    changeClassroomAvatarSuccess(spaceId, url, dispatch);
    analyticsTrackEvent(analytics.CLASSROOM_CHANGE_AVATAR, {
      activeClassroom: spaceId,
    });
  } catch (error) {
    changeClassroomAvatarError(error, dispatch);
    throw error;
  }
};

export default changeClassroomAvatar;
