export const isScheduledPost = (post) =>
  !!post.scheduledFor && !post.publishedAt;

export const comparePostsByDate = (order) => (first, second) => {
  const firstDate = new Date(first.scheduledFor || first.createdAt);
  const secondDate = new Date(second.scheduledFor || second.createdAt);
  return order * (firstDate.getTime() - secondDate.getTime());
};

export const ASCENDING = 1;
export const DESCENDING = -1;

export const getSortedDistinctPostIds = ({
  existingIds,
  newIds,
  posts,
  order,
}) => {
  const distinctIds = [...new Set([...existingIds, ...newIds])];
  return distinctIds
    .map((id) => posts[id])
    .filter((post) => !!post)
    .sort(comparePostsByDate(order))
    .map((p) => p.objectId);
};

export const addUpdatingId = ({ state, id }) => {
  const { postIdsUpdating: ids = [] } = state;
  if (!id || (ids && ids.includes(id))) {
    return ids;
  }

  return [...ids, id];
};

export const removeUpdatingId = ({ state, id }) => {
  const { postIdsUpdating: ids = [] } = state;
  if (!id || !ids.length || !ids.includes(id)) {
    return ids;
  }

  return ids.filter((idInArray) => idInArray !== id);
};

export const sortImportantPostsIds = (importantPostsIds, posts) => {
  if (!importantPostsIds) {
    return null;
  }

  return importantPostsIds.sort(
    (postId1, postId2) =>
      new Date(posts?.[postId2].createdAt) -
      new Date(posts?.[postId1].createdAt)
  );
};

export const computeNewImportantPosts = ({
  posts = [],
  newPost,
  importantPosts,
}) => {
  if (!importantPosts) {
    return null;
  }

  const newPostId = newPost.objectId;
  const oldPost = posts[newPostId];
  const wasImportant = !!oldPost && oldPost.important;
  let newImportantPosts = [...importantPosts];

  if (wasImportant && !newPost.important) {
    newImportantPosts = importantPosts.filter((postId) => postId !== newPostId);
  }

  if (!wasImportant && newPost.important) {
    newImportantPosts.push(newPostId);
  }

  return sortImportantPostsIds(newImportantPosts, posts);
};

export const computeNewImportantPostCount = ({
  posts = [],
  newPost,
  importantPostCount,
}) => {
  const oldPost = posts[newPost.objectId];
  const wasImportant = !!oldPost && oldPost.important;

  if (wasImportant && !newPost.important) {
    return Math.max((importantPostCount || 0) - 1, 0);
  }

  if (!wasImportant && newPost.important) {
    return (importantPostCount || 0) + 1;
  }

  return importantPostCount;
};
