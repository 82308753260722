import { INBOX_CONVERSATION } from '../../../constants/endpoints';
import request from './request';

export default async (conversationId, title) => {
  const config = {
    url: INBOX_CONVERSATION(conversationId),
    method: 'put',
    data: {
      conversation: { title },
    },
  };
  const response = await request(config);
  return response.data.conversation;
};
