import { connect } from 'react-redux';
import NotificationsSettings from './Table';
import { operations, selectors } from '../../../../../store/notifications';

const mapStateToProps = (state) => ({
  hasFetchedSettings: selectors.hasFetchedSettings(state),
  isFetchingSettings: selectors.isFetchingSettings(state),
  isSavingSettings: selectors.isSavingSettings(state),
  settings: selectors.notificationSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  toggleSetting: (settings) => (type, value, enabled) => {
    const settingsPatch = {
      [type]: {
        ...settings[type],
        [value]: enabled,
      },
    };
    dispatch(operations.saveNotificationSettings(settingsPatch));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { toggleSetting } = dispatchProps;
  const { settings } = stateProps;

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    toggleSetting: toggleSetting(settings),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(NotificationsSettings);
