const cypress = () => (next) => (action) => {
  if (typeof action === 'object') {
    window.Cypress.log({
      name: 'action',
      message: action.type,
      consoleProps: () => action,
    });
  }

  next(action);
};

export default cypress;
