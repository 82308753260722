const searchParticipantsInGroups = (state, action) => {
  if (action.status !== 'success') {
    return state;
  }

  return {
    ...state,
    searchedSets: action.sets,
  };
};

export default searchParticipantsInGroups;
