import { connect } from 'react-redux';
import { selectors as inboxSelectors } from '../../../../store/conversations';
import ConversationIcon from './ConversationIcon';

const mapStateToProps = (state, ownProps) => ({
  isGroup: inboxSelectors.isGroupConversation(state, ownProps.conversationId),
  title: inboxSelectors.conversationTitle(state, ownProps.conversationId),
  educatorOnly: inboxSelectors.isEducatorConversation(
    state,
    ownProps.conversationId
  ),
});

export default connect(mapStateToProps)(ConversationIcon);
