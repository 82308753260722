import { Entities } from '../../utils/commentsEntityTypes';

/* eslint-disable-next-line import/prefer-default-export */
export const fetchCommentsReducer = (state, action) => {
  switch (action.status) {
    case 'started':
      return state;
    case 'success': {
      const { comments, entityId, entityType } = action.payload;

      if (!comments.length) {
        return state;
      }

      const groupedReplies = comments.reduce((acc, comment) => {
        if (!comment.root) {
          return acc;
        }
        return {
          ...acc,
          [comment.root]: [...(acc[comment.root] || []), comment].sort(),
        };
      }, {});

      const entityComments = comments.reduce(
        (acc, comment) => ({
          ...acc,
          [comment.id]: {
            ...comment,
            user: comment.user || {
              firstName: 'Deleted user',
              lastName: '',
            },
          },
        }),
        {}
      );
      const compareDates = (a, b) =>
        new Date(a.createdAt) - new Date(b.createdAt);
      const entityCommentsWithReplies = Object.keys(entityComments).reduce(
        (acc, commentId) => ({
          ...acc,
          [commentId]: {
            ...entityComments[commentId],
            replies: (groupedReplies[commentId] || [])
              .sort(compareDates)
              .map(({ id }) => id),
          },
        }),
        {}
      );

      const entityKey = Entities[entityType];

      const updatedEntity = {
        ...state[entityKey][entityId],
        comments: Object.keys(entityCommentsWithReplies)
          .reduce((acc, commentId) => {
            const comment = entityCommentsWithReplies[commentId];
            if (comment.root) {
              return acc;
            }
            return [...acc, comment];
          }, [])
          .sort(compareDates)
          .map((comment) => comment.id),
      };

      return {
        ...state,
        comments: {
          ...state.comments,
          ...entityCommentsWithReplies,
        },
        [entityKey]: {
          ...state[entityKey],
          [entityId]: updatedEntity,
        },
      };
    }
    case 'error':
      return {
        ...state,
        isErrored: true,
        error: action.error,
      };

    default:
      return state;
  }
};

export const createCommentReducer = (state, action) => {
  switch (action.status) {
    case 'started':
      return state;
    case 'success': {
      const { payload } = action;
      // Do not dispatch if comment already exists
      if (state.comments[payload.comment.id]) {
        return state;
      }

      const entityKey = Entities[payload.comment.entityType];

      const comment = {
        ...payload.comment,
        local: true,
        replies: [],
      };
      const entity = { ...state[entityKey][comment.entityId] };
      if (!entity || !Object.keys(entity).length) {
        return state;
      }

      entity.comments = [
        ...new Set([
          ...(entity.comments ? entity.comments : []),
          ...(!comment.root ? [comment.id] : []),
        ]),
      ];

      const updatedRootComment = {};
      if (comment.root && state.comments[comment.root]) {
        const rootComment = state.comments[comment.root];
        updatedRootComment[comment.root] = {
          ...rootComment,
          replies: [...rootComment.replies, comment.id],
        };
      }

      return {
        ...state,
        comments: {
          ...state.comments,
          ...updatedRootComment,
          [comment.id]: comment,
        },
        [entityKey]: {
          ...state[entityKey],
          [comment.entityId]: entity,
        },
      };
    }
    case 'error':
      return {
        ...state,
        error: action.error,
        commentData: action.commentData,
      };
    default:
      return state;
  }
};

export const editCommentReducer = (state, action) => {
  switch (action.status) {
    case 'success': {
      const { comment } = action;
      const commentId = comment.id;
      return {
        ...state,
        comments: {
          ...state.comments,
          [commentId]: {
            ...state.comments[commentId],
            ...comment,
          },
        },
      };
    }
    default:
      return state;
  }
};

export const deleteCommentReducer = (state, action) => {
  switch (action.status) {
    case 'success': {
      const { comment } = action;
      const entityKey = Entities[comment.entityType];

      const commentId = comment.id;
      const deletedComment = state.comments[commentId];
      if (!deletedComment) {
        return state;
      }
      const rootCommentId = deletedComment.root;
      const updatedRootComment = rootCommentId
        ? {
            [rootCommentId]: {
              ...state.comments[rootCommentId],
              replies: state.comments[rootCommentId].replies.filter(
                (id) => id !== commentId
              ),
            },
          }
        : {};

      const updatedComments = {
        ...state.comments,
        ...updatedRootComment,
      };
      delete updatedComments[commentId];
      deletedComment.replies.forEach((id) => delete updatedComments[id]);

      const { entityId } = deletedComment;

      const updatedEntity = { ...state[entityKey][entityId] };
      updatedEntity.comments = updatedEntity.comments.filter(
        (id) => id !== commentId
      );

      return {
        ...state,
        [entityKey]: {
          ...state[entityKey],
          [entityId]: updatedEntity,
        },
        comments: updatedComments,
      };
    }

    default:
      return state;
  }
};
