import styled from 'react-emotion';
import UnstyledGroup from '@material-ui/icons/Group';
import {
  theme,
  styleUtils,
  Avatar as AUIAvatar,
} from '@ublend-npm/aulaui-next';

const getGroupIconColor = ({ title }) =>
  styleUtils.color.picker({
    seed: title,
    choices: [
      theme.color.designSystem.purple60,
      theme.color.designSystem.success,
      theme.color.designSystem.error,
    ],
  });

export const Avatar = styled(AUIAvatar)`
  width: 24px;
  height: 24px;
`;

export const Group = styled(UnstyledGroup)`
  color: ${theme.color.white};
  width: 20px;
  height: 20px;
`;

export const GroupIconContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'title',
})`
  width: 24px;
  height: 24px;
  background-color: ${(props) => getGroupIconColor(props)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`;
