import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';

const trackStyle = {
  width: 8,
  right: 4,
  bottom: 2,
  top: 2,
  borderRadius: 3,
};

const getViewProps = (defaultProps, givenViewProps) => {
  const { style, ...otherGivenViewProps } = givenViewProps;
  const props = {
    ...defaultProps,
    style: {
      ...defaultProps.style,
      ...style,
      overflowX: 'hidden',
    },
    ...otherGivenViewProps,
  };
  return props;
};

const ScrollBarWrapper = ({
  thumbSize,
  children,
  scrollBarRef,
  pathname,
  resetScrollOnMount,
  style,
  onScrollFrame,
  autoHide,
  viewProps,
  attributes,
}) => {
  const localScrollBarRef = useRef(null);
  useEffect(() => {
    if (localScrollBarRef.current && resetScrollOnMount) {
      localScrollBarRef.current.scrollToTop();
    }
  }, [pathname]);

  return (
    <Scrollbars
      ref={(ref) => {
        scrollBarRef(ref);
        localScrollBarRef.current = ref;
      }}
      style={style}
      onScrollFrame={onScrollFrame}
      autoHide={autoHide}
      renderTrackVertical={(propsData) => (
        <div style={{ ...propsData.style, ...trackStyle }} />
      )}
      renderThumbVertical={(data) => (
        <div
          style={{
            ...data.style,
            backgroundColor: 'rgba(0,0,0,0.4)',
            ...(thumbSize ? { height: thumbSize } : {}),
            cursor: 'pointer',
            borderRadius: 4,
          }}
        />
      )}
      renderView={(defaultViewProps) => (
        <div
          {...attributes}
          {...getViewProps(defaultViewProps, viewProps)}
          className="scroll-view"
        />
      )}
    >
      {children}
    </Scrollbars>
  );
};

ScrollBarWrapper.propTypes = {
  viewProps: PropTypes.shape({}),
  scrollBarRef: PropTypes.func,
  thumbSize: PropTypes.number,
  pathname: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  resetScrollOnMount: PropTypes.bool,
  style: PropTypes.shape({}),
  onScrollFrame: PropTypes.func,
  autoHide: PropTypes.bool,
  attributes: PropTypes.shape({}),
};

ScrollBarWrapper.defaultProps = {
  thumbSize: null,
  children: null,
  scrollBarRef: () => {},
  viewProps: {},
  resetScrollOnMount: true,
  style: {},
  onScrollFrame: null,
  autoHide: null,
  attributes: {},
};

export default ScrollBarWrapper;
