import cancellableRequest from '../../api/cancellableRequest';
import { GROUP_SET } from '../../constants/endpoints';
import request from './request';

const fetchGroupSet =
  (cancelToken) =>
  async (accessToken, { groupSetId }) => {
    const config = {
      method: 'get',
      url: GROUP_SET(groupSetId),
      cancelToken,
    };

    const response = await request(accessToken, config);
    return response.data;
  };

export default cancellableRequest(fetchGroupSet).fn;
