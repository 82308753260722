const markFetching = (store, conversationId) => ({
  ...store,
  [conversationId]: true,
});

const markDoneFetching = (store, conversationId) => {
  const newStore = { ...store };
  delete newStore[conversationId];
  return newStore;
};

const fetchMessageReducer = (state, action) => {
  const { conversationId } = action.payload;
  const store = { ...state.fetchingMessage };

  switch (action.status) {
    case 'started':
      return {
        ...state,
        fetchingMessage: markFetching(store, conversationId),
      };
    case 'success':
      return {
        ...state,
        fetchingMessage: markDoneFetching(store, conversationId),
      };
    case 'error':
      return {
        ...state,
        fetchingMessage: markDoneFetching(store, conversationId),
      };
    default:
      return state;
  }
};

export default fetchMessageReducer;
