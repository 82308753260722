import { INBOX_OPEN_CLOSE_LEAVE_GROUP } from '../actions';

const closeLeaveGroup = () => (dispatch) =>
  dispatch({
    type: INBOX_OPEN_CLOSE_LEAVE_GROUP,
    payload: {
      leaveGroupOpened: false,
    },
  });

export default closeLeaveGroup;
