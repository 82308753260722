import {
  SPACE_SELECTOR_ITEM_TYPE,
  SPACE_SELECTOR_SUB_CATEGORY_SELECTED,
} from '../../constants/analytics';
import { analyticsTrackEvent } from '../../utils/analytics';
import { openTimestamp, openCorrelationId } from '../selectors';
import getNow from '../getNow';

const subCategorySelectedSpaceSelector =
  (category, subCategory) => (_dispatch, getState) => {
    const state = getState();

    analyticsTrackEvent(SPACE_SELECTOR_SUB_CATEGORY_SELECTED, {
      itemType: SPACE_SELECTOR_ITEM_TYPE,
      selectorOpenFor: Math.round(getNow() - openTimestamp(state)),
      correlationId: openCorrelationId(state),
      category,
      subCategory,
      categoriesEnabled: true,
    });
  };

export default subCategorySelectedSpaceSelector;
