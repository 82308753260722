import { FetchSectionsAction } from '../sharedActions';
import { Material } from '../types';
import { MaterialAppReducer, MaterialsAppState } from './state';

const getFirstSectionId = (materials: Material[]) =>
  materials.find((material) => !material.isFolder)?.id || null;

const getCurrentSectionId = (state: MaterialsAppState, materials: Material[]) =>
  state.currentSection || getFirstSectionId(materials);

const fetchSectionsAppReducer: MaterialAppReducer<FetchSectionsAction> = (
  state,
  action
) => {
  switch (action.status) {
    case 'started':
      return { ...state, isFetchingSections: true, isErrored: false };
    case 'success':
      return {
        ...state,
        hasFetchedSections: true,
        isFetchingSections: false,
        isErrored: false,
        currentSection: getCurrentSectionId(state, action.payload.materials),
      };
    case 'error':
      return {
        ...state,
        hasFetchedSections: false,
        isFetchingSections: false,
        isErrored: true,
      };
    default:
      return state;
  }
};

export default fetchSectionsAppReducer;
