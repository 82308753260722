import createFolder from './createFolder.operation';
import createSection from './createSection.operation';
import deleteSection from './deleteSection.operation';
import getSection from './getSection.operation';
import getVersion from './getVersion.operation';
import displayRevertModal from './displayRevertModal.operation';
import fetchSections from './fetchSections.operation';
import { hideMaterial, unHideMaterial } from './hideUnhideMaterial.operation';
import importMaterial from './importMaterial.operation';
import leaveWithoutSaving from './leaveWithoutSaving.operation';
import liveDeletedMaterial from './liveDeletedMaterial.operation';
import liveUpdatedMaterial from './liveUpdatedMaterial.operation';
import reorderMaterial from './reorderMaterial.operation';
import revertSectionToVersion from './revertSectionToVersion.operation';
import saveSection from './saveSection.operation';
import setEditing from './setEditing.operation';
import toggleFolder from './toggleFolder.operation';
import {
  openRenameFolder,
  closeRenameFolder,
} from './openCloseRenameFolder.operation';
import {
  openDeleteFolder,
  closeDeleteFolder,
} from './openCloseDeleteFolder.operation';
import {
  openScheduleMaterial,
  closeScheduleMaterial,
} from './openCloseScheduleMaterial.operation';
import editFolder from './editFolder.operation';
import deleteFolder from './deleteFolder.operation';
import hideDiscussion from './hideDiscussion.operation';

export default {
  createFolder,
  createSection,
  deleteSection,
  getSection,
  getVersion,
  displayRevertModal,
  fetchSections,
  hideMaterial,
  unHideMaterial,
  importMaterial,
  leaveWithoutSaving,
  liveDeletedMaterial,
  liveUpdatedMaterial,
  reorderMaterial,
  revertSectionToVersion,
  saveSection,
  setEditing,
  toggleFolder,
  openRenameFolder,
  closeRenameFolder,
  editFolder,
  deleteFolder,
  openDeleteFolder,
  closeDeleteFolder,
  openScheduleMaterial,
  closeScheduleMaterial,
  hideDiscussion,
};
