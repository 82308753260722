import { INBOX_REPLY_TO_MESSAGE } from '../actions';

const dismissReplyToMessage = () => (dispatch) => {
  dispatch({
    type: INBOX_REPLY_TO_MESSAGE,
    payload: {},
  });
};

export default dismissReplyToMessage;
