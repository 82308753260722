import { DeleteFolderAction, DeleteSectionAction } from '../sharedActions';
import { MaterialDataReducer } from './state';

const deleteMaterialDataReducer: MaterialDataReducer<
  DeleteSectionAction | DeleteFolderAction
> = (state, action) => {
  if (action.status !== 'success') {
    return state;
  }

  const { materialId, childrenSectionIds } = action.payload;

  const updatedMaterials = { ...state };

  if (childrenSectionIds) {
    childrenSectionIds.forEach((id) => delete updatedMaterials[id]);
  }

  delete updatedMaterials[materialId];

  return updatedMaterials;
};

export default deleteMaterialDataReducer;
