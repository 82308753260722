import { addUpdatingId, removeUpdatingId } from '../../../utils/posts';
import {
  STARTED_STATUS,
  SUCCESS_STATUS,
  ERROR_STATUS,
} from '../../../constants/actionsStatus';
import { ANNOUNCEMENTS } from '../../../constants/postFeedType';

const postsWithPostRemoved = (state, { objectId: postId }) => {
  const posts = { ...state.posts };
  delete posts[postId];
  return posts;
};

const postsStateKey = (feedType) =>
  feedType === ANNOUNCEMENTS ? 'announcements' : 'posts';
const scheduledPostsStateKey = (feedType) =>
  feedType === ANNOUNCEMENTS ? 'scheduledAnnouncements' : 'scheduledPosts';

const spaceWithPostRemoved = (
  state,
  { objectId: postId, feedType, classRoom }
) => {
  const space = state.classRooms[classRoom];

  const differentId = (id) => id !== postId;

  const postsKey = postsStateKey(feedType);
  const scheduledKey = scheduledPostsStateKey(feedType);

  return {
    ...space,
    [postsKey]: space[postsKey].filter(differentId),
    [scheduledKey]: space[scheduledKey].filter(differentId),
  };
};

/* eslint-disable-next-line import/prefer-default-export */
export const deletePostReducer = (state, action) => {
  switch (action.status) {
    case STARTED_STATUS:
      return {
        ...state,
        isUpdatingPost: true,
        postIdsUpdating: addUpdatingId({ state, id: action.id }),
      };
    case SUCCESS_STATUS: {
      const { post } = action;

      const updatedSpace = spaceWithPostRemoved(state, post);
      const updatedPosts = postsWithPostRemoved(state, post);

      return {
        ...state,
        classRooms: {
          ...state.classRooms,
          [updatedSpace.objectId]: updatedSpace,
        },
        posts: updatedPosts,
        isUpdatingPost: false,
        postIdsUpdating: removeUpdatingId({ state, id: post.objectId }),
      };
    }
    case ERROR_STATUS:
      return {
        ...state,
        isUpdatingPost: false,
        postIdsUpdating: removeUpdatingId({ state, id: action.id }),
      };
    default:
      return {
        ...state,
        isUpdatingPost: false,
      };
  }
};
