import aulaLive from '@ublend-npm/aula-live';
import * as actionTypes from '../../constants/actions';

export const joinConversation = (groupId) => (dispatch) => {
  // eslint-disable-next-line no-console
  console.debug('>> joinConversation', groupId);
  aulaLive.joinConversation(groupId);
  dispatch({
    type: actionTypes.LIVE_JOIN_CONV,
    status: 'success',
    groupId,
  });
};

export const joinClass = (classId) => (dispatch) => {
  aulaLive.joinClass(classId);
  dispatch({
    type: actionTypes.LIVE_JOIN_CLASS,
    status: 'success',
  });
};

export const userIsTyping = (groupId) => () => {
  aulaLive.userIsTyping(groupId);
};
