import { INBOX_FETCH_MESSAGE } from '../actions';
import { message as getMessage, hasNewerMessages } from '../selectors';

const onMessage = (data) => async (dispatch, getState) => {
  const { conversationId, message, localId } = data;
  const state = getState();
  /* Most frequent case for a live message already being stored is when
   * you're the author!
   * That said:
   * - it's simpler to just check if it's there
   * - it might cover other cases */
  const alreadyThere = !!getMessage(state, message.id);
  if (alreadyThere) return;

  dispatch({
    type: INBOX_FETCH_MESSAGE,
    status: 'success',
    payload: {
      conversationId,
      message,
      localId,
      hasNewerMessages: hasNewerMessages(state, conversationId),
    },
  });
};

export default onMessage;
