import * as actions from '../actions';
import toggleReactionReducer from './toggleReaction.app.reducer';

const initialState = {
  isModifyingReaction: {},
};

const appReducers = {
  [actions.TOGGLE_REACTION]: toggleReactionReducer,
};

const reducer = (state = initialState, action) => {
  if (!action) {
    return state;
  }

  const r = appReducers[action.type];
  return r ? r(state, action) : state;
};

export default reducer;
