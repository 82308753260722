/* eslint-disable prefer-destructuring */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prefer-stateless-function */
import PropTypes from 'prop-types';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider as AulaUINextProvider } from '@ublend-npm/aulaui-next';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import push from '../../utils/routing';
import ErrorBar from '../containers/Errors/ErrorBarContainer';
import { getStore } from '../../store';
import { checkMobile } from '../../utils/device';
import ConnectionIndicator from './Connection';
import ClientInfo from '../../utils/clientInfo';
import Toast from './Toast';

import {
  Progress,
  Main,
  Content,
  ContentContainer,
  OmniSearch,
  Wrapper,
  UserProfile,
  SkipContentButton,
} from './App.styled';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.onWindowFocus = this.onWindowFocus.bind(this);
    this.onWindowBlur = this.onWindowBlur.bind(this);
  }

  componentDidMount() {
    window.addEventListener('focus', this.onWindowFocus);
    window.addEventListener('blur', this.onWindowBlur);

    const { onWindowFocusChanged } = this.props;
    onWindowFocusChanged(document.hasFocus());
  }

  componentDidUpdate(prevProps) {
    const {
      location: { pathname },
      appcuesUrlChange,
      dataLoadingOverlayOpened,
    } = this.props;
    const previousLocation = prevProps.location.pathname;
    if (!dataLoadingOverlayOpened && pathname !== previousLocation) {
      appcuesUrlChange();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('focus', this.onWindowFocus);
    window.removeEventListener('blur', this.onWindowBlur);
  }

  onWindowFocus() {
    const { onWindowFocusChanged } = this.props;
    onWindowFocusChanged(true);
  }

  onWindowBlur() {
    const { onWindowFocusChanged } = this.props;
    onWindowFocusChanged(false);
  }

  render() {
    if (checkMobile()) {
      const location = getStore()
        .getState()
        .routing.locationBeforeTransitions.pathname.split('/');
      if (location[0] !== 'mobile' && location[1] !== 'mobile') {
        push('mobile');
      }
    }

    let children = null;
    let childrens = null;

    const skipToContent = () => {
      // based on https://github.com/KittyGiraudel/focusable-selectors/blob/main/index.js
      const focussable = [
        'a[href]:not([tabindex^="-"])',
        'area[href]:not([tabindex^="-"])',
        'input:not([type="hidden"]):not([type="radio"]):not([disabled]):not([tabindex^="-"])',
        'input[type="radio"]:not([disabled]):not([tabindex^="-"])',
        'select:not([disabled]):not([tabindex^="-"])',
        'textarea:not([disabled]):not([tabindex^="-"])',
        'button:not([disabled]):not([tabindex^="-"])',
        'iframe:not([tabindex^="-"])',
        'audio[controls]:not([tabindex^="-"])',
        'video[controls]:not([tabindex^="-"])',
        '[contenteditable]:not([tabindex^="-"])',
        '[tabindex]:not([tabindex^="-"])',
      ].join();

      document
        .getElementById('main-content')
        .querySelector(focussable)
        .focus({ preventScroll: true });
    };

    if (this.props.children) {
      children = this.props.children;
    }
    if (this.props.overlayLoader && this.props.content) {
      childrens = (
        <div>
          <SkipContentButton
            tabIndex="0"
            onKeyPress={(e) => e.key === 'Enter' && skipToContent()}
          >
            Skip to main content
          </SkipContentButton>
          <div className="overlayLoader">{this.props.overlayLoader}</div>
          <OmniSearch id="omni-search-container" className="omniSearch">
            {this.props.omniSearch}
          </OmniSearch>
          <UserProfile id="user-profile-container" className="userProfile">
            {this.props.userProfile}
          </UserProfile>
          <ErrorBar />
          <Main>
            <ContentContainer id="content-container">
              <Wrapper>
                {this.props.userBar}
                <Content className="content">
                  {this.props.spaceBar}
                  {this.props.content}
                </Content>
              </Wrapper>
            </ContentContainer>
            <div className="inboxMenu">{this.props.inboxGroupMenu}</div>
          </Main>
          <Toast />
        </div>
      );
    }

    // AulaUINextProvider includes a MuiThemeProvider too.
    // The themes that they provide are not the same for example AulaUINext uses system fonts.
    // Currently the MUI theme provided by AulaUINextProvider will be overridden by the child
    // MuiThemeProvider, if we change the order then this will change the fonts for all MUI
    // components on the page.
    return (
      <DndProvider backend={HTML5Backend}>
        <AulaUINextProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div>
              {!this.props.connection ? <ConnectionIndicator /> : null}
              {childrens}
              {children}
              {
                <Progress
                  id="top-progress-bar"
                  hidden={!this.props.displayInlineLoader}
                />
              }
            </div>
          </MuiPickersUtilsProvider>
        </AulaUINextProvider>
      </DndProvider>
    );
  }
}

/* eslint-disable react/require-default-props */
App.propTypes = {
  connection: PropTypes.bool,
  children: PropTypes.element,
  displayInlineLoader: PropTypes.bool,
  overlayLoader: PropTypes.element,
  content: PropTypes.element,
  inboxGroupMenu: PropTypes.element,
  omniSearch: PropTypes.element,
  userBar: PropTypes.element,
  userProfile: PropTypes.element,
  spaceBar: PropTypes.element,
  onWindowFocusChanged: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  appcuesUrlChange: PropTypes.func.isRequired,
  dataLoadingOverlayOpened: PropTypes.bool,
};
/* eslint-enable react/require-default-props */

App.defaultProps = {
  displayInlineLoader: false,
  dataLoadingOverlayOpened: true,
};

export default App;
