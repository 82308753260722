import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Twemoji } from 'react-emoji-render';
import styles from '../../../styles/presentational/emoji/emoji';
import EmojiUtils from '../../../../core/utils/emoji';

@Radium
class Emoji extends React.PureComponent {
  constructor(props) {
    super(props);

    this.setEmoji(props);

    this.handleEmojiMouseClick = this.handleEmojiMouseClick.bind(this);
    this.handleEmojiKeyDown = this.handleEmojiKeyDown.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setEmoji(nextProps);
  }

  setEmoji(props) {
    const { unicode: unicodeProp } = props;
    let unicode = '';
    if (props.emoji) {
      unicode = props.emoji.unified || '';
    } else if (unicodeProp) {
      unicode = unicodeProp;
    }
    this.unicode = unicode
      .replace(/200D-/g, '')
      .replace(/FE0F-/g, '')
      .toLowerCase();
    this.shortName = props.emoji ? props.emoji.short_name : props.shortName;
  }

  handleEmojiMouseClick() {
    const { onSelect, emoji } = this.props;
    onSelect(emoji || this.unicode);
  }

  handleEmojiKeyDown(e) {
    if (e.key === 'Enter') {
      const { onSelect, emoji } = this.props;
      onSelect(emoji || this.unicode);
    }
  }

  renderWithWrapper(child) {
    const { emoji, wrapperStyle, isShortcut } = this.props;
    return (
      <span
        role="button"
        aria-label={EmojiUtils.getAriaLabel(this.shortName)}
        id={`emoji-${isShortcut ? 'shortcut-' : ''}${
          emoji.shortName || this.unicode
        }`}
        style={{ ...styles.emojiWrapper, ...wrapperStyle }}
        key={`${this.shortName}_${this.unicode}`}
        onClick={this.handleEmojiMouseClick}
        onKeyDown={this.handleEmojiKeyDown}
        tabIndex="0"
      >
        {child}
      </span>
    );
  }

  renderEmoji() {
    const { size, emoji } = this.props;

    if (this.unicode) {
      return <Twemoji text={`:${this.shortName}:`} style={styles.emoji} />;
    }

    if (emoji && (emoji.url || emoji.file)) {
      return (
        <img
          src={emoji.url || emoji.file.url}
          alt={emoji.shortName}
          style={{
            width: size,
            height: size,
            display: 'block',
          }}
        />
      );
    }

    return null;
  }

  render() {
    const { emoji, size, shortName, style, withWrapper } = this.props;

    // Just in case it is a custom emoji not added in the DB
    if (!emoji) {
      return null;
    }

    const emojiElem = (
      <span
        key={`${shortName}${this.unicode}`}
        style={{
          ...styles.emojiContainer,
          width: size,
          height: size,
          ...style,
        }}
      >
        {this.renderEmoji()}
      </span>
    );

    return withWrapper ? this.renderWithWrapper(emojiElem) : emojiElem;
  }
}

Emoji.propTypes = {
  isShortcut: PropTypes.bool,
  emoji: PropTypes.shape({
    unified: PropTypes.string,
    short_name: PropTypes.string,
    shortName: PropTypes.string,
    url: PropTypes.string,
    file: PropTypes.shape({ url: PropTypes.string }),
  }),
  shortName: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  unicode: PropTypes.string,
  size: PropTypes.number,
  onSelect: PropTypes.func,
  withWrapper: PropTypes.bool,
  wrapperStyle: PropTypes.shape({}),
  style: PropTypes.shape({}),
};

const noop = () => {};

Emoji.defaultProps = {
  isShortcut: false,
  emoji: null,
  shortName: '',
  unicode: '',
  onSelect: noop,
  withWrapper: false,
  wrapperStyle: {},
  style: {},
  size: 22,
};

export default Emoji;
