import * as types from '../../constants/actions';

const initialState = {
  user: null,
  userId: null,
  isLoggingIn: false,
  isLoggingOut: false,
  isLogged: false,
  isRegistering: false,
  isUpdatingInfo: false,
  isErrored: false,
  isRequestingCodeLogin: false,
  requestedCodeLogin: false,
  changingAvatar: false,
  isVisibleNotificationSettingsTab: false,
};

const codeLoginRequestReducer = (state = initialState, action) => {
  switch (action.status) {
    case 'started':
      return {
        ...state,
        isRequestingCodeLogin: true,
        requestedCodeLogin: false,
      };
    case 'success':
      return {
        ...initialState,
        isRequestingCodeLogin: false,
        requestedCodeLogin: true,
      };
    case 'error':
      return {
        ...state,
        isErrored: true,
        isRequestingCodeLogin: false,
        requestedCodeLogin: false,
      };

    default:
      return state;
  }
};

const userLoginReducer = (state = initialState, action) => {
  switch (action.status) {
    case 'started':
      return { ...state, isLoggingIn: true };
    case 'success':
      return {
        ...initialState,
        user: action.user,
        userId: action.user.id,
        isLogged: true,
      };
    case 'error':
      return {
        ...state,
        isErrored: true,
        isLoggingIn: false,
      };

    default:
      return state;
  }
};

const userLogoutReducer = (state = initialState, action) => {
  switch (action.status) {
    case 'started':
      return {
        ...state,
        isLoggingOut: true,
      };
    case 'success':
      return {
        ...initialState,
        user: null,
        userId: '',
        isLogged: null,
      };
    case 'error':
      return {
        ...state,
        isErrored: true,
        isLoggingOut: false,
      };

    default:
      return state;
  }
};

const changeAvatarReducer = (state = initialState, action) => {
  switch (action.status) {
    case 'started':
      return {
        ...state,
        changingAvatar: true,
      };
    case 'success':
      return {
        ...state,
        user: Object.assign({}, state.user, { avatar: action.url }),
        isErrored: false,
        changingAvatar: false,
      };
    case 'error':
      return {
        ...state,
        isErrored: true,
        changingAvatar: false,
      };

    default:
      return state;
  }
};

const changeStatusReducer = (state = initialState, action) => {
  switch (action.status) {
    case 'started':
      return {
        ...state,
        changingStatus: true,
      };
    case 'success':
      return {
        ...state,
        user: Object.assign({}, state.user, {
          status: action.statusData.status,
        }),
        isErrored: false,
        changingStatus: false,
      };
    case 'error':
      return {
        ...state,
        isErrored: true,
        changingStatus: false,
      };

    default:
      return state;
  }
};

const userReducer = (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case types.REQUEST_LOGIN:
      return codeLoginRequestReducer(state, action);
    case types.USER_LOGIN:
      return userLoginReducer(state, action);
    case types.USER_LOGOUT:
      return userLogoutReducer(state, action);
    case types.USER_FETCH_CURRENT:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.user,
        },
      };
    case types.USER_CHANGE_AVATAR:
      return changeAvatarReducer(state, action);
    case types.USER_CHANGE_STATUS:
      return changeStatusReducer(state, action);
    case types.SET_NOTIFICATION_SETTINGS_VISIBLE_TAB: {
      return {
        ...state,
        isVisibleNotificationSettingsTab: action.isVisible,
      };
    }
    default:
      return state;
  }
};

export default userReducer;
