// eslint-disable-next-line
export const fetch = (state, action) => {
  switch (action.status) {
    case 'success':
      return {
        ...state,
        devices: action.devices.reduce(
          (r, device) => ({
            ...r,
            [device.objectId]: {
              ...device,
              current: device.deviceId === action.deviceId,
            },
          }),
          {}
        ),
      };
    default:
      return state;
  }
};
