import formatComment from '../../utils/formatComment';

export const getUntilDate = ({ reset, posts, oldestPostDate, latest }) =>
  !reset && posts.length > 0 && !latest ? oldestPostDate : new Date();

export const groupCommentByPostReducer = (acc, comment) => {
  const formattedComment = formatComment(comment);
  const result = { ...acc };
  const postId = comment.entityId;
  result[postId] = [...(acc[postId] || []), formattedComment];
  return result;
};
