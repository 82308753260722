import platform from '../../../core/utils/platform';

const electron = (store) => (next) => (action) => {
  const result = next(action);
  if (platform !== 'desktop') {
    return result;
  }

  window.onStateUpdate(store.getState(), action);
  return result;
};

export default electron;
