const markFetching = (fetchingMessages, conversationId) => ({
  ...fetchingMessages,
  [conversationId]: true,
});

const markDoneFetching = (fetchingMessages, conversationId) => {
  const newFetchingMessages = { ...fetchingMessages };
  delete newFetchingMessages[conversationId];
  return newFetchingMessages;
};

const fetchMessagesReducer = (state, action) => {
  const { conversationId, replace, done } = action.payload;

  switch (action.status) {
    case 'started':
      return {
        ...state,
        fetchingMessages: markFetching(state.fetchingMessages, conversationId),
        conversationHasOlderMessages: {
          ...state.conversationHasOlderMessages,
          [conversationId]:
            !!replace || state.conversationHasOlderMessages[conversationId],
        },
      };
    case 'success':
      return {
        ...state,
        conversationHasOlderMessages: {
          ...state.conversationHasOlderMessages,
          [conversationId]: !done,
        },
        fetchingMessages: markDoneFetching(
          state.fetchingMessages,
          conversationId
        ),
        initialFetchDone: {
          ...state.initialFetchDone,
          [conversationId]: true,
        },
      };
    case 'error':
      return {
        ...state,
        fetchingMessages: markDoneFetching(
          state.fetchingMessages,
          conversationId
        ),
      };
    default:
      return state;
  }
};

export default fetchMessagesReducer;
