import * as api from '../api/directMessages';
import { INBOX_SET_CONVERSATION_REOPENED } from '../actions';
import toastOperations from '../../toasts/operations';

const sendConversationReopenedStarted = (dispatch, payload) =>
  dispatch({
    type: INBOX_SET_CONVERSATION_REOPENED,
    status: 'started',
    payload,
  });

const sendConversationReopenedSuccess = (dispatch, payload) =>
  dispatch({
    type: INBOX_SET_CONVERSATION_REOPENED,
    status: 'success',
    payload,
  });

const sendConversationReopenedError = (dispatch, payload) => {
  dispatch({
    type: INBOX_SET_CONVERSATION_REOPENED,
    status: 'error',
    payload,
  });
  dispatch(
    toastOperations.showToast({
      message: "We couldn't reopen the conversation, please try again.",
    })
  );
};

const setConversationReopened = (conversationId) => async (dispatch) => {
  const payload = { conversationId };
  sendConversationReopenedStarted(dispatch, payload);
  try {
    await api.conversations.reopenConversation(conversationId);
    sendConversationReopenedSuccess(dispatch, payload);
  } catch (error) {
    sendConversationReopenedError(dispatch, { ...payload, error });
  }
};

export default setConversationReopened;
