import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import BaseDialogActions from './DialogActions';

const getStyles = () => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    margin: 0,
    padding: '8px 24px 24px',
  },
  spacing: {
    '& > :not(:first-child)': {
      marginLeft: 16,
    },
  },
});

const DialogActions = withStyles(getStyles)(BaseDialogActions);

export default React.memo(DialogActions);
