const changeSpace = (state, action) => {
  switch (action.status) {
    case 'success':
      return {
        ...state,
        selectedSpace: action.payload.spaceId,
        allSpaces: action.payload.allSpaces,
        submissions: {},
        hasFetchedAssignments: false,
      };
    default:
      return state;
  }
};

export default changeSpace;
