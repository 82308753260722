const resetErrorState = (state, action) => {
  switch (action.status) {
    case 'success':
      return {
        ...state,
        assignmentCreationError: null,
        assignmentUpdateError: null,
      };
    default:
      return state;
  }
};

export default resetErrorState;
