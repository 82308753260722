import { push, replace } from 'react-router-redux';
import { currentClassRoomId } from '../../selectors/classroom';

const selectGroupSet = (groupSetId) => (dispatch, getState) => {
  const state = getState();
  const currentPathname = state.routing.locationBeforeTransitions.pathname;
  const classRoomId = currentClassRoomId(state);
  const groupsPathPrefix = `/dashboard/${classRoomId}/people/groups`;
  const url = `/dashboard/${classRoomId}/people/groups/${groupSetId}`;

  if (currentPathname === groupsPathPrefix) {
    dispatch(replace(url));
  } else if (currentPathname.startsWith(groupsPathPrefix)) {
    dispatch(push(url));
  }
};

export default selectGroupSet;
