export const newPostInClassRoomReducer = (state, action) => {
  switch (action.status) {
    case 'success': {
      const classRoom = state.classRooms[action.post.classRoom.objectId];
      // Inserted in the head of the list to keep chronological order
      classRoom.posts = [
        ...new Set([action.post.objectId, ...classRoom.posts]),
      ];
      return {
        ...state,
        classRooms: Object.assign({}, state.classRooms, {
          [classRoom.objectId]: classRoom,
        }),
      };
    }
    default:
      return state;
  }
};

export const fetchClassRoomPostReducer = (state, action) => {
  switch (action.status) {
    case 'success': {
      if (!state.classRooms[action.post.classRoom]) {
        return state;
      }

      const classRoom = Object.assign(
        {},
        state.classRooms[action.post.classRoom]
      );

      return {
        ...state,
        classRooms: {
          ...state.classRooms,
          [action.post.classRoom]: {
            ...classRoom,
            posts: [...new Set([...classRoom.posts, action.post.objectId])],
          },
        },
      };
    }
    default:
      return state;
  }
};
