import React from 'react';
import styled from 'react-emotion';

import BaseDialogContent from '@material-ui/core/DialogContent';
import { styleUtils, theme } from '@ublend-npm/aulaui-next';

const DialogContent = styled(BaseDialogContent)`
  font-size: ${theme.font.size.copy};
  line-height: ${theme.font.lineHeight.copy};
  padding: 0;
  color: ${theme.color.designSystem.blackOpacity87};
  :focus-within {
    ${styleUtils.focusOutline}
  }
`;

export default React.memo(DialogContent);
