import { validateGroupEmails as validateGroupEmailsApi } from '../api';
import refreshAccessToken from '../../actions/accessToken/refreshAccessToken.action';
import { currentClassRoomId as getCurrentClassRoomId } from '../../selectors/classroom';

const validateGroupEmails =
  ({ emails, space = undefined }) =>
  async (dispatch, getState) => {
    const state = getState();
    const spaceId = space || getCurrentClassRoomId(state);

    const accessToken = await refreshAccessToken(
      dispatch,
      getState
    )({ spaceId });

    return validateGroupEmailsApi(accessToken, {
      spaceId,
      emails,
    });
  };

export default validateGroupEmails;
