import { GetAllSectionsContract } from '@ublend-npm/aula-schema/types/materials';
import { CLASSROOM_GET_MATERIAL_SECTIONS } from '../../constants/endpoints';
import request, { getData } from './request';
import toFolder from './toFolder';
import toSection from './toSection';
import { DeletedSection, Folder, Section } from '../types';

function fetchSections(
  classRoomId: string,
  skipContent: boolean,
  onlyDeleted: true
): Promise<(DeletedSection | Folder)[]>;

function fetchSections(
  classRoomId: string,
  skipContent?: boolean,
  onlyDeleted?: false
): Promise<(Section | Folder)[]>;

async function fetchSections(
  classRoomId: string,
  skipContent?: boolean,
  onlyDeleted?: boolean
) {
  const url = CLASSROOM_GET_MATERIAL_SECTIONS(
    classRoomId,
    skipContent,
    onlyDeleted
  );
  const { sections, folders } = await request<GetAllSectionsContract>({
    method: 'get',
    url,
  }).then(getData);
  return [...folders.map(toFolder), ...sections.map(toSection)];
}

export default fetchSections;
