const indexForTabId = Object.freeze({
  all: 0,
  posts: 1,
  comments: 2,
  messages: 3,
  materials: 4,
  files: 5,
});

export default indexForTabId;
