import { reactionWithoutUser, updateReactions } from './utils';

const removeReactionReducer = (state, action) => {
  const { status, payload } = action;
  switch (status) {
    case 'started': {
      const { oldReaction, user } = payload;
      const local = true;
      return updateReactions(
        state,
        reactionWithoutUser(oldReaction, user),
        local,
        user
      );
    }
    case 'success': {
      const { reaction, user, local } = payload;
      return updateReactions(state, reaction, local, user);
    }
    case 'error': {
      const { oldReaction, user } = payload;
      const local = true;
      return updateReactions(state, oldReaction, local, user);
    }
    default:
      return state;
  }
};

export default removeReactionReducer;
