import { GROUP_SETS } from '../../constants/endpoints';
import request from './request';

const editedGroupSet = async (accessToken, patch, setId) => {
  const config = {
    method: 'patch',
    url: GROUP_SETS({ setId }),
    data: {
      set: patch,
    },
  };

  const response = await request(accessToken, config);
  return response.data.set;
};

export default editedGroupSet;
