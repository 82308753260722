const toggleReactionsReducer = (state, action) => {
  const {
    status,
    payload: { itemId, emojiName },
  } = action;
  const reactionKey = `${itemId}:${emojiName}`;
  switch (status) {
    case 'started':
      return {
        ...state,
        isModifyingReaction: {
          ...state.isModifyingReaction,
          [reactionKey]: true,
        },
      };
    case 'success':
    case 'error': {
      const updatedModifying = { ...state.isModifyingReaction };
      delete updatedModifying[reactionKey];
      return {
        ...state,
        isModifyingReaction: updatedModifying,
      };
    }
    default:
      return state;
  }
};

export default toggleReactionsReducer;
