import { useEffect, useState } from 'react';
import throttle from 'lodash.throttle';

export type Breakpoints = {
  xs: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
};

// TODO: move these to the theme
export const config = {
  sm: 360,
  md: 900,
  lg: 1200,
  xl: 1440,
};

const getBreakpoints = (width: number): Breakpoints => {
  return {
    xs: width < config.sm,
    sm: width >= config.sm,
    md: width >= config.md,
    lg: width >= config.lg,
    xl: width >= config.xl,
  };
};

const useBreakpoints = (): Breakpoints => {
  const [breakpoints, setBreakpoints] = useState(() =>
    getBreakpoints(window.innerWidth)
  );

  useEffect(() => {
    const update = throttle(() => {
      setBreakpoints(getBreakpoints(window.innerWidth));
    }, 200);

    window.addEventListener('resize', update);

    return () => window.removeEventListener('resize', update);
  }, []);

  return breakpoints;
};

export default useBreakpoints;
