import { HideUnhideMaterialAction } from '../sharedActions';
import { MaterialAppReducer } from './state';

const hideUnhide: MaterialAppReducer<HideUnhideMaterialAction> = (
  state,
  action
) => ({
  ...state,
  isHidingMaterial: action.status === 'started',
});

export default hideUnhide;
