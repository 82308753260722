import * as types from '../constants/actions';

const initialState = {
  email: undefined,
  code: undefined,
};

export default (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case types.GET_EMAIL:
      return { ...state, email: action.email };
    case types.GET_CODE:
      return { ...state, code: action.code };
    default:
      return state;
  }
};
