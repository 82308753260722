import * as selectors from '../../selectors/routing';
import push from '../../utils/routing';
import { NAVBAR_NOTIFICATIONS_BADGE } from '../../../core/constants/actions';

const toggleNotifications = () => (dispatch, getState) => {
  const state = getState();
  const location = selectors.location(state);
  const { query } = location;

  if (selectors.isNotificationsOpen(state)) {
    const { notifications, ...newQuery } = query;
    push({ ...location, query: newQuery });
    dispatch({
      type: NAVBAR_NOTIFICATIONS_BADGE,
      hasUnread: false,
    });
  } else {
    const newQuery = { ...query, notifications: true };
    push({ ...location, query: newQuery });
  }
};

export default toggleNotifications;
