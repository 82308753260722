import { AulaReduxAction } from '../../types/state';
import { CLASSROOM_OPEN_CLOSE_SCHEDULE_MATERIAL } from '../actions';

type OpenScheduleMaterialActionFactory = (
  materialId: string
) => AulaReduxAction;

export const openScheduleMaterial: OpenScheduleMaterialActionFactory =
  (materialId) => (dispatch) =>
    dispatch({
      type: CLASSROOM_OPEN_CLOSE_SCHEDULE_MATERIAL,
      payload: { scheduleMaterialDialogOpenFor: materialId },
    });

type CloseScheduleMaterialActionFactory = () => AulaReduxAction;

export const closeScheduleMaterial: CloseScheduleMaterialActionFactory =
  () => (dispatch) =>
    dispatch({
      type: CLASSROOM_OPEN_CLOSE_SCHEDULE_MATERIAL,
      payload: { scheduleMaterialDialogOpenFor: null },
    });
