import React from 'react';
import { icons, Tooltip } from '@ublend-npm/aulaui-next';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import TabLabel from '../../ClassRoom/Settings/TabLabel';

const getStyles = () => ({
  customWidth: {
    maxWidth: 158,
  },
  // prevent tooltip from being visible when a conversation is open
  // See: https://mui.com/customization/z-index/,
  popper: {
    zIndex: 1200,
  },
});

const PeopleIcon = ({ classes }: WithStyles) => {
  return (
    <Tooltip
      title="Participants and Groups"
      classes={{ tooltip: classes.customWidth, popper: classes.popper }}
    >
      <icons.Group />
    </Tooltip>
  );
};

const PeopleIconWithSlides = withStyles(getStyles)(PeopleIcon);

export default [
  {
    id: 'community',
    label: <TabLabel text="Community" icon={null} />,
    documentTitlePrefix: 'Community',
    visible: () => true,
  },
  {
    id: 'journey',
    label: <TabLabel text="Journey" icon={null} />,
    documentTitlePrefix: 'Journey',
    visible: () => true,
  },
  {
    id: 'people',
    label: null,
    icon: <PeopleIconWithSlides />,
    documentTitlePrefix: 'People',
    visible: () => true,
  },
];
