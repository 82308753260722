import axios, { AxiosRequestConfig } from 'axios';
import { getCurrentUser } from '../utils/currentUser';
import getAnalyticsHeaders from '../utils/analyticsHeaders';
import { EndpointContract } from '@ublend-npm/aula-schema';

const getCredentialHeaders = () => {
  const user = getCurrentUser();
  return {
    'x-user-id': user.id,
  };
};

const request = async <T extends EndpointContract = EndpointContract>(
  config: AxiosRequestConfig<T['body']>
) => {
  const response = await axios<T['responseBody']>({
    ...config,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      ...config.headers,
      ...getCredentialHeaders(),
      ...getAnalyticsHeaders(),
    },
  });
  return response;
};

export default request;
