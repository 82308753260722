import { INBOX_CONVERSATION } from '../../../constants/endpoints';
import request from './request';

/**
 * @param {string} conversationId
 */
export default async (conversationId) => {
  const config = {
    url: INBOX_CONVERSATION(conversationId),
    method: 'get',
  };
  const response = await request(config);
  return response.data.conversation;
};
