const changeSpace = (state, action) => {
  switch (action.status) {
    case 'success':
      return {
        ...state,
        assignments: [],
        submissions: {},
      };
    default:
      return state;
  }
};

export default changeSpace;
