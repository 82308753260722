export const STARTED_STATUS = 'started';
export const SUCCESS_STATUS = 'success';
export const INITIATED_STATUS = 'initiated';
export const ABORTED_STATUS = 'aborted';
export const REQUEST_STATUS = 'request';
export const ERROR_STATUS = 'error';
export const ADDED_STATUS = 'added';
export const CLEARED_STATUS = 'cleared';

export type ActionStatus =
  | typeof STARTED_STATUS
  | typeof ERROR_STATUS
  | typeof SUCCESS_STATUS
  | typeof ABORTED_STATUS
  | typeof INITIATED_STATUS
  | typeof REQUEST_STATUS;
