// @ts-check

import { CHANGE_ASSIGNMENTS_SELECTED_SPACE } from '../actions';

const changeSpaceSuccess = (dispatch, payload) =>
  dispatch({
    type: CHANGE_ASSIGNMENTS_SELECTED_SPACE,
    status: 'success',
    payload,
  });

const changeSpace = async (dispatch, spaceId, allSpaces) => {
  changeSpaceSuccess(dispatch, { spaceId, allSpaces });
};

export default (dispatch) => (spaceId, allSpaces) =>
  changeSpace(dispatch, spaceId, allSpaces);
