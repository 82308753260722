import { INBOX_MESSAGE } from '../../../constants/endpoints';
import request from './request';

export default async (conversationId, messageId) => {
  const config = {
    method: 'GET',
    url: INBOX_MESSAGE(conversationId, messageId),
  };
  const response = await request(config);
  return response.data.message;
};
