export {
  CLASSROOM_MODULE_CREATE_SECTION,
  CLASSROOM_MODULE_DELETE_SECTION,
  CLASSROOM_MODULE_GET_SECTION,
  CLASSROOM_MODULE_FETCH_SECTIONS,
  CLASSROOM_MODULE_IMPORT_SECTIONS,
  CLASSROOM_MODULE_LIVE_DELETED_MATERIAL,
  CLASSROOM_MODULE_LIVE_UPDATED_MATERIAL,
  CLASSROOM_MODULE_SAVE_CURRENT_SECTION,
  CLASSROOM_MODULE_SCHEDULE_CURRENT_SECTION,
  CLASSROOM_MODULE_REORDER_MATERIAL,
} from '../constants/actions';

export const CLASSROOM_MODULE_CREATE_FOLDER = 'CLASSROOM_MODULE_CREATE_FOLDER';
export const CLASSROOM_MODULE_RESET = 'CLASSROOM_MODULE_RESET';
export const CLASSROOM_MODULE_HIDEUNHIDE_MATERIAL =
  'CLASSROOM_MODULE_HIDEUNHIDE_MATERIAL';
export const CLASSROOM_MODULE_SET_EDITING = 'CLASSROOM_MODULE_SET_EDITING';
export const CLASSROOM_MODULE_LEAVE_WITHOUT_SAVING =
  'CLASSROOM_MODULE_LEAVE_WITHOUT_SAVING';
export const CLASSROOM_MODULE_DISPLAY_REVERT_MODAL =
  'CLASSROOM_MODULE_DISPLAY_REVERT_MODAL';
export const CLASSROOM_MODULE_REVERT = 'CLASSROOM_MODULE_REVERT';
export const CLASSROOM_MODULE_GET_VERSION = 'CLASSROOM_MODULE_GET_VERSION';
export const CLASSROOM_MODULE_TOGGLE_FOLDER = 'CLASSROOM_MODULE_TOGGLE_FOLDER';
export const CLASSROOM_OPEN_CLOSE_RENAME_FOLDER =
  'CLASSROOM_OPEN_CLOSE_RENAME_FOLDER';
export const CLASSROOM_OPEN_CLOSE_DELETE_FOLDER =
  'CLASSROOM_OPEN_CLOSE_DELETE_FOLDER';
export const CLASSROOM_OPEN_CLOSE_SCHEDULE_MATERIAL =
  'CLASSROOM_OPEN_CLOSE_SCHEDULE_MATERIAL';
export const CLASSROOM_EDIT_FOLDER = 'CLASSROOM_EDIT_FOLDER';
export const CLASSROOM_DELETE_FOLDER = 'CLASSROOM_DELETE_FOLDER';
export const CLASSROOM_MODULE_EDIT_MATERIAL = 'CLASSROOM_MODULE_EDIT_MATERIAL';
export const CLASSROOM_MODULE_UPDATE_SECTION_PROGRESS =
  'CLASSROOM_MODULE_UPDATE_SECTION_PROGRESS';
