import { AulaState } from '@core/types/state';
import { createSelector } from 'reselect';
import { Assignment } from './types';

const byId = ({ id }: Assignment) => id;

const findById = (toMatchId: string) => (a: Assignment) =>
  a.id === toMatchId || a.objectId === toMatchId;

const findByExtension =
  (toMatchId: string) =>
  ({ extensions }: Assignment) =>
    extensions?.map(byId).includes(toMatchId);

const appState = (state: AulaState) => state.assignments;
const data = (state: AulaState) => state.data.assignments;

export const hasFetchedAssignments = (state: AulaState) =>
  appState(state).hasFetchedAssignments;

export const isFetchingAssignments = (state: AulaState) =>
  appState(state).isFetching;

export const isErrored = (state: AulaState) => appState(state).isErrored;

export const isEditingAssignment = (state: AulaState) =>
  appState(state).isEditing;

export const getAssignmentCreationError = (state: AulaState) =>
  appState(state).assignmentCreationError;

export const getAssignmentUpdateError = (state: AulaState) =>
  appState(state).assignmentUpdateError;

export const getAssignmentsError = (state: AulaState) => appState(state).error;

export const getSelectedSpaceId = (state: AulaState) =>
  appState(state).selectedSpace;

export const getAssignmentQuery = (state: AulaState) => appState(state).query;

export const getAssignments = (state: AulaState) => data(state).assignments;

export const getExtensionFromAssignments =
  (state: AulaState) => (extensionId: string) =>
    (getAssignments(state).find(findByExtension(extensionId)) || {}).extensions;

export const getAssignment = (state: AulaState, assignmentId: string) =>
  getAssignments(state).find(findById(assignmentId));

export const getExtension = (state: AulaState, extensionId: string) =>
  (getExtensionFromAssignments(state)(extensionId) || []).find(
    findById(extensionId)
  );

export const getAssignmentSubmissions = (
  state: AulaState,
  assignmentId: string
) => data(state).submissions[assignmentId] || [];

export const isFetchingSubmissions = (state: AulaState, assignmentId: string) =>
  !!(appState(state).submissions[assignmentId] || {}).fetching;

export const hasFetchSubmissionsErrored = (
  state: AulaState,
  assignmentId: string
) => !!(appState(state).submissions[assignmentId] || {}).errored;

export const hasFetchedSubmissions = (state: AulaState, assignmentId: string) =>
  !!(appState(state).submissions[assignmentId] || {}).fetched;

export const getTotalSubmissions = (state: AulaState, assignmentId: string) =>
  (appState(state).submissions[assignmentId] || {}).totalSubmissions;

export const getSubmissionFilterGroup = (
  state: AulaState,
  assignmentId: string
) => (appState(state).submissions[assignmentId] || {}).byGroup;

export const hasSubmissionInParent = (state: AulaState) => {
  const creationError = getAssignmentCreationError(state);
  return (
    creationError &&
    creationError.errorCode &&
    creationError.errorCode.code === 'parent-assignment-submissions-exist'
  );
};
export const hasSubmissionInParentOnUpdate = (state: AulaState) => {
  const updateError = getAssignmentUpdateError(state);
  return updateError?.errorCode === 'parent-assignment-submissions-exist';
};

export const hasSubmissionsInCurrentExtension = (state: AulaState) => {
  const updateError = getAssignmentUpdateError(state);
  return (
    updateError &&
    updateError.errorCode &&
    updateError.errorCode === 'extension-submissions-exist'
  );
};

export const getStudentsInAssignment = createSelector(
  [getAssignmentSubmissions],
  (assignmentSubmissions) =>
    assignmentSubmissions.map(
      ({ user: { id, firstName, lastName, avatar, email } }) => ({
        id,
        avatar,
        email,
        fullName: `${firstName} ${lastName}`,
      })
    )
);
