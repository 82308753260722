const removeParticipantReducer = (state, action) => {
  if (action.status !== 'success') return state;
  const { conversationId, participantId } = action.payload;
  const conversation = state.conversations[conversationId];

  if (!conversation) {
    return state;
  }

  const updatedParticipants = { ...conversation.participants };
  delete updatedParticipants[participantId];

  return {
    ...state,
    conversations: {
      ...state.conversations,
      [conversationId]: {
        ...conversation,
        participants: updatedParticipants,
      },
    },
  };
};

export default removeParticipantReducer;
