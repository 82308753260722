import { INBOX_SET_CONVERSATION_REOPENED } from '../actions';

const onConversationReopened = (data) => (dispatch) => {
  const {
    conversation: { id: conversationId },
  } = data;

  dispatch({
    type: INBOX_SET_CONVERSATION_REOPENED,
    status: 'started', // in optimisic update approach, the success state is provided at 'started'
    payload: {
      conversationId,
    },
  });
};

export default onConversationReopened;
