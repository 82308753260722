import { GROUPS_EDIT_GROUP_SET } from '../actions';
import { editGroupSet as editGroupSetApi } from '../api';

import refreshAccessToken from '../../actions/accessToken/refreshAccessToken.action';
import { groupSet as getGroupSet } from '../selectors';
import { currentClassRoomId as getCurrentClassRoomId } from '../../selectors/classroom';
import showToast from '../../toasts/operations/showToast.operation';
import {
  HIDE_GROUP_SET_SUCCESS,
  EDIT_GROUP_SET_SUCCESS,
  EDIT_GROUP_SET_ERROR,
} from './toasts';

const editGroupSetSuccess = (payload) => ({
  type: GROUPS_EDIT_GROUP_SET,
  status: 'success',
  payload,
});

const editGroupSetError = (payload) => ({
  type: GROUPS_EDIT_GROUP_SET,
  status: 'error',
  payload,
});

const getPatchPayload = (origGroupSet, updatedGroupSet) => {
  const isInstitutionManaged = !!origGroupSet.externalId;
  const allowedFields = isInstitutionManaged
    ? ['isHidden']
    : ['name', 'isHidden'];
  const updates = {
    ...origGroupSet,
    ...updatedGroupSet,
  };

  const payload = {
    groupSet: Object.keys(updates).reduce((acc, field) => {
      if (allowedFields.includes(field)) {
        return {
          ...acc,
          [field]: updates[field],
        };
      }
      return acc;
    }, {}),
  };

  return payload;
};

const editGroupSet = (groupSet) => async (dispatch, getState) => {
  const state = getState();
  const origGroupSet = getGroupSet(state, groupSet.id);
  const isHiding = !origGroupSet.isHidden && groupSet.isHidden;
  const payload = getPatchPayload(origGroupSet, groupSet);

  try {
    const spaceId = getCurrentClassRoomId(state);
    const accessToken = await refreshAccessToken(
      dispatch,
      getState
    )({ spaceId });
    const editedGroupSet = await editGroupSetApi(
      accessToken,
      payload.groupSet,
      origGroupSet.id
    );
    const toast = isHiding ? HIDE_GROUP_SET_SUCCESS : EDIT_GROUP_SET_SUCCESS;

    dispatch(editGroupSetSuccess({ groupSet: editedGroupSet }));
    dispatch(showToast(toast));
  } catch (error) {
    dispatch(
      editGroupSetError({
        groupSet: { ...origGroupSet, ...payload.groupSet },
        error,
      })
    );
    dispatch(showToast(EDIT_GROUP_SET_ERROR));
  }
};

export default editGroupSet;
