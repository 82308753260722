import React from 'react';
import { List } from './SpaceSelector.styled';
import SpaceSelectorItem from './SpaceSelectorItem';
import { Space } from './types';

type SpaceListProps = {
  spaces: Space[];
  onClick: (id: string) => void;
  onStar: (id: string) => Promise<void>;
  inline: boolean;
};

const SpaceList = ({
  spaces,
  onClick,
  onStar,
  inline = false,
}: SpaceListProps) => {
  return (
    <List compact inline={inline}>
      {spaces.map(({ id, archived, name, avatar, hasActivity }) => (
        <SpaceSelectorItem
          key={id}
          spaceId={id}
          onClick={() => onClick(id)}
          onStar={() => onStar(id)}
          archived={archived}
          avatar={avatar}
          name={name}
          hasActivity={hasActivity}
        />
      ))}
    </List>
  );
};

export default SpaceList;
