import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectors as inboxSelectors } from '../../../store/conversations';
import * as appCreators from '../../../actions/inboxv2';

import Inbox from './ConversationPanel';

const mapStateToProps = createStructuredSelector({
  selectedConversationLoaded: (state) =>
    !!inboxSelectors.currentConversation(state),
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(appCreators.selectConversation(undefined)),
  openConversation: (conversationId) =>
    dispatch(appCreators.selectConversation(conversationId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Inbox);
