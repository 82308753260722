const byIdReducer = (acc, next) => {
  acc[next.id] = {
    messages: [],
    ...next,
  };
  return acc;
};

const fetchConversationsReducer = (state, action) => {
  if (action.status !== 'success') return state;

  const {
    payload: { conversations },
  } = action;

  return {
    ...state,
    conversations: conversations.reduce(byIdReducer, {}),
  };
};

export default fetchConversationsReducer;
