const formatReaction = (reaction) => ({
  objectId: reaction.id || reaction.objectId,
  emojiName: reaction.emojiName,
  itemType: reaction.itemType,
  itemId: reaction.itemId,
  count: reaction.count,
  createdAt: reaction.createdAt,
  updatedAt: reaction.updatedAt,
  users: reaction.users,
});

export default formatReaction;
