const formatUser = (user) => {
  const formattedUser = {
    objectId: user.id,
    ...user,
  };
  delete formattedUser.id;
  return formattedUser;
};

export default formatUser;
