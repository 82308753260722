import { VersionContent } from '@ublend-npm/aula-schema/types/materials';
import { Version } from '../types';

const toVersion = (data: VersionContent): Version => ({
  id: data.version,
  title: data.title,
  content: data.content,
  editor: data.editor,
  updatedAt: data.updatedAt,
});

export default toVersion;
