/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
import React, { useState } from 'react';
import styled from 'react-emotion';
import { IconButton, icons } from '@ublend-npm/aulaui-next';
import getEnvironmentFromInstitutionUrl from '@core/utils/getEnvironmentFromInstitutionUrl';
import useBreakpoints from '../../../hooks/useBreakpoints';

const Container = styled('div')`
  position: fixed;
  display: flex;
  bottom: 12px;
  left: 12px;
  height: 20px;
  padding: 10px 10px 10px 12px;
  background-color: white;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
`;

const ButtonContainer = styled('div')`
  margin-top: -2px;
  padding-left: 10px;
`;

const AulaDevTools = () => {
  const environment = getEnvironmentFromInstitutionUrl(window.location.href);
  const [closed, setClosed] = useState(false);
  const { sm, md, lg, xl } = useBreakpoints();
  const isDev = environment === 'local' || environment === 'development';

  if (!isDev || closed) {
    return null;
  }

  const breakpoint = xl ? 'xl' : lg ? 'lg' : md ? 'md' : sm ? 'sm' : 'xs';

  return (
    <Container>
      {breakpoint}
      <ButtonContainer>
        <IconButton
          label="Close"
          onClick={() => setClosed(true)}
          size="xSmall"
          icon={icons.Close}
        />
      </ButtonContainer>
    </Container>
  );
};

export default AulaDevTools;
