import { GROUPS_OPEN_CLOSE_ADD_GROUP } from '../actions';

export const openAddGroup = (setId) => (dispatch) =>
  dispatch({
    type: GROUPS_OPEN_CLOSE_ADD_GROUP,
    payload: { createGroupDialogOpenFor: setId },
  });

export const closeAddGroup = (dispatch) =>
  dispatch({
    type: GROUPS_OPEN_CLOSE_ADD_GROUP,
    payload: { createGroupDialogOpenFor: null },
  });
