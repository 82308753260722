import { isCancel } from 'axios';
import { fetchSubmissions as fetchSubmissionsApi } from '../api';
import { FETCH_SUBMISSIONS } from '../actions';

const fetchSubmissionsStarted = (dispatch, payload) =>
  dispatch({
    type: FETCH_SUBMISSIONS,
    status: 'started',
    payload,
  });

const fetchSubmissionsSuccess = (dispatch, payload) =>
  dispatch({
    type: FETCH_SUBMISSIONS,
    status: 'success',
    payload,
  });

const fetchSubmissionsError = (dispatch, payload) =>
  dispatch({
    type: FETCH_SUBMISSIONS,
    status: 'error',
    payload,
  });

const fetchSubmissions =
  ({ accessToken, assignmentId, group = null }) =>
  async (dispatch) => {
    if (!accessToken || !assignmentId) {
      return;
    }

    fetchSubmissionsStarted(dispatch, { assignmentId, group });

    try {
      const {
        data: { submissions, totalSubmissions },
      } = await fetchSubmissionsApi({
        accessToken,
        assignmentId,
        group,
      });

      fetchSubmissionsSuccess(dispatch, {
        assignmentId,
        submissions,
        totalSubmissions,
      });
    } catch (error) {
      if (!isCancel(error)) {
        fetchSubmissionsError(dispatch, { assignmentId, error });
      }
    }
  };

export default fetchSubmissions;
