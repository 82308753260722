import React from 'react';
import Table from './Table.container';

import { Container } from './Settings.styled';

const Settings = () => (
  <Container>
    <Table />
  </Container>
);

export default React.memo(Settings);
