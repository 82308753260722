import { GROUP_SETS } from '../../constants/endpoints';
import cancellableRequest from '../../api/cancellableRequest';
import request from './request';

const searchGroupSets =
  (cancelToken) =>
  async (accessToken, { spaceId, search, excludeInstitutionManaged }) => {
    const config = {
      method: 'get',
      url: GROUP_SETS({ spaceId, search, excludeInstitutionManaged }),
      cancelToken,
    };

    const response = await request(accessToken, config);
    return response.data.sets;
  };

export default cancellableRequest(searchGroupSets);
