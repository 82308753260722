const isObject = (o) => o === Object(o);

export const objectGetKeyFromString = (o, s) => {
  let obj = o;
  let string = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  string = string.replace(/^\./, ''); // strip a leading dot
  const a = string.split('.');
  for (let i = 0, n = a.length; i < n; i += 1) {
    const k = a[i];
    if (isObject(obj) && k in obj) {
      obj = obj[k];
    } else {
      return null;
    }
  }

  return obj;
};
