import queryKeys from '../hooks/queryKeys';

const addCommentToQueryData = (queryClient, comment) => {
  const { entityType, entityId } = comment;
  const queryKey = queryKeys.comments(entityType, entityId);
  queryClient.setQueryData(queryKey, (data) => {
    return {
      comments: [...(data?.comments || []), comment],
      reactions: data?.reactions || [],
    };
  });
};

export default addCommentToQueryData;
