export default (comment) => ({
  id: comment.objectId,
  author: comment.user && comment.user.objectId,
  user: comment.user,
  post: comment.post.objectId,
  content: comment.content,
  createdAt: comment.createdAt,
  editedAt:
    comment.createdAt !== comment.updatedAt ? comment.updatedAt : undefined,
  deletedAt: comment.deletedAt,
  parent: comment.parent && comment.parent.objectId,
  root: comment.root && comment.root.objectId,
  entityId: comment.entityId,
  entityType: comment.entityType,
});
