import { NOTIFICATIONS } from '../../constants/endpoints';
import request from '../../api/request';

const fetchNotificationsApi = async (
  { unreadOnly } = { unreadOnly: false }
) => {
  const {
    data: { notifications },
  } = await request({
    method: 'GET',
    url: NOTIFICATIONS(unreadOnly),
  });

  return notifications;
};

export default fetchNotificationsApi;
