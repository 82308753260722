import { notificationTypes } from '../../constants/notifications';

export const fetchNotificationsReducer = (state, action) => {
  switch (action.status) {
    case 'started':
      return {
        ...state,
        isFetchingData: true,
      };
    case 'success': {
      const unreadSpaces = action.notifications.reduce((prev, notif) => {
        const { spaceId } = notif.notification;
        return notif.read === true
          ? prev
          : {
              ...prev,
              [spaceId]: true,
            };
      }, {});

      const classRooms = Object.keys(state.classRooms).reduce(
        (prev, classRoomId) => {
          return {
            ...prev,
            [classRoomId]: {
              ...state.classRooms[classRoomId],
              unread: unreadSpaces[classRoomId] || false,
            },
          };
        },
        {}
      );
      return {
        ...state,
        notifications: action.notifications,
        classRooms,
        isFetchingData: false,
      };
    }
    case 'error':
      return {
        ...state,
        isFetchingData: false,
      };

    default:
      return state;
  }
};

export const setReadNotificationReducer = (state, action) => {
  switch (action.status) {
    case 'started': {
      const readNotifications = state.notifications.map((notif) =>
        action.notificationIds.includes(notif.id)
          ? {
              ...notif,
              read: true,
            }
          : notif
      );
      return {
        ...state,
        notifications: readNotifications,
        notificationsError: false,
      };
    }
    case 'error': {
      const readNotifications = state.notifications.map((notif) =>
        action.notificationIds.includes(notif.id)
          ? {
              ...notif,
              read: false,
            }
          : notif
      );
      return {
        ...state,
        notifications: readNotifications,
        notificationsError: true,
      };
    }
    default:
      return state;
  }
};

export const setReadNotificationTypeReducer = (state, action) => {
  switch (action.status) {
    case 'success': {
      let { classRoomId } = action;

      if (!state.classRooms[classRoomId]) {
        // eslint-disable-next-line prefer-destructuring
        classRoomId = Object.keys(state.classRooms)[0];
      }
      const classRoomNotificationsIds =
        state.classRooms[classRoomId].notifications;
      const classRoomNotifications = classRoomNotificationsIds
        .map((notificationId) => state.notifications[notificationId])
        .filter(
          (nu) =>
            notificationTypes[nu.notification.action] ===
            action.notificationType
        )
        .map((nu) => ({
          ...nu,
          read: true,
        }));

      const notifications = classRoomNotifications.reduce(
        (notif, notification) => ({
          ...notif,
          [notification.objectId]: notification,
        }),
        { ...state.notifications }
      );

      return {
        ...state,
        notifications,
      };
    }
    default:
      return state;
  }
};
