import { USER } from '../../constants/endpoints';
import request from '../request';

const patchUser = async ({ userId, patch }) => {
  const {
    data: { user },
  } = await request({
    method: 'PATCH',
    url: `${USER(userId)}`,
    data: patch,
  });

  return user;
};

export default patchUser;
