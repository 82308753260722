import { appendAndReorderMessages, unique } from './utils';

const getId = (o) => o.id;

const copyMessages = (store) => (conversation) => {
  const { messages } = store[conversation.id] || {};
  return {
    ...conversation,
    messages: messages || [],
  };
};

const getFromStore = (store) => (id) => store[id];

const byId = (acc, next) => {
  acc[next.id] = next;
  return acc;
};

export default (state, action) => {
  if (action.status !== 'success') return state;
  const {
    conversations,
    currentConversationId,
    resetConversationIds,
    currentConversationMessages,
  } = action.payload;

  const updatedConversations = conversations
    .map(copyMessages(state.conversations))
    .reduce(byId, {});
  if (
    currentConversationId &&
    currentConversationMessages &&
    currentConversationMessages.length
  ) {
    const currentConversationFromState =
      state.conversations[currentConversationId];
    const updatedConversationMessages = appendAndReorderMessages(
      currentConversationFromState.messages.map(getFromStore(state.messages)),
      currentConversationMessages
    );

    updatedConversations[currentConversationId].messages = unique(
      updatedConversationMessages.map(getId)
    );
  }
  resetConversationIds.forEach((id) => {
    updatedConversations[id].messages = [];
  });

  return {
    ...state,
    conversations: {
      ...state.conversations,
      ...updatedConversations,
    },
    messages: {
      ...state.messages,
      ...(currentConversationMessages || []).reduce(byId, {}),
    },
  };
};
