import * as types from '../../constants/actions';

const initialState = {
  isFetching: false,
  fetched: false,
  emojis: [],
  isErrored: false,
};

const emojiFetchReducer = (state = initialState, action) => {
  switch (action.status) {
    case 'started':
      return {
        ...state,
        isFetching: true,
      };
    case 'success':
      return {
        ...initialState,
        emojis: action.emojis.map((e) => {
          if (e.classRoom) {
            return {
              ...e,
              classRoom: e.classRoom.objectId,
            };
          }

          return e;
        }),
        isFetching: false,
        fetched: true,
      };
    case 'error':
      return {
        ...state,
        isFetching: false,
        fetched: false,
        isErrored: true,
      };

    default:
      return state;
  }
};

const emojiReducer = (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case types.FETCH_EMOJIS:
      return emojiFetchReducer(state, action);
    default:
      return state;
  }
};

export default emojiReducer;
