const toggleHideAssignmentReducer = (state, action) => {
  switch (action.status) {
    case 'started':
    case 'error': {
      const { assignment } = action;

      const assignments = state.assignments.map((item) =>
        item.id === assignment.id ? { ...item, isHidden: !item.isHidden } : item
      );

      return {
        ...state,
        assignments,
      };
    }

    default:
      // as isHidden is optimistically toggled on 'started', 'success' can simply return state
      return state;
  }
};

export default toggleHideAssignmentReducer;
