import { newError } from '../errors';
import { fetchSpaceContent } from '../space';
import filterByTag from './filter';
import * as actionTypes from '../../constants/actions';
import changeSpaceInAssignments from '../../assignments/operations/changeSpace.operation';
import fetchGroupSets from '../../groups/operations/fetchGroupSets.operation';
import {
  analyticsTrackEvent,
  analyticsChangeClassRoom,
} from '../../utils/analytics';
import identifyUserInAppcues from '../../utils/appcues';
import * as analyticsTypes from '../../constants/analytics';
import fetchAccessTokenAction from '../accessToken/accessToken.action';
import getSpace from '../../api/spaces/getSpace';

// Fetch joined classRoom
export const fetchJoinedClassRoomsSuccess = (classRoomUsers, dispatch) => {
  dispatch({
    type: actionTypes.CLASSROOM_FETCH_JOINED,
    status: 'success',
    classRoomUsers,
  });
};

// Change classRoom
const changeClassRoomStart = ({ id }) => ({
  type: actionTypes.CLASSROOM_CHANGE,
  status: 'started',
  payload: { id },
});

const changeClassRoomSuccess = ({ id, tagId }, dispatch) => {
  dispatch({
    type: actionTypes.CLASSROOM_CHANGE,
    status: 'success',
    payload: { id, tagId },
  });
};

const changeClassRoomError = (error, dispatch) => {
  dispatch(newError(error, 'changeCurrentClassRoom'));
  dispatch({
    type: actionTypes.CLASSROOM_CHANGE,
    status: 'error',
    error,
  });
};

const changeClassRoom =
  (id, report = true, reset = false, tagId, fetchFeatureFlags = null) =>
  async (dispatch, getState) => {
    if (!id) {
      const err = { message: "You need to provide the classRoom's id" };
      changeClassRoomError(err, dispatch);
      return;
    }

    const state = getState();
    let classRoom = state.data.classRooms[id];
    const isStaff = state.user.user.userRoles.includes('staff');

    if (isStaff && !classRoom) {
      const space = await getSpace({ spaceId: id });

      fetchJoinedClassRoomsSuccess(
        [
          {
            objectId: space.id,
            classRoom: {
              objectId: space.id,
              name: space.name,
              importantPostCount: space.importantPostCount,
              participantCount: space.participantCount,
              studentCount: space.studentCount,
              instructors: space.educators,
              isStaff: true,
            },
          },
        ],
        dispatch
      );

      // In the normal case the space is already in the state and it has been processed by
      // the appropriate reducer. In this case, we just fetched it from the backend,
      // so we have to "fake" its format: adding `posts`, `announcements` and `objectId`
      // as they are the properties accessed by the action.
      classRoom = { objectId: space.id, posts: [], announcements: [] };
    } else if (!isStaff && !state.data.classRooms[id]) {
      // TODO: redirect to 404 space
      return;
    }

    dispatch(changeClassRoomStart({ id }));

    if (fetchFeatureFlags) {
      await fetchFeatureFlags(id);
    }

    dispatch({
      type: actionTypes.FEED_FILTER_TAG,
      payload: { tagId: undefined },
    });

    const { accessTokens } = getState();

    // fetch accessToken for a space if it's not fetched yet
    const shouldFetchToken = !accessTokens[id];

    try {
      if (shouldFetchToken) {
        await fetchAccessTokenAction(dispatch)({
          spaceId: id,
        });
      }
      const fetchContentByTag = (providedTagId) =>
        providedTagId
          ? filterByTag(providedTagId, classRoom)
          : fetchSpaceContent({ classRoom, reset });

      await dispatch(fetchContentByTag(tagId));

      changeClassRoomSuccess({ id, tagId }, dispatch);
      // change classroom in assignments as well
      // for default value on startup
      changeSpaceInAssignments(dispatch)(id);

      // initialize Analytics again because we have changed class
      analyticsChangeClassRoom(getState);

      // Appcues: Identifying the user here allows us to ensure
      // space has successfully loaded for the user when first
      // loading the app. This should trigger Appcues when web-app
      // first loads. You will find another call to appcues in
      // `App.jsx`.
      dispatch(identifyUserInAppcues(id));
      dispatch(fetchGroupSets());

      if (report) {
        analyticsTrackEvent(analyticsTypes.CHANGE_CLASSROOM, {
          activeClassroom: id,
        });
      }
      return;
    } catch (error) {
      changeClassRoomError(error, dispatch);
    }
  };

export { changeClassRoom };
