import {
  SPACE_SELECTOR_ITEM_TYPE,
  SPACE_SELECTOR_SPACE_SELECTED,
} from '../../constants/analytics';
import { getSpaceById } from '../../selectors/space';
import { analyticsTrackEvent } from '../../utils/analytics';
import { SELECT_SPACE_SELECTOR } from '../actions';
import { openTimestamp, openCorrelationId } from '../selectors';
import getNow from '../getNow';

const selectSpaceSelector =
  (spaceId, showArchived, sort, category, subCategory) =>
  (dispatch, getState) => {
    const state = getState();

    const { isStarred } = getSpaceById(state, spaceId) || {
      isStarred: false,
    };
    analyticsTrackEvent(SPACE_SELECTOR_SPACE_SELECTED, {
      itemType: SPACE_SELECTOR_ITEM_TYPE,
      selectorOpenFor: Math.round(getNow() - openTimestamp(state)),
      correlationId: openCorrelationId(state),
      selectedSpaceId: spaceId,
      categoriesEnabled: true,
      showArchived,
      sort,
      category,
      subCategory,
      isStarred,
    });

    dispatch({
      type: SELECT_SPACE_SELECTOR,
    });
  };

export default selectSpaceSelector;
