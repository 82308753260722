import React from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import NotificationsIcon from '@material-ui/icons/Notifications';
import HelpIcon from '@material-ui/icons/Help';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import {
  Avatar,
  NavBar,
  Menu,
  ListItem,
  Tooltip,
} from '@ublend-npm/aulaui-next';

import useCustomZendeskItems from '@app/hooks/useCustomZendeskItems';

import {
  IconButton,
  AvatarContainer,
  HelpButtonContainer,
  OpenInNewTabIcon,
  SubTextContainer,
} from './UserBar.styled';
import Zendesk from '../../Zendesk/Zendesk';
import Intercom from '../../Intercom/Intercom.container';
import NotificationsDialog from '../../NotificationsDialog';
import Badge from '../../common/Badge';
import { AULA_PRODUCT_PORTAL, GET_HELP } from '../../../../constants/texts';

const UserBarWrapper = ({
  onSearchClick,
  onHelpClick,
  onPortalMenuOpen,
  onAvatarClick,
  onNotificationsClick,
  avatarName,
  avatarSrc,
  hasUnreadNotifications,
  isStaffOrEducator,
}) => {
  const { educatorItems, studentItems, showCustomItems } =
    useCustomZendeskItems();

  const notificationsButton = (
    <HelpButtonContainer>
      <IconButton
        onClick={onNotificationsClick}
        size="small"
        label="Notifications"
        type="secondary"
        icon={NotificationsIcon}
      />
    </HelpButtonContainer>
  );
  const wrappedNotificationsButton = hasUnreadNotifications ? (
    <Badge variant="dot" size="small">
      {notificationsButton}
    </Badge>
  ) : (
    notificationsButton
  );

  const openInNewTab = () => {
    window.open('https://portal.aula.education/tabs/3-launched', '_blank');
  };

  const getMenuItems = [
    {
      key: 'GET_HELP',
      text: GET_HELP,
      id: 'get-help',
      fn: () => onHelpClick,
      info: '',
      showIcon: false,
    },
    ...(isStaffOrEducator ? educatorItems : studentItems),
    {
      key: 'AULA_PRODUCT_PORTAL',
      text: AULA_PRODUCT_PORTAL,
      id: 'aula-product-portal',
      fn: () => openInNewTab,
      info: 'Learn about new and upcoming features',
      showIcon: true,
    },
  ];

  const renderText = (text, showIcon) => (
    <div>
      {text}
      {showIcon && <OpenInNewTabIcon IconType={OpenInNewIcon} />}
    </div>
  );

  const renderSubText = (info) => <SubTextContainer> {info} </SubTextContainer>;

  return (
    <NavBar
      role="region"
      aria-label="user bar"
      bottomDecoration={
        <Menu
          menuListComponent="ul"
          menuListAttributes={{
            role: 'listbox',
            'aria-label': 'Get help',
          }}
          renderTarget={({ handleTargetClick }) => (
            <Tooltip title="Help" position="right">
              <HelpButtonContainer>
                <IconButton
                  onClick={(...args) => {
                    onPortalMenuOpen();
                    handleTargetClick(...args);
                  }}
                  size="small"
                  label="help"
                  icon={HelpIcon}
                  type="secondary"
                />
              </HelpButtonContainer>
            </Tooltip>
          )}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          onSelect={({ item }) => item?.()}
          renderChildren={({ getItemProps }) =>
            getMenuItems.map(({ key, text, id, showIcon, info, fn }) => (
              <ListItem
                size="medium"
                key={key}
                primaryText={renderText(text, showIcon)}
                secondaryText={renderSubText(info)}
                attributes={{
                  id,
                  'aria-label': text,
                  'data-testid': key.toLowerCase(),
                }}
                {...getItemProps({
                  item: fn(),
                })}
              />
            ))
          }
        />
      }
    >
      <Tooltip title="My profile" space={4} position="right">
        <AvatarContainer
          onClick={onAvatarClick}
          role="button"
          aria-label="My profile"
          onKeyPress={(e) => e.key === 'Enter' && onAvatarClick()}
          tabIndex={0}
        >
          <Avatar name={avatarName} src={avatarSrc} size="large" />
        </AvatarContainer>
      </Tooltip>
      <Tooltip title="Search" space={4} position="right">
        <HelpButtonContainer>
          <IconButton
            onClick={onSearchClick}
            size="small"
            label="Search"
            type="secondary"
            icon={SearchIcon}
          />
        </HelpButtonContainer>
      </Tooltip>
      <Tooltip title="Notifications" space={4} position="right">
        {wrappedNotificationsButton}
      </Tooltip>

      {!window.Cypress && (
        <>
          <Zendesk suppressContactForm={showCustomItems} />
          <Intercom />
        </>
      )}
      <NotificationsDialog />
    </NavBar>
  );
};

UserBarWrapper.defaultProps = {
  avatarName: '',
  avatarSrc: '',
  hasUnreadNotifications: false,
};

UserBarWrapper.propTypes = {
  avatarName: PropTypes.string,
  avatarSrc: PropTypes.string,
  hasUnreadNotifications: PropTypes.bool,
  onHelpClick: PropTypes.func.isRequired,
  onPortalMenuOpen: PropTypes.func.isRequired,
  onAvatarClick: PropTypes.func.isRequired,
  onSearchClick: PropTypes.func.isRequired,
  onNotificationsClick: PropTypes.func.isRequired,
  isStaffOrEducator: PropTypes.bool.isRequired,
};

export default React.memo(UserBarWrapper);
