import { tryToSubscribeToSpaceLevelEvents } from '../actions/socket';
import { currentClassRoomId } from '../selectors/classroom';
import { LIVE_CONNECT_ON, CLASSROOM_CHANGE } from '../constants/actions';

const subscribeToSpaceLevelEvents =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    if (action.type === LIVE_CONNECT_ON) {
      const spaceId = currentClassRoomId(getState());
      dispatch(tryToSubscribeToSpaceLevelEvents(spaceId));
    } else if (
      action.type === CLASSROOM_CHANGE &&
      action.status === 'started'
    ) {
      const spaceId = action.payload?.id;
      dispatch(tryToSubscribeToSpaceLevelEvents(spaceId));
    }

    return next(action);
  };

export default subscribeToSpaceLevelEvents;
