import React from 'react';
import { theme } from '@ublend-npm/aulaui-next';
import {
  CircularProgress,
  MuiThemeProvider,
  createTheme,
} from '@material-ui/core';

export const loaderTheme = createTheme({
  palette: {
    primary: {
      main: theme.color.designSystem.purple70,
    },
  },
});

type CircularProgressProps = {
  size?: string;
  thickness?: number;
  style?: React.CSSProperties;
};

const CircularProgressPresentational = (props: CircularProgressProps) => {
  return (
    <MuiThemeProvider theme={loaderTheme}>
      <CircularProgress {...props} />
    </MuiThemeProvider>
  );
};

export default CircularProgressPresentational;
