const byObjectIdReducer = (acc, next) => {
  acc[next.objectId] = {
    ...next,
    id: next.objectId,
  };
  return acc;
};

export const fetchUsersReducer = (state, action) => {
  const { status } = action;
  if (status !== 'success') return state;

  const {
    payload: { users },
  } = action;
  return {
    ...state,
    ...users.reduce(byObjectIdReducer, {}),
  };
};
