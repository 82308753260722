import { GenericAction } from '../../types/redux';
import { CLASSROOM_MODULE_LIVE_UPDATED_MATERIAL } from '../actions';
import { Material } from '../types';
import { MaterialDataReducer } from './state';

export type LiveUpdatedMaterialAction = GenericAction<
  typeof CLASSROOM_MODULE_LIVE_UPDATED_MATERIAL,
  {
    material: Material;
    localId?: string;
  }
>;

const getUpdatedMaterial = <T extends Material>(
  existing: T | undefined,
  newMaterial: T
) => ({
  ...existing,
  ...newMaterial,
});

const liveUpdatedMaterialReducer: MaterialDataReducer<
  LiveUpdatedMaterialAction
> = (state, action) => {
  const { material, localId } = action.payload;

  const newState = {
    ...state,
    [material.id]: getUpdatedMaterial(state[material.id], material),
  };

  if (localId) {
    delete newState[localId];
  }

  return newState;
};

export default liveUpdatedMaterialReducer;
