import React from 'react';
import PropTypes from 'prop-types';

import BaseDialog from '@material-ui/core/Dialog';

import DialogTitle from './DialogTitle.styled';

export const DIALOG_ANIMATION_DURATION_MS = 300;

const Dialog = ({ children, id, onClose, ...props }) => {
  const titleId = `${id}-dialogtitle`;

  return (
    <BaseDialog
      transitionDuration={{
        enter: DIALOG_ANIMATION_DURATION_MS,
        exit: DIALOG_ANIMATION_DURATION_MS,
      }}
      scroll="paper"
      id={id}
      aria-labelledby={titleId}
      onClose={onClose}
      aria-modal
      fullWidth
      {...props}
    >
      {React.Children.toArray(children).map((child) => {
        if (child.type === DialogTitle) {
          return React.cloneElement(child, {
            onClose,
            id: titleId,
          });
        }
        return child;
      })}
    </BaseDialog>
  );
};

Dialog.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Dialog.defaultProps = {
  onClose: () => {},
};

export default Dialog;
