import React from 'react';
import styled from 'react-emotion';
import { theme, IconButton as AulauiIconButton } from '@ublend-npm/aulaui-next';

export const IconButton = styled(AulauiIconButton)`
  color: ${theme.color.white}!important;
  background-color: transparent;
  opacity: 70%;
  :hover {
    background-color: ${theme.color.blackAlpha(0.28)};
  }
  :active {
    background-color: ${theme.color.blackAlpha(0.08)};
  }
`;

export const HelpButtonContainer = styled('div')`
  display: flex;
  height: fit-content;
  width: fit-content;
  margin-bottom: 12px;
`;

export const AvatarContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  margin-bottom: 12px;
`;

export const OpenInNewTabIcon = styled(({ IconType, ...props }) => (
  <IconType {...props} />
))`
  font-size: ${theme.font.size.copy};
  color: ${theme.color.grey11};
  margin-left: 8px;
  vertical-align: middle;
`;

export const SubTextContainer = styled.div`
  font-family: ${theme.font.family.workSans};
  font-size: ${theme.font.size.small};
  line-height: 24px;
  color: ${theme.color.grey11};
`;
