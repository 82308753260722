import { REMOVE_REACTION } from '../actions';
import { deleteReaction } from '../api';
import { currentUserId } from '../../selectors/user';
import { reaction as getReaction } from '../selectors';

const removeReactionStarted = (dispatch, payload) =>
  dispatch({
    type: REMOVE_REACTION,
    status: 'started',
    payload,
  });

const removeReactionSuccess = (dispatch, payload) =>
  dispatch({
    type: REMOVE_REACTION,
    status: 'success',
    payload,
  });

const removeReactionError = (dispatch, payload) =>
  dispatch({
    type: REMOVE_REACTION,
    status: 'error',
    payload,
  });

const removeReaction = (reactionId) => async (dispatch, getState) => {
  const state = getState();
  const user = currentUserId(state);
  const oldReaction = getReaction(state, reactionId);
  removeReactionStarted(dispatch, {
    oldReaction,
    user,
  });
  try {
    const reaction = await deleteReaction(reactionId);
    removeReactionSuccess(dispatch, {
      reaction,
      user,
      local: true,
    });
    return reaction;
  } catch (error) {
    removeReactionError(dispatch, {
      oldReaction,
      error,
      user,
    });
    throw error;
  }
};

export default removeReaction;
