const sortByEndDate = (prev, next) => {
  const prevEndDate = new Date(prev.endDate);
  const nextEndDate = new Date(next.endDate);

  if (prevEndDate > nextEndDate) {
    return 1;
  }

  if (prevEndDate < nextEndDate) {
    return -1;
  }

  return 0;
};

export default sortByEndDate;
