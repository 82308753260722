/* eslint-disable prefer-destructuring */
import duck from '../../core/notifications';

import displayNotification from '../utils/displayNotification';

const notifications = duck({ displayNotification });

export const operations = notifications.operations;
export const selectors = notifications.selectors;

export default notifications;
