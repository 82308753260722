import { AulaReduxAction } from '@core/types/state';
import { FetchProvidersAction } from '@core/reducers/lti/fetchProviders.reducer';
import { NewErrorAction, newError } from '../errors';
import * as actionTypes from '../../constants/actions';
import fetch from '../../api/lti/fetchProviders';
import { LtiProvider } from '@ublend-npm/aula-schema';
import { Dispatch } from 'redux';

type FetchProvidersDispatch = Dispatch<FetchProvidersAction | NewErrorAction>;

const fetchProvidersStart = (dispatch: FetchProvidersDispatch) => {
  dispatch({
    type: actionTypes.LTI_FETCH_PROVIDERS,
    status: 'started',
  });
};

const fetchProvidersSuccess = (
  providers: LtiProvider[],
  dispatch: FetchProvidersDispatch
) => {
  dispatch({
    type: actionTypes.LTI_FETCH_PROVIDERS,
    status: 'success',
    payload: { providers },
  });
};

const fetchProvidersError = (
  error: unknown,
  dispatch: FetchProvidersDispatch
) => {
  dispatch(newError(error, 'fetchProviders'));
  dispatch({
    type: actionTypes.LTI_FETCH_PROVIDERS,
    status: 'error',
  });
};

type FetchProvidersActionFactory = () => AulaReduxAction<
  void,
  FetchProvidersAction | NewErrorAction
>;

const fetchProviders: FetchProvidersActionFactory =
  () => async (dispatch, getState) => {
    if (!getState().user.isLogged) {
      const err = { message: 'You need to login first' };
      return fetchProvidersError(err, dispatch);
    }

    fetchProvidersStart(dispatch);

    try {
      const providers = await fetch();
      return fetchProvidersSuccess(providers, dispatch);
    } catch (error) {
      return fetchProvidersError(error, dispatch);
    }
  };

export default fetchProviders;
