import { css } from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const container = css`
  display: flex;
  flex: 1;
  gap: ${theme.spacing.xxSmall};
  justify-content: space-between;
  align-items: center;
  height: ${theme.spacing.xxxGiant};
  padding: ${theme.spacing.xSmall};
  box-sizing: border-box;
  background-color: ${theme.color.white};
  border-top: 1px solid ${theme.color.gray};
`;

export const content = css`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-expand: 0;
  align-items: center;
  height: 100%;
  padding: 0;
  box-sizing: border-box;
  background: ${theme.color.white};
  border: 0;

  & input {
    color: ${theme.color.mediumBlue};
    margin-left: ${theme.spacing.xxxSmall};
    border: 0;
    font: ${theme.font.weight.regular} ${theme.font.size.copy} 'Work Sans',
      sans-serif;
  }

  & input:focus {
    outline: none;
  }
`;

export const focused = css`
  border-color: ${theme.color.purple};
`;

export const icon = css`
  width: ${theme.spacing.xLarge};
  height: ${theme.spacing.xLarge};
  color: ${theme.color.grey11};
`;

export const searchInput = css`
  flex: 1;
`;
