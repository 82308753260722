import * as actions from '../actions';

import {
  addParticipants,
  applyPayload,
  createConversation,
  editMessage,
  fetchConversations,
  fetchMessage,
  fetchMessages,
  filterConversations,
  leaveConversation,
  liveTyping,
  openCloseEditMessage,
  reconnectReducer,
  renameConversation,
  removeParticipant,
  replyToMessage,
  highlightMessage,
} from './inbox';

const initialState = {
  addingParticipants: false,
  addParticipantOpened: false,
  conversationHasOlderMessages: {},
  createGroupOpened: false,
  creatingConversation: false,
  creatingConversationWith: {},
  currentlyEditedMessageId: undefined,
  replyingMessageId: undefined,
  editingMessage: false,
  fetchingConversations: true,
  fetchingMessage: {},
  fetchingMessages: {},
  filtering: {
    query: '',
    conversationIds: [],
  },
  initialFetchDone: {},
  opened: true,
  leaveGroupOpened: false,
  leavingConversation: false,
  removingParticipant: false,
  renameGroupOpened: false,
  renamingConversation: false,
  typingInConversations: {},
  viewParticipantsOpened: false,
  highlightedMessage: null,
};

const reducers = {
  [actions.INBOX_ADD_PARTICIPANTS_TO_CONVERSATION]: addParticipants,
  [actions.INBOX_CREATE_CONVERSATION]: createConversation,
  [actions.INBOX_EDIT_MESSAGE]: editMessage,
  // no typo, the fetchConversations reducer is suitable here
  [actions.INBOX_FETCH_CONVERSATION]: fetchConversations,
  [actions.INBOX_FETCH_CONVERSATIONS]: fetchConversations,
  [actions.INBOX_FETCH_MESSAGE]: fetchMessage,
  [actions.INBOX_FETCH_MESSAGES]: fetchMessages,
  [actions.INBOX_FILTER_CONVERSATIONS]: filterConversations,
  [actions.LIVE_TYPING]: liveTyping,
  [actions.INBOX_OPEN_CLOSE_EDIT_MESSAGE]: openCloseEditMessage,
  [actions.INBOX_OPEN_CLOSE_LEAVE_GROUP]: applyPayload,
  [actions.INBOX_OPEN_CLOSE_RENAME_GROUP]: applyPayload,
  [actions.INBOX_OPEN_CLOSE_VIEW_PARTICIPANTS]: applyPayload,
  [actions.INBOX_LEAVE_CONVERSATION]: leaveConversation,
  [actions.INBOX_RECONNECT]: reconnectReducer,
  [actions.INBOX_REMOVE_PARTICIPANT_FROM_CONVERSATION]: removeParticipant,
  [actions.INBOX_RENAME_CONVERSATION]: renameConversation,
  [actions.INBOX_REPLY_TO_MESSAGE]: replyToMessage,
  [actions.INBOX_HIGHLIGHT_MESSAGE]: highlightMessage,
};

const reducer = (state = initialState, action) => {
  if (!action) {
    return state;
  }

  const r = reducers[action.type];
  return r ? r(state, action) : state;
};

export default reducer;
