import * as userSelectors from '../../selectors/user';
import { LIVE_TYPING } from '../actions';

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const sendLiveTypingStart = (groupId, userId) => ({
  type: LIVE_TYPING,
  status: 'start',
  groupId,
  userId,
});

const sendLiveTypingEnd = (groupId, userId) => ({
  type: LIVE_TYPING,
  status: 'end',
  groupId,
  userId,
});

const updateHits = (typers, groupId, userId, value) => {
  const groupTypers = typers[groupId] || {};
  const hits = groupTypers[userId] || 0;
  groupTypers[userId] = hits + value;
  // eslint-disable-next-line no-param-reassign
  typers[groupId] = groupTypers;

  return groupTypers[userId];
};

const incrementHits = (typers, groupId, userId) =>
  updateHits(typers, groupId, userId, 1);
const decrementHits = (typers, groupId, userId) =>
  updateHits(typers, groupId, userId, -1);

export default () => {
  const typers = {};

  return (data) => async (dispatch, getState) => {
    const currentUserId = userSelectors.currentUserId(getState());
    const { group, user } = JSON.parse(data);

    // Do not dispatch if typing user is current one
    if (user === currentUserId) return;

    incrementHits(typers, group, user);
    dispatch(sendLiveTypingStart(group, user));

    await delay(3000);

    const hits = decrementHits(typers, group, user);
    if (!hits) {
      dispatch(sendLiveTypingEnd(group, user));
    }
  };
};
