const updateMessageDraftReducer = (state, action) => {
  if (action.status !== 'success') {
    return state;
  }

  const { drafts } = state;
  const {
    payload: { conversationId, draft },
  } = action;

  return {
    ...state,
    drafts: {
      ...drafts,
      [conversationId]: draft,
    },
  };
};

export default updateMessageDraftReducer;
