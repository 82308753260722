import React from 'react';
import PropTypes from 'prop-types';
import Emoji from '../../emoji/Emoji';
import StyledEmojiButton from './EmojiButton.styled';

const EmojiButton = ({ emoji, onClick }) => {
  return (
    <StyledEmojiButton
      type="button"
      onClick={onClick}
      onKeyDown={(e) =>
        (e.keyCode === ENTER || e.keyCode === SPACE) && onClick()
      }
    >
      <Emoji emoji={emoji} />
    </StyledEmojiButton>
  );
};

EmojiButton.propTypes = {
  emoji: PropTypes.shape({
    unified: PropTypes.string,
    short_name: PropTypes.string,
    shortName: PropTypes.string,
    url: PropTypes.string,
    file: PropTypes.shape({ url: PropTypes.string }),
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default EmojiButton;
