const { isInstructor } = require('../selectors/classroom');

export const lastNotification = (state) =>
  state.liveNotifications.newNotification;

export const lastNotificationId = (state) => {
  const notification = lastNotification(state);
  return notification && notification.id;
};

export const notifications = (state) => state.data.notifications;
export const notificationsError = (state) => state.data.notificationsError;

export const culpritData = (state, culprit) => {
  const { firstName, lastName, avatar } = culprit;
  return {
    isInstructor: isInstructor(state, culprit.objectId),
    firstName,
    lastName,
    avatar,
  };
};

export const notificationData = (notification) => {
  const {
    actionText,
    actionData,
    alert,
    content,
    action: actionType,
    parentItemId,
    itemId,
  } = notification;

  // Temporary until we "flush" out old notifications
  const actions = {
    newPost: 'just posted',
    newComment: 'just commented on your post',
    newCommentReply: 'just replied to your comment',
    newCommentOnCommentedPost: 'also commented on this post',
  };

  if (actionText && actionData) {
    const constructedAction = Object.keys(actionData).reduce((msg, data) => {
      if (data === 'culprit') {
        return msg.replace('{{culprit}} ', '');
      }
      return msg.replace(`{{${data}}}`, actionData[data]);
    }, actionText);
    return {
      actionType,
      action: constructedAction,
      content: content.replace(/<br \/>/g, ''),
      parentItemId,
      itemId,
    };
  }
  const notificationFields = alert.split(/: (.+)/);
  return {
    content:
      notificationFields[1] && notificationFields[1].replace(/<br \/>/g, ''),
    actionType,
    action: actions[actionType],
    parentItemId,
    itemId,
  };
};

export const notificationTypeFilter = (state) =>
  state.liveNotifications.notificationTypeFilter;

export const hasFetchedSettings = (state) =>
  !!state.liveNotifications.isFetched;
export const isFetchingSettings = (state) =>
  !!state.liveNotifications.isFetchingNotificationsSettings;
export const isSavingSettings = (state) =>
  !!state.liveNotifications.isSavingNotificationsSettings;
export const notificationSettings = (state) =>
  state.data.liveNotifications.settings;
export const mutedConversations = (state) =>
  notificationSettings(state).mutedConversations;
export const isConversationMuted = (state, conversationId) =>
  mutedConversations(state) && !!mutedConversations(state)[conversationId];
export const mutedSpaces = (state) => notificationSettings(state).mutedSpaces;
export const isSpaceMuted = (state, spaceId) =>
  mutedSpaces(state) && !!mutedSpaces(state)[spaceId];
export const hasUnreadNotifications = (state) =>
  state.ui.navBarNoticiationsBadge;
