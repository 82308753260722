const createAssignment = (state, action) => {
  switch (action.status) {
    case 'started':
      return {
        ...state,
        isCreating: true,
      };
    case 'success':
      return {
        ...state,
        isCreating: false,
      };
    case 'error':
      return {
        ...state,
        isCreating: false,
        assignmentCreationError: action.payload.assignmentCreationError,
      };
    default:
      return state;
  }
};

export default createAssignment;
