import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TabPanelContainer, Title, Tab } from './TabPanel.styled';
import { ENTER } from '../../../../utils/keyCodes';

const TabPanel = ({
  tabs,
  title,
  titlePosition,
  selectedTabIndex,
  canSelectDisabled,
  onTabClick,
  dense,
  scrollable,
}) => (
  <TabPanelContainer
    className="tabPanel"
    role="tablist"
    scrollable={scrollable}
    _dense={dense}
  >
    {tabs.map(({ TitleComponent, title: tabTitle, disabled, key }, index) => (
      <Fragment key={key || `fr_${tabTitle}`}>
        {index === titlePosition ? (
          <Title position={titlePosition}>{title}</Title>
        ) : null}
        <span
          className="tabPanel__wrapper"
          role="tab"
          tabIndex={0}
          onKeyDown={(event) => event.keyCode === ENTER && onTabClick(index)}
          aria-selected={selectedTabIndex === index}
        >
          <Tab
            className="tabPanel__tab"
            key={tabTitle}
            onClick={() => onTabClick(index)}
            selected={selectedTabIndex === index}
            disabled={disabled}
            canSelectDisabled={canSelectDisabled}
          >
            {TitleComponent ? <TitleComponent /> : tabTitle}
          </Tab>
        </span>
      </Fragment>
    ))}
  </TabPanelContainer>
);

TabPanel.propTypes = {
  /**
   * Label shown on top of the tabs
   */
  title: PropTypes.string,
  /**
   * Index where the title will be placed
   */
  titlePosition: PropTypes.number,
  /**
   * List of tabs to show. They'll be displayed in the provided order
   * Title can be an object in the case of UserProfile as we are returning a custom Component
   */
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
      disabled: PropTypes.bool,
      TitleComponent: PropTypes.elementType,
    })
  ).isRequired,
  /**
   * Index of the tab shown as selected
   */
  selectedTabIndex: PropTypes.number,
  /**
   * If true, disabled tabs will be clickable
   */
  canSelectDisabled: PropTypes.bool,
  /**
   * Called with the index of the clicked tab
   */
  onTabClick: PropTypes.func,
  /**
   * Toggle between padded and non padded version
   */
  dense: PropTypes.bool,
  /**
   * Tabs list is scrollable
   */
  scrollable: PropTypes.bool,
};

TabPanel.defaultProps = {
  title: '',
  titlePosition: 0,
  selectedTabIndex: -1,
  canSelectDisabled: false,
  onTabClick: () => undefined,
  dense: false,
  scrollable: false,
};

export default TabPanel;
