import { newError } from '../errors';
import * as actionTypes from '../../constants/actions';
import { getUniqueDeviceId } from '../../../app/utils/device';
import { socketDisconnect } from '../socket';
import logoutApiCall from '../../api/users/logout';

const logoutDeviceStart = () => ({
  type: actionTypes.USER_LOGOUT_DEVICE,
  status: 'started',
});

const logoutDeviceSuccess = (deviceId, dispatch) => {
  dispatch({
    type: actionTypes.USER_LOGOUT_DEVICE,
    status: 'success',
    deviceId,
  });
};

const logoutDeviceError = (error, dispatch) => {
  dispatch(newError(error, 'logoutDevice'));
  dispatch({
    type: actionTypes.USER_LOGOUT_DEVICE,
    status: 'error',
    error,
  });
};

export const logoutDevice =
  (deviceId, email, makeLogoutApiCall = true) =>
  async (dispatch) => {
    dispatch(logoutDeviceStart());
    if (makeLogoutApiCall) {
      await logoutApiCall({
        email,
        deviceId,
      }).catch((err) => {
        logoutDeviceError(err, dispatch);
      });
    }
    logoutDeviceSuccess(deviceId, dispatch);
  };

export const logout =
  (makeLogoutApiCall = true) =>
  (dispatch, getState) =>
    new Promise(async (resolve) => {
      if (!getState().user.isLogged) {
        const err = 'User not logged in';
        resolve(err);
        return;
      }

      dispatch(socketDisconnect());

      const {
        user: {
          user: { username: email },
        },
      } = getState();

      const deviceId = await getUniqueDeviceId();

      const logoutAndDispatchAction = async () => {
        await dispatch(logoutDevice(deviceId, email, makeLogoutApiCall));
        dispatch({
          type: actionTypes.USER_LOGOUT,
          status: 'started',
        });
      };

      try {
        await logoutAndDispatchAction();
      } catch (e) {
        // Retry before returning error.
        await logoutAndDispatchAction().catch((secondError) =>
          resolve(secondError)
        );
      }

      dispatch({
        type: actionTypes.USER_LOGOUT,
        status: 'success',
      });

      resolve();
    });
