const createConversation = (state, { status, payload }) => {
  const participantIds = (payload && payload.participantIds) || [];
  const creatingConversationWith = state.creatingConversationWith || {};
  switch (status) {
    case 'started':
      return {
        ...state,
        creatingConversation: true,
        creatingConversationWith: participantIds.reduce(
          (acc, userId) => {
            acc[userId] = true;
            return acc;
          },
          { ...creatingConversationWith }
        ),
      };
    case 'success':
    case 'error':
      return {
        ...state,
        creatingConversation: false,
        creatingConversationWith: Object.keys(creatingConversationWith).reduce(
          (acc, userId) => {
            if (!participantIds.includes(userId)) {
              acc[userId] = true;
            }
            return acc;
          },
          {}
        ),
      };
    default:
      return state;
  }
};

export default createConversation;
