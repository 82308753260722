import { isCancel } from 'axios';
import config from '../config/raven';
import { isProduction } from './environment';
import Raven from '../../app/utils/raven';
import getEnvironmentFromInstitutionUrl from './getEnvironmentFromInstitutionUrl';

const TEST_ENVIRONMENTS = ['staging', 'app', 'demo'];

let environment = {};
if (window && window.location && window.location.href) {
  environment = {
    environment: getEnvironmentFromInstitutionUrl(window.location.href),
  };
}

// this is originally sourced from core/utils/getEnvironmentFromInstitutionUrl.js but they can change independently
const checkWhetherLiveInstitution = (url) => {
  const httpVersion = url.startsWith('https') ? 'https://' : 'http://';
  const urlSplitOnHttp = url.split(httpVersion);
  if (urlSplitOnHttp.length < 2) {
    return true;
  }

  const firstPartOfUrl = urlSplitOnHttp[1].split('.')[0];
  if (
    firstPartOfUrl.startsWith('pa-') ||
    firstPartOfUrl.includes('-test') ||
    TEST_ENVIRONMENTS.includes(firstPartOfUrl)
  ) {
    return false;
  }

  if (firstPartOfUrl === 'local') {
    return false;
  }

  return true;
};

const getDSNForEnvironment = () => {
  if (
    window?.location?.href &&
    checkWhetherLiveInstitution(window.location.href)
  ) {
    return config.DSN;
  }

  // eslint-disable-next-line consistent-return
  return undefined;
};

const errorCanBeIgnored = (error) => {
  if (!error || !error.message) {
    return false;
  }
  if (isCancel(error)) {
    return true;
  }

  const { message } = error;
  if (message.includes('Not Found!')) {
    return true;
  }

  switch (message) {
    /** We are having a high number of network error and
     * this is eating away our sentry quota, so we're ignoring this for now.
     */
    case 'Network Error':
      return true;
    /** In theory this error should be caught by the `isCancel`
     * but since it has a high number I'm also adding this check
     * to avoid without a doubt that this sort of error is sent.
     */
    case 'Newer request.':
      return true;
    /**
     * When someone accesses a space they don't have permission to, this
     * error is thrown. No need to log to sentry, we can only improve
     * the UX - here's the issue for it: https://github.com/AulaEducation/aula/issues/9401
     */
    case 'You need to provide the classRoom':
      return true;
    default:
      return false;
  }
};

export const beforeSend = (event, hint) => {
  const originalError = hint.originalException;
  if (errorCanBeIgnored(originalError)) {
    return null;
  }

  return event;
};

const denyUrls = [
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
];

Raven.init({
  dsn: isProduction() ? getDSNForEnvironment(environment) : undefined,
  ...environment,
  beforeSend,
  denyUrls,
  // At the moment, web-app sends too many events to Sentry.
  // Reducing the sample rate will ensure we do not consume our quota
  // whilst ensuring we get a good enough sample to identify the
  // most common issues.
  sampleRate: 0.05,
});

export default Raven;
