import { EditorState } from '@ublend-npm/aula-editor';
import { CLASSROOM_MODULE_GET_SECTION } from '../actions';
import { MaterialAppReducer } from './state';
import { GenericStatusAction } from '../../types/redux';

export type GetSectionAction = GenericStatusAction<
  typeof CLASSROOM_MODULE_GET_SECTION,
  {
    sectionId: string;
    content: EditorState;
  }
>;

const getSection: MaterialAppReducer<GetSectionAction> = (state, action) => {
  switch (action.status) {
    case 'started':
      return {
        ...state,
        isLoadingContent: true,
        sectionId: action.payload.sectionId,
      };
    case 'aborted':
      return {
        ...state,
        isLoadingContent: false,
      };
    case 'success':
      return {
        ...state,
        selectedContent:
          state.sectionId === action.payload.sectionId
            ? action.payload.content
            : state.selectedContent,
        isLoadingContent: false,
        sectionId: action.payload.sectionId,
      };
    case 'error':
      return {
        ...state,
        selectedContent: null,
        isLoadingContent: false,
      };
    default:
      return state;
  }
};

export default getSection;
