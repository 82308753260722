import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';

import Transition from './SideDialogTransition';
import {
  Dialog,
  DialogContent,
  CloseButton,
  TitleContainer,
  TitleText,
  Subtitle,
  Content,
  Header,
} from './SideDialog.styled';

const SideDialog = ({
  open,
  onClose,
  title,
  subtitle,
  children,
  width,
  withHeaderDivider,
  testAttribute,
  contentStyle,
  onContentScroll,
  keepMounted,
}) => {
  const titleId = title.toLowerCase().trim().replace(' ', '');
  return (
    <Dialog
      scroll="paper"
      id={titleId}
      aria-label={title}
      aria-modal
      open={open}
      onClose={onClose}
      fullScreen
      TransitionComponent={Transition}
      width={width}
      data-testid={testAttribute}
      keepMounted={!!keepMounted}
    >
      <Header>
        <TitleContainer>
          <TitleText>{title}</TitleText>
          {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
        </TitleContainer>
        <CloseButton
          id={`${titleId}-dialogTitle`}
          aria-label="Close dialog"
          onClick={onClose}
        >
          <CloseIcon />
        </CloseButton>
      </Header>
      {withHeaderDivider ? <Divider /> : null}
      <DialogContent>
        <Content style={contentStyle} onScroll={onContentScroll}>
          {children}
        </Content>
      </DialogContent>
    </Dialog>
  );
};

SideDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.node.isRequired,
  width: PropTypes.string,
  withHeaderDivider: PropTypes.bool,
  testAttribute: PropTypes.string,
  contentStyle: PropTypes.shape({}),
  onContentScroll: PropTypes.func,
  keepMounted: PropTypes.bool,
};

SideDialog.defaultProps = {
  subtitle: null,
  width: null,
  withHeaderDivider: false,
  testAttribute: '',
  contentStyle: null,
  onContentScroll: () => {},
  keepMounted: false,
};

export default SideDialog;
