import { REACTIONS } from '../../constants/endpoints';
import request from '../../api/request';

const postReaction = async ({ itemId, itemType, emojiName }) => {
  const url = REACTIONS();
  const {
    data: { reaction },
  } = await request({
    method: 'post',
    url,
    data: {
      reaction: {
        itemId,
        itemType,
        emojiName,
      },
    },
  });

  return reaction;
};

export default postReaction;
