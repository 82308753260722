const renameConversationReducer = (state, action) => {
  if (action.status !== 'success') return state;

  const {
    payload: { conversationId, title },
  } = action;
  const conversation = state.conversations[conversationId];

  return {
    ...state,
    conversations: {
      ...state.conversations,
      [conversationId]: {
        ...conversation,
        title,
      },
    },
  };
};

export default renameConversationReducer;
