import * as api from '../api/directMessages';

const MAX_RETRIES = 4;
const BASE_RETRY_TIME_S = 4;

const doSend = async ({ conversationId, messageData, localId }) =>
  api.conversations.addMessage(conversationId, messageData, localId);

class MessageQueue {
  constructor() {
    this.queue = [];
    this.processing = false;
  }

  send(getState, message) {
    return new Promise((resolve, reject) => {
      const messageContext = {
        resolve,
        reject,
        message,
        retries: 0,
      };
      this.push(getState, messageContext);
    });
  }

  push(getState, messageContext) {
    this.queue.push(messageContext);
    this.consume(getState);
  }

  async consume(getState) {
    if (this.processing) return;
    if (!this.queue.length) return;
    if (!getState().connection) return;

    this.processing = true;

    const messageContext = this.queue.shift();
    const { resolve, reject, message, retries } = messageContext;

    try {
      const res = await doSend(message);
      resolve(res);
    } catch (error) {
      const status = error && error.response && error.response.status;
      const isClientError = status >= 400 && status < 500;
      if (isClientError || retries === MAX_RETRIES) {
        reject(error);
      } else {
        this.scheduleRetry(getState, messageContext);
      }
    } finally {
      this.processing = false;
      this.consume(getState);
    }
  }

  scheduleRetry(getState, messageContext) {
    const { retries } = messageContext;
    const updatedRetries = retries + 1;
    const updatedMessageContext = {
      ...messageContext,
      retries: updatedRetries,
    };
    const timeoutMs = BASE_RETRY_TIME_S ** updatedRetries * 1000;
    setTimeout(() => {
      this.push(getState, updatedMessageContext);
    }, timeoutMs);
  }
}

const messageQueue = new MessageQueue();

export default messageQueue;
