// @ts-check

import { SEARCH_ASSIGNMENT } from '../actions';

const searchAssignmentSuccess = (dispatch, payload) =>
  dispatch({
    type: SEARCH_ASSIGNMENT,
    status: 'success',
    payload,
  });

const searchAssignment = async (dispatch, query) => {
  searchAssignmentSuccess(dispatch, { query });
};

export default (dispatch) => (query) => searchAssignment(dispatch, query);
