import React, { useState, useEffect, useCallback, useMemo } from 'react';
import uniqBy from 'lodash.uniqby';
import { icons, theme } from '@ublend-npm/aulaui-next';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { hideVisually } from 'polished';
import Fuse from 'fuse.js';
import getCategories from '@core/spaceSelector/utils/getCategories';
import filterByCategory from '@core/spaceSelector/utils/filterByCategory';
import getSubCategories from '@core/spaceSelector/utils/getSubCategories';
import CircularProgress from '../../../CircularProgress';
import { ENTER, DOWN_ARROW } from '../../../../../utils/keyCodes';
import { stringIsContained } from '../../../../../utils/search';
import useCategoryPreferences from '../hooks/useCategoryPreferences';
import {
  SpaceSelectorContainer,
  SpaceAvatar,
  ArrowDropdownIcon,
  SpaceTitle,
  SpaceSelectorTarget,
  Text,
  Divider,
  BackToMainCategoryButton,
  BackToMainCategoryText,
  SpaceListContainer,
  GrayText,
  NoMatchContainer,
} from './SpaceSelector.styled';
import CurrentSpace from './CurrentSpace';
import StaffSpaces from './StaffSpaces.container';
import SpaceProptypes from './Space.proptype';
import SpaceFilter from './SpaceFilter';
import SideDialog from '../../../common/SideDialog';
import CategorySelector from './CategorySelector';
import SpaceList from './SpaceList';
import SpaceListAccordion from './SpaceListAccordion';
import StarSpaceOnboarding from './StarSpaceOnboarding';

const defaultSort = 'ascending';

const starredCategory = {
  label: 'Starred spaces',
  order: -1,
};

const SpaceSelector = ({
  currentSpace,
  isLoading,
  userRoles,
  spaces,
  onOpen,
  onDismiss,
  onSpaceImageChange,
  onSpaceSelect,
  onSpaceStar,
  onArchivedToggleChange,
  onSearchChange,
  onSortChange,
  onCategorySelect,
  onSubCategorySelect,
  isAdmin,
  tooltipPosition,
}) => {
  const [showArchived, setShowArchived] = useState(false);
  const [spaceSelectorOpened, setSpaceSelectorOpened] = useState(false);
  const [fuse, setFuse] = useState(null);
  const [filter, setFilter] = useState('');
  const [sortedBy, setSortedBy] = useState(defaultSort);
  const [filteredSpaces, setFilteredSpaces] = useState([]);
  const [dividerVisible, setDividerVisible] = useState(false);
  const [showStaffSpaces, setShowStaffSpaces] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [recentlyUnstarredSpaces, setRecentlyUnstarredSpaces] = useState([]);
  const [starredSpaces, setStarredSpaces] = useState([]);

  const categories = useMemo(
    () => [starredCategory, ...getCategories(spaces)],
    [spaces]
  );

  const {
    categoryPreferences,
    setCategoryPreference,
    setSortPreference,
    setSubCategoriesPreference,
  } = useCategoryPreferences();

  let primaryCategory = null;

  if (categories.length > 0) {
    [primaryCategory] = categories;
  }

  const toggleArchivedVisible = () => setShowArchived(!showArchived);

  const toggleSpaceSelectorOpened = () => {
    if (!spaceSelectorOpened) {
      onOpen();
    }

    setSpaceSelectorOpened(!spaceSelectorOpened);
  };

  useEffect(() => {
    if (!spaceSelectorOpened) {
      return;
    }
    if (categoryPreferences && categoryPreferences.category) {
      const category = categories.find(
        (c) => c.label === categoryPreferences.category.label
      );
      if (category) {
        setSelectedCategory(category);
      }
    } else {
      setSelectedCategory(primaryCategory);
    }

    if (categoryPreferences && categoryPreferences.sort) {
      setSortedBy(categoryPreferences.sort);
    }
  }, [spaceSelectorOpened, primaryCategory, categories, categoryPreferences]);

  useEffect(() => {
    setFuse(
      new Fuse(spaces, {
        threshold: 0.3,
        location: 0,
        distance: 1000,
        keys: ['name'],
        shouldSort: true,
      })
    );
  }, [spaces]);

  const handleSortChange = (newSort) => {
    const previousSort = sortedBy;

    onSortChange(
      previousSort,
      newSort,
      showArchived,
      selectedCategory ? selectedCategory.label : null
    );
    setSortedBy(newSort);
    setSortPreference(newSort);
  };

  const sortOptions = {
    ascending: {
      comparator: (a, b) => a.name.localeCompare(b.name),
      description: 'Name: A to Z',
      setSortOption: () => handleSortChange('ascending'),
    },
    descending: {
      comparator: (a, b) => b.name.localeCompare(a.name),
      description: 'Name: Z to A',
      setSortOption: () => handleSortChange('descending'),
    },
  };

  const searchSpaces = useCallback(
    (query) => {
      if (!fuse) return;

      if (query) {
        setFilteredSpaces(
          uniqBy(
            [
              ...spaces.reduce(
                (matches, space) =>
                  stringIsContained({ parent: space.name, child: query })
                    ? [...matches, space]
                    : matches,
                []
              ),
              ...fuse.search(query),
            ],
            ({ id }) => id
          )
        );
        onSearchChange(
          query,
          sortedBy,
          showArchived,
          selectedCategory ? selectedCategory.label : null
        );
      } else {
        setFilteredSpaces(spaces);
      }
    },
    [fuse, onSearchChange, selectedCategory, spaces, sortedBy, showArchived]
  );

  const searchSpacesWithDebounce = useMemo(
    () => debounce(searchSpaces, 200),
    [searchSpaces]
  );

  useEffect(() => {
    if (!filter) {
      // reset spaces immediately if clearing the input
      searchSpacesWithDebounce.cancel();
      searchSpaces(filter);
    } else {
      searchSpacesWithDebounce(filter);
    }
  }, [filter, searchSpaces, searchSpacesWithDebounce]);

  // Adding spaces to starred category as they are starred, and removing them when
  // they are unstarred only as the user navigates away from the starred category
  useEffect(() => {
    const newStarredSpaces = filteredSpaces.reduce((acc, s) => {
      const isRecentlyUnstarred = recentlyUnstarredSpaces.some(
        (rs) => rs.id === s.id
      );
      if (s.isStarred && !isRecentlyUnstarred) {
        return [...acc, { ...s, categories: [starredCategory] }];
      }
      return acc;
    }, []);

    setStarredSpaces([...newStarredSpaces, ...recentlyUnstarredSpaces]);
  }, [filteredSpaces, recentlyUnstarredSpaces]);

  const handleArchivedToggleChange = () => {
    onArchivedToggleChange(
      !showArchived,
      sortedBy,
      selectedCategory ? selectedCategory.label : null
    );
    toggleArchivedVisible();
  };

  const renderSpaceAvatar = () =>
    isLoading ? (
      <CircularProgress size="32px" />
    ) : (
      <SpaceAvatar
        name={currentSpace.name}
        src={currentSpace.avatar}
        size="medium"
      />
    );

  const closeSpaceSelector = () => {
    setSpaceSelectorOpened(false);
    setFilter('');
    setFilteredSpaces(spaces);
    setShowArchived(false);
    setSortedBy(defaultSort);
    setDividerVisible(false);
    setRecentlyUnstarredSpaces([]);
  };

  let shownSpaces = [...starredSpaces, ...filteredSpaces];

  if (!showArchived) {
    shownSpaces = shownSpaces.filter(({ archived }) => !archived);
  }

  const spacesToShow = shownSpaces.sort(sortOptions[sortedBy].comparator);

  const handleSpaceSelect = (id, subCategory = null) => {
    if (spaceSelectorOpened) {
      closeSpaceSelector();
      onSpaceSelect(
        id,
        showArchived,
        sortedBy,
        selectedCategory ? selectedCategory.label : null,
        subCategory
      );
    }
  };

  const handleSpaceStar = async (spaceId, subCategory = null) => {
    const space = starredSpaces.find((s) => s.id === spaceId);
    const hasBeenRecentlyUnstarred = recentlyUnstarredSpaces.some(
      (s) => s.id === spaceId
    );
    if (space && !hasBeenRecentlyUnstarred) {
      setRecentlyUnstarredSpaces([...recentlyUnstarredSpaces, space]);
    }
    await onSpaceStar({
      spaceId,
      showArchived,
      sort: sortedBy,
      search: filter,
      category: selectedCategory?.label || null,
      subCategory,
    });
  };

  const handleDismiss = () => {
    closeSpaceSelector();
    onDismiss(
      showArchived,
      sortedBy,
      selectedCategory ? selectedCategory.label : null
    );
    setSelectedCategory(primaryCategory);
  };

  const onListItemsScroll = (e) => {
    const {
      target: { scrollTop },
    } = e;
    if (scrollTop === 0) {
      setDividerVisible(false);
    } else {
      setDividerVisible(true);
    }
  };

  const selectCategory = (category) => {
    setSelectedCategory(category);
    onCategorySelect(category.label);
    setCategoryPreference(category);
    // ensure to clear any unstarred spaces when switching categories
    setRecentlyUnstarredSpaces([]);
  };

  const categorySelectors = () => {
    return (
      selectedCategory &&
      categories
        .slice(1)
        .map((category) => (
          <CategorySelector
            key={category.label}
            categoryLabel={category.label}
            onClick={() => selectCategory(category)}
          />
        ))
    );
  };

  const renderSpaces = () => {
    if (categories.length === 0) {
      return (
        <SpaceList
          spaces={spacesToShow}
          onClick={handleSpaceSelect}
          onStar={handleSpaceStar}
        />
      );
    }

    if (!selectedCategory) {
      return null;
    }

    const subCategoriesInSelectedCategory = getSubCategories(
      spaces,
      selectedCategory.label
    );

    if (subCategoriesInSelectedCategory.length > 0) {
      return subCategoriesInSelectedCategory.map((subCategory) => (
        <SpaceListAccordion
          key={subCategory.label}
          spaces={filterByCategory(spacesToShow, selectedCategory, subCategory)}
          isOpen={categoryPreferences?.subCategories?.[subCategory.label]}
          summary={subCategory.label}
          onClick={(id) => {
            handleSpaceSelect(id, subCategory.label);
          }}
          onStar={(id) => handleSpaceStar(id, subCategory.label)}
          onOpen={(subcategorySelected) => {
            if (subcategorySelected) {
              onSubCategorySelect(selectedCategory.label, subCategory.label);
            }
            setSubCategoriesPreference(subCategory);
          }}
        />
      ));
    }

    const spacesInSelectedCategory = filterByCategory(
      spacesToShow,
      selectedCategory
    );

    if (spacesInSelectedCategory.length === 0) {
      if (selectedCategory.label === starredCategory.label && !filter) {
        return <StarSpaceOnboarding />;
      }

      return (
        <NoMatchContainer>
          <GrayText>Your search didn’t match any of your spaces.</GrayText>
          <GrayText>Try with different keywords.</GrayText>
        </NoMatchContainer>
      );
    }

    return (
      <SpaceList
        spaces={spacesInSelectedCategory}
        onClick={handleSpaceSelect}
        onStar={handleSpaceStar}
      />
    );
  };

  const primaryCategorySelected =
    selectedCategory && selectedCategory.label === primaryCategory.label;

  const renderBackButton = ({ onClick }) => {
    const backLabel = primaryCategory
      ? `Back to ${primaryCategory.label.toLowerCase()}`
      : 'Back to your spaces';

    return (
      <BackToMainCategoryButton
        type="text"
        iconLeft={() => (
          <icons.ChevronLeft
            style={{
              color: theme.color.purple3,
              marginLeft: -10,
            }}
          />
        )}
        onClick={onClick}
      >
        <BackToMainCategoryText>{backLabel}</BackToMainCategoryText>
      </BackToMainCategoryButton>
    );
  };

  const renderHeader = () => {
    if (showStaffSpaces) {
      return (
        <>
          {renderBackButton({ onClick: () => setShowStaffSpaces(false) })}
          <Text>Spaces with staff access</Text>
        </>
      );
    }

    if (categories.length === 0) {
      return null;
    }

    if (!primaryCategorySelected) {
      return renderBackButton({
        onClick: () => {
          selectCategory(primaryCategory);
        },
      });
    }

    if (getSubCategories(spaces, selectedCategory.label).length > 0) {
      return null;
    }

    return <Text>{selectedCategory?.label}</Text>;
  };

  const renderSelector = () => {
    const topPadding =
      categories.length === 0 ||
      (primaryCategorySelected &&
        getSubCategories(spaces, selectedCategory.label).length > 0);
    const showStaffSpacesButton =
      userRoles.includes('staff') &&
      (primaryCategorySelected || categories.length === 0);

    return (
      <>
        {spaces.length ? (
          <>
            <SpaceFilter
              topPadding={topPadding}
              filter={filter}
              onArchivedToggleChange={handleArchivedToggleChange}
              currentSortOption={sortOptions[sortedBy].description}
              sortOptions={sortOptions}
              onFilterChange={setFilter}
              hasArchivedSpaces={spaces.some(({ archived }) => archived)}
            />
            {dividerVisible && <Divider />}
          </>
        ) : null}
        <SpaceListContainer>{renderSpaces()}</SpaceListContainer>
        {primaryCategorySelected && categorySelectors()}
        {showStaffSpacesButton && (
          <CategorySelector
            categoryLabel="Spaces with staff access"
            onClick={() => {
              setShowStaffSpaces(true);
              onCategorySelect('Spaces with staff access');
            }}
          />
        )}
      </>
    );
  };

  const renderStaffSelector = () => (
    <StaffSpaces
      onSpaceSelect={handleSpaceSelect}
      sortOptions={sortOptions}
      selectedSortOption={sortOptions[sortedBy]}
    />
  );

  return (
    <SpaceSelectorContainer
      id="space-selector"
      data-testid="space-selector-title"
    >
      <SpaceSelectorTarget
        role="button"
        tabIndex="0"
        aria-expanded={spaceSelectorOpened}
        onClick={toggleSpaceSelectorOpened}
        onKeyDown={(e) => {
          if (e.keyCode === ENTER || e.keyCode === DOWN_ARROW) {
            toggleSpaceSelectorOpened();
          }
        }}
      >
        {renderSpaceAvatar()}
        <SpaceTitle title={currentSpace.name}>{currentSpace.name}</SpaceTitle>
        <ArrowDropdownIcon />
      </SpaceSelectorTarget>
      <SideDialog
        title="My spaces"
        open={spaceSelectorOpened}
        onClose={() => handleDismiss()}
        width="488px"
        testAttribute="select-space"
        contentStyle={{
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          heigh: 'calc(100% - 67px)',
        }}
        onContentScroll={onListItemsScroll}
        withHeaderDivider
      >
        <CurrentSpace
          space={currentSpace}
          isLoading={isLoading}
          onAvatarClick={onSpaceImageChange}
          isAdmin={isAdmin}
          tooltipPosition={tooltipPosition}
          avatarUploaderId="space-avatar-uploader"
        />
        {renderHeader()}
        {showStaffSpaces ? renderStaffSelector() : renderSelector()}
      </SideDialog>
      <div
        id="current-space-name"
        tabIndex={-1}
        style={{ ...hideVisually() }}
        aria-live="polite"
      >
        {currentSpace.name}
      </div>
    </SpaceSelectorContainer>
  );
};

SpaceSelector.propTypes = {
  currentSpace: PropTypes.shape(SpaceProptypes).isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string),
  spaces: PropTypes.arrayOf(PropTypes.shape({})),
  onSpaceImageChange: PropTypes.func,
  onOpen: PropTypes.func,
  onDismiss: PropTypes.func,
  onArchivedToggleChange: PropTypes.func,
  onSearchChange: PropTypes.func,
  onSortChange: PropTypes.func,
  onSpaceSelect: PropTypes.func,
  onSpaceStar: PropTypes.func,
  onCategorySelect: PropTypes.func,
  onSubCategorySelect: PropTypes.func,
  isLoading: PropTypes.bool,
  isAdmin: PropTypes.bool,
  tooltipPosition: PropTypes.string,
};

SpaceSelector.defaultProps = {
  spaces: [],
  userRoles: [],
  onOpen: () => {},
  onDismiss: () => {},
  onArchivedToggleChange: () => {},
  onSearchChange: () => {},
  onSortChange: () => {},
  onSpaceImageChange: () => {},
  onSpaceSelect: () => {},
  onSpaceStar: () => {},
  onCategorySelect: () => {},
  onSubCategorySelect: () => {},
  isLoading: false,
  isAdmin: false,
  tooltipPosition: 'bottom',
};

export default React.memo(SpaceSelector);
