import { createSelector } from 'reselect';
import { currentUserId } from '../selectors/user';

export const reactionData = (state) => state.data.reactions;
const appData = (state) => state.reactions;

const compareCreatedAt = (a, b) => {
  if (a.createdAt === b.createdAt) return 0;
  if (a.createdAt < b.createdAt) return -1;
  return 1;
};

export const reactionsForItem = (reactions, itemId) =>
  Object.values(reactions)
    .filter((r) => r.itemId === itemId && r.count > 0)
    .sort(compareCreatedAt);

export const getReactionsSelector = () =>
  createSelector(
    (state, { itemId }) => reactionsForItem(reactionData(state), itemId),
    (reactions) => reactions
  );

export const getReactionsCountSelector = (reactionsSelector) =>
  createSelector(reactionsSelector, (reactions) =>
    reactions.reduce((count, reaction) => count + (reaction.count || 0), 0)
  );

export const reactionForItem = (state, { itemId, itemType, emojiName }) => {
  const reactions = reactionData(state);
  const reactionId = Object.keys(reactions).find((id) => {
    const r = reactions[id];
    return (
      r.itemId === itemId &&
      r.itemType === itemType &&
      r.emojiName === emojiName
    );
  });
  return reactionId ? reactions[reactionId] : null;
};

export const reaction = (state, id) => reactionData(state)[id] || null;

export const isTogglingReaction = (state, itemId, emojiName) => {
  const key = `${itemId}:${emojiName}`;
  return !!appData(state).isModifyingReaction[key];
};

export const hasCurrentUserReacted = (state, r) => {
  const userId = currentUserId(state);
  return r ? !!r.users[userId] : false;
};
