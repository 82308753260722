const createConversationReducer = (state, action) => {
  if (action.status !== 'success') return state;

  const { conversation } = action.payload;
  const conversationFromState = state.conversations[conversation.id] || {};

  return {
    ...state,
    conversations: {
      ...state.conversations,
      [conversation.id]: {
        messages: [],
        ...conversationFromState,
        ...conversation,
      },
    },
  };
};

export default createConversationReducer;
