import appReducer from './appReducers';
import dataReducer from './dataReducers';
import registerLive from './live';
import operations from './operations';
import * as selectors from './selectors';

const conversations = (services) => {
  const protoDuck = {
    appReducer,
    dataReducer,
    operations: operations(services),
    selectors,
  };

  return {
    ...protoDuck,
    registerLive: registerLive(protoDuck.operations),
  };
};

export default conversations;
