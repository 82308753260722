import formatUser from './formatUser';

const formatPost = (post, tagPosts) => ({
  objectId: post.id,
  user: formatUser(post.author || {}),
  classRoom: post.space,
  content: post.content,
  important: post.important,
  createdAt: post.createdAt,
  editedAt: post.editedAt,
  deletedAt: post.deletedAt,
  scheduledFor: post.scheduledFor,
  publishedAt: post.publishedAt,
  tagPosts:
    tagPosts?.map((tagPost) => ({
      tagId: tagPost.tag.objectId,
      tagPostId: tagPost.objectId,
    })) || [],
  feedType: post.feedType,
});

export default formatPost;
