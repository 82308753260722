import { POST_TAG } from '../../constants/endpoints';
import request from '../request';

const deleteTagFromPost = async ({ postId, tagId }) => {
  await request({
    method: 'delete',
    url: POST_TAG({ postId, tagId }),
  });
};

export default deleteTagFromPost;
