const removeKeys = (obj, keys) => {
  const objWithoutKeys = { ...obj };
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    delete objWithoutKeys[key];
  }

  return objWithoutKeys;
};

const reconnectReducer = (state, action) => {
  switch (action.status) {
    case 'started':
      return {
        ...state,
        fetchingConversations: true,
      };
    case 'error':
      return {
        ...state,
        fetchingConversations: false,
      };
    case 'success': {
      const { resetConversationIds = [] } = action.payload;
      const conversationHasOlderMessages = resetConversationIds.reduce(
        (acc, id) => ({
          ...acc,
          [id]: true,
        }),
        state.conversationHasOlderMessages
      );
      const initialFetchDone = removeKeys(
        state.initialFetchDone,
        resetConversationIds
      );
      return {
        ...state,
        fetchingConversations: false,
        conversationHasOlderMessages,
        initialFetchDone,
      };
    }
    default:
      return state;
  }
};

export default reconnectReducer;
