import * as actionTypes from '../../constants/actions';

export const fetchCommentsSuccess = (postId, comments, dispatch) => {
  dispatch({
    type: actionTypes.COMMENTS_FETCH,
    status: 'success',
    postId,
    comments,
  });
};
