import { AulaReduxAction } from '../../types/state';
import { CLASSROOM_MODULE_TOGGLE_FOLDER } from '../actions';

type ToggleFolderActionFactory = (folderId: string) => AulaReduxAction<void>;

const toggleFolder: ToggleFolderActionFactory = (folderId) => (dispatch) =>
  dispatch({
    type: CLASSROOM_MODULE_TOGGLE_FOLDER,
    payload: { folderId },
  });

export default toggleFolder;
