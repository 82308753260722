import { VALIDATE_GROUP_EMAILS } from '../../constants/endpoints';
import request from './request';

const validate = async (accessToken, { emails, spaceId }) => {
  const config = {
    method: 'post',
    url: VALIDATE_GROUP_EMAILS({ spaceId }),
    data: {
      emails,
      spaceId,
    },
  };

  const response = await request(accessToken, config);
  return response.data;
};

export default validate;
