import * as api from '../api/directMessages';
import { INBOX_FETCH_MESSAGES } from '../actions';
import * as inboxSelectors from '../selectors';
import fetchReactionsSuccess from '../../reactions/operations/fetchReactionsSuccess.operation';

const fetchMessagesStarted = (dispatch, payload) =>
  dispatch({
    type: INBOX_FETCH_MESSAGES,
    status: 'started',
    payload,
  });

const fetchMessagesSuccess = (dispatch, payload) =>
  dispatch({
    type: INBOX_FETCH_MESSAGES,
    status: 'success',
    payload,
  });

const fetchMessagesError = (dispatch, payload) =>
  dispatch({
    type: INBOX_FETCH_MESSAGES,
    status: 'error',
    payload,
  });

const fetchMessages =
  ({ conversationId: cId, before, after, aroundMessageId, replace = false }) =>
  async (dispatch, getState) => {
    const state = getState();
    const conversationId = cId || inboxSelectors.currentConversationId(state);
    if (aroundMessageId && inboxSelectors.message(state, aroundMessageId)) {
      return;
    }
    fetchMessagesStarted(dispatch, {
      conversationId,
      replace,
    });
    try {
      const { messages, done, reactions } =
        await api.conversations.fetchMessages(conversationId, {
          before,
          after,
          aroundMessageId,
        });
      fetchMessagesSuccess(dispatch, {
        conversationId,
        messages,
        done,
        replace,
      });
      dispatch(fetchReactionsSuccess(reactions));
    } catch (error) {
      fetchMessagesError(dispatch, { error, conversationId });
    }
  };

export default fetchMessages;
