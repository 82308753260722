import styled from 'react-emotion';

export const TabLabelContainer = styled('div')`
  display: flex;
  flex-basis: 0;
  align-items: center;
`;

export const TabLabelIconContainer = styled('div')`
  margin-right: 8px;
`;
