import formatUser from './formatUser';

const formatComment = (comment) => ({
  id: comment.id,
  author: formatUser(comment.user || {}),
  user: formatUser(comment.user || {}),
  post: comment.post,
  entityType: comment.entityType,
  entityId: comment.entityId,
  content: comment.content,
  parent: comment.parent,
  createdAt: comment.createdAt,
  editedAt: comment.editedAt,
  root: comment.root,
});

export default formatComment;
