import { connect } from 'react-redux';
import * as appCreators from '../../../../actions/inboxv2';
import {
  operations as creators,
  selectors as inboxSelectors,
} from '../../../../store/conversations';
import { selectors as notificationSelectors } from '../../../../store/notifications';
import { currentUserId } from '../../../../../core/selectors/user';
import InboxItem from './InboxItem';

const noop = () => null;

const showUnread = (state, conversationId, userId, allowEmptyOrOwn) => {
  if (!allowEmptyOrOwn) {
    return (
      !inboxSelectors.conversationLatestMessageIsOwn(state, conversationId) &&
      inboxSelectors.isConversationUnreadBy(state, conversationId, userId)
    );
  }
  return inboxSelectors.isConversationUnreadByEvenIfEmpty(
    state,
    conversationId,
    userId
  );
};

const mapStateToProps = (state, ownProps) => ({
  featSetConversationUnreadEnabled: false,
  conversation: inboxSelectors.conversation(state, ownProps.conversationId),
  selected:
    ownProps.conversationId === inboxSelectors.currentConversationId(state),
  unread: showUnread(
    state,
    ownProps.conversationId,
    currentUserId(state),
    false
  ),
  muted: notificationSelectors.isConversationMuted(
    state,
    ownProps.conversationId
  ),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeGroup: () => {
    dispatch(appCreators.selectConversation(ownProps.conversationId));
    ownProps.changeGroup();
  },
  setConversationUnread: () =>
    dispatch(creators.setConversationUnread(ownProps.conversationId)),
  setConversationClosed: (isClosingModalOpen) => (conversationId) => {
    if (isClosingModalOpen) {
      dispatch(appCreators.selectConversation(undefined));
    }
    dispatch(creators.setConversationClosed(conversationId));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {
    changeGroup,
    setConversationUnread,
    setConversationClosed,
    ...otherDispatchProps
  } = dispatchProps;

  return {
    ...stateProps,
    ...otherDispatchProps,
    ...ownProps,
    changeGroup,
    setConversationUnread: stateProps.featSetConversationUnreadEnabled
      ? setConversationUnread
      : noop,
    setConversationClosed: setConversationClosed(stateProps.selected),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(InboxItem);
