import indexForTabId from './indexForTabId';
import getCommentEntityUrl from '../../utils/getCommentEntityUrl';

const highlightTitle = (highlight) =>
  !!(highlight['user.firstName'] || highlight['user.lastName']);

const getObjectId = ({ objectId, id }) => objectId || id;

const getCommentResultUrl = (commentResult) => {
  const { entityType, entityId } = commentResult;

  return getCommentEntityUrl({
    spaceId: commentResult.classRoomId,
    entityId,
    entityType,
  });
};

const infoForObject = (state, object, innerHitsTypes, highlight = {}) => {
  let extraIndexes = [];
  if (innerHitsTypes.has('file') || innerHitsTypes.has('fileBlock')) {
    extraIndexes = [5];
  }

  switch (object.className) {
    case 'UBClassRoomPost':
      return {
        tabIndexes: [indexForTabId.all, indexForTabId.posts, ...extraIndexes],
        title: `${object.user.firstName} ${object.user.lastName}`,
        highlightTitle: highlightTitle(highlight),
        avatar: object.user ? object.user.avatar : undefined,
        footerPrefix: 'Posted in',
        footerKey: object.classRoomId,
        redirectUrl: `dashboard/${
          object.classRoomId
        }/community/post/${getObjectId(object)}`,
      };
    case 'UBComment':
      return {
        tabIndexes: [
          indexForTabId.all,
          indexForTabId.comments,
          ...extraIndexes,
        ],
        title: `${object.user.firstName} ${object.user.lastName}`,
        highlightTitle: highlightTitle(highlight),
        avatar: object.user ? object.user.avatar : undefined,
        footerPrefix: 'Commented in',
        footerKey: object.classRoomId,
        redirectUrl: getCommentResultUrl(object),
      };
    case 'UBInboxMessage':
      return {
        tabIndexes: [
          indexForTabId.all,
          indexForTabId.messages,
          ...extraIndexes,
        ],
        title: `${object.user.firstName} ${object.user.lastName}`,
        highlightTitle: highlightTitle(highlight),
        avatar: object.user ? object.user.avatar : undefined,
        footerPrefix: 'Sent in',
        footerKey: object.groupId,
        redirectQuery: {
          inbox: object.groupId,
          messageId: getObjectId(object),
        },
      };
    case 'UBClassRoomMaterialModule':
      return {
        tabIndexes: [
          indexForTabId.all,
          indexForTabId.materials,
          ...extraIndexes,
        ],
        title: object.title,
        avatar: undefined,
        footerPrefix: 'Created in',
        footerKey: object.classRoomId,
        redirectUrl: `dashboard/${object.classRoomId}/materials/${getObjectId(
          object
        )}`,
      };
    default:
      return {
        tabIndexes: [indexForTabId.all, ...extraIndexes],
      };
  }
};

const processInnerHits = (innerHits) =>
  innerHits.blocks.hits.hits.reduce(
    (acc, hit) => ({
      keysHighlightMap: {
        ...acc.keysHighlightMap,
        [hit._source.key]: hit.highlight['blocks.plainText'],
      },
      types: [...acc.types, hit._source.type],
    }),
    { keysHighlightMap: [], types: [] }
  );

const enrichResults = (state, results) =>
  results.map(({ _source, highlight, inner_hits: innerHits }) => {
    const { keysHighlightMap, types } = processInnerHits(innerHits);
    return {
      id: getObjectId(_source),
      createdAt: _source.createdAt,
      content: _source.content,
      ...infoForObject(state, _source, new Set(types), highlight),
      keysHighlightMap,
    };
  });

const groupResultsByTab = (results, tabs) =>
  results.reduce(
    (acc, { id, tabIndexes }) =>
      tabIndexes.reduce((acc2, tabIndex) => {
        const { resultsSet } = tabs[tabIndex];
        // Don't include result if tab already contains it
        if (resultsSet.has(id)) {
          return acc2;
        }

        return [
          ...acc2.slice(0, tabIndex),
          [...acc2[tabIndex], id],
          ...acc2.slice(tabIndex + 1, acc2.length),
        ];
      }, acc),
    Array(tabs.length).fill([])
  );

const updateTabs = (tabs, results, pageSize, selectedTabIndex) => {
  const groupedResults = groupResultsByTab(results, tabs);
  return tabs.map((tab, index) => {
    if (selectedTabIndex === 0 || selectedTabIndex === index) {
      const tabResults = [...tab.results, ...groupedResults[index]];
      return {
        ...tab,
        results: tabResults,
        resultsSet: new Set(tabResults),
        moreToLoad:
          selectedTabIndex === index
            ? tabResults.length > tab.results.length &&
              tabResults.length >= pageSize
            : tab.moreToLoad,
      };
    }

    return tab;
  });
};

const fetchResults = (state, { status, payload }) => {
  switch (status) {
    case 'started':
      return {
        ...state,
        loading: true,
        query: payload.query,
      };
    case 'success':
      // Ignore responses from old queries
      if (payload.query === state.query) {
        const results = enrichResults(state, payload.results);
        return {
          ...state,
          loading: false,
          results: results.reduce(
            (acc, result) => ({
              ...acc,
              [result.id]: result,
            }),
            state.results || {}
          ),
          tabs: updateTabs(
            state.tabs,
            results,
            payload.pageSize,
            payload.selectedTabIndex || indexForTabId.all
          ),
        };
      }

      return {
        ...state,
        loading: false,
      };

    case 'error':
      return {
        ...state,
        loading: false,
        results: {},
      };
    default:
      return state;
  }
};

export default fetchResults;
