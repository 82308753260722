import { GROUP_SETS } from '../../constants/endpoints';
import request from './request';

const fetchGroupSets = async (accessToken, { spaceId }) => {
  const config = {
    method: 'get',
    url: GROUP_SETS({ spaceId }),
  };

  const response = await request(accessToken, config);
  return response.data.sets;
};

export default fetchGroupSets;
