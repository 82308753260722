import fonts from '../../../../styles/fonts';

export default {
  defaultErrorComponent: {
    ...fonts.regular,
    // height: 50,
    padding: 25,
    margin: '0 auto',
    marginTop: 20,
    marginBottom: 20,
    width: 'calc(100% - 100px)',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 99999999999,
    borderRadius: 5,
  },
};
