import axios from 'axios';
import { getHeaders } from './utils';
import { AULA_ASSIGNMENTS } from '../../constants/endpoints';

const reopen = ({ accessToken, spaceId, assignment }) => {
  const assignmentId = assignment.externalId;
  const url = `${AULA_ASSIGNMENTS()}/${assignmentId}/re-open`;

  return axios.post(
    url,
    {
      classId: spaceId,
      ...assignment,
    },
    { headers: getHeaders(accessToken) }
  );
};

export default reopen;
