const fetchGroupSetReducer = (state, action) => {
  if (action.status !== 'success') {
    return state;
  }

  const {
    payload: { set },
  } = action;

  const groups = set.groups.reduce((acc, group) => {
    acc[group.id] = group;
    return acc;
  }, {});

  const sets = {
    ...state.sets,
    [set.id]: { ...set, groups: set.groups.map(({ id }) => id) },
  };

  return {
    ...state,
    sets,
    groups,
  };
};

export default fetchGroupSetReducer;
