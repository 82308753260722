import {
  SPACE_SELECTOR_ITEM_TYPE,
  SPACE_SELECTOR_CATEGORY_SELECTED,
} from '../../constants/analytics';
import { analyticsTrackEvent } from '../../utils/analytics';
import { openTimestamp, openCorrelationId } from '../selectors';
import getNow from '../getNow';

const categorySelectedSpaceSelector = (category) => (_dispatch, getState) => {
  const state = getState();

  analyticsTrackEvent(SPACE_SELECTOR_CATEGORY_SELECTED, {
    itemType: SPACE_SELECTOR_ITEM_TYPE,
    selectorOpenFor: Math.round(getNow() - openTimestamp(state)),
    correlationId: openCorrelationId(state),
    category,
    categoriesEnabled: true,
  });
};

export default categorySelectedSpaceSelector;
