import { deleteAssignmentSuccess } from './editAssignments.operation';
import { getAssignment, getExtension } from '../selectors';

const onAssignmentDeleted =
  ({ assignmentId }) =>
  (dispatch, getState) => {
    const assignment =
      getAssignment(getState(), assignmentId) ||
      getExtension(getState(), assignmentId);
    if (!assignment) {
      return null;
    }

    return deleteAssignmentSuccess(dispatch, {
      spaceId: assignment.space,
      assignment,
    });
  };

export default onAssignmentDeleted;
