import { CLASSROOM_MODULE_DISPLAY_REVERT_MODAL } from '../actions';
import { getVersions } from '../api';
import * as materialsSelectors from '../selectors';
import { AulaReduxAction } from '../../types/state';
import { ActionStatus } from '@core/constants/actionsStatus';
import { getCurrentSpaceId } from '../../selectors/space';

const displayRevertModalAction = (status: ActionStatus, payload: unknown) => ({
  type: CLASSROOM_MODULE_DISPLAY_REVERT_MODAL,
  status,
  payload,
});

type DisplayRevertModalActionFactory = (sectionId: string) => AulaReduxAction;

const displayRevertModal: DisplayRevertModalActionFactory =
  (sectionId) => async (dispatch, getState) => {
    const state = getState();
    const classRoom = getCurrentSpaceId(state);
    const section = materialsSelectors.getMaterial(state, sectionId);

    const payload = {
      classRoom,
      section,
    };
    dispatch(displayRevertModalAction('started', payload));

    if (!section) {
      return;
    }

    try {
      const versions = await getVersions(classRoom, sectionId);
      dispatch(
        displayRevertModalAction('success', {
          ...payload,
          section: { ...section, versions },
        })
      );
    } catch (error: unknown) {
      dispatch(displayRevertModalAction('error', payload));
    }
  };

export default displayRevertModal;
