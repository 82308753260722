import { RevertSectionToVersionAction } from '../sharedActions';
import { MaterialAppReducer } from './state';

const revertSectionToVersionAppReducer: MaterialAppReducer<
  RevertSectionToVersionAction
> = (state, action) => {
  switch (action.status) {
    case 'started':
      return {
        ...state,
        isRevertingSection: true,
      };
    case 'success':
      return {
        ...state,
        isRevertingSection: false,
        currentRevertCandidate: null,
      };
    case 'error':
      return {
        ...state,
        isRevertingSection: false,
      };
    default:
      return state;
  }
};

export default revertSectionToVersionAppReducer;
