/* eslint-disable no-console */
// eslint-disable-next-line import/extensions,import/no-unresolved
import aulaLive from '@ublend-npm/aula-live';
import * as actionTypes from '../../constants/actions';
import { WEBSOCKET_ENDPOINT } from '../../constants/endpoints';
import clients from './clients';
import refreshAccessToken from '../accessToken/refreshAccessToken.action';

export * from './utils';

let SOCKET;

// We need this here to be able to use it outside redux
export const getSocket = () => SOCKET;

export const socketConnect =
  ({
    conversations,
    groups,
    materials,
    notifications,
    reactions,
    assignments,
    events,
    users,
    onReconnect,
    onConnect,
  }) =>
  (dispatch, getState) => {
    console.debug('>> socketConnect');
    const host = WEBSOCKET_ENDPOINT(getState);
    if (SOCKET) {
      return;
    }

    SOCKET = aulaLive.connect(
      host,
      Object.keys(getState().data.classRooms).join('|')
    );

    SOCKET.on('connect', () => {
      console.debug('Socket: Connected', SOCKET.id);

      if (onConnect) {
        onConnect();
      }

      dispatch({
        type: actionTypes.LIVE_CONNECT_ON,
        status: 'success',
        socketId: SOCKET.id,
      });
      dispatch({
        type: actionTypes.CONNECTION_ON,
        status: 'on',
      });
    });

    // External listeners
    events(SOCKET, dispatch, getState);
    clients(SOCKET, dispatch, getState);
    dispatch(materials(SOCKET));
    dispatch(conversations(SOCKET));
    dispatch(groups(SOCKET));
    dispatch(notifications(SOCKET));
    dispatch(reactions(SOCKET));
    dispatch(users(SOCKET));
    dispatch(assignments(SOCKET));

    // Error listeners
    SOCKET.on('error', (error) => {
      console.error('Socket: error', error);
      dispatch({
        type: actionTypes.LIVE_CONNECT_OFF,
        status: 'error',
        error,
      });
      dispatch({
        type: actionTypes.CONNECTION_OFF,
        status: 'off',
      });
    });

    SOCKET.on('connect_error', (e) => {
      console.error('connect_error', e);
    });

    SOCKET.on('connect_timeout', (e) => {
      console.error('connect_timeout', e);
    });

    SOCKET.on('disconnect', (e) => {
      console.debug('Socket: disconnect', e);
      dispatch({
        type: actionTypes.LIVE_CONNECT_OFF,
        status: 'disconnect',
        e,
      });
      dispatch({
        type: actionTypes.CONNECTION_OFF,
        status: 'off',
      });
    });

    SOCKET.on('reconnect', (n) => {
      console.debug('Socket: reconnect', n);
      if (onReconnect) onReconnect();
    });

    SOCKET.on('reconnect_attempt', (n) => {
      console.debug('Socket: reconnect attempt', n);
    });

    SOCKET.on('reconnecting', (n) => {
      console.debug('Socket: reconnecting', n);
    });

    SOCKET.on('reconnect_error', (e) => {
      console.error('Socket: reconnect error', e);
    });

    SOCKET.on('reconnect_failed', () => {
      console.error('Socket: reconnect failed');
    });

    dispatch({
      type: actionTypes.LIVE_CONNECT_START,
      status: 'start',
    });
  };

// move socketDisconnect here to enable access to SOCKET
export const socketDisconnect = () => () => {
  SOCKET = undefined;
  aulaLive.disconnect();
};

export const tryToSubscribeToSpaceLevelEvents =
  (spaceId) => async (dispatch, getState) => {
    if (!spaceId) {
      console.debug('Not subscribing to space-level events - no space id');
      return;
    }

    const accessToken = await refreshAccessToken(
      dispatch,
      getState
    )({ spaceId });

    if (!getSocket()?.connected) {
      console.debug(
        'Not subscribing to space-level events - socket not connected'
      );
      return;
    }

    console.debug('Subscribing to space-level events for space', spaceId);
    aulaLive.subscribeByAccessToken(accessToken);
  };
