import styled from 'react-emotion';
import { Button as UnstyledButton, theme } from '@ublend-npm/aulaui-next';

export const Container = styled('div')`
  background: ${theme.color.white};
  padding: 22px 79px;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xxSmall};
`;

export const Heading = styled('div')`
  height: ${theme.font.lineHeight.copy};
  color: ${theme.color.designSystem.blackOpacity87};
  font-size: ${theme.font.size.large};
  font-weight: ${theme.font.weight.medium};
  line-height: ${theme.font.lineHeight.copy};
`;

export const Description = styled('div')`
  color: ${theme.color.designSystem.blackOpacity60};
  font-size: ${theme.font.size.copy};
  font-weight: ${theme.font.weight.regular};
  font-family: ${theme.font.family.workSans};
  line-height: ${theme.font.lineHeight.copy};
`;

export const Button = styled(UnstyledButton)`
  font-size: ${theme.font.size.copy};
  font-weight: ${theme.font.weight.medium};
  font-family: ${theme.font.family.workSans};
  line-height: ${theme.font.lineHeight.small};
`;

export const ButtonContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  gap: 15px;
`;
