const overlayLoaderStyle = {
  container: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    zIndex: -1,
    opacity: 0,
    transition: 'all 0.5s',
  },
  open: {
    opacity: 1,
    zIndex: 999999999,
  },
  loaderContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: '50%',
    padding: '15px',
    position: 'absolute',
    width: 50,
    height: 50,
    top: 'calc(50% - 37px)',
    left: 'calc(50% - 37px)',
  },
};

export default overlayLoaderStyle;
