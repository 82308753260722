import * as NotificationTypes from '../constants/notificationTypes';
import { notificationActions } from '../constants/notifications';

const mapNotificationActionsToFilterTypes = {
  [notificationActions.newPost]: NotificationTypes.POSTS,
  [notificationActions.newComment]: NotificationTypes.COMMENTS,
  [notificationActions.newCommentOnCommentedPost]: NotificationTypes.COMMENTS,
  [notificationActions.newCommentReply]: NotificationTypes.COMMENTS,
};

export const notificationFilters = Object.values(NotificationTypes);

export const groupNotificationsByFilterType = (notifications) =>
  notifications.reduce(
    (accumulator, entry) => {
      const notificationFilterType =
        mapNotificationActionsToFilterTypes[entry.notification.action];

      return {
        ...accumulator,
        ...{
          [NotificationTypes.ALL]: [
            ...accumulator[NotificationTypes.ALL],
            entry,
          ],
        },
        ...(notificationFilterType && {
          [notificationFilterType]: [
            ...accumulator[notificationFilterType],
            entry,
          ],
        }),
      };
    },
    {
      [NotificationTypes.ALL]: [],
      [NotificationTypes.POSTS]: [],
      [NotificationTypes.COMMENTS]: [],
    }
  );

export const emptyNotificationMessages = {
  [NotificationTypes.ALL]: 'There are no notifications.',
  [NotificationTypes.POSTS]: 'There are no post notifications.',
  [NotificationTypes.COMMENTS]: 'There are no comment notifications.',
};

export const filterUnreadStatus = {
  [NotificationTypes.ALL]: 'You have unread notifications',
  [NotificationTypes.POSTS]: 'You have unread post notifications',
  [NotificationTypes.COMMENTS]: 'You have unread comment notifications',
};

export const isUnread = (entry) => !('read' in entry) || entry.read === false;

export const hasUnreadNotifications = (entries) => !!entries.find(isUnread);

export const hasNewNotificationOfType = (
  newGroupedNotifications,
  groupedNotifications,
  filterType
) =>
  newGroupedNotifications[filterType][0]?.id !==
  groupedNotifications[filterType][0]?.id;
