export const notificationActions = {
  newPost: 'newPost',
  newComment: 'newComment',
  newCommentReply: 'newCommentReply',
  newCommentOnCommentedPost: 'newCommentOnCommentedPost',
  newMessage: 'newMessage',
};

export const notificationTypes = {
  [notificationActions.newPost]: 'post',
  [notificationActions.newComment]: 'comment',
  [notificationActions.newCommentOnCommentedPost]: 'commentOnCommentedPost',
  [notificationActions.newCommentReply]: 'commentReply',
  [notificationActions.newMessage]: 'message',
};

export const notificationSettings = {
  student: [
    'commentedMyPost',
    'commentedPostICommented',
    'newPost',
    'newMessage',
  ],
  instructor: [
    'commentedMyPost',
    'commentedPostICommented',
    'newPost',
    'newMessage',
  ],
};
