import { GenericStatusAction } from '@core/types/redux';
import { CLASSROOM_MODULE_GET_VERSION } from '../actions';
import { Version } from '../types';
import { MaterialAppReducer } from './state';

export type GetVersionAction = GenericStatusAction<
  typeof CLASSROOM_MODULE_GET_VERSION,
  {
    versionId: string;
    content: Version;
  }
>;

const getVersion: MaterialAppReducer<GetVersionAction> = (state, action) => {
  switch (action.status) {
    case 'started':
      return {
        ...state,
        selectedVersion: { id: action.payload.versionId },
        isLoadingVersion: true,
      };
    case 'aborted':
      return {
        ...state,
        selectedVersion: null,
        isLoadingVersion: false,
      };
    case 'success':
      return {
        ...state,
        selectedVersion: action.payload.content,
        isLoadingVersion: false,
      };
    case 'error':
      return {
        ...state,
        selectedVersion: null,
        isLoadingVersion: false,
      };
    default:
      return state;
  }
};

export default getVersion;
