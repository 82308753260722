import { INBOX_PARTICIPATION } from '../../../constants/endpoints';
import request from './request';

export default async (conversationId, userId) => {
  const config = {
    url: INBOX_PARTICIPATION(conversationId, userId),
    method: 'post',
  };
  const response = await request(config);
  return response.data.conversation;
};
