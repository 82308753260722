const deleteSubmissionReducer = (state, action) => {
  if (action.status !== 'success') return state;

  const {
    payload: { assignmentId, studentId, groupId },
  } = action;

  const updatedSubmissions = state.submissions[assignmentId].map(
    (submission) => {
      // group assignment
      if (groupId) {
        if (submission.group && submission.group.id === groupId) {
          return {
            user: submission.user,
            group: submission.group,
          };
        }
        return submission;
      }
      // individual assignment
      if (submission.user.id === studentId) {
        return {
          user: submission.user,
        };
      }
      return submission;
    }
  );

  return {
    ...state,
    submissions: {
      ...state.submissions,
      [assignmentId]: updatedSubmissions,
    },
  };
};

export default deleteSubmissionReducer;
