import { createSelector } from 'reselect';
import { currentUserId } from '../selectors/user';

export const inbox = (state) => state.inboxv2;
export const inboxData = (state) => state.data.inboxv2;

export const conversations = (state) => inboxData(state).conversations;
export const messages = (state) => inboxData(state).messages;
export const drafts = (state) => inboxData(state).drafts;

/*
 * CONVERSATIONS
 */

const compareMessageDate = (userId) => (a, b) => {
  let aDate = (a.latestMessage && a.latestMessage.createdAt) || a.createdAt;
  let bDate = (b.latestMessage && b.latestMessage.createdAt) || b.createdAt;

  const aJoinedAt =
    (a.participants &&
      a.participants[userId] &&
      a.participants[userId].joinedAt) ||
    null;
  if (aJoinedAt && aDate < aJoinedAt) {
    aDate = aJoinedAt;
  }

  const bJoinedAt =
    (b.participants &&
      b.participants[userId] &&
      b.participants[userId].joinedAt) ||
    null;
  if (bJoinedAt && bDate < bJoinedAt) {
    bDate = bJoinedAt;
  }

  if (aDate === bDate) return 0;
  if (aDate < bDate) return 1;
  return -1;
};

export const filteredConversationQuery = (state) =>
  inbox(state).filtering.query;

export const filteredConversationIds = (state) =>
  inbox(state).filtering.conversationIds;

const isOpen = (c) => !c.closed;

export const visibleConversations = createSelector(
  conversations,
  filteredConversationQuery,
  filteredConversationIds,
  currentUserId,
  (convs, query, filteredIds, userId) => {
    const hasQuery = query !== '';
    return Object.values(convs)
      .filter(hasQuery ? (c) => filteredIds.includes(c.id) : isOpen)
      .sort(compareMessageDate(userId));
  }
);

export const visibleConversationsIds = createSelector(
  visibleConversations,
  (convos) => convos.map((c) => c.id)
);

export const openConversations = createSelector(
  conversations,
  currentUserId,
  (convs, userId) =>
    Object.values(convs).filter(isOpen).sort(compareMessageDate(userId))
);

export const conversation = (state, id) => {
  if (typeof id === 'undefined') return undefined;
  // eslint-disable-next-line consistent-return
  return conversations(state)[id] || null;
};

export const currentConversationId = (state) => {
  const query =
    (state &&
      state.routing &&
      state.routing.locationBeforeTransitions &&
      state.routing.locationBeforeTransitions.query) ||
    {};
  return query.inbox;
};

export const currentConversationMessageId = (state) => {
  const query =
    (state &&
      state.routing &&
      state.routing.locationBeforeTransitions &&
      state.routing.locationBeforeTransitions.query) ||
    {};
  return query.messageId;
};

export const currentConversation = (state) =>
  conversation(state, currentConversationId(state));

export const isGroupConversation = (state, id) =>
  (conversation(state, id) || {}).isGroup;
export const isEducatorConversation = (state, id) =>
  (conversation(state, id) || {}).educatorOnly;

export const conversationMessages = (state, conversationId) =>
  (conversation(state, conversationId) || {}).messages;

export const conversationLatestMessage = (state, conversationId) =>
  (conversation(state, conversationId) || {}).latestMessage;

export const conversationLatestMessageDate = (state, conversationId) =>
  (conversationLatestMessage(state, conversationId) || {}).createdAt;

const conversationLatestMessageWriter = (state, conversationId) =>
  (conversationLatestMessage(state, conversationId) || {}).writer;

export const conversationLatestMessageIsOwn = (state, conversationId) => {
  const userId = currentUserId(state);
  if (typeof userId === 'undefined') return undefined;

  const writer = conversationLatestMessageWriter(state, conversationId);
  if (typeof writer === 'undefined') return undefined;

  // eslint-disable-next-line consistent-return
  return writer === userId;
};

export const conversationDraft = (state, conversationId) =>
  drafts(state)[conversationId];

export const otherParticipantsIdsInConversation = (state, id) => {
  const c = conversation(state, id);
  if (!c) return undefined;
  const participants = { ...c.participants };
  const userId = currentUserId(state);
  delete participants[userId];
  // eslint-disable-next-line consistent-return
  return Object.keys(participants);
};

export const recipientId = (state, id) => {
  const othersIds = otherParticipantsIdsInConversation(state, id);
  if (!othersIds) return undefined;
  if (othersIds.length > 1) return undefined;
  // eslint-disable-next-line consistent-return
  return othersIds.pop();
};

const fetchingMessage = (state) => inbox(state).fetchingMessage;
const fetchingMessages = (state) => inbox(state).fetchingMessages;

export const fetchingMessagesForConversation = (state, id) =>
  !!fetchingMessages(state)[id];
export const fetchingMessageForConversation = (state, id) =>
  !!fetchingMessage(state)[id];

const typingInConversations = (state) => inbox(state).typingInConversations;

export const typersForConversation = (state, id) =>
  typingInConversations(state)[id] || [];

/*
 * MESSAGES
 */

export const currentlyEditedMessageId = (state) =>
  inbox(state).currentlyEditedMessageId;
export const currentlyEditedMessage = (state) =>
  messages(state)[currentlyEditedMessageId(state)];

export const message = (state, id) => {
  if (typeof id === 'undefined') return undefined;
  // eslint-disable-next-line consistent-return
  return messages(state)[id] || null;
};

export const replyingToMessageId = (state) => inbox(state).replyingMessageId;
export const replyingToMessage = (state) => {
  const messageId = replyingToMessageId(state);
  return messageId && message(state, messageId);
};

export const oldestLoadedMessage = (state, conversationId) => {
  // const c = conversation(state, conversationId);
  // if (!c) return undefined;
  // const { messages: messagesList } = c;
  const messagesList = conversationMessages(state, conversationId);
  if (!(messagesList && messagesList.length)) return undefined;
  const messageId = messagesList[0];
  const m = message(state, messageId);
  // eslint-disable-next-line consistent-return
  return m ? m.createdAt : undefined;
};

export const newestLoadedMessage = (state, conversationId) => {
  const messagesList = conversationMessages(state, conversationId);
  if (!(messagesList && messagesList.length)) return undefined;
  const messageId = messagesList[messagesList.length - 1];
  const m = message(state, messageId);
  // eslint-disable-next-line consistent-return
  return m ? m.createdAt : undefined;
};

export const participationsForConversation = (state, conversationId) =>
  (conversation(state, conversationId) || {}).participants;

export const participation = (state, conversationId, userId) =>
  (participationsForConversation(state, conversationId) || {})[userId];

export const isParticipant = (state, conversationId, userId) =>
  !!participation(state, conversationId, userId);

export const isConversationUnreadBy = (state, conversationId, userId) => {
  const p = participation(state, conversationId, userId);
  if (typeof p === 'undefined') return undefined;

  const lm = conversationLatestMessage(state, conversationId);
  // eslint-disable-next-line consistent-return
  if (typeof lm === 'undefined') return false;

  const readUpTo = p.readUpTo || new Date(0).toISOString();
  // eslint-disable-next-line consistent-return
  return readUpTo < lm.createdAt;
};

export const isConversationUnreadByEvenIfEmpty = (
  state,
  conversationId,
  userId
) => {
  const p = participation(state, conversationId, userId);
  if (typeof p === 'undefined') return false;

  const lm =
    conversationLatestMessage(state, conversationId) ||
    conversation(state, conversationId);

  const readUpTo = p.readUpTo || new Date(0).toISOString();
  // eslint-disable-next-line consistent-return
  return readUpTo < lm.createdAt;
};

export const conversationTitle = (state, conversationId) => {
  const c = conversation(state, conversationId);
  return c && c.title ? c.title : 'Conversation with yourself';
};

export const highlightedMessage = (state) => inbox(state).highlightedMessage;

export const isMessageHighlighted = (state, messageId) =>
  highlightedMessage(state) === messageId;

export const hasOlderMessages = (state, id) =>
  !!inbox(state).conversationHasOlderMessages[id];

export const hasNewerMessages = (state, id) => {
  const conversationLatestMsgDate = conversationLatestMessageDate(state, id);
  if (!conversationLatestMsgDate) {
    return false;
  }

  const newestLoadedMsgDate = newestLoadedMessage(state, id);
  return (
    !newestLoadedMsgDate || newestLoadedMsgDate < conversationLatestMsgDate
  );
};

export const isCreatingConversation = (state) =>
  inbox(state).creatingConversation;
export const countUnreadConversations = (state, allConversations, userId) => {
  const count = Object.values(allConversations)
    .map(({ latestMessage, id }) => {
      if (!latestMessage || conversationLatestMessageIsOwn(state, id)) {
        return false;
      }

      return isConversationUnreadBy(state, id, userId);
    })
    .filter((result) => !!result).length;

  if (!count) {
    return null;
  }

  return count;
};
