import PropTypes from 'prop-types';
import React from 'react';
import { ConversationTitle } from './ConversationDescription.styled';

const ConversationDescription = ({ title, unread }) => (
  <ConversationTitle bold={unread} aria-label={title}>
    {title}
  </ConversationTitle>
);

ConversationDescription.propTypes = {
  title: PropTypes.string.isRequired,
  unread: PropTypes.bool,
};

ConversationDescription.defaultProps = {
  unread: false,
};

export default ConversationDescription;
