import { INBOX_CONVERSATION_MESSAGES } from '../../../constants/endpoints';
import request from './request';

export default async (conversationId, params = {}) => {
  const config = {
    method: 'get',
    url: INBOX_CONVERSATION_MESSAGES(conversationId),
    params,
  };
  const {
    data: { messages, count, reactions },
  } = await request(config);
  return {
    messages,
    done: messages.length >= count,
    reactions,
  };
};
