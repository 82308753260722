import PropTypes from 'prop-types';
import React from 'react';
import { Popover } from './EmojiPicker.styled';
import EmojiPickerContent from './EmojiPickerContent';

class EmojiPicker extends React.Component {
  constructor(props) {
    super(props);

    this.onSelect = this.onSelect.bind(this);
  }

  onSelect(e) {
    const { onSelect, onClose } = this.props;

    onClose();
    onSelect(e);
  }

  render() {
    const {
      id,
      show,
      anchorOrigin,
      transformOrigin,
      anchorEl,
      onClose,
      title,
    } = this.props;

    const open = show && !!anchorEl;

    return (
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        style={{ zIndex: 999999 }}
      >
        <EmojiPickerContent id={id} onSelect={this.onSelect} title={title} />
      </Popover>
    );
  }
}

export const PositionPropType = PropTypes.shape({
  vertical: PropTypes.string,
  horizontal: PropTypes.string,
});

EmojiPicker.propTypes = {
  id: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  anchorOrigin: PositionPropType,
  transformOrigin: PositionPropType,
  anchorEl: PropTypes.shape({}),
  title: PropTypes.string,
};

EmojiPicker.defaultProps = {
  show: false,
  onClose: null,
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  anchorEl: null,
  title: 'Reactions',
};

export default EmojiPicker;
