import React from 'react';
import PropTypes from 'prop-types';
import { ReactElementType } from '@app/components/propTypes/common';
import Wrapper from './TextButtonStyle';
import { ENTER } from '../../../../utils/keyCodes';

const TextButton = ({
  label,
  onClick,
  disabled,
  containerStyles,
  buttonStyles,
}) => (
  <div style={containerStyles}>
    <Wrapper
      disabled={disabled}
      role="button"
      tabIndex="0"
      style={{
        ...buttonStyles,
      }}
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.keyCode === ENTER) {
          onClick();
        }
      }}
    >
      {label}
    </Wrapper>
  </div>
);

TextButton.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, ReactElementType]).isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  containerStyles: PropTypes.shape({}),
  buttonStyles: PropTypes.shape({}),
};

TextButton.defaultProps = {
  buttonStyles: {},
  disabled: false,
  onClick: () => {},
  containerStyles: {},
};

export default TextButton;
