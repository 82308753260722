import changeSpace from './changeSpace.operation';
import closeAssignment from './closeAssignment.operation';
import createAssignment from './createAssignments.operation';
import editAssignment from './editAssignments.operation';
import fetchAssignment from './fetchAssignment.operation';
import fetchAssignments from './fetchAssignments.operation';
import fetchSubmissions from './fetchSubmissions.operation';
import fetchAllSubmissionsIncludingExtensions from './fetchAllSubmissionsIncludingExtensions.operation';
import reopenAssignment from './reopenAssignment.operation';
import search from './search.operation';
import onAssignmentDeleted from './onAssignmentDeleted.operation';
import onAssignmentSaved from './onAssignmentSaved.operation';
import onSubmissionUpdated from './onSubmissionUpdated.operation';
import deleteSubmission from './deleteSubmission.operation';
import resetErrorState from './resetErrorState.operation';
import convertAssignmentToExtension from './convertAssignmentToExtension.operation';
import toggleHideAssignment from './toggleHideAssignment.operation';

export default {
  changeSpace,
  closeAssignment,
  createAssignment,
  convertAssignmentToExtension,
  editAssignment,
  fetchAssignment,
  fetchAssignments,
  fetchSubmissions,
  fetchAllSubmissionsIncludingExtensions,
  reopenAssignment,
  search,
  onAssignmentDeleted,
  onAssignmentSaved,
  onSubmissionUpdated,
  deleteSubmission,
  resetErrorState,
  toggleHideAssignment,
};
