const fetchSubmissionsReducer = (state, action) => {
  if (action.status !== 'success') return state;

  const {
    payload: { assignmentId, submissions },
  } = action;

  return {
    ...state,
    submissions: {
      ...state.submissions,
      [assignmentId]: submissions,
    },
  };
};

export default fetchSubmissionsReducer;
