import { FETCH_USERS } from '../../constants/actions';
import fetchUsersByIds from '../../api/users/fetchUsersByIds';
import { fetchingUser } from '../../selectors/users';

export const fetchUsersStarted = (dispatch, payload) =>
  dispatch({
    type: FETCH_USERS,
    status: 'started',
    payload,
  });

export const fetchUsersError = (dispatch, payload) =>
  dispatch({
    type: FETCH_USERS,
    status: 'error',
    payload,
  });

export const fetchUsersSuccess = (dispatch, payload) =>
  dispatch({
    type: FETCH_USERS,
    status: 'success',
    payload,
  });

const fetchUsers = (userIds) => async (dispatch, getState) => {
  const state = getState();
  const missingUserIds = userIds.filter(
    (userId) => !fetchingUser(state, userId)
  );
  if (missingUserIds.length === 0) {
    return;
  }

  const payload = { requestedUsers: missingUserIds };
  fetchUsersStarted(dispatch, payload);
  try {
    const receivedUsers = await fetchUsersByIds({ userIds: missingUserIds });
    await fetchUsersSuccess(dispatch, { ...payload, users: receivedUsers });
  } catch (error) {
    fetchUsersError(dispatch, { ...payload, error });
  }
};

export const fetchUser = (userId) => async (dispatch) => {
  dispatch(fetchUsers([userId]));
};

export default fetchUsers;
