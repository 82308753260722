import PropTypes from 'prop-types';

export const User = PropTypes.shape({
  objectId: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
});

export const Message = PropTypes.shape({
  id: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  writer: PropTypes.string.isRequired,
});

export const Status = PropTypes.shape({
  text: PropTypes.string.isRequired,
  emojiName: PropTypes.string.isRequired,
});

export const GroupedMessages = PropTypes.shape({
  author: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  messages: PropTypes.arrayOf(PropTypes.string),
});

const Participation = PropTypes.shape({
  readUpTo: PropTypes.string,
});

export const Conversation = PropTypes.shape({
  id: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  isGroup: PropTypes.bool.isRequired,
  latestMessage: Message,
  title: PropTypes.string,
  icon: PropTypes.string,
  participants: PropTypes.objectOf(Participation).isRequired,
});

export const Conversations = PropTypes.arrayOf(Conversation);

export const Location = PropTypes.shape({
  pathname: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  query: PropTypes.object,
});
