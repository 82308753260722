import React from 'react';
import PropTypes from 'prop-types';
import push from '../../../../../utils/routing';

import { Container, Link, Divider } from './Footer.styled';
import {
  BUTTON_TERMS,
  BUTTON_PRIVACY,
  BUTTON_LOGOUT,
} from '../../../../../constants/texts';

const Footer = ({ className }) => (
  <Container className={className}>
    <Link
      tabIndex={0}
      href="https://www.aula.education/terms-of-service"
      target="_blank"
    >
      {BUTTON_TERMS}
    </Link>
    <Link
      tabIndex={0}
      href="https://www.aula.education/privacy"
      target="_blank"
    >
      {BUTTON_PRIVACY}
    </Link>
    <Divider />
    <Link
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === 'Enter') push('/logout');
      }}
      onClick={() => push('/logout')}
    >
      {BUTTON_LOGOUT}
    </Link>
  </Container>
);

Footer.propTypes = {
  className: PropTypes.string,
};

Footer.defaultProps = {
  className: '',
};

export default Footer;
