import toSection from './api/toSection';
import toFolder from './api/toFolder';
import { Dispatch } from 'redux';
import { Folder, Section } from '@ublend-npm/aula-schema/types/materials';
import { LiveDeletedMaterial } from './operations/liveDeletedMaterial.operation';
import { LiveUpdatedMaterial } from './operations/liveUpdatedMaterial.operation';
import { Socket } from '@ublend-npm/aula-live';

type Operations = {
  liveDeletedMaterial: LiveDeletedMaterial;
  liveUpdatedMaterial: LiveUpdatedMaterial;
};

const registerLive =
  ({ liveDeletedMaterial, liveUpdatedMaterial }: Operations) =>
  (socket: Socket) => {
    const onSectionAddedUpdated =
      (dispatch: Dispatch) =>
      ({ section, localId }: { section: Section; localId: string }) => {
        const formattedSection = toSection(section);
        const act = liveUpdatedMaterial(dispatch);
        act({ material: formattedSection, localId });
      };

    const onMaterialDeleted =
      (dispatch: Dispatch) =>
      ({ id }: { id: string }) => {
        const act = liveDeletedMaterial(dispatch);
        act({ id });
      };

    const onFolderAddedUpdated =
      (dispatch: Dispatch) =>
      ({ folder, localId }: { folder: Folder; localId: string }) => {
        const formattedFolder = toFolder(folder);
        const act = liveUpdatedMaterial(dispatch);
        act({ material: formattedFolder, localId });
      };

    return (dispatch: Dispatch) => {
      socket.on('Material:SectionAdded', onSectionAddedUpdated(dispatch));
      socket.on('Material:SectionUpdated', onSectionAddedUpdated(dispatch));
      socket.on('Material:SectionDeleted', onMaterialDeleted(dispatch));
      socket.on('Material:FolderAdded', onFolderAddedUpdated(dispatch));
      socket.on('Material:FolderUpdated', onFolderAddedUpdated(dispatch));
      socket.on('Material:FolderDeleted', onMaterialDeleted(dispatch));
    };
  };

export default registerLive;
