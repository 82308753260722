const editGroupReducer = (state, action) => {
  if (action.status !== 'success') {
    return state;
  }

  const {
    payload: { group, studentsWithNoGroup },
  } = action;

  if (!state.groups[group.id]) {
    return state;
  }

  return {
    ...state,
    groups: {
      ...state.groups,
      [group.id]: {
        ...state.groups[group.id],
        ...group,
      },
    },
    sets: {
      ...state.sets,
      [group.set]: {
        ...state.sets[group.set],
        studentsWithNoGroup,
      },
    },
  };
};

export default editGroupReducer;
