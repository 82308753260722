import { updateSpacePosts } from './updateSpacePosts';

/* eslint-disable-next-line import/prefer-default-export */
export const editPostReducer = (state, action) => {
  if (action.status !== 'success') {
    return state;
  }

  const { post } = action;
  const classRoom = state.classRooms[post.classRoom];
  const { tags, ...updatedPost } = action.post;

  return {
    ...state,
    classRooms: {
      ...state.classRooms,
      [post.classRoom]: updateSpacePosts({
        classRoom,
        post,
        posts: state.posts,
      }),
    },
    posts: {
      ...state.posts,
      [action.post.objectId]: {
        ...state.posts[action.post.objectId],
        ...updatedPost,
      },
    },
  };
};
