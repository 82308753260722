import {
  CreateFolderBody,
  CreateFolderContract,
} from '@ublend-npm/aula-schema/types/materials';
import { CLASSROOM_MATERIAL_FOLDERS } from '../../constants/endpoints';
import request, { getData } from './request';
import toFolder from './toFolder';

const createFolder = async (
  classRoomId: string,
  folder: CreateFolderBody,
  localId?: string
) => {
  const url = CLASSROOM_MATERIAL_FOLDERS(classRoomId);
  const data = await request<CreateFolderContract>({
    method: 'post',
    url,
    data: { folder, localId },
  }).then(getData);
  return toFolder(data);
};

export type CreateFolder = typeof createFolder;

export default createFolder;
