import * as actionTypes from '../../../constants/actions';
import {
  STARTED_STATUS,
  SUCCESS_STATUS,
  ERROR_STATUS,
} from '../../../constants/actionsStatus';
import { getClassRoomPost } from '../../../utils/data';
import fetchReactionsSuccess from '../../../reactions/operations/fetchReactionsSuccess.operation';
import { fetchCommentsSuccess } from '../../comment/fetch';
import fetchPostById from '../../../api/posts/fetchPostById';
import showToast from '../../../toasts/operations/showToast.operation';
import { NO_POST_ID_MESSAGE } from './constants';

const fetchSpacePostStart = () => (dispatch) => {
  dispatch({
    type: actionTypes.CLASSROOM_FETCH_POST,
    status: STARTED_STATUS,
  });
};

export const fetchSpacePostSuccess = (post) => async (dispatch) => {
  dispatch({
    type: actionTypes.CLASSROOM_FETCH_POST,
    status: SUCCESS_STATUS,
    post,
  });
};

// Don't dispatch a actionTypes.ERROR_NEW error as we explicitly handle with the toast.
const fetchSpacePostError = (error) => async (dispatch) => {
  dispatch({
    type: actionTypes.CLASSROOM_FETCH_POST,
    status: ERROR_STATUS,
    error,
  });
};

const getFetchSpacePostErrorToast = (error) => {
  if (error.response.status === 404) {
    return {
      emoji: '🙈',
      message: "Oops! The post doesn't exist",
    };
  }
  return {
    emoji: '😬',
    message: "Oops! We could't load the post",
  };
};

export const fetchSpacePost =
  (objectId, ignoreCache = false) =>
  async (dispatch, getState) => {
    if (!objectId) {
      const err = { message: NO_POST_ID_MESSAGE };
      return fetchSpacePostError(err)(dispatch);
    }

    const post = getClassRoomPost(getState(), objectId);

    if (!ignoreCache && post) {
      return new Promise((resolve) => {
        resolve(post);
      });
    }
    dispatch(fetchSpacePostStart());

    try {
      const {
        post: postFetched,
        reactions,
        comments,
      } = await fetchPostById(objectId);

      await dispatch(fetchSpacePostSuccess(postFetched));

      dispatch(fetchReactionsSuccess(reactions));
      fetchCommentsSuccess(objectId, comments, dispatch);

      return postFetched;
    } catch (error) {
      const toast = getFetchSpacePostErrorToast(error);
      dispatch(showToast(toast));
      return dispatch(fetchSpacePostError(error));
    }
  };
