import { GROUPS_OPEN_CLOSE_ADD_GROUP_SET } from '../actions';

export const openAddGroupSet = (dispatch) =>
  dispatch({
    type: GROUPS_OPEN_CLOSE_ADD_GROUP_SET,
    payload: { createGroupSetDialogOpen: true },
  });

export const closeAddGroupSet = (dispatch) =>
  dispatch({
    type: GROUPS_OPEN_CLOSE_ADD_GROUP_SET,
    payload: { createGroupSetDialogOpen: false },
  });
