import * as types from '../../constants/actions';

const initialState = {
  name: 'Aula',
  fetched: false,
  isFetching: false,
  keys: {
    parse: {
      appId: undefined,
      javascriptKey: undefined,
      serverURL: undefined,
      websocketUrl: undefined,
      live: undefined,
    },
    endpoints: {
      api: undefined,
      apiV2: undefined,
    },
  },
  features: {},
  general: {},
  user: {
    isCheckingEmail: false,
    email: '',
    institutions: [],
  },
};

const fetchInstitutionInformation = (state = initialState, action) => {
  switch (action.status) {
    case 'started':
      return { ...state, isFetching: true };
    case 'success': {
      const api = `https://api.${action.results.general.base_domain}`;
      const apiV2 = `https://apiv2.${action.results.general.base_domain}`;
      const services = `https://services.${action.results.general.base_domain}`;
      const endpoints = {
        api,
        apiV2,
        services,
      };

      return {
        ...state,
        general: action.results.general,
        name: action.results.general.display_name,
        fetched: true,
        isFetching: false,
        keys: {
          ...state.keys,
          endpoints,
          parse: action.results.parse,
          launchDarkly: action.results.launchDarkly,
        },
      };
    }
    case 'error':
      return {
        ...state,
      };

    default:
      return state;
  }
};

const checkInstitutionInformation = (state = initialState, action) => {
  switch (action.status) {
    case 'started': {
      return {
        ...state,
        user: {
          ...state.user,
          isCheckingEmail: true,
        },
      };
    }
    case 'success': {
      return {
        ...state,
        user: {
          ...state.user,
          institutions: action.results,
          isCheckingEmail: false,
          email: action.email,
        },
      };
    }
    case 'error':
      return {
        ...state,
      };

    default:
      return state;
  }
};

const institutionReducer = (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case types.FETCH_INSTITUTION:
      return fetchInstitutionInformation(state, action);
    case types.CHECK_INSTITUTION:
      return checkInstitutionInformation(state, action);
    case types.SPACE_SET_FEATURES:
      return {
        ...state,
        features: action.features,
      };
    default:
      return state;
  }
};

export default institutionReducer;
