import { INBOX_REPLY_TO_MESSAGE } from '../actions';

const replyToMessage = (messageId) => (dispatch) => {
  if (!messageId) return;

  dispatch({
    type: INBOX_REPLY_TO_MESSAGE,
    payload: {
      messageId,
    },
  });
};

export default replyToMessage;
