import big from 'big.js';
import { Material } from './types';

const getOrder = (item: Material) => big(item.order);

/**
 * Generate a value for 'order' that is higher than all existing materials.
 *
 * Should only be used for local Materials while we wait for the backend response.
 */
export const generateLocalOrder = (topLevelMaterials: Material[]): string => {
  const maxOrder = topLevelMaterials.length
    ? topLevelMaterials.reduce((prevOrder, curr) => {
        const order = getOrder(curr);
        return prevOrder > order ? prevOrder : order;
      }, big(0))
    : big(5);

  return maxOrder.plus(10).div(2).toString();
};

export const reduceById = (
  current: Record<string, Material>,
  next: Material
): Record<string, Material> => ({
  ...current,
  [next.id]: next,
});
