export default {
  menuWidth: 80, // 260,
  menuWidthCollapsed: 80,
  menuWidthOpen: 260,
  classBarWidth: 80,
  navigationBarHeight: 88,
  inboxGroupsMenuWidth: 256,
  inboxGroupsMenuWidthCollapsed: 80,
  minScreenWidth: 1200,
  inboxHeaderAndSearchWidth: 166,
  leftMenuWidth: 120,
  userBarWidth: 64,
};
