const byParentAssignmentId =
  (parentAssignmentIdToMatch) =>
  ({ parentAssignmentId }) =>
    parentAssignmentId === parentAssignmentIdToMatch;
const parentAssignmentsFilter = ({ parentAssignmentId }) => !parentAssignmentId;

const fetchAssignmentsReducer = (state, action) => {
  if (action.status !== 'success') return state;
  const { assignments, isEducator, accessingAsStaff } = action.payload;

  // For students, we will save assignments as returned by the API
  if (!isEducator && !accessingAsStaff) {
    return {
      ...state,
      assignments,
    };
  }
  // For educators, we have to make extensions a property of parent assignments

  // Filter all parent assignments
  const parentAssignments = assignments.filter(parentAssignmentsFilter);

  const aggregatedAssignments = parentAssignments.reduce(
    (allAssignments, currentParentAssignment) => {
      // filter extensions for a particular parent assignment
      const extensions = assignments.filter(
        byParentAssignmentId(currentParentAssignment.objectId)
      );

      if (extensions.length) {
        return [
          ...allAssignments,
          {
            ...currentParentAssignment,
            extensions,
          },
        ];
      }

      // no extensions
      return [...allAssignments, currentParentAssignment];
    },
    []
  );

  return {
    ...state,
    assignments: aggregatedAssignments,
  };
};

export default fetchAssignmentsReducer;
