import { INBOX_FETCH_MESSAGE } from '../actions';

const handler = (data) => (dispatch) => {
  const { conversationId, message } = data;
  dispatch({
    type: INBOX_FETCH_MESSAGE,
    status: 'success',
    payload: { conversationId, message },
  });
};

export default handler;
