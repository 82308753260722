import * as userSelectors from '../../selectors/user';
import * as api from '../api/directMessages';
import { INBOX_LEAVE_CONVERSATION } from '../actions';
import * as inboxSelectors from '../selectors';

const sendLeaveConversationStarted = (dispatch, payload) =>
  dispatch({
    type: INBOX_LEAVE_CONVERSATION,
    status: 'started',
    payload,
  });

const sendLeaveConversationSuccess = (dispatch, payload) =>
  dispatch({
    type: INBOX_LEAVE_CONVERSATION,
    status: 'success',
    payload,
  });

const sendLeaveConversationError = (dispatch, payload) =>
  dispatch({
    type: INBOX_LEAVE_CONVERSATION,
    status: 'error',
    payload,
  });

const leaveConversation = () => async (dispatch, getState) => {
  const state = getState();
  const conversationId = inboxSelectors.currentConversationId(state);
  const { objectId: userId } = userSelectors.currentUser(state);
  const payload = { conversationId };
  sendLeaveConversationStarted(dispatch, payload);
  try {
    await api.conversations.removeParticipant(conversationId, userId);
    sendLeaveConversationSuccess(dispatch, payload);
  } catch (error) {
    sendLeaveConversationError(dispatch, { ...payload, error });
  }
};

export default leaveConversation;
