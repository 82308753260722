import * as types from '../../constants/actions';

const clients = (state = {}, action) => {
  let newState;
  switch (action.type) {
    case types.LIVE_CLIENTS_REFRESHED:
      // This is a whole new list of clients, superseeding the previous one
      newState = {};
      action.clients.forEach((i) => {
        newState[i.match(/:(.*)-/)[1]] = i;
      });
      return newState;
    case types.LIVE_CLIENT_CONNECTED:
      newState = { ...state };
      newState[action.clientId] = action.clientId;
      return newState;
    case types.LIVE_CLIENT_DISCONNECTED:
      newState = { ...state };
      delete newState[action.clientId];
      return newState;
    default:
      return state;
  }
};

export default clients;
