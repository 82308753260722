import PropTypes from 'prop-types';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Tooltip, IconButton } from '@ublend-npm/aulaui-next';

import { Container, TopHalf, CloseButtonContainer } from './LeftPanel.styled';
import ProfileUpdate from './ProfileUpdate';
import Navigation from './Navigation';
import Footer from './Footer';
import { tabType } from '../Tabs';

const LeftPanel = ({
  closeModal,
  currentTab,
  onTabChange,
  tabs,
  ariaLabel,
}) => {
  return (
    <Container className="leftPanel">
      <TopHalf className="leftPanel__main">
        <Tooltip title={ariaLabel} position="bottom" spacing={4}>
          <CloseButtonContainer tabIndex={0}>
            <IconButton
              icon={CloseIcon}
              size="medium"
              label={ariaLabel}
              onClick={closeModal}
            />
          </CloseButtonContainer>
        </Tooltip>
        <ProfileUpdate closeModal={closeModal} />
        <Navigation
          className="leftPanel__navigation"
          currentTab={currentTab}
          onTabChange={onTabChange}
          tabs={tabs}
        />
      </TopHalf>
      <Footer className="leftPanel__footer" />
    </Container>
  );
};

LeftPanel.propTypes = {
  closeModal: PropTypes.func.isRequired,
  currentTab: PropTypes.number.isRequired,
  onTabChange: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(tabType).isRequired,
  ariaLabel: PropTypes.string,
};

export default React.memo(LeftPanel);
