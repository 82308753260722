import axios from 'axios';
import { getHeaders } from './utils';
import { AULA_ASSIGNMENTS } from '../../constants/endpoints';

const fetchGrades = ({ accessToken, assignmentId, spaceId }) =>
  axios.get(
    `${AULA_ASSIGNMENTS()}/grades/${spaceId}?assignments=${assignmentId}`,
    {
      withCredentials: true,
      headers: getHeaders(accessToken),
    }
  );

export default fetchGrades;
