import PropTypes from 'prop-types';
import React from 'react';
import { Modal, theme } from '@ublend-npm/aulaui-next';
import { MuiThemeProvider, createTheme } from '@material-ui/core';
import {
  Container,
  LeftContainer,
  RightContainer,
  modalPaperClass,
} from './UserProfile.styled';
import LeftPanel from './LeftPanel';
import { tabType } from './Tabs';

const getModalContainer = () =>
  document.getElementById('user-profile-container');

class UserProfile extends React.PureComponent {
  constructor(props) {
    super(props);

    this.containerRef = React.createRef();

    const { tabs, isVisibleNotificationSettingsTab } = props;

    // find the first renderable tab (tab with component)
    const currentTab = tabs.findIndex(({ component }) => !!component);

    this.state = {
      currentTab: isVisibleNotificationSettingsTab ? 1 : 0,
      isNestedModalVisible: false,
    };

    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleNestedModalToggle = this.handleNestedModalToggle.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { isVisibleNotificationSettingsTab } = this.props;
    const previousIsVisibleNotificationSettingsTab =
      prevProps.isVisibleNotificationSettingsTab;
    if (
      isVisibleNotificationSettingsTab !==
      previousIsVisibleNotificationSettingsTab
    ) {
      return this.setState({
        currentTab: isVisibleNotificationSettingsTab ? 1 : 0,
      });
    }
  }

  handleTabChange(clickedTabId) {
    const { tabs } = this.props;

    const clickedTab = tabs[clickedTabId];
    if (clickedTab.component) {
      return this.setState({
        currentTab: clickedTabId,
      });
    }

    return clickedTab.onClick();
  }

  handleNestedModalToggle(state) {
    this.setState({ isNestedModalVisible: state });
  }

  render() {
    const { currentTab, isNestedModalVisible } = this.state;
    const { tabs, shouldShowUserProfile, closeModal } = this.props;
    const Tab = tabs[currentTab].component;
    const ariaLabel = 'Closes user profile dialog';
    const { color, font } = theme;

    const aulaTheme = createTheme({
      typography: {
        fontFamily: font.family.workSans,
        useNextVariants: true,
      },
      palette: {
        primary: {
          main: color.designSystem.purple70,
        },
        text: {
          hint: color.grey11,
        },
      },
    });

    return (
      <MuiThemeProvider theme={aulaTheme}>
        <Modal
          id="user-profile-modal"
          container={getModalContainer}
          open={shouldShowUserProfile}
          onClose={closeModal}
          classes={{ paper: modalPaperClass }}
          role="dialog"
          aria-label="Edit my profile settings"
        >
          <Container
            visible={!isNestedModalVisible}
            innerRef={this.containerRef}
          >
            <LeftContainer>
              <LeftPanel
                closeModal={closeModal}
                currentTab={currentTab}
                onTabChange={this.handleTabChange}
                tabs={tabs}
                ariaLabel={ariaLabel}
              />
            </LeftContainer>
            <RightContainer>
              <Tab toggleNestedModal={this.handleNestedModalToggle} />
            </RightContainer>
          </Container>
        </Modal>
      </MuiThemeProvider>
    );
  }
}

UserProfile.propTypes = {
  closeModal: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(tabType).isRequired,
  shouldShowUserProfile: PropTypes.bool.isRequired,
};

export default UserProfile;
