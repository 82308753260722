const closeAssignment = (state, action) => {
  switch (action.status) {
    case 'started':
      return {
        ...state,
        isEditing: true,
      };
    case 'success':
      return {
        ...state,
        isEditing: false,
      };
    case 'error':
      return {
        ...state,
        isEditing: false,
      };
    default:
      return state;
  }
};

export default closeAssignment;
