import { AulaReduxAction } from '../../types/state';
import { CLASSROOM_OPEN_CLOSE_DELETE_FOLDER } from '../actions';

type OpenDeleteFolderActionFactory = (folderId: string) => AulaReduxAction;

type CloseDeleteFolderActionFactory = () => AulaReduxAction;

export const openDeleteFolder: OpenDeleteFolderActionFactory =
  (folderId) => (dispatch) =>
    dispatch({
      type: CLASSROOM_OPEN_CLOSE_DELETE_FOLDER,
      payload: { deleteFolderDialogOpenFor: folderId },
    });

export const closeDeleteFolder: CloseDeleteFolderActionFactory =
  () => (dispatch) =>
    dispatch({
      type: CLASSROOM_OPEN_CLOSE_DELETE_FOLDER,
      payload: { deleteFolderDialogOpenFor: null },
    });
