import { connect } from 'react-redux';
import ProfileUpdate from './ProfileUpdate';
import uploadAvatar from '../../../../../../core/utils/uploadAvatar';
import changeUserAvatar from '../../../../../../core/actions/user/avatar';

const mapStateToProps = (state) => ({
  user: state.user.user,
  isChangingAvatar: state.user.changingAvatar,
});

const mapDispatchToProps = (dispatch) => ({
  changeAvatar: (file) => dispatch(changeUserAvatar(uploadAvatar)(file)),
});

const ProfileUpdateContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileUpdate);

export default ProfileUpdateContainer;
