import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@ublend-npm/aulaui-next';
import InputAdornment from '@material-ui/core/InputAdornment';
import CancelIcon from '@material-ui/icons/Cancel';

import { Tabs, Tab } from '../../common/Tabs';
import EmojiUtils from '../../../../../core/utils/emoji';
import EmojiList from './EmojiList';

import Categories from './categories';
import {
  Container,
  Title,
  Content,
  SearchContainer,
  OutlinedInput,
  ShortcutContainer,
  CategoryContainer,
  CategoryTitleRow,
  CategoryTitle,
  CategoryIconContainer,
  Separator,
  NoResultsContainer,
} from './EmojiPicker.styled';

const EmojisByCategory = EmojiUtils.getByCategory();
const EmojiShortcuts = [
  'joy',
  'thinking_face',
  '+1',
  'rocket',
  'heart',
  'white_check_mark',
  'nerd_face',
].map((name) => EmojiUtils.getFromShortName(name));

class EmojiPickerContent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedCategoryIndex: 0,
      searchText: '',
      emojiResults: [],
    };

    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleClearSearch = this.handleClearSearch.bind(this);
  }

  handleCategoryChange(_event, categoryIndex) {
    this.setState({
      selectedCategoryIndex: categoryIndex,
      searchText: '',
      emojiResults: [],
    });
  }

  handleSearchChange(event) {
    const searchText = event.target.value;
    this.setState({
      searchText,
      emojiResults: searchText ? EmojiUtils.search(searchText) : [],
    });
  }

  handleClearSearch() {
    this.setState({
      searchText: '',
      emojiResults: [],
    });
  }

  renderTabs() {
    const { selectedCategoryIndex } = this.state;
    return (
      <Content>
        <Tabs
          value={selectedCategoryIndex}
          onChange={this.handleCategoryChange}
          style={{ minHeight: 0 }}
        >
          {Categories.map(({ name, Icon }) => (
            <Tab
              key={name}
              icon={<Icon />}
              aria-label={name}
              classes="showFocus"
            />
          ))}
        </Tabs>
      </Content>
    );
  }

  renderSearch() {
    const { id } = this.props;
    const { searchText } = this.state;
    const searching = !!searchText;
    return (
      <Content>
        <SearchContainer>
          <OutlinedInput
            id={`${id}-search`}
            type="text"
            placeholder="Search..."
            value={searchText}
            onChange={this.handleSearchChange}
            endAdornment={
              searching && (
                <InputAdornment position="end">
                  <IconButton
                    icon={CancelIcon}
                    type="secondary"
                    size="xSmall"
                    label="clear search"
                    onClick={this.handleClearSearch}
                    disableRipple
                  />
                </InputAdornment>
              )
            }
            labelWidth={0}
          />
        </SearchContainer>
      </Content>
    );
  }

  renderEmojis() {
    const { searchText, emojiResults, selectedCategoryIndex } = this.state;
    const { onSelect } = this.props;
    const searching = !!searchText;
    const category = Categories[selectedCategoryIndex];
    const isDefaultCategory = selectedCategoryIndex === 0;

    if (searching) {
      return emojiResults.length > 0 ? (
        <Content>
          <EmojiList emojis={emojiResults} onSelect={onSelect} />
        </Content>
      ) : (
        <NoResultsContainer>No results found</NoResultsContainer>
      );
    }

    const shortcuts =
      !searching && isDefaultCategory ? (
        <ShortcutContainer>
          <EmojiList emojis={EmojiShortcuts} onSelect={onSelect} isShortcut />
        </ShortcutContainer>
      ) : null;

    return (
      <Content>
        {shortcuts}
        <CategoryContainer>
          <CategoryTitleRow>
            <CategoryIconContainer>
              <category.Icon style={{ fontSize: 20 }} />
            </CategoryIconContainer>
            <CategoryTitle>{category.name}</CategoryTitle>
          </CategoryTitleRow>
          <EmojiList
            emojis={EmojisByCategory[category.name]}
            onSelect={onSelect}
          />
        </CategoryContainer>
      </Content>
    );
  }

  render() {
    const { title } = this.props;

    return (
      <Container>
        <Title>{title}</Title>
        {this.renderTabs()}
        <Separator />
        {this.renderSearch()}
        {this.renderEmojis()}
      </Container>
    );
  }
}

EmojiPickerContent.propTypes = {
  id: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  title: PropTypes.string,
};

EmojiPickerContent.defaultProps = {
  title: 'Reactions',
};

export default EmojiPickerContent;
