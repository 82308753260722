import {
  FETCH_ASSIGNMENTS,
  CREATE_ASSIGNMENT,
  EDIT_ASSIGNMENT,
  DELETE_ASSIGNMENT,
  CLOSE_ASSIGNMENT,
  REOPEN_ASSIGNMENT,
  FETCH_SUBMISSIONS,
  CHANGE_ASSIGNMENTS_SELECTED_SPACE,
  DELETE_SUBMISSION,
  CONVERT_ASSIGNMENT,
  FETCH_ALL_SUBMISSIONS_INCLUDING_EXTENSIONS,
  TOGGLE_HIDE_ASSIGNMENT,
} from '../actions';
import fetchAssignments from './fetchAssignments.data.reducer';
import createAssignment from './createAssignment.data.reducer';
import editAssignment from './editAssignment.data.reducer';
import convertAssignment from './convertAssignment.data.reducer';
import deleteAssignment from './deleteAssignment.data.reducer';
import closeAssignment from './closeAssignment.data.reducer';
import reopenAssignment from './reopenAssignment.data.reducer';
import fetchSubmissions from './fetchSubmissions.data.reducer';
import fetchAllSubmissionsIncludingExtensions from './fetchAllSubmissionsIncludingExtensions.data.reducer';
import changeSpace from './changeSpace.data.reducer';
import deleteSubmission from './deleteSubmission.data.reducer';
import toggleHideAssignment from './toggleHideAssignment.data.reducer';

const inititialState = {
  assignments: [],
  submissions: {},
};

const reducers = {
  [FETCH_ASSIGNMENTS]: fetchAssignments,
  [CREATE_ASSIGNMENT]: createAssignment,
  [EDIT_ASSIGNMENT]: editAssignment,
  [DELETE_ASSIGNMENT]: deleteAssignment,
  [CLOSE_ASSIGNMENT]: closeAssignment,
  [REOPEN_ASSIGNMENT]: reopenAssignment,
  [FETCH_SUBMISSIONS]: fetchSubmissions,
  [FETCH_ALL_SUBMISSIONS_INCLUDING_EXTENSIONS]:
    fetchAllSubmissionsIncludingExtensions,
  [CHANGE_ASSIGNMENTS_SELECTED_SPACE]: changeSpace,
  [DELETE_SUBMISSION]: deleteSubmission,
  [CONVERT_ASSIGNMENT]: convertAssignment,
  [TOGGLE_HIDE_ASSIGNMENT]: toggleHideAssignment,
};

const reducer = (state = inititialState, action) => {
  if (!action) {
    return state;
  }
  const r = reducers[action.type];
  return r ? r(state, action) : state;
};

export default reducer;
