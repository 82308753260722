import { INBOX_OPEN_CLOSE_VIEW_PARTICIPANTS } from '../actions';

const closeViewParticipants = () => (dispatch) =>
  dispatch({
    type: INBOX_OPEN_CLOSE_VIEW_PARTICIPANTS,
    payload: {
      addParticipantOpened: false,
      viewParticipantsOpened: false,
    },
  });

export default closeViewParticipants;
