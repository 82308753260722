import { CLASSROOM_EDIT_FOLDER } from '../actions';
import * as selectors from '../selectors';
import { patchFolder } from '../api';
import { AulaReduxAction } from '../../types/state';
import { ActionStatus } from '@core/constants/actionsStatus';
import { UpdateFolderBody } from '@ublend-npm/aula-schema';

const editFolderAction = (status: ActionStatus, payload: unknown) => ({
  type: CLASSROOM_EDIT_FOLDER,
  status,
  payload,
});

type EditFolderActionFactory = (
  folderId: string,
  patch: UpdateFolderBody
) => AulaReduxAction;

const editFolder: EditFolderActionFactory =
  (folderId, patch) => async (dispatch, getState) => {
    const state = getState();
    const oldFolder = selectors.getMaterial(state, folderId);

    if (!oldFolder?.space) {
      return;
    }

    dispatch(editFolderAction('started', { materialId: folderId }));

    try {
      const updatedFolder = await patchFolder(oldFolder.space, folderId, patch);
      dispatch(editFolderAction('success', { updatedMaterial: updatedFolder }));
    } catch (error: unknown) {
      dispatch(editFolderAction('error', { error }));
    }
  };

export default editFolder;
