import * as userSelectors from '../../selectors/user';
import { INBOX_SET_CONVERSATION_READ } from '../actions';
import * as inboxSelectors from '../selectors';

const handler = (data) => (dispatch, getState) => {
  const {
    conversation: conversationId,
    participant: participantId,
    readUpTo,
  } = data;

  const state = getState();

  const { objectId: currentUserId } = userSelectors.currentUser(state);
  if (currentUserId !== participantId) return;

  const conversation = inboxSelectors.conversation(state, conversationId);

  if (!conversation) return;

  const { participants } = conversation;
  const participation = participants[currentUserId] || {};
  const previousReadUpTo = participation.readUpTo;

  // we want to dispatch even if previousReadUpTo is undefined ( = new conversation)
  if (previousReadUpTo && new Date(readUpTo) <= new Date(previousReadUpTo))
    return;

  dispatch({
    type: INBOX_SET_CONVERSATION_READ,
    status: 'success',
    payload: {
      conversationId,
      readUpTo,
      userId: participantId,
    },
  });
};

export default handler;
