import * as actions from '../actions';
import { LOCATION_CHANGE } from '../../constants/actions';

import fetchGroupSets from './fetchGroupSets.app.reducer';
import fetchGroupSet from './fetchGroupSet.app.reducer';
import addGroupSet from './addGroupSet.app.reducer';
import editGroupSet from './editGroupSet.app.reducer';
import deleteGroupSet from './deleteGroupSet.app.reducer';
import addGroup from './addGroup.app.reducer';
import addGroups from './addGroups.app.reducer';
import editGroup from './editGroup.app.reducer';
import deleteGroup from './deleteGroup.app.reducer';
import generateGroups from './generateGroups.app.reducer';
import openCloseAddGroupSet from './openCloseAddGroupSet.app.reducer';
import openCloseEditGroupSet from './openCloseEditGroupSet.app.reducer';
import openCloseAddGroup from './openCloseAddGroup.app.reducer';
import openCloseEditGroup from './openCloseEditGroup.app.reducer';
import openCloseGenerateGroups from './openCloseGenerateGroups.app.reducer';
import searchParticipantsInGroups from './searchParticipantsInGroups.app.reducer';
import locationChanged from './locationChanged.app.reducer';
import groupsLoaded from './groupsLoaded.app.reducer';

const initialState = {
  groupSetsLoaded: false,
  groupsLoaded: false,
  createGroupSetDialogOpen: false,
  editGroupSetDialogOpenFor: null,
  createGroupDialogOpenFor: null,
  creatingGroups: false,
  editGroupDialogOpenFor: null,
  generatingGroups: false,
  generateGroupsDialogOpenFor: null,
  isSearchingForParticipantsInGroups: false,
  searchingForParticipantsInGroupsQuery: '',
  selectedGroupSetId: '',
};

const reducerMap = {
  [actions.GROUPS_FETCH_GROUP_SETS]: fetchGroupSets,
  [actions.GROUPS_FETCH_GROUP_SET]: fetchGroupSet,
  [actions.GROUPS_ADD_GROUP_SET]: addGroupSet,
  [actions.GROUPS_EDIT_GROUP_SET]: editGroupSet,
  [actions.GROUPS_DELETE_GROUP_SET]: deleteGroupSet,
  [actions.GROUPS_ADD_GROUP]: addGroup,
  [actions.GROUPS_ADD_GROUPS]: addGroups,
  [actions.GROUPS_EDIT_GROUP]: editGroup,
  [actions.GROUPS_DELETE_GROUP]: deleteGroup,
  [actions.GROUPS_GENERATE_GROUPS]: generateGroups,
  [actions.GROUPS_OPEN_CLOSE_ADD_GROUP_SET]: openCloseAddGroupSet,
  [actions.GROUPS_OPEN_CLOSE_EDIT_GROUP_SET]: openCloseEditGroupSet,
  [actions.GROUPS_OPEN_CLOSE_ADD_GROUP]: openCloseAddGroup,
  [actions.GROUPS_OPEN_CLOSE_EDIT_GROUP]: openCloseEditGroup,
  [actions.GROUPS_OPEN_CLOSE_GENERATE_GROUPS]: openCloseGenerateGroups,
  [actions.GROUPS_PARTICIPANT_SEARCH]: searchParticipantsInGroups,
  [LOCATION_CHANGE]: locationChanged,
  [actions.GROUPS_LOADED]: groupsLoaded,
};

export default (state = initialState, action) => {
  const reducer = reducerMap[action.type];
  if (typeof reducer === 'undefined') return state;
  return reducer(state, action);
};
