import { GenericAction } from '../../types/redux';
import { CLASSROOM_OPEN_CLOSE_SCHEDULE_MATERIAL } from '../actions';
import { MaterialAppReducer } from './state';

type OpenCloseScheduleMaterialAction = GenericAction<
  typeof CLASSROOM_OPEN_CLOSE_SCHEDULE_MATERIAL,
  { scheduleMaterialDialogOpenFor: string }
>;

const openCloseScheduleMaterial: MaterialAppReducer<
  OpenCloseScheduleMaterialAction
> = (state, action) => ({
  ...state,
  scheduleMaterialDialogOpenFor: action.payload.scheduleMaterialDialogOpenFor,
});

export default openCloseScheduleMaterial;
