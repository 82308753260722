import * as actionTypes from '../../constants/actions';

export const navigationBarChangeButtons = (buttons) => (dispatch) => {
  dispatch({
    type: actionTypes.NAVBAR_CHANGE_BUTTONS,
    status: 'success',
    buttons,
  });
};

export const navigationBarChangeSearchBar = (searchBar) => (dispatch) => {
  dispatch({
    type: actionTypes.NAVBAR_CHANGE_SEARCHBAR,
    status: 'success',
    searchBar,
  });
};
