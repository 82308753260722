export class FileFormatError extends Error {
  constructor(...args) {
    super(...args);
    Error.captureStackTrace(this, FileFormatError);
  }
}

export class FileFormatVersionError extends Error {
  constructor(...args) {
    super(...args);
    Error.captureStackTrace(this, FileFormatVersionError);
  }
}
