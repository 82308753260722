import { appendAndReorderMessages } from './utils';

const reorderMessages = (messageStore, messageIds) => {
  const messages = messageIds.map((id) => messageStore[id]);
  return appendAndReorderMessages(messages, []).map((m) => m.id);
};

const addMessageToConversationStore = (
  conversationStore,
  messageStore,
  conversationId,
  messageId
) => {
  const conversation = conversationStore[conversationId];
  const messages = reorderMessages(messageStore, [
    ...(conversation.messages || []),
    messageId,
  ]);
  return {
    ...conversationStore,
    [conversationId]: {
      ...conversation,
      messages,
      latestMessage: messageStore[messages[messages.length - 1]],
    },
  };
};

const replaceMessageInConversationStore = (
  conversationStore,
  messageStore,
  conversationId,
  previousMessageId,
  messageId
) => {
  const conversation = conversationStore[conversationId];
  const messages = [...conversation.messages];
  const previousIndex = messages.indexOf(previousMessageId);
  const hasPreviousMessage = previousIndex !== -1;

  if (hasPreviousMessage) {
    const newIndex = messages.indexOf(messageId);
    const hasNewMessage = newIndex !== -1;

    if (hasNewMessage) {
      messages.splice(previousIndex, 1);
    } else {
      messages[previousIndex] = messageId;
    }
  }

  const reorderedMessages = reorderMessages(messageStore, messages);

  return {
    ...conversationStore,
    [conversationId]: {
      ...conversation,
      messages: reorderedMessages,
      latestMessage:
        messageStore[reorderedMessages[reorderedMessages.length - 1]],
    },
  };
};

const addMessageToStore = (messageStore, message) => ({
  ...messageStore,
  [message.id]: message,
});

const removeItemFromStore = (messageStore, message) => {
  const updatedStore = { ...messageStore };
  delete updatedStore[message.id];
  return updatedStore;
};

const sendMessageReducer = (state, action) => {
  const { pendingMessage, message, conversation } = action.payload;

  switch (action.status) {
    case 'started': {
      const messages = addMessageToStore(state.messages, pendingMessage);
      const drafts = removeItemFromStore(state.drafts, conversation);
      return {
        ...state,
        conversations: addMessageToConversationStore(
          state.conversations,
          messages,
          conversation.id,
          pendingMessage.id
        ),
        messages,
        drafts,
      };
    }
    case 'error': {
      const messages = {
        ...state.messages,
        [pendingMessage.id]: {
          ...pendingMessage,
          failed: true,
        },
      };
      return {
        ...state,
        messages,
      };
    }
    case 'success': {
      const messages = removeItemFromStore(
        addMessageToStore(state.messages, message),
        pendingMessage
      );
      const conversations = replaceMessageInConversationStore(
        state.conversations,
        messages,
        conversation.id,
        pendingMessage.id,
        message.id
      );
      return {
        ...state,
        conversations,
        messages,
      };
    }
    default:
      return state;
  }
};

export default sendMessageReducer;
