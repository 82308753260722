import axios, { CancelToken, CancelTokenSource } from 'axios';

/**
 * This wrapper function receives another function (aka RequestFn)
 * whose only argument is a cancelToken;
 *
 * When this function is called, it creates an axios cancelToken and
 * passes it along to the RequestFn, while also saving such
 * cancelToken.
 *
 * If this function is called and there's already a saved cancelToken, it
 * will first call the cancel method of this cancelToken, which will cancel
 * any axios calls that have such cancelToken in its object.
 *
 * Therefore, it is expected that the RequestFn will pass the
 * cancelToken to axios calls that are supposed to be cancelled as soon
 * as this wrapper function is called again.
 *
 * @see: core/materials/api/getSection.ts
 */
const cancellableRequest = <T extends (...args: unknown[]) => any>(
  request: (cancelToken?: CancelToken) => T
) => {
  const cancelToken: { current: null | CancelTokenSource } = { current: null };

  const cancellableFn = (...params: Parameters<T>): ReturnType<T> => {
    if (cancelToken.current) {
      cancelToken.current.cancel('Newer request.');
    }

    cancelToken.current = axios.CancelToken.source();
    return request(cancelToken.current.token)(...params);
  };

  return { fn: cancellableFn, cancelToken };
};

export default cancellableRequest;
