import { GROUPS_DELETE_GROUP_SET } from '../actions';
import { deleteGroupSet as deleteGroupSetApi } from '../api';

import refreshAccessToken from '../../actions/accessToken/refreshAccessToken.action';
import { currentClassRoomId as getCurrentClassRoomId } from '../../selectors/classroom';
import showToast from '../../toasts/operations/showToast.operation';
import { DELETE_GROUP_SET_SUCCESS, DELETE_GROUP_SET_ERROR } from './toasts';

const deleteGroupSetSuccess = (payload) => ({
  type: GROUPS_DELETE_GROUP_SET,
  status: 'success',
  payload,
});

const deleteGroupSetError = (payload) => ({
  type: GROUPS_DELETE_GROUP_SET,
  status: 'error',
  payload,
});

const deleteGroupSet = (groupSetId) => async (dispatch, getState) => {
  const payload = { groupSetId };
  try {
    const state = getState();
    const spaceId = getCurrentClassRoomId(state);
    const accessToken = await refreshAccessToken(
      dispatch,
      getState
    )({ spaceId });
    await deleteGroupSetApi(accessToken, groupSetId);
    dispatch(deleteGroupSetSuccess(payload));
    dispatch(showToast(DELETE_GROUP_SET_SUCCESS));
  } catch (error) {
    dispatch(showToast(DELETE_GROUP_SET_ERROR));
    dispatch(deleteGroupSetError({ ...payload, error }));
  }
};

export default deleteGroupSet;
