import { connect } from 'react-redux';
import Intercom from './Intercom';
import { location } from '../../../selectors/routing';
import * as userSelectors from '../../../../core/selectors/user';
import * as institutionSelectors from '../../../../core/selectors/institution';

const mapStateToProps = (state) => {
  const {
    objectId: userId,
    firstName,
    lastName,
    createdAt,
    email,
  } = userSelectors.currentUser(state);

  return {
    userId,
    email,
    userName: `${firstName} ${lastName}`,
    created_at: new Date(createdAt).getTime(),
    open: location(state).search.includes('intercom-help'),
    institution: institutionSelectors.institutionName(state),
    role: userSelectors.getUserRole(state),
  };
};

const IntercomContainer = connect(mapStateToProps)(Intercom);

export default IntercomContainer;
