import { ImportMaterialsAction } from '../sharedActions';
import { MaterialAppReducer } from './state';

const importMaterialsAppReducer: MaterialAppReducer<ImportMaterialsAction> = (
  state,
  action
) => {
  switch (action.status) {
    case 'started':
      return {
        ...state,
        isImportingMaterials: true,
      };
    case 'success':
    case 'error':
      return {
        ...state,
        isImportingMaterials: false,
      };
    default:
      return state;
  }
};

export default importMaterialsAppReducer;
