import {
  DeletedSection as DeletedApiSection,
  Section as ApiSection,
} from '@ublend-npm/aula-schema/types/materials';
import { DeletedSection, Section } from '../types';

function toSection(data: DeletedApiSection): DeletedSection;
function toSection(data: ApiSection): Section;

function toSection(
  data: ApiSection | DeletedApiSection
): DeletedSection | Section {
  return {
    versions: [],
    ...data,
    content: data.content ? JSON.parse(data.content) : null,
  };
}

export default toSection;
