import { CLASSROOM_MODULE_GET_SECTION } from '../actions';
import { getSection as apiGetSection } from '../api';
import { AulaReduxAction } from '../../types/state';
import { getCurrentSpaceId } from '../../selectors/space';
import { ActionStatus } from '../../constants/actionsStatus';
import { Section } from '../types';
import { isLocalId } from '../../utils/localId';

const getSectionAction = (status: ActionStatus, payload?: unknown) => ({
  type: CLASSROOM_MODULE_GET_SECTION,
  status,
  payload,
});

type GetSectionActionFactory = (_: { sectionId: string }) => AulaReduxAction;

const getSection: GetSectionActionFactory =
  ({ sectionId }) =>
  async (dispatch, getState) => {
    const state = getState();
    const classRoomId = getCurrentSpaceId(state);

    dispatch(getSectionAction('started', { sectionId }));

    if (!sectionId || isLocalId(sectionId)) {
      dispatch(getSectionAction('aborted'));
      return;
    }

    try {
      const { content }: Section = await apiGetSection(classRoomId, sectionId);

      dispatch(getSectionAction('success', { content, sectionId }));
    } catch (error) {
      // This means the request was cancelled by selecting a new section.
      if (error.message !== 'Newer request.') {
        dispatch(getSectionAction('error', error));
      }
    }
  };

export default getSection;
