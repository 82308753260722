import styled from 'react-emotion';

import DialogContent from './DialogContent.styled';

export const ContentWrapper = styled.div``;

export default styled(DialogContent)`
  text-align: left;
  display: flex;
  align-items: left;
  justify-content: left;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 16px;
`;
