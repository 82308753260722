const errorMessage = 'Oops! Something went wrong.';

export const ADD_GROUP_SUCCESS = {
  emoji: '🙌',
  message: 'Success! Group created.',
};

export const ADD_GROUP_ERROR = {
  emoji: '🤔',
  message: errorMessage,
};

export const ADD_GROUPS_ERROR = {
  emoji: '🤔',
  message: errorMessage,
};

export const ADD_GROUPS_PARTIAL_SUCCESS = {
  emoji: '🤔',
  message: 'Oops! Something went wrong. Only some of the groups were created.',
};

export const ADD_GROUPS_SUCCESS = {
  emoji: '🎉',
  message: 'Success! Groups created.',
};

export const ADD_GROUP_SET_SUCCESS = {
  emoji: '🎉',
  message: 'Success! Set created.',
};

export const ADD_GROUP_SET_ERROR = {
  emoji: '🤔',
  message: errorMessage,
};

export const EDIT_GROUP_SUCCESS = {
  emoji: '🙌',
  message: 'Success! Group updated.',
};

export const EDIT_GROUP_ERROR = {
  emoji: '😅',
  message: errorMessage,
};

export const HIDE_GROUP_SET_SUCCESS = {
  emoji: '👀',
  message: 'Success! Set hidden.',
};

export const EDIT_GROUP_SET_SUCCESS = {
  emoji: '🙌',
  message: 'Success! Set updated.',
};

export const EDIT_GROUP_SET_ERROR = {
  emoji: '🙈',
  message: errorMessage,
};

export const DELETE_GROUP_SET_SUCCESS = {
  emoji: '👋',
  message: 'Success! Set deleted.',
};

export const DELETE_GROUP_SET_ERROR = {
  emoji: '😬',
  message: errorMessage,
};

export const DELETE_GROUP_SUCCESS = {
  emoji: '👋',
  message: 'Success! Group deleted.',
};

export const DELETE_GROUP_ERROR = {
  emoji: '😬',
  message: errorMessage,
};

export const GENERATE_GROUPS_SUCCESS = {
  emoji: '👏',
  message: 'Success! Groups created.',
};

export const GENERATE_GROUPS_ERROR = {
  emoji: '🤔',
  message: errorMessage,
};

export const SET_IMPORTANT_POST_SUCCESS = {
  emoji: '',
  message:
    'The post has been pinned. It will remain at the top of the feed for 72 hours after it was created.',
};

export const READ_NOTIFICATION_ERROR = {
  emoji: '🙈',
  message: 'Oops! Something went wrong. Try again later.',
};

export const CREATE_CONVERSATION_SUCCESS = {
  emoji: '🎉',
  message: 'Success! Group conversation created.',
};

export const EDIT_CONVERSATION_SUCCESS = {
  emoji: '🙌',
  message: 'Success! Group conversation updated.',
};

export const DELETE_CONVERSATION_SUCCESS = {
  emoji: '👋',
  message: 'Success! Group conversation deleted.',
};
