import * as userSelectors from '../../selectors/user';
import { INBOX_SEND_MESSAGE } from '../actions';
import dismissReplyToMessage from './dismissReplyToMessage.action';
import fetchMessages from './fetchMessages.action';
import * as inboxSelectors from '../selectors';
import { generateLocalId } from '../../utils/localId';

export const consumeMessageQueue = (queue) => () => (_dispatch, getState) => {
  queue.consume(getState);
};

const sendMessageStarted = (dispatch, payload) =>
  dispatch({
    type: INBOX_SEND_MESSAGE,
    status: 'started',
    payload,
  });

const sendMessageSuccess = (dispatch, payload) =>
  dispatch({
    type: INBOX_SEND_MESSAGE,
    status: 'success',
    payload,
  });

const sendMessageError = (dispatch, payload) =>
  dispatch({
    type: INBOX_SEND_MESSAGE,
    status: 'error',
    payload,
  });

const sendMessage =
  (queue) => (content, conversationId) => async (dispatch, getState) => {
    const state = getState();
    const { objectId: writer } = userSelectors.currentUser(state);

    const conversation = inboxSelectors.conversation(state, conversationId);
    const repliedMessageId = inboxSelectors.replyingToMessageId(state);
    const pendingMessage = {
      id: generateLocalId(),
      content: JSON.stringify(content),
      createdAt: new Date().toISOString(),
      writer,
      repliedMessage: inboxSelectors.replyingToMessage(state),
    };

    if (inboxSelectors.hasNewerMessages(state, conversationId)) {
      dispatch(fetchMessages({ conversationId }));
    }
    sendMessageStarted(dispatch, { conversation, pendingMessage });
    if (repliedMessageId) {
      dispatch(dismissReplyToMessage());
    }
    try {
      const messageData = { content, repliedMessageId };
      const message = await queue.send(getState, {
        conversationId,
        messageData,
        localId: pendingMessage.id,
      });
      sendMessageSuccess(dispatch, { message, pendingMessage, conversation });
    } catch (error) {
      sendMessageError(dispatch, { pendingMessage, conversation, error });
    }
  };

export default sendMessage;
