import * as types from '../../constants/actions';

const initialState = {
  buttons: [],
  searchBar: null,
};

const navigationBarChangeButtons = (state = initialState, action) => ({
  ...state,
  buttons: action.buttons,
});

const navigationBarChangeSearchBar = (state = initialState, action) => ({
  ...state,
  searchBar: action.searchBar,
});

const cleanNavigationBarButtons = (state = initialState) => ({
  ...state,
  buttons: [],
});

const cleanNavigationBarSearchBar = (state = initialState) => ({
  ...state,
  searchBar: null,
});

const PostsReducer = (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case types.NAVBAR_CHANGE_BUTTONS:
      return navigationBarChangeButtons(state, action);
    case types.NAVBAR_CHANGE_SEARCHBAR:
      return navigationBarChangeSearchBar(state, action);
    case '@@router/LOCATION_CHANGE': {
      let newState = {};
      if (action.payload.pathname.split('/')[3] !== 'search') {
        newState = {
          ...cleanNavigationBarSearchBar(state),
        };
      }
      newState = {
        ...cleanNavigationBarButtons(state),
      };

      return newState;
    }
    default:
      return state;
  }
};

export default PostsReducer;
