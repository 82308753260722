import { isCancel } from 'axios';
import { fetchAssignments as fetchAssignmentsApi } from '../api';
import { FETCH_ASSIGNMENTS } from '../actions';
import {
  isInstructorInSpace,
  accessingCurrentSpaceAsStaff,
} from '../../selectors/classroom';
import { analyticsTrackEvent } from '../../utils/analytics';
import * as analyticEvents from '../../constants/analytics';

const fetchAssignmentsStarted = (dispatch, payload) =>
  dispatch({
    type: FETCH_ASSIGNMENTS,
    status: 'started',
    payload,
  });

const fetchAssignmentsSuccess = (dispatch, payload) =>
  dispatch({
    type: FETCH_ASSIGNMENTS,
    status: 'success',
    payload,
  });

const fetchAssignmentsError = (dispatch, payload) =>
  dispatch({
    type: FETCH_ASSIGNMENTS,
    status: 'error',
    payload,
  });

const fetchAssignments =
  (dispatch, getState) =>
  async ({ user, accessToken, spaceId }) => {
    if (!user || !accessToken || !spaceId) {
      fetchAssignmentsError(dispatch, { spaceId });
      return;
    }

    fetchAssignmentsStarted(dispatch, { spaceId });

    try {
      const { data = {} } = await fetchAssignmentsApi({
        accessToken,
        spaceId,
      });

      const { assignments = [] } = data;

      analyticsTrackEvent(analyticEvents.FETCH_ASSIGNMENTS, {
        activeClassroom: spaceId,
      });
      const isEducator = isInstructorInSpace(getState())(
        user.objectId,
        spaceId
      );
      const accessingAsStaff = accessingCurrentSpaceAsStaff(getState());

      fetchAssignmentsSuccess(dispatch, {
        assignments,
        spaceId,
        isEducator,
        accessingAsStaff,
      });
    } catch (e) {
      if (!isCancel(e)) {
        fetchAssignmentsError(dispatch, { spaceId });
      }
    }
  };

export default fetchAssignments;
