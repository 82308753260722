import * as api from '../api/directMessages';
import { INBOX_FETCH_CONVERSATION } from '../actions';

const fetchConversationStarted = (dispatch, payload) =>
  dispatch({
    type: INBOX_FETCH_CONVERSATION,
    status: 'started',
    payload,
  });

const fetchConversationSuccess = (dispatch, payload) =>
  dispatch({
    type: INBOX_FETCH_CONVERSATION,
    status: 'success',
    payload,
  });

const fetchConversationError = (dispatch, payload) =>
  dispatch({
    type: INBOX_FETCH_CONVERSATION,
    status: 'error',
    payload,
  });

const fetchConversation = (conversationId) => async (dispatch) => {
  const payload = { conversationId };
  fetchConversationStarted(dispatch, payload);
  try {
    const conversation = await api.conversations.fetchConversation(
      conversationId
    );
    fetchConversationSuccess(dispatch, { ...payload, conversation });
  } catch (error) {
    fetchConversationError(dispatch, { ...payload, error });
  }
};

export default fetchConversation;
