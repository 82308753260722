import { CreateSectionAction } from '../sharedActions';
import { MaterialDataReducer } from './state';

const createSectionDataReducer: MaterialDataReducer<CreateSectionAction> = (
  state,
  action
) => {
  const { section } = action.payload;
  return {
    ...state,
    [section.id]: section,
  };
};

export default createSectionDataReducer;
