import { StatusAction } from '../../types/redux';
import { CLASSROOM_MODULE_REORDER_MATERIAL } from '../actions';
import { Material } from '../types';
import { MaterialDataReducer } from './state';

type StartedReorderMaterialAction = StatusAction<
  typeof CLASSROOM_MODULE_REORDER_MATERIAL,
  'started',
  {
    materialId: string;
    patch: { order: string };
  }
>;
type ErrorReorderMaterialAction = StatusAction<
  typeof CLASSROOM_MODULE_REORDER_MATERIAL,
  'error',
  {
    materialId: string;
    oldMaterial: Material;
  }
>;
type SuccessReorderMaterialAction = StatusAction<
  typeof CLASSROOM_MODULE_REORDER_MATERIAL,
  'success',
  {
    materialId: string;
    updatedMaterial: Material;
  }
>;

export type ReorderMaterialAction =
  | StartedReorderMaterialAction
  | SuccessReorderMaterialAction
  | ErrorReorderMaterialAction;

const reorderMaterialReducer: MaterialDataReducer<ReorderMaterialAction> = (
  state,
  action
) => {
  switch (action.status) {
    case 'started': {
      const { materialId, patch } = action.payload;

      return {
        ...state,
        [materialId]: {
          ...state[materialId],
          ...patch,
        },
      };
    }
    case 'success': {
      const { materialId, updatedMaterial } = action.payload;

      return {
        ...state,
        [materialId]: updatedMaterial,
      };
    }
    case 'error':
    default: {
      const { materialId, oldMaterial } = action.payload;
      return {
        ...state,
        [materialId]: oldMaterial,
      };
    }
  }
};

export default reorderMaterialReducer;
