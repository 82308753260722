import { AulaReduxAction } from '../../types/state';
import { CLASSROOM_MODULE_LEAVE_WITHOUT_SAVING } from '../actions';
import fetchSections from './fetchSections.operation';
import { getCurrentSpaceId } from '../../selectors/space';

type LeaveWithoutSavingActionFactory = (
  destinationPath: string,
  keepEditing: boolean | null
) => AulaReduxAction;

const leaveWithoutSaving: LeaveWithoutSavingActionFactory =
  (destinationPath, keepEditing) => (dispatch, getState) => {
    dispatch({
      type: CLASSROOM_MODULE_LEAVE_WITHOUT_SAVING,
      payload: {
        destinationPath,
        keepEditing,
      },
    });
    if (keepEditing === false) {
      dispatch(fetchSections(getCurrentSpaceId(getState())));
    }
  };

export default leaveWithoutSaving;
