import { UpdateMaterialProgressAction } from '../sharedActions';
import { MaterialDataReducer } from './state';

const updateMaterialProgressDataReducer: MaterialDataReducer<
  UpdateMaterialProgressAction
> = (state, action) => {
  if (action.status !== 'success') {
    return state;
  }

  const { materialId, updatedProgress } = action.payload;

  return {
    ...state,
    [materialId]: {
      ...state[materialId],
      userProgress: updatedProgress,
    },
  };
};

export default updateMaterialProgressDataReducer;
