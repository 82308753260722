const expectedStatuses = ['started', 'error', 'success'];

const asKeysWithValue = (value) => (acc, next) => {
  acc[next] = value;
  return acc;
};

const fetchUsers = (state, action) => {
  if (!expectedStatuses.includes(action.status)) return state;

  const {
    status,
    payload: { requestedUsers },
  } = action;
  if (!requestedUsers.length) return state;

  const reducer = asKeysWithValue(status === 'started');
  return {
    ...state,

    /* setting fetchingUsers[id] to false when done instead of deleting allows
     * us to know later on if we already attempted to fetch the user
     * (it will be false) or not (it will be undefined) */
    fetchingUsers: requestedUsers.reduce(reducer, state.fetchingUsers),
  };
};

export default fetchUsers;
