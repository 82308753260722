import axios from 'axios';
import { SEARCH } from '../../constants/endpoints';
import cancellableRequest from '../cancellableRequest';

const getUsers =
  (cancelToken) =>
  async ({ query, spaceId, size = 1000, includeEducatorInSpaces }) => {
    if (!query) {
      return [];
    }

    let requestUrl = `${SEARCH()}/v2/users/${query.toLowerCase()}?size=${size}${
      spaceId ? `&spaceId=${spaceId}` : ''
    }`;
    if (includeEducatorInSpaces) {
      requestUrl += '&includeEducatorInSpaces=true';
    }

    const {
      data: { users },
    } = await axios.get(requestUrl, {
      withCredentials: true,
      cancelToken,
    });
    return users;
  };

export const usersSearch = cancellableRequest(getUsers).fn;

const getSearch =
  (cancelToken) =>
  ({ type, query, skip, pageSize, spaceId }) =>
    axios.get(
      `${SEARCH()}/${type}/${query.toLowerCase()}?size=${pageSize}&skip=${skip}${
        spaceId ? `&spaceId=${spaceId}` : ''
      }`,
      {
        withCredentials: true,
        cancelToken,
      }
    );

export const omniSearch = cancellableRequest(getSearch).fn;
